import React from "react";
import DropdownInput from "./InputProps/DropdownInputProps";

interface DropdownInputComponentProps {
  label: string;
  value: string;
  options: string[];
  onChange: (value: string) => void;
}

const DropdownInputComponent: React.FC<DropdownInputComponentProps> = ({ label, value, options, onChange }) => (
  <div className="flex max-sm:flex-col w-full justify-between gap-4">
    <div className="text-[14px] font-[400] text-gray-900 dark:text-gray-200 flex justify-center items-center">
      {label}
    </div>
    <div className="flex w-full sm:w-1/2 items-center justify-center">
      <DropdownInput value={value} options={options} onChange={onChange} />
    </div>
  </div>
);

export default DropdownInputComponent;