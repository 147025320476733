import React from 'react';

interface TooltipProps {
  content: string;
  isVisible: boolean;
  position: 'top' | 'bottom';
}

const Tooltip: React.FC<TooltipProps> = ({ content, isVisible, position }) => {
  if (!isVisible) return null;

  const positionClass = position === 'top' ? 'top-5' : 'bottom-5';

  return (
    <div
      className={`z-10 absolute ${positionClass} overflow-y-auto max-h-32 left-48 max-sm:left-1/2 w-3/4 textpx2 transform -translate-x-1/2 p-2 bg-sky-50 text-blue-950 border border-blue-200 font-[500] dark:border-none dark:bg-zinc-900 dark:text-white rounded-sm shadow-xl selective-scrollbar`}
    >
      {content}
    </div>
  );
};

export default Tooltip;
