// export const CardAuthorBox2Main = "nc-CardAuthorBox2 flex flex-col overflow-hidden bg-white dark:bg-zinc-900 rounded-3xl"
export const CardAuthorBox2Main ="nc-CardAuthorBox2 flex flex-col overflow-hidden bg-white dark:bg-zinc-900 rounded-3xl h-[17rem] md:h-72 lg:h-[19rem] min-h-[15rem] max-h-[24rem]";
export const CardAuthorBox2TopSection = "relative flex-shrink-0 ";
export const CardAuthorBox2TopImgSection ="flex aspect-w-7 aspect-h-5 w-full h-0 ";
export const CardAuthorBox2DownSection = "-mt-8 m-8 text-center";
export const CardAuthorBox2DownImgProp = "ring-2 ring-white";
export const CardAuthorBox2DownTitleSection = "mt-3";
export const CardAuthorBox2DownTitle = "text-base font-medium";
export const CardAuthorBox2DownTitleSpan = "line-clamp-1";
export const CardAuthorBox2DownTitleDesc ="block mt-1 text-sm text-gray-500 dark:text-gray-400";
