import { Dispatch } from "redux";
import { readFromDB } from "./indexDBUtils";
import { updateCommunicationAddress, updateKycDetails, updateNomineeDetails, updateOwnerDetails, updatePreviousInsurer, updateVehicleDetails } from "../reducers/slices/carBikeProposalSlice";
import { FormState } from "../reducers/slices/carBikeProposalSlice";  // Ensure you import the correct type for formState

export const fetchProposalMotorData = async (dispatch: Dispatch, formState: FormState) => {
  const OwnerDetails = (await readFromDB("motor-proposal-OwnerDetails")) || formState.ownerDetails;
  const NomineeDetails = (await readFromDB("motor-proposal-NomineeDetails")) || formState.nomineeDetails;
  const CommunicationAddress = (await readFromDB("motor-proposal-CommunicationAddress")) || formState.communicationAddress;
  const VehicleDetailsData = (await readFromDB("motor-proposal-VehicleDetailsData")) || formState.vehicleDetails;
  const PreviousInsurerData = (await readFromDB("motor-proposal-PreviousInsurerData")) || formState.previousInsurer;
  const kycDetailsData = (await readFromDB("motor-proposal-kycDetailsData")) || formState.kycDetails;

  dispatch(updateOwnerDetails(OwnerDetails));
  dispatch(updateNomineeDetails(NomineeDetails));
  dispatch(updateCommunicationAddress(CommunicationAddress));
  dispatch(updateVehicleDetails(VehicleDetailsData));
  dispatch(updatePreviousInsurer(PreviousInsurerData));
  dispatch(updateKycDetails(kycDetailsData));
};