import React from "react";
import ProposalOuterLayout from "../ProposalOuterLayout";
// import Layout from "../Layout";
import { useNavigate } from "react-router-dom";
import ButtonPrimary from "../../Button/ButtonPrimary";
import Heading from "../../Heading/Heading";
import { healthProposalPageData } from "../../../data/Proposal/healthProposal/healthProposalData";
import Label from "../../Label/Label";
import Input from "../../Input/Input";
import Select from "../../Select/Select";

const ProposerDetails: React.FC = () => {
  const navigate = useNavigate();

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    navigate("/proposal/health-nominee");
  };
  return (
    <div>
      <ProposalOuterLayout>
        {/* <Layout> */}
          <span className={`lg:hidden md:block  `}>
            <Heading desc="">
              {healthProposalPageData.ProposerDetailsData.title1}
            </Heading>
          </span>
          <form
            className="grid md:grid-cols-5 gap-6"
            onSubmit={handleSubmit}
            method="post"
          >
            <label className="block ">
              <Label>{healthProposalPageData.ProposerDetailsData.label1}</Label>

              <Input type="text" className="mt-1" />
            </label>
            <label className="block md:col-span-2">
              <Label>{healthProposalPageData.ProposerDetailsData.label2}</Label>

              <Input type="text" className="mt-1" />
            </label>

            <label className="block md:col-span-2">
              <Label>{healthProposalPageData.ProposerDetailsData.label4}</Label>

              <Input type="text" className="mt-1" />
            </label>
            <label className="block ">
              <Label>{healthProposalPageData.ProposerDetailsData.label6}</Label>

              <Input type="number" className="mt-1" />
            </label>
            <label className="block md:col-span-2">
              <Label>{healthProposalPageData.ProposerDetailsData.label5}</Label>

              <Input type="text" className="mt-1" />
            </label>
            <label className="block md:col-span-2">
              <Label>{healthProposalPageData.ProposerDetailsData.label9}</Label>

              <Input type="email" className="mt-1" />
            </label>
            <label className="block ">
              <Label>
                {healthProposalPageData.ProposerDetailsData.label12}
              </Label>

              <Input type="number" className="mt-1" />
            </label>
            <label className="block md:col-span-2">
              <Label>{healthProposalPageData.ProposerDetailsData.label8}</Label>

              <Select className="mt-1">
                <option value="-1"></option>
                {healthProposalPageData.ProposerDetailsData.Gender.map(
                  (insurer, index) => (
                    <option key={index} value={insurer}>
                      {insurer}
                    </option>
                  )
                )}
              </Select>
            </label>
            <label className="block md:col-span-2">
              <Label>{healthProposalPageData.ProposerDetailsData.label7}</Label>

              <Input type="date" className="mt-1" />
            </label>
            <label className="block ">
              <Label>
                {healthProposalPageData.ProposerDetailsData.label11}
              </Label>

              <Input type="number" className="mt-1" />
            </label>

            <label className="block md:col-span-2">
              <Label>
                {healthProposalPageData.ProposerDetailsData.label10}
              </Label>
              <Select className="mt-1">
                <option value="-1"></option>
                {healthProposalPageData.ProposerDetailsData.MaritalStatus.map(
                  (status, index) => (
                    <option key={index} value={status}>
                      {status}
                    </option>
                  )
                )}
              </Select>
            </label>
            <label className="block md:col-span-2">
              <Label>
                {healthProposalPageData.ProposerDetailsData.label13}
              </Label>
              <Select className="mt-1">
                <option value="-1"></option>
                {healthProposalPageData.ProposerDetailsData.Occupation.map(
                  (Occupation, index) => (
                    <option key={index} value={Occupation}>
                      {Occupation}
                    </option>
                  )
                )}
              </Select>
            </label>

            <label className="block md:col-span-5">
              <Label>
                {healthProposalPageData.ProposerDetailsData.label16}
              </Label>

              <Input type="text" className="mt-1" />
            </label>
            <label className="block md:col-span-5">
              <Label>
                {healthProposalPageData.ProposerDetailsData.label17}
              </Label>

              <Input type="text" className="mt-1" />
            </label>
            <label className="block md:col-span-5">
              <Label>
                {healthProposalPageData.ProposerDetailsData.label18}
              </Label>

              <Input type="text" className="mt-1" />
            </label>
            <label className="block md:col-span-5">
              <Label>
                {healthProposalPageData.ProposerDetailsData.label19}
              </Label>

              <Input type="text" className="mt-1" />
            </label>

            <ButtonPrimary className="md:col-span-5">
              {healthProposalPageData.ProposerDetailsData.button1}
            </ButtonPrimary>
          </form>
        {/* </Layout> */}
      </ProposalOuterLayout>
    </div>
  );
};

export default ProposerDetails;
