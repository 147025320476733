import { FC } from "react";
import MenuBar from "../MenuBar/MenuBar";
import Logo from "../Logo/Logo";
import Navigation from "../Navigation/Navigation";
import SearchModal from "./SearchModal";
import NotifyDropdown from "./NotifyDropdown";
import SwitchDarkMode from "../SwitchDarkMode/SwitchDarkMode";
import { container, lastSectionDiv, logoDiv, mainNav2Logged, menuBarDiv, navigationDiv, renderContentMainDiv } from "../styleComponents/headerStyles/mainNav2LoggedStyle";
import LogOut from "../LogOut/LogOut";

export interface MainNav2LoggedProps {}

const MainNav2Logged: FC<MainNav2LoggedProps> = () => {
  const renderContent = () => {
    return (
      <div className={renderContentMainDiv}>
        <div className={menuBarDiv}>
          <MenuBar />
        </div>

        <div className={logoDiv}>
          <Logo img="https://i.postimg.cc/pXL47bcL/p4usLogo.png"/>
        </div>

        <div className={navigationDiv}>
          <Navigation />
        </div>

        <div className={lastSectionDiv}>
          <SearchModal />
          <div className="hidden sm:block"><SwitchDarkMode /></div>
          <NotifyDropdown />
          <div className="hidden sm:block"><LogOut /></div>
        </div>
      </div>
    );
  };

  return (
    <div className={mainNav2Logged}>
      <div className={container}>{renderContent()}</div>
    </div>
  );
};

export default MainNav2Logged;
