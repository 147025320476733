import { useEffect, useState } from "react";
import HeaderComponent from "./HeaderComponent/HeaderComponent";
import MainCardComponent from "./MainCardComponent";
import CommonPopupForSelection from "../../components/CommonDesignForSelection/CommonPopupForSelection";
import { readFromDB, writeToDB } from "../../utils/indexDBUtils";
import { RootState } from "../../reducers/store/store";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setId, setWhichTab } from "../../reducers/slices/commonPremiumSlice";
import { useDispatch } from "react-redux";
import { fetchSpecialDetailsIndexData } from "../../utils/fetchMotorSpecialDetails";
import { calculateMinMaxIDV } from "./PremiumFunctions/calculateIDV";
import { fetchSSEData } from "./PremiumFunctions/fetchSSEData";
import { updateIndexedDB } from "./PremiumFunctions/updateIndexedDB";

interface HeaderData {
  [key: string]: any;
}

interface MainCardData {
  [key: string]: any;
}

interface UpdatedValues {
  [key: string]: any;
}

const Premium = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { dbUpdateTrigger } = useSelector(
    (state: RootState) => state.commonPremiumData
  );
  const { prevCompreh, prevTP, newTP } = useSelector(
    (state: RootState) => state.motorDetails
  );
  const [whichTab, setWhichTabState] = useState("");
  const [uniqueId, setUniqueId ]= useState("");
  const logoMapping: Record<string, string> = {
    logo1: "https://i.postimg.cc/MZNzT2zs/Bajaj.jpg",
    logo2: "https://i.postimg.cc/PqXjcdMq/GoDigit.jpg",
    logo3: "https://i.postimg.cc/9Mzjm1K5/Iffco-Toko.jpg",
    logo4: "https://i.postimg.cc/J0kVJ0Rp/National.jpg",
    logo5: "https://i.postimg.cc/CxN1dPPz/Reliance.jpg",
    logo6: "https://i.postimg.cc/Pq5qDW9K/Royal.jpg",
    logo7: "https://i.postimg.cc/nh0Fnsrm/Shriram.jpg",
  };

  const [headerData, setHeaderData] = useState<HeaderData>({});
  const [mainCardData, setMainCardData] = useState<MainCardData[]>([]);
  const [sendingValues, setSendingValues] = useState<UpdatedValues>({});
    //  const url =`https://core.insurancepolicy4us.com/api/id=${uniqueId}/car/premiumData`;
  const token = `Bearer ${import.meta.env.VITE_API_TOKEN}` as string;

  const formatDate = (date: Date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  
  useEffect(() => {
    const fetchWhichTab = async () => {
      const tab = await readFromDB("whichTab");
      const id = await readFromDB("uniqueUserId");
      if (tab && tab !== "" && id && id !=="") {
        dispatch(setWhichTab(tab));
        dispatch(setId(id));
        setWhichTabState(tab);
        setUniqueId(id);
        if (tab === "Bike" || tab === "Car") {
          fetchSpecialDetailsIndexData(dispatch);
        }
      } else {
        navigate("/");
      }
    };
    fetchWhichTab();    
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const fetchIndexDB = async () => {
      const storedData = await readFromDB("PremiumData");
      if (storedData) {
        const [{ headerData, updatedValues }] = storedData;

        const updatedHeaderData = { ...headerData };
        if (updatedHeaderData?.editIcon1?.label3Value) {
          updatedHeaderData.editIcon1.label3Value = "";
        }
         // Get today's date and tomorrow's date
      const today = new Date();
      const tomorrow = new Date(today);
      tomorrow.setDate(today.getDate() + 1);

      // Update policyStartDate and prevPolicyEndDate based on userSelectedType
      if (updatedValues.policyStartDate || updatedValues.prevPolicyEndDate) {
        updatedValues.policyStartDate = whichTab === "New"
          ? formatDate(today) // Today's date for 'New'
          : formatDate(tomorrow); // Tomorrow's date for non-'New'
        
        updatedValues.prevPolicyEndDate = formatDate(today); // Always today's date
      }
        setHeaderData(updatedHeaderData);
        setSendingValues(updatedValues);
        console.log("sendlinggggggg", updatedValues);

      } else {
        navigate("/");
      }
    };

    if (whichTab) {
      fetchIndexDB();
    }
  }, [whichTab, dbUpdateTrigger]);

  useEffect(() => {
    if (
      (whichTab.toLowerCase().includes("bike") ||
        whichTab.toLowerCase().includes("car")) &&
      !prevTP &&
      !newTP &&
      mainCardData.length > 0
    ) {
      const hasIDVMinMax = mainCardData.every((cardData) => {
        return cardData.hasOwnProperty("IDV");
      });

      if (hasIDVMinMax) {
        const { minIDV, maxIDV } = calculateMinMaxIDV(mainCardData);

        // Function to update IndexedDB and state
        const updateButton2Data = async () => {
          const storedData = await readFromDB("PremiumData");
          if (storedData) {
            const [{ headerData, updatedValues }] = storedData;

            const selectedRangeValue =
            headerData.button2.selectedRange !== null &&
            headerData.button2.selectedRange


            const updatedButton2Data = {
              minValue: minIDV,
              maxValue: maxIDV,
              selectedRange: selectedRangeValue,
            };

            const updatedHeaderData = {
              ...headerData,
              button2: {
                ...headerData.button2,
                ...updatedButton2Data,
              },
            };

            const updatedData = [
              { headerData: updatedHeaderData, updatedValues },
            ];
            await writeToDB("PremiumData", updatedData);

            setHeaderData(updatedHeaderData);
          } else {
            console.error("No data found in IndexedDB.");
          }
        };

        updateButton2Data();
      }
    }
  }, [mainCardData, whichTab, prevTP, newTP]);

  // useEffect(() => {
  //   fetchSSEData(
  //     sendingValues,
  //     url,
  //     token,
  //     setMainCardData,
  //     dispatch,
  //     logoMapping
  //   );
  // }, [sendingValues]);

  useEffect(() => {
    if (whichTab && sendingValues.policyType) {
      const lowerCaseTab = whichTab.toLowerCase();
      const lowerCasePolicyType = sendingValues.policyType.toLowerCase();
  
      const url = `https://aim-core.insurancepolicy4us.com/dummy/id=${uniqueId}/generatequote/${lowerCaseTab}/${lowerCasePolicyType}`;
      const uniqueKey = "premium";
      
      console.log("Dynamic URL:", url);
  
      // Call fetchSSEData with the dynamic URL
      fetchSSEData(
        sendingValues,
        url,
        token,
        uniqueKey,
        setMainCardData,
        dispatch,
        logoMapping
      );
    }
  }, [sendingValues, whichTab, uniqueId]);

  const updateCombinedData = async (
    headerUpdates: Partial<HeaderData> = {},
    sendingUpdates: Partial<UpdatedValues> = {}
  ) => {
    await updateIndexedDB(
      headerUpdates,
      sendingUpdates,
      setHeaderData,
      setSendingValues
    );
  };
  console.log("header", headerData);
  console.log("sendingvalue", sendingValues);
  console.log("backend", mainCardData);

  return (
    <div className="w-full h-full bg-[#f8f8f8] dark:bg-zinc-950/95">
      <CommonPopupForSelection />
      {headerData && (
        <HeaderComponent
          headerData={headerData}
          updateCombinedData={updateCombinedData}
          prevCompreh={prevCompreh}
          prevTP={prevTP}
          newTP={newTP}
          whichTab={whichTab}
          mainCardData={mainCardData}
        />
      )}
      <div className="container py-6 pb-16 pt-5 lg:pb-20 lg:pt-[20px] space-y-2">
        {mainCardData.length > 0 && (
          <div className="flex  items-center justify-end">
            <p className="text-neutral-400 text-sm max-sm:text-xs dark:text-zinc-600">
              {headerData.subHeading}
            </p>
          </div>
        )}
        {mainCardData && (
          <MainCardComponent
            mainCardData={mainCardData}
            headerData={headerData}
            sendingValues={sendingValues}
          />
        )}
      </div>
    </div>
  );
};
export default Premium;
