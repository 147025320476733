import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../reducers/store/store";
import {
  setCurrentStep,
  setSelectedFuel,
  setFuelCode,
  setSelectedSubModel,
  setSubModelCode,
  setSelectedCode,
  setRtoCode,
  setSelectedYear,
  setVechicalCode,
  setSelectedCC,
  setCcCode,
  setSendingRto,
  setSelectedRegDate,
} from "../../../reducers/slices/MotorSlice";
import { fetchMotorVariants } from "../../../data/Premium/MotorData/api";
import CommonRadioInput from "../../CommonDesignForSelection/CommonRadioInput";

interface ModelSelectionProps {
  collectionName: string;
  fuels: any[];
  setVarients: React.Dispatch<React.SetStateAction<any[]>>;
}

const FuelSelection: React.FC<ModelSelectionProps> = ({
  collectionName,
  fuels,
  setVarients,
}) => {
  const dispatch = useDispatch();
  const { companyCode, modelCode, fuelCode } =
    useSelector((state: RootState) => state.motorDetails);

  const handleFuelSelection = async (name: string, code: string) => {
    try {
      if (code !== fuelCode) {
        dispatch(setSelectedFuel(name));
        dispatch(setFuelCode(code));
        dispatch(setSelectedSubModel(""));
        dispatch(setSubModelCode(""));
        dispatch(setSelectedCC(""));
        dispatch(setCcCode(""));
        dispatch(setVechicalCode(""));
        dispatch(setSelectedCode(""));
        dispatch(setRtoCode(""));
        dispatch(setSendingRto(""));
        dispatch(setSelectedYear(""));
        dispatch(setSelectedRegDate(""));
        dispatch(setCurrentStep(3));
        setVarients([]);
        const data = await fetchMotorVariants(
          collectionName,
          companyCode,
          modelCode,
          code
        );
        setVarients(data);
      } else {
        dispatch(setSelectedFuel(name));
        dispatch(setFuelCode(code));
        dispatch(setCurrentStep(3));
        setVarients([]);
        const data = await fetchMotorVariants(
          collectionName,
          companyCode,
          modelCode,
          code
        );
        setVarients(data);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <>
      <CommonRadioInput
        heading="What is the fuel type?"
        options={fuels}
        selectedOption={fuelCode}
        handleSelect={handleFuelSelection}
      />
    </>
  );
};

export default FuelSelection;
