import React from "react";

export const handleDropdownChange = (
  value: string,
  setDropdown2: React.Dispatch<React.SetStateAction<string>>,
  setNewValue: React.Dispatch<React.SetStateAction<string>>
) => {
  const selectingValue = value;
  setDropdown2(selectingValue);

  switch (selectingValue) {
    case "0%":
      setNewValue("20%");
      break;
    case "20%":
      setNewValue("25%");
      break;
    case "25%":
      setNewValue("35%");
      break;
    case "35%":
      setNewValue("45%");
      break;
    case "45%":
      setNewValue("50%");
      break;
    case "50%":
      setNewValue("50%"); // If previous NCB is 50, new NCB remains 50
      break;
    default:
      setNewValue("");
  }
};
