import React, { useEffect, useState } from "react";
import ButtonPrimary from "../Button/ButtonPrimary";
import { readFromDB } from "../../utils/indexDBUtils";

interface ProposalData {
  [key: string]: any;
}

const RoyalSundaramPayment: React.FC<ProposalData> = ({proposalData}) => {

  const [details, setDetails] = useState<any>({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async() => {
      const ownerDetails = await readFromDB("motor-proposal-OwnerDetails")
      setDetails(ownerDetails)
    }
    fetchData()
  },[])

  const handleSubmit = () => {
    // Set loading to true when form is submitted
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 8000);
  };
 
  // console.log("proposalData",proposalData);
  // console.log("details",details);
  // console.log("proposalData[0].TotalPremium",proposalData[0].TotalPremium);
  // console.log("proposalData[0].QuoteID",proposalData[0].QuoteID);
  // console.log("details.firstName",details.firstName);
  // console.log("details.emailId",details.emailId);
  // console.log("details.mobileNo",details.mobileNo);

  return (
    <>
          <form
            id="PostForm"
            name="PostForm"
             autoComplete="off"
            action="https://www.royalsundaram.net/web/test/paymentgateway"
            method="post"
            target="_blank"
            onSubmit={handleSubmit}
          >
            <input type="hidden" name="reqType" id="reqType" value="JSON" />
            <input type="hidden" name="process" value="paymentOption" />
            <input
              type="hidden"
              name="apikey"
              id="apikey"
              value="310ZQmv/bYJMYrWQ1iYa7s43084="
            />
            <input type="hidden" name="agentId" id="agentId" value="BA506786" />
            {/* <input type="hidden" name="premium" value="35374.04" /> */}
            <input type="hidden" name="premium" value={proposalData[0].TotalPremium} />
            <input
              type="hidden"
              name="quoteId"
              id="quoteId"
              // value="QVPC1275986"
              value={proposalData[0].QuoteID}
            />
            <input type="hidden" name="version_no" id="version_no" value="V2" />
            <input
              type="hidden"
              name="strFirstName"
              id="strFirstName"
              value={details.firstName}
            />
            <input
              type="hidden"
              name="strEmail"
              id="strEmail"
              value={details.emailId}
            />
            <input
              type="hidden"
              name="strMobileNo"
              id="strMobileNo"
              value={details.mobileNo}
            />
            <input
              type="hidden"
              name="isQuickRenew"
              id="isQuickRenew"
              value="No"
            />
            <input
              type="hidden"
              name="returnUrl"
              id="returnUrl"
              value="https://www.insurancepolicy4us.com/payment"
            />
           
            <input
              type="hidden"
              value="privatePassengerCar"
              name="vehicleSubLine"
              id="PassengercarryingVehicle"
            />
            <input type="hidden" value="" name="elc_value" id="elc_value" />
            <input
              type="hidden"
              value=""
              name="nonelc_value"
              id="nonelc_value"
            />
            <input type="hidden" value="razorpay" name="paymentType" />
            <div className=" w-full flex justify-center text-center">
          <ButtonPrimary
            className="max-sm:w-[7rem] sm:w-[14rem] md:w-[14rem]"
            type="submit"
          >
          {loading ? (
            <span className="flex items-center">
              <svg
                className="animate-spin h-5 w-5 mr-3"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A8.004 8.004 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            </span>
          ) : (
            "Pay Now"
          )}
        </ButtonPrimary>
            </div>
          </form>
         
    </>
  );
};

export default RoyalSundaramPayment;
