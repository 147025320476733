import { FC } from "react";
import { PostDataType } from "../../data/types";
import Link from "../Link";
import CategoryBadgeList from "../CategoryBadgeList/CategoryBadgeList";
import CardAuthor2 from "../CardAuthor2/CardAuthor2";
import NcImage from "../NcImage/NcImage";
import NextPrev from "../NextPrev/NextPrev";
import {
  cardLarge1CardLeft,
  cardLarge1CardRight,
  cardLarge1CardRightImg,
  cardLarge1CardRightImgProp,
  cardLarge1CardRightSection,
  cardLarge1CardTitle,
  cardLarge1CardTitleLink,
  cardLarge1MainDiv,
  cardLarge1MoveBtn,
  cardLarge1MoveBtnDiv,
  cardLarge1Section1,
} from "../styleComponents/cardLarge1Styles";
import { relative } from "../styleComponents/headerStyles/searchModalStyle";

export interface CardLarge1Props {
  className?: string;
  post: PostDataType;
  onClickNext?: () => void;
  onClickPrev?: () => void;
}

const CardLarge1: FC<CardLarge1Props> = ({
  className = "",
  post,
  onClickNext = () => {},
  onClickPrev = () => {},
}) => {
  const { featuredImage, title, categories, author, href } = post;

  return (
    <div className={`${cardLarge1MainDiv} ${className}`}>
      <div className={cardLarge1Section1}>
        <div className={cardLarge1CardLeft}>
          <CategoryBadgeList categories={categories} />

          <h2 className={cardLarge1CardTitle}>
            <Link href={href} className={cardLarge1CardTitleLink} title={title}>
              {title}
            </Link>
          </h2>

          <CardAuthor2 className={relative} author={author} />
        </div>
        <div className={cardLarge1MoveBtnDiv}>
          <NextPrev
            btnClassName={cardLarge1MoveBtn}
            onClickNext={onClickNext}
            onClickPrev={onClickPrev}
          />
        </div>
      </div>
      <div className={cardLarge1CardRightSection}>
        <Link href={href} className={cardLarge1CardRight}>
          <NcImage
            containerClassName={cardLarge1CardRightImgProp}
            className={cardLarge1CardRightImg}
            src={featuredImage}
            alt={title}
            fill
            sizes="(max-width: 768px) 100vw, 50vw"
          />
        </Link>
      </div>
    </div>
  );
};

export default CardLarge1;
