import { Dispatch } from "redux";
import { setCurrentStep, setMotorPremiumOn } from "../../../reducers/slices/MotorSlice";

export const handelGoBack = (
  index: number,
  optionObject: string[],
  dispatch: Dispatch,
  setSelectedOption: (option: string) => void
) => {
  if (index > 0) {
    setSelectedOption(optionObject[index - 1]);
    dispatch(setCurrentStep(index - 1));
  } else {
    dispatch(setCurrentStep(null));
    dispatch(setMotorPremiumOn(true));
  }
};
