import React from "react";
import { useDispatch } from "react-redux";
import { setIsOpenPopup } from "../../../reducers/slices/commonPremiumSlice";
import { setCurrentStep } from "../../../reducers/slices/MotorSlice";
import LinkLayout from "../../Link/LinkLayout";

interface LinkProps {
  linkText: string;
}

const Link1: React.FC<LinkProps> = ({ linkText }) => {
  const dispatch = useDispatch();
  const handleClick = () => {
    dispatch(setIsOpenPopup(true));
    dispatch(setCurrentStep(0));
  };

  return <LinkLayout handleClick={handleClick} link={linkText} />;
};

export default Link1;
