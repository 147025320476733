import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../reducers/store/store";
import {
  setCurrentStep,
  setSelectedSubModel,
  setSubModelCode,
  setSelectedCode,
  setRtoCode,
  setSelectedYear,
  setVechicalCode,
  setSelectedCC,
  setCcCode,
  setSendingRto,
  setSelectedRegDate,
} from "../../../reducers/slices/MotorSlice";
import {
  fetchP4usId,
  fetchRto,
} from "../../../data/Premium/MotorData/api";
import CommonSearchDropdown from "../../CommonDesignForSelection/CommonSearchDropdown";

interface VariantSelectionProps {
  collectionName: string;
  rto: any[];
  varients: any[];
  setRto: React.Dispatch<React.SetStateAction<any[]>>;
}

const VariantSelection: React.FC<VariantSelectionProps> = ({
  collectionName,
  rto,
  varients,
  setRto,
}) => {
  const dispatch = useDispatch();
  const { companyCode, modelCode, fuelCode, subModelCode, selectedModel } = useSelector(
    (state: RootState) => state.motorDetails
  );

  const handleVariantSelection = async (name: string, code: string) => {
    try {
      const selectedVariant = varients.find(
        (variant) => variant.name === name && variant.code === code
      );

      if (code !== subModelCode) {
        dispatch(setSelectedSubModel(name));
        dispatch(setSubModelCode(code));
        dispatch(setSelectedCode(""));
        dispatch(setRtoCode(""));
        dispatch(setSendingRto(""));
        dispatch(setSelectedYear(""));
        dispatch(setSelectedRegDate(""));
        dispatch(setVechicalCode(""));
        if (selectedVariant) {
          const { ccName, ccCode } = selectedVariant;
          dispatch(setSelectedCC(ccName));
          dispatch(setCcCode(ccCode));
          const p4usId = await fetchP4usId(
            collectionName,
            companyCode,
            modelCode,
            fuelCode,
            code,
            ccCode
          );
          dispatch(setVechicalCode(p4usId));
        }
        dispatch(setCurrentStep(4));

        if (rto.length === 0) {
          const data = await fetchRto();
          setRto(data);
        }
      } else {
        dispatch(setSelectedSubModel(name));
        dispatch(setSubModelCode(code));
        if (selectedVariant) {
          const { ccName, ccCode } = selectedVariant;
          dispatch(setSelectedCC(ccName));
          dispatch(setCcCode(ccCode));
          const p4usId = await fetchP4usId(
            collectionName,
            companyCode,
            modelCode,
            fuelCode,
            code,
            ccCode
          );
          dispatch(setVechicalCode(p4usId));
        }
        dispatch(setCurrentStep(4));
        if (rto.length === 0) {
          const data = await fetchRto();
          setRto(data);
        }
      }
    } catch (error) {
      console.error("Error in handleVariantSelection:", error);
    }
  };

  return (
    <>
      <CommonSearchDropdown
        heading={`Which ${selectedModel} variant do you drive?`}
        options={varients}
        selectedOption={subModelCode}
        handleSelect={handleVariantSelection}
        height="h-[360px]"
      />
    </>
  );
};

export default VariantSelection;
