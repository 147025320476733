import React, { useState } from "react";
import PopLayout from "../../Popup/PopLayout";
import PremiumButton from "../../Button/PremiumButton";
import ButtonPrimary from "../../Button/ButtonPrimary";

interface ButtonProps {
  keys: any;
  updateCombinedData: (headerUpdates: object, sendingUpdates?: object) => void;
  mainCardData: any[];
}

const Button2: React.FC<ButtonProps> = ({
  keys,
  updateCombinedData,
  mainCardData,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isMore, setIsMore] = useState<boolean>(true);
  const [selectedValue, setSelectedValue] = useState<number | null>(null);


  const handleSubmit = () => {
    const stringValue = String(selectedValue);
    const updates = {
      button2: {
        ...keys,
        selectedRange: selectedValue,
      },
    };
    const sendingData = {
      idv: stringValue,
    };
    updateCombinedData(updates, sendingData);
    setIsOpen(false);
  };

  return (
    <div className={`relative ${isOpen ? "z-50" : ""}`}>
      <div>
        {keys.selectedRange === 0 && mainCardData.length <= 0 ? (
          <PremiumButton
            title={keys.title}
            label={keys.label}
            labelRange={keys.nonValueText}
            disable={true}
          />
        ) : (
          <PremiumButton
            title={keys.title}
            label={keys.label}
            labelRange={
              keys.selectedRange !== 0 ? keys.selectedRange : ""
            }
            onClick={() => {
              setSelectedValue(keys.selectedRange);
              setIsOpen(!isOpen);
            }}
          />
        )}
      </div>
      <div>
        {isOpen && (
          <PopLayout
            isOpen={isOpen}
            onClose={() => setIsOpen(false)}
            title={keys.heading}
            maxWidth="max-w-sm"
          >
            <div className="py-4 px-4 sm:px-6 md:py-5 flex flex-col mt-3">
              <div className="flex justify-center mb-4 text-[15px] max-sm:text-[14px]">
                <span className="text-gray-500 dark:text-gray-300">
                  {keys.sublabel}:
                </span>
               <span className="font-semibold text-gray-800 dark:text-gray-50 ml-2">
                &#8377; {selectedValue !== 0 ? selectedValue : keys.minValue }
              </span>
              </div>
              <input
                type="range"
                id="vol"
                name="vol"
                min={keys.minValue}
                max={keys.maxValue}
                value={selectedValue || keys.minValue}
                onChange={(e) => setSelectedValue(Number(e.target.value))}
                className="appearance-none w-full h-3 rounded-full focus:outline-none focus:ring-2 focus:ring-blue-200 focus:ring-opacity-50 cursor-pointer bg-gradient-to-r from-blue-50 to-blue-400 dark:from-slate-300 dark:to-gray-700"
              />
              <div className="flex justify-between mt-2 text-slate-600 dark:text-slate-300 text-[14px] max-sm:text-[13px]">
                <div>
                  <span>&#8377; {keys.minValue}</span>
                  <span className="text-[10px] ml-1 max-sm:hidden">(Min)</span>
                </div>
                <div>
                  <span>&#8377; {keys.maxValue}</span>
                  <span className="text-[10px] ml-1 max-sm:hidden">(Max)</span>
                </div>
              </div>
              <ButtonPrimary
                onClick={handleSubmit}
                className="w-1/2 mx-auto mt-5"
              >
                {keys.buttonLabel}
              </ButtonPrimary>
              <div className="mt-4 border-t border-gray-200 dark:border-zinc-700 pt-3 text-start text-[15px] max-sm:text-[14px]">
                <div className="flex w-full justify-between items-center -mb-4">
                  <span className="font-medium">{keys.describtionHeading}</span>
                  {isMore ? (
                    <span
                      className="mb-1.5 cursor-pointer text-gray-500"
                      onClick={() => setIsMore(false)}
                    >
                      &#9660;
                    </span>
                  ) : (
                    <div
                      className="cursor-pointer text-gray-500"
                      onClick={() => setIsMore(true)}
                    >
                      &#9650;
                    </div>
                  )}
                </div>
                <br />
                {isMore && (
                  <span className="text-gray-500 dark:text-slate-400 text-[13px] max-sm:text-[12px]">
                    {keys.describtion}
                  </span>
                )}
              </div>
            </div>
          </PopLayout>
        )}
      </div>
    </div>
  );
};

export default Button2;
