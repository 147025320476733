import { FC } from "react";
import { PostDataType } from "../../data/types";
import { DEMO_POSTS_AUDIO } from "../../data/posts";
import Heading from "../Heading/Heading";
import Card16Podcast from "../Card16Podcast/Card16Podcast";
import Card17Podcast from "../Card17Podcast/Card17Podcast";
import {
  SectionMagazine8Headiing,
  SectionMagazine8LeftCard,
  SectionMagazine8LeftSection,
  SectionMagazine8Main,
  SectionMagazine8RightSection,
} from "../styleComponents/sectionMagazine8Style";

const postsDemo: PostDataType[] = DEMO_POSTS_AUDIO.filter(
  (_, i) => i > 7 && i < 17
);

export interface SectionMagazine8Props {
  posts?: PostDataType[];
  className?: string;
}

const SectionMagazine8: FC<SectionMagazine8Props> = ({
  posts = postsDemo,
  className = "",
}) => {
  return (
    <div className={`${SectionMagazine8Main} ${className}`}>
      <Heading
        desc={"Extra Ways to Get in Touch"}
        className={SectionMagazine8Headiing}
        isCenter
      >
        Connect
      </Heading>
      <div className={SectionMagazine8LeftSection}>
        <Card16Podcast className={SectionMagazine8LeftCard} post={posts[0]} />
        <Card16Podcast className={SectionMagazine8LeftCard} post={posts[1]} />
        <div className={SectionMagazine8RightSection}>
          {posts
            .filter((_, i) => i > 1 && i < 6)
            .map((p,index) => (
              <Card17Podcast key={index} post={p} />
            ))}
        </div>
      </div>
    </div>
  );
};

export default SectionMagazine8;
