import React from "react";

type PremiumButtonProps = {
  onClick?: () => void; // Make onClick optional
  title: string;
  label: string | number;
  labelRange?: string;
  disable?: boolean; // Optional prop to disable the button
  optionLength?: number; // Optional prop for option length
  className1?: string; // Optional prop for button class name
  className2?: string; // Optional prop for title div class name
  className3?: string;
};

const PremiumButton: React.FC<PremiumButtonProps> = ({
  onClick,
  title,
  label,
  labelRange,
  disable = false,
  optionLength,
  className1,
  className2,
  className3
}) => {
  const buttonClassName = className1
    ? className1
    : `flex max-sm:w-32 w-48 flex-col items-center justify-center border-2 border-primary-700/70 hover:bg-gradient-to-t from-blue-50/70 dark:border-blue-900 dark:from-gray-950 dark:to-black to-white text-gray-700 max-sm:py-2 max-sm:px-3 py-3 px-4 rounded-lg transition-all duration-300 ${
        disable ? "cursor-not-allowed" : "cursor-pointer"
      }`;

  const titleDivClassName = className2
    ? className2
    : `absolute cursor-pointer -top-3 max-sm:left-2 left-3 flex items-center justify-center px-0.5 rounded-2xl bg-white dark:bg-black text-primary-700 dark:text-blue-400/80 max-sm:text-[11px] text-[13px] font-[500] z-10 ${
        disable ? "cursor-not-allowed" : "cursor-pointer"
      }`;
 
 const spanClassName = className3  ? className3
 : "text-base max-sm:text-xs font-[500] text-blue-950/90 dark:text-gray-300";

  return (
    <div className={`relative ${disable ? "opacity-50" : ""}`}>
      <button
        className={buttonClassName}
        onClick={onClick ? onClick : undefined} // Conditionally use onClick if not disabled
        disabled={disable} // Disable the button
      >
        {labelRange ? (
          <span className="text-base max-sm:text-xs font-[500] flex gap-1 text-blue-950/90 dark:text-gray-400">
            {label}:
            <span className="text-blue-500 dark:text-slate-300">
              {labelRange}
            </span>
          </span>
        ) : (
          <div className="flex gap-2 items-center justify-center">
            <span className={spanClassName}>
              {label}
            </span>
            {optionLength && optionLength !== 0 && (
              <div className="bg-primary-500 dark:bg-primary-6000 rounded-full w-5 h-5 flex items-center justify-center">
                <p className="text-[12px] max-sm:text-[11px] text-white w-full">{optionLength}</p>
              </div>
            )}
          </div>
        )}
      </button>
      <div className={titleDivClassName}>{title}</div>
    </div>
  );
};

export default PremiumButton;
