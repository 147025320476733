import { FC } from "react";
import { PostAuthorType } from "../../data/types";
import Link from "../Link";
import NcImage from "../NcImage/NcImage";
import Avatar from "../Avatar/Avatar";
import { CardAuthorBox2DownImgProp, CardAuthorBox2DownSection, CardAuthorBox2DownTitle, CardAuthorBox2DownTitleDesc, CardAuthorBox2DownTitleSection, CardAuthorBox2DownTitleSpan, CardAuthorBox2Main, CardAuthorBox2TopImgSection, CardAuthorBox2TopSection } from "../styleComponents/cardAuthorBoxStyle";

export interface CardAuthorBox2Props {
  className?: string;
  author: PostAuthorType;
}

const CardAuthorBox2: FC<CardAuthorBox2Props> = ({
  className = "",
  author,
}) => {
  const { displayName, href = "/", avatar, jobName, bgImage } = author;
  return (
    <Link
      href={href}
      className={`${CardAuthorBox2Main} ${className}`}
    >
      <div className={CardAuthorBox2TopSection}>
        <div className={CardAuthorBox2TopImgSection}>
          <NcImage
            alt="author"
            containerClassName={CardAuthorBox2TopImgSection}
            src={bgImage || ""}
            fill
            sizes="(max-width: 600px) 480px, 33vw"
          />
        </div>
       
      </div>

      <div className={CardAuthorBox2DownSection}>
        <Avatar
          containerClassName={CardAuthorBox2DownImgProp}
          sizeClass="w-16 h-16 text-2xl"
          radius="rounded-full"
          imgUrl={avatar}
          userName={displayName}
        />
        <div className={CardAuthorBox2DownTitleSection}>
          <h2 className={CardAuthorBox2DownTitle}>
            <span className={CardAuthorBox2DownTitleSpan}>{displayName}</span>
          </h2>
          <span
            className={CardAuthorBox2DownTitleDesc}
          >
            {jobName}
          </span>
        </div>
      </div>
    </Link>
  );
};

export default CardAuthorBox2;
