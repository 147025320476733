import React from "react";

interface VehicleDetailsProps {
  vehicleDetailsData: any;
  previousInsurerData:any;
  userSelectedType:any;

}

const VehicleDetails: React.FC<VehicleDetailsProps> = ({ vehicleDetailsData,previousInsurerData,userSelectedType }) => {
  return (
    <>
     <dl className="py-2 ml-4">
                  {/* Vehicle Details Data */}
                  {vehicleDetailsData ? (
                    <div
                      className="grid grid-cols-1 md:grid-cols-2"
                      style={{ gap: "10px" }}
                    >
                      <div className="flex py-1">
                        {vehicleDetailsData.registrationNo && (
                          <>
                            <dt className="text-sm font-medium text-gray-500 dark:text-gray-300 w-1/2 max-sm:text-[12px]">
                              Registration Number:
                            </dt>
                            <dd className="text-sm text-gray-900 dark:text-gray-200 font-medium w-1/2 ml-2 max-sm:text-[12px]">
                              {vehicleDetailsData.registrationNo}
                            </dd>
                          </>
                        )}
                      </div>
                      <div className="flex py-1">
                        <dt className="text-sm font-medium text-gray-500 dark:text-gray-300 w-1/2 max-sm:text-[12px]">
                          Registration Date:
                        </dt>
                        <dd className="text-sm text-gray-900 dark:text-gray-200 font-medium w-1/2 ml-2 max-sm:text-[12px]">
                          {vehicleDetailsData.registrationDate}
                        </dd>
                      </div>
                      <div className="flex py-1">
                        <dt className="text-sm font-medium text-gray-500 dark:text-gray-300 w-1/2 max-sm:text-[12px]">
                          Chassis Number:
                        </dt>
                        <dd className="text-sm text-gray-900 dark:text-gray-200 font-medium w-1/2 ml-2 max-sm:text-[12px]">
                          {vehicleDetailsData.chassisNo}
                        </dd>
                      </div>
                      <div className="flex py-1">
                        <dt className="text-sm font-medium text-gray-500 dark:text-gray-300 w-1/2 max-sm:text-[12px]">
                          Engine Number:
                        </dt>
                        <dd className="text-sm text-gray-900 dark:text-gray-200 font-medium w-1/2 ml-2 max-sm:text-[12px]">
                          {vehicleDetailsData.engineNo}
                        </dd>
                      </div>
                    </div>
                  ) : (
                    <p>No vehicle details available</p>
                  )}

                  {/* Previous Insurer Data */}
                  {previousInsurerData ? (
                    userSelectedType !== "New" && (
                      <div
                        className="grid grid-cols-1 md:grid-cols-2"
                        style={{ gap: "10px" }}
                      >
                        <div className="flex py-1">
                          <dt className="text-sm font-medium text-gray-500 dark:text-gray-300 w-1/2 max-sm:text-[12px]">
                            Previous Policy Insurers:
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 dark:text-gray-200 font-medium w-1/2 ml-2 max-sm:text-[12px]">
                            {previousInsurerData.prevInsurer}
                          </dd>
                        </div>
                        <div className="flex py-1">
                          <dt className="text-sm font-medium text-gray-500 dark:text-gray-300 w-1/2 max-sm:text-[12px]">
                            Previous Policy Number:
                          </dt>
                          <dd className="text-sm text-gray-900 dark:text-gray-200 font-medium w-1/2 ml-2 max-sm:text-[12px]">
                            {previousInsurerData.prevPolicyNo}
                          </dd>
                        </div>
                        <div className="flex py-1">
                          <dt className="text-sm font-medium text-gray-500 dark:text-gray-300 w-1/2 max-sm:text-[12px]">
                            Active TP Policy Insurers:
                          </dt>
                          <dd className="text-sm text-gray-900 dark:text-gray-200 font-medium w-1/2 ml-2 max-sm:text-[12px]">
                            {previousInsurerData.tpInsurer}
                          </dd>
                        </div>
                        <div className="flex py-1">
                          <dt className="text-sm font-medium text-gray-500 dark:text-gray-300 w-1/2 max-sm:text-[12px]">
                            Active TP Policy Number:
                          </dt>
                          <dd className="text-sm text-gray-900 dark:text-gray-200 font-medium w-1/2 ml-2 max-sm:text-[12px]">
                            {previousInsurerData.tpPolicyNumber}
                          </dd>
                        </div>
                        <div className="flex py-1">
                          <dt className="text-sm font-medium text-gray-500 dark:text-gray-300 w-1/2 max-sm:text-[12px]">
                            Loan Agreement:
                          </dt>
                          <dd className="text-sm text-gray-900 dark:text-gray-200 font-medium w-1/2 ml-2 max-sm:text-[12px]">
                            {previousInsurerData.isItUnderLoan}
                          </dd>
                        </div>
                        {previousInsurerData.isItUnderLoan === "yes" && (
                          <div className="flex py-1">
                            <dt className="text-sm font-medium text-gray-500 dark:text-gray-300 w-1/2">
                              Loan Provider:
                            </dt>
                            <dd className="text-sm text-gray-900 dark:text-gray-200 font-medium w-1/2 ml-2">
                              {previousInsurerData.loanProvider}
                            </dd>
                          </div>
                        )}
                      </div>
                    )
                  ) : (
                    <p className="px-3 py-1 text-sm font-medium text-gray-500 dark:text-gray-300">
                      No data found
                    </p>
                  )}
                </dl>
    </>
  );
};

export default VehicleDetails;