import React, { useEffect, useState } from "react";
import PopLayout from "../Popup/PopLayout";
import { useSelector } from "react-redux";
import { RootState } from "../../reducers/store/store";
import {
  fetchDocId,
  fetchMotorVariants,
  fetchVariantMapped,
} from "../../data/Premium/MotorData/api";
import Heading2 from "../Heading/Heading2";
import { readFromDB, writeToDB } from "../../utils/indexDBUtils";
import VariantSelectDropdown from "./VariantSelectDropdown";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
// import { setIsOpenPopup } from "../../reducers/slices/commonPremiumSlice";
import LinkLayout from "../Link/LinkLayout";
import { fetchSSEData } from "../Premium/PremiumFunctions/fetchSSEData";
import { setResponseData } from "../../reducers/slices/MotorSlice";
import ButtonPrimary from "../Button/ButtonPrimary";

interface Option {
  name: string;
  code: string;
}

interface UpdatedValues {
  [key: string]: any;
}

interface VehicleVerificationProps {
  closePopup: () => void;
  isPopupOpen: boolean;
}

interface MainCardData {
  [key: string]: any;
}

const VehicleVerification: React.FC<VehicleVerificationProps> = ({
  closePopup,
  isPopupOpen,
}) => {
  const loading = useSelector(
    (state: RootState) => state.commonPremiumData.loadingStates["verification"] || false
  );
  const [verified, setVerified] = useState<boolean>(false);
  const {
    selectedCompany,
    selectedModel,
    companyCode,
    modelCode,
    selectedSubModel,
    fuelCode,
    vechicalCode, //P4US_ID
    proposalData,
  } = useSelector((state: RootState) => state.motorDetails);
  const [carVariants, setCarVariants] = useState<Option[]>([]);
  const [carVariantSelected, setCarVariantSelected] = useState<string>("");
  const [carVariantMapped, setCarVariantMapped] = useState<any>("");
  const [quoteResponse, setQuoteResponse] = useState<any>("");
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const [updatedValuesState, setUpdatedValuesState] = useState<UpdatedValues>(
    []
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // console.log("carVariants", carVariants);
  const [mainCardData, setMainCardData] = useState<MainCardData[]>([]);
  const [isFetchComplete, setIsFetchComplete] = useState(false);
  const [insuranceItem, setInsuranceItem] = useState<string>("");
  const { whichTab, id } = useSelector(
    (state: RootState) => state.commonPremiumData
  );

  // const logoMapping: Record<string, string> = {
  //   logo1: "https://i.postimg.cc/MZNzT2zs/Bajaj.jpg",
  //   logo2: "https://i.postimg.cc/tTwmxTDd/godigit.png",
  //   logo3: "https://i.postimg.cc/9Mzjm1K5/Iffco-Toko.jpg",
  //   logo4: "https://i.postimg.cc/J0kVJ0Rp/National.jpg",
  //   logo5: "https://i.postimg.cc/CxN1dPPz/Reliance.jpg",
  //   logo6: "https://i.postimg.cc/Pq5qDW9K/Royal.jpg",
  //   logo7: "https://i.postimg.cc/nh0Fnsrm/Shriram.jpg",
  // };

  //for master selection------------------------------------
  let master = proposalData.responseData.CompanyName.toLowerCase();
  console.log("master", master);
  let masterwithoutspace =
    proposalData.responseData.CompanyName.toLowerCase().replace(/\s+/g, "");
  console.log("masterwithoutspace", masterwithoutspace);

  const funcData = async () => {
    const insItem = await readFromDB("whichTab");

    console.log("insuranceItem-----", insItem);
    if (insItem && typeof insItem === "string") {
      const lowercaseInsuranceItem = (insItem as string).toLowerCase();
      setInsuranceItem(lowercaseInsuranceItem);
      console.log("insuranceItem...........", lowercaseInsuranceItem);
    } else {
      console.log("No insurance item found or the item is not a string.");
    }
  };

  useEffect(() => {
    funcData();
  }, []);

  //fetch mapped variant
  useEffect(() => {
    console.log("vechicalCode", vechicalCode, companyCode, modelCode, fuelCode);
    console.log("selectedSubModel", selectedSubModel);
    console.log("insuranceItem+++++", insuranceItem);

    const fetchData = async () => {
      // Ensure `insuranceItem` is available before continuing
      if (!insuranceItem) {
        await funcData();
      }

    if (
      vechicalCode !== undefined &&
      vechicalCode !== null &&
      vechicalCode !== "" &&
      insuranceItem !== ""
    ) {
      const fetchVariant = async () => {
        try {
          const variant = await fetchVariantMapped(
            `${master}_${insuranceItem}s`, // "reliance general_cars"
            vechicalCode
          );
          console.log("variant mapped in company master", variant);

          setCarVariantMapped(variant[0]);
        } catch (error) {
          console.error("Error fetching car variant mapped:", error);
        }
      };

      fetchVariant();
    } else {
      console.log("vechicalCode is not defined");
    }

    //finding variants array for the selected make, model, fuel
    if (
      companyCode !== undefined &&
      companyCode !== null &&
      companyCode !== "" &&
      modelCode !== undefined &&
      modelCode !== null &&
      modelCode !== "" &&
      fuelCode !== undefined &&
      fuelCode !== null &&
      fuelCode !== "" &&
      insuranceItem !== ""
    ) {
      const variantSelection = async () => {
        await funcData()
        const variants = await fetchMotorVariants(
          `${master}_${insuranceItem}s`, // "reliance general_cars"
          companyCode,
          modelCode,
          fuelCode
        );
        // console.log("variants", variants);

        setCarVariants(variants);
      };

      variantSelection();
    } else {
      console.log("companyCode or modelCode or fuelCode is not defined");
    }
  };

    fetchData();
  }, [insuranceItem, vechicalCode, companyCode, modelCode, fuelCode]);

  //vehicle with mapped variant
  const selectedInfoString = `${selectedCompany}, ${selectedModel}, ${carVariantMapped} `;
  // console.log("selectedInfoString", selectedInfoString);

  // Use useEffect to perform actions after mainCardData is updated

  const handleVerify = async () => {
    setVerified(true);
    try {
      const policyType = updatedValuesState.policyType?.toLowerCase(); // Extract policyType from updatedValuesState

      console.log("hhghfgfgfgfg", updatedValuesState, insuranceItem);

      if (!insuranceItem || !policyType) {
        console.error("Insurance item or policy type is missing");
        return;
      }

      // Construct the dynamic URL
      const url = `https://aim-core.insurancepolicy4us.com/dummy/id=${id}/generatequote/${insuranceItem}/${policyType}/verify/${masterwithoutspace}`;

      const uniqueKey = "verification"

      console.log("Dynamic URL:", url);

      const token = `Bearer ${import.meta.env.VITE_API_TOKEN}` as string;

      console.log("updatedforverifyyyyyyyyyyyyyyy", updatedValuesState);

      // Fetch SSE Data
      fetchSSEData(
        updatedValuesState,
        url,
        token,
        uniqueKey,
        setMainCardData,
        dispatch
        // logoMapping
      );
      if (loading) {
        // Poll for mainCardData to be updated
        const checkMainCardData = () => {
          return new Promise((resolve) => {
            const interval = setInterval(() => {
              if (mainCardData.length !== 0) {
                clearInterval(interval);
                resolve(true);
              }
            }, 1000); // Check every 1 second
          });
        };

        // Wait until mainCardData is updated
        await checkMainCardData();
      }
      setIsFetchComplete(true);
    } catch (error) {
      console.error("Error fetching quote:", error);
    }
  };
  useEffect(() => {
    const handleMainCardDataUpdate = async () => {
      const isValidMainCardData =
        mainCardData.length !== 0 &&
        mainCardData.some(
          (item) =>
            item.hasOwnProperty("TotalPremium") &&
            item.TotalPremium != null &&
            item.TotalPremium !== "" &&
            item.hasOwnProperty("NetPremium") &&
            item.NetPremium != null &&
            item.NetPremium !== ""
        );
      if (!loading && isValidMainCardData) {
        console.log("mainCardData", mainCardData);
        setQuoteResponse(mainCardData);

        const Data = {
          correctedVariant: carVariantSelected,
          verified: true,
          companyName: proposalData.responseData.CompanyName,
        };
        dispatch(setResponseData(mainCardData[0]));
        await writeToDB("responseObjectForProposal", {
          premiumData: proposalData.premiumData,
          responseData: mainCardData[0],
        });
        await writeToDB("mainCardData-proposal", mainCardData);
        await writeToDB("motor-variant-corrected", Data);
        closePopup();
      } else if (isFetchComplete) {
        if (mainCardData.length === 0) {
          setQuoteResponse(null); // Show error message
          const Data = {
            correctedVariant: "",
            verified: false,
            companyName: "",
          };
          await writeToDB("motor-variant-corrected", Data);
        }
      }
    };

    handleMainCardDataUpdate();
  }, [loading, mainCardData, isFetchComplete]);

  const handleNoVerification = async () => {
    console.log("hi.....");
    const cleanedName = selectedSubModel.replace(/\s*\(.*?\)\s*/g, "").trim();
    console.log("selectedSubModel11111111111111", cleanedName);
    await fetchDocID(companyCode, modelCode, fuelCode, cleanedName);

    const Data = {
      correctedVariant: selectedSubModel,
      verified: true,
      companyName: proposalData.responseData.CompanyName,
    };
    await writeToDB("motor-variant-corrected", Data);

    closePopup();
  };

  useEffect(() => {
    console.log("Updated state after update:", updatedValuesState);
  }, [updatedValuesState]);

  const handleSelect = async(name: string) => {
    setCarVariantSelected(name);
    const cleanedName = name.replace(/\s*\(.*?\)\s*/g, "").trim();
   await fetchDocID(companyCode, modelCode, fuelCode, cleanedName);
    // console.log("Cleaned variant name:", cleanedName);
  };

  const handleVerification = () => {
    setShowDropdown(true);
  };

  const handleBackClick = () => {
    setShowDropdown(false);
  };

  const handleQuoteFailed = () => {
    navigate(`/${whichTab.toLowerCase()}/quote`);
  };

  const fetchDocID = async (
    companyCode: string,
    modelCode: string,
    fuelCode: string,
    carVariantWithoutCC: string
  ) => {
    try {
      if (
        companyCode !== undefined &&
        companyCode !== null &&
        companyCode !== "" &&
        modelCode !== undefined &&
        modelCode !== null &&
        modelCode !== "" &&
        fuelCode !== undefined &&
        fuelCode !== null &&
        fuelCode !== "" &&
        carVariantWithoutCC !== undefined &&
        carVariantWithoutCC !== null &&
        carVariantWithoutCC !== ""
      ) {
        const response = await fetchDocId(
          `${master}_${insuranceItem}s`, // "reliance general_cars"
          companyCode,
          modelCode,
          fuelCode,
          carVariantWithoutCC
        );
        console.log("responseeeeeeeeeee", response);
        writeToDB("vehicle-documentID", response.id);

        const premiumData = await readFromDB("PremiumData");

        const updatedPremiumData = {
          ...premiumData[0].updatedValues, // spread the existing properties
          InsCompanyID: response.id, // add or update InsCompanyID
        };

        setUpdatedValuesState(updatedPremiumData);

        // Log the state to confirm it's set (may still show the old value due to async nature)
        console.log("updatedValuesState after setState", updatedValuesState);
      } else {
        console.warn(
          "Required values for fetching document ID are not defined or empty"
        );
      }
    } catch (error) {
      console.error("Error fetching document ID:", error);
    }
  };

  return (
    <PopLayout
      isOpen={isPopupOpen}
      onClose={closePopup}
      title="Vehicle Verification"
      maxWidth="max-w-screen-lg"
      nonClose
    >
      <div className="relative w-full flex flex-col p-6">
        {!showDropdown && (
          <>
            <div>
              <Heading2>Confirm Your Vehicle Variant</Heading2>
              <span className="flex justify-center py-2">
                {selectedInfoString}
              </span>
            </div>
            <div className="flex justify-center">
              <button
                className="bg-blue-300 text-gray-900 rounded-3xl text-lg px-7 p-1 mr-2 m-auto font-semibold"
                onClick={handleNoVerification} //No need of running another quote api
              >
                Yes
              </button>
              <button
                className="bg-blue-300 text-gray-900 rounded-3xl text-lg px-7 p-1 ml-2 m-auto font-semibold"
                onClick={handleVerification}
              >
                No
              </button>
            </div>
          </>
        )}
        {showDropdown && (
          <>
            {!quoteResponse === null ||
              (!isFetchComplete && (
                <div className="flex justify-end ">
                  <LinkLayout
                    link="Take Me Back"
                    handleClick={handleBackClick}
                  />
                </div>
              ))}
            {!verified && (
              <VariantSelectDropdown
                heading={`Select your ${selectedModel} Variant`}
                options={carVariants}
                selectedOption={carVariantSelected}
                handleSelect={handleSelect}
              />
            )}

            {loading ? (
              <h2 className="absolute inset-0 flex justify-center items-center text-center text-xl text-gray-900 bg-white bg-opacity-75">
                <span className="loader"></span>
              </h2>
            ) : (
              quoteResponse === null &&
              isFetchComplete && (
                <>
                  <h2 className="flex justify-center items-center text-center text-xl text-red-600 mb-10">
                    Failed to Verify Your Vehicle. Please try again.
                  </h2>
                  <button
                    className=" bg-blue-300 text-gray-900 rounded-2xl text-lg px-7 p-1 m-auto font-semibold"
                    onClick={handleQuoteFailed}
                  >
                    Go Back
                  </button>
                </>
              )
            )}

            {!verified && carVariantSelected && !loading && (
             <div className="w-full flex">
                <ButtonPrimary
                  className="w-1/2 sm:w-1/5 bg-blue-300 text-gray-900 rounded-2xl text-lg px-7 py-1 m-auto font-semibold"
                  onClick={handleVerify}
                >
                  Verify
                </ButtonPrimary>
             </div>
            )}
          </>
        )}
      </div>
    </PopLayout>
  );
};

export default VehicleVerification;
