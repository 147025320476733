import React, { useState } from "react";
import LinkLabel from "./LinkLabel";
import ButtonPrimary from "../../Button/ButtonPrimary";

interface NewSelectionPageProps {
  labelLinkText: string;
  handleLabelLink: () => void;
  selectedMotor: string;
  selectedFuel: string;
  selectedRto: string;
  selectedYear: string;
  handleViewQuotesValid: () => void;
}

const NewSelectionPage: React.FC<NewSelectionPageProps> = ({
  labelLinkText,
  handleLabelLink,
  selectedMotor,
  selectedFuel,
  selectedRto,
  selectedYear,
  handleViewQuotesValid,
}) => {
  const [loading, setLoading] = useState<boolean>(false); // State to manage loading state of the button

  const handleViewQuotes = async (): Promise<void> => {
    setLoading(true); // Set loading state to true

    // Call asynchronous function to handle quotes
    await handleViewQuotesValid();

    setLoading(false); // Reset loading state
  };

  return (
    <div className="w-full flex items-center justify-center px-4 py-5">
      <div className="flex flex-col px-3 py-3 w-full">
        <LinkLabel text={labelLinkText} onClick={handleLabelLink} />
        <div className="flex flex-col py-4 items-center justify-center px-3">
          <div className="mb-4 text-xl text-zinc-900 dark:text-white font-[600] sm:text-2xl">
            {selectedMotor}
          </div>
          <div className="flex flex-row dark:text-zinc-300 flex-wrap gap-3 text-base">
            <p>{selectedFuel}</p>
            <p>{selectedRto}</p>
            <p>{selectedYear}</p>
          </div>
        </div>
        <div className="w-full flex items-baseline justify-center mt-5 md:mt-8">
          <ButtonPrimary
            className={`w-3/4 md:w-1/2 ${
              !loading ? "cursor-pointer" : "cursor-not-allowed"
            }`}
            onClick={handleViewQuotes}
            disabled={loading}
          >
            {loading ? (
              <span className="flex items-center">
                <svg
                  className="animate-spin h-5 w-5 mr-3"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A8.004 8.004 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
                Loading...
              </span>
            ) : (
              "View Quotes"
            )}
          </ButtonPrimary>
        </div>
      </div>
    </div>
  );
};

export default NewSelectionPage;
