import { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import HeaderLogged from "../components/Header/HeaderLogged";
import { readFromDB } from "../utils/indexDBUtils";

const SiteHeader = () => {
  const [tokenExpired, setTokenExpired] = useState<boolean>(false); // default to false
  const pathname = useLocation().pathname;

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [pathname]);

  useEffect(() => {
    const checkToken = async () => {
      const userInfo = await readFromDB("userInfo");
      if (!userInfo || !userInfo.loginTime) {
        setTokenExpired(false); // No user info or login time, consider expired
      } else {
        setTokenExpired(true); // Token is valid
      }
    };

    checkToken();
  }, []);

  console.log("tokenExpired:", tokenExpired);

  const headerComponent = useMemo(() => {
    if (!tokenExpired) {
      return null; // Do not show header if token is not valid
    }

    let HeadComponent = HeaderLogged;

    switch (pathname) {
      case "/":
        HeadComponent = HeaderLogged;
        break;

      default:
        break;
    }

    return <HeadComponent />;
  }, [pathname, tokenExpired]);

  return <>{headerComponent}</>;
};

export default SiteHeader;
