import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface KYCVerifiedData {
    [key: string]: any;
  }
  
  interface ProposalData {
    [key: string]: any;
  }
  
  interface DataState {
    kycData: KYCVerifiedData[];
    proposalData: ProposalData[];
  }
  
  const initialState: DataState = {
    kycData: [],
    proposalData: [],
  };


const dataSlice = createSlice({
    name: 'data',
    initialState,
    reducers: {
      setKYCData(state, action: PayloadAction<KYCVerifiedData[]>) {
        state.kycData = action.payload;
      },
      setProposalData(state, action: PayloadAction<ProposalData[]>) {
        state.proposalData = action.payload;
      },
    },
  });
  
  export const { setKYCData, setProposalData } = dataSlice.actions;
  export default dataSlice.reducer;