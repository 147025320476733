import __authors from "./jsons/__users.json";
import { PostAuthorType } from "./types";
import { Route } from "../routes/types";

// Define the array of avatar URLs
let avatarUrls = [
  { id: "Bajaj Allianz", url: "https://i.postimg.cc/LX6x7PQ3/bajaj.png" },
  { id: "Reliance General", url: "https://i.postimg.cc/mkxWtvxJ/image.png" },
  { id: "Zuno", url: "https://i.postimg.cc/L4Jc8dvB/zuno.jpg" },
  { id: "National Insurance", url: "https://i.postimg.cc/tgRf08Y6/national-insurance.jpg" },
  { id: "Go Digit", url: "https://i.postimg.cc/tTwmxTDd/godigit.png" },
  { id: "Shriram General Insurance", url: "https://i.postimg.cc/ncvwXnm7/shriram.png" },
  { id: "Iffco Tokio", url: "https://i.postimg.cc/v87W9TPQ/iffco-tokio-logo.png" },
  { id: "tata", url: "https://i.postimg.cc/xCwtdZTt/tata.png" },
  { id: "United India", url: "https://i.postimg.cc/Yqfbpbcz/united-india.jpg" },
  { id: "Royal Sundaram", url: "https://i.postimg.cc/mgLVhCBf/royal-sundharam.png" },
  { id: "Care", url: "https://i.postimg.cc/KYV59WLx/care.png" }
];

const DEMO_AUTHORS: PostAuthorType[] = __authors.map((item, index) => ({
  ...item,
  avatar: avatarUrls[index].url,
  href: item.href as Route,
}));

export { DEMO_AUTHORS , avatarUrls};
