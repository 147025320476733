import MyRoutes from "./routes/MyRoutes";
import { Provider } from "react-redux";
import store from "./reducers/store/store";
import { BrowserRouter } from "react-router-dom"; // Import BrowserRouter

function App() {
  return (
    <Provider store={store}>
      <BrowserRouter> {/* Wrap MyRoutes with BrowserRouter */}
        <div className="bg-[#f8f8f8] text-base text-neutral-900 dark:bg-zinc-950 dark:text-gray-200 h-full font-body">
          <MyRoutes />
        </div>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
