import React from "react";
import { useNavigate } from "react-router-dom";
import ProposalOuterLayout from "../ProposalOuterLayout";
// import Layout from "../Layout";
import Heading from "../../Heading/Heading";
import { healthProposalPageData } from "../../../data/Proposal/healthProposal/healthProposalData";
import Label from "../../Label/Label";
import Input from "../../Input/Input";
import Select from "../../Select/Select";
import ButtonPrimary from "../../Button/ButtonPrimary";

const HealthNominee: React.FC = () => {
  const navigate = useNavigate();

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    navigate("/proposal/members-details");
  };
  return (
    <div>
      <ProposalOuterLayout>
        {/* <Layout> */}
        <span className={`lg:hidden md:block  `}>
          <Heading desc="">{healthProposalPageData.HealthNominee.title1}</Heading>
        </span>
          <form
            className="grid md:grid-cols-2 gap-6"
            onSubmit={handleSubmit}
            // action="/proposal/kyc"
            method="post"
          >
           <label className="block ">
            <Label>{healthProposalPageData.HealthNominee.label1}</Label>

            <Input type="text" className="mt-1" />
          </label>
          <label className="block ">
            <Label>{healthProposalPageData.HealthNominee.label2}</Label>

            <Input type="text" className="mt-1" />
          </label>
          <label className="block ">
            <Label>{healthProposalPageData.HealthNominee.label3}</Label>

            <Input type="text" className="mt-1" />
          </label>
          <label className="block">
          <Label>{healthProposalPageData.HealthNominee.label4}</Label>

          <Select className="mt-1">
            <option value="-1"></option>
            {healthProposalPageData.HealthNominee.Relationship.map(
              (relation, index) => (
                <option key={index} value={relation}>
                  {relation}
                </option>
              )
            )}
          </Select>
        </label>
            <ButtonPrimary className="md:col-span-2">{healthProposalPageData.HealthNominee.button1}</ButtonPrimary>
          </form>
        {/* </Layout> */}
      </ProposalOuterLayout>
    </div>
  );
};

export default HealthNominee;
