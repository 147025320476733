import { useEffect } from "react";

const useClickOutside = (ref: React.RefObject<HTMLDivElement>, isOpen: boolean, setIsOpen: (isOpen: boolean) => void) => {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      window.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      window.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen, ref, setIsOpen]);
};

export default useClickOutside;
