export const shouldShowForwardButton = (
    index: number,
    currentStep: number | null,
    selectedCompany: string,
    selectedModel: string,
    selectedFuel: string,
    selectedSubModel: string,
    selectedCode: string,
    selectedYear: string,
    selectedRegDate: string,
    userSelectedType: string | null
  ) => {
    const conditions = [
      selectedCompany !== "" && userSelectedType !== "",
      selectedCompany !== "" && selectedModel !== "" && userSelectedType !== "",
      selectedCompany !== "" && selectedModel !== "" && selectedFuel !== "" && userSelectedType !== "",
      selectedCompany !== "" && selectedModel !== "" && selectedFuel !== "" && selectedSubModel !== "" && userSelectedType !== "",
      selectedCompany !== "" && selectedModel !== "" && selectedFuel !== "" && selectedSubModel !== "" && selectedCode !== "" && selectedYear !== "" && selectedRegDate !=="" && userSelectedType !== ""
    ];
  
    return currentStep === index && conditions[index];
  };
  