import React, { ChangeEvent } from "react";

interface AccessoriesInputProps {
  placeholder: string;
  onChange: (value: number) => void;
  selectedAddon: { option: string; value: number }[]; // Modify the props to accept the selected addon
  name: string; // Name of the addon to find the corresponding value
}

const AccessoriesInput: React.FC<AccessoriesInputProps> = ({
  placeholder,
  onChange,
  selectedAddon,
  name,
}) => {
  // Find the selected addon object by name
  const selectedAddonObject = selectedAddon.find((addon) => addon.option === name);

  // Extract the value from the selected addon object
  const value = selectedAddonObject ? selectedAddonObject.value : null;

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const parsedValue = parseFloat(e.target.value);
    onChange(parsedValue);
  };

  return (
    <div className="p-1 ml-7 mt-3">
      <input
        type="number"
        placeholder={placeholder}
        onWheel={(e) => e.currentTarget.blur()}
        onChange={handleInputChange}
        value={value !== null && value !== 0 ? value : ""} // Set the value of the input field
        className="px-3 py-2  text-sm border border-gray-300 rounded-md dark:bg-zinc-900 dark:text-white dark:placeholder:text-gray-400 text-gray-700 focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200 transition duration-200 appearance-none inputtextbox"
      />
    </div>
  );
};

export default AccessoriesInput;
