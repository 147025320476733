import React, { useEffect, useState } from "react";
import LinkLayout from "../Link/LinkLayout";

interface GridItem {
  id: string;
  code: string;
  name: string;
  image?: string;
}

interface Props {
  heading: string;
  subheading?: string;
  searchPlaceholder?: string;
  gridItems: GridItem[];
  buttonText?: string;
  linkText?: string;
  handleClick: (code: string, name: string) => void;
  selectedOption: string;
  handleButtonClick?: () => void;
  handleLinkClick?: () => void;
}

const CommonOptionsGrid: React.FC<Props> = ({
  heading,
  subheading,
  searchPlaceholder,
  gridItems,
  buttonText,
  linkText,
  handleClick,
  selectedOption,
  handleButtonClick,
  handleLinkClick,
}) => {
  const [selectedOptionInternal, setSelectedOptionInternal] =
    useState<string>("");
  const [searchQuery, setSearchQuery] = useState<string>("");

  const [displayLoader, setDisplayLoader] = useState(true); // State to manage loader
  const [showNoDataMessage, setShowNoDataMessage] = useState(false); // State to manage "no data" message

  useEffect(() => {
    setSelectedOptionInternal(selectedOption);
  }, [selectedOption]);

  const handleItemClick = (code: string, name: string) => {
    setSelectedOptionInternal(code);
    handleClick(code, name);
  };

  // Filter the grid items based on the search query
  const filteredGridItems = searchQuery
    ? gridItems.filter((item) =>
        item.name.toLowerCase().includes(searchQuery.toLowerCase())
      )
    : gridItems;

  // Handle logic for loader and "no data" message
  useEffect(() => {
    if (filteredGridItems.length === 0) {
      // Show loader initially
      setDisplayLoader(true);
      setShowNoDataMessage(false);

      // After 2 seconds, hide the loader and show "no data found" message
      const timeoutId = setTimeout(() => {
        setDisplayLoader(false);
        setShowNoDataMessage(true);
      }, 10000);

      return () => clearTimeout(timeoutId); // Clean up the timeout
    } else {
      // Reset the states if there are grid items
      setDisplayLoader(false);
      setShowNoDataMessage(false);
    }
  }, [filteredGridItems]);

  return (
    <div className="p-4 w-full flex flex-col items-center justify-center gap-2 mb-2">
      <div className="mb-4 w-full flex flex-col items-center justify-center relative">
        <h2 className="text-lg mb-2 sm:text-xl">{heading}</h2>
        {subheading && (
          <p className="text-gray-500 dark:text-gray-400 mb-2 text-sm">
            {subheading}
          </p>
        )}
        {searchPlaceholder && (
          <input
            type="text"
            placeholder={searchPlaceholder}
            className="mt-2 rounded-md focus:outline-none focus:ring focus:ring-blue-200 border focus:border-blue-100 border-gray-300 placeholder:text-gray-400 px-4 py-2 w-52 sm:w-72 mb-1"
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        )}
        {linkText && handleLinkClick && (
          <LinkLayout
            link={linkText}
            handleClick={handleLinkClick}
            className="absolute top-full mt-2 right-2 sm:right-8 md:right-16 mb-5"
          />
        )}
      </div>
      {/* LOADER  */}
      {displayLoader && (
        <div className="flex items-center justify-center h-60">
          <div className="loader"></div>
        </div>
      )}

      {/* "No data found" message after 2 seconds if no data */}
      {showNoDataMessage && (
        <div className="flex items-center justify-center h-60">
          <p>No data found.........</p>
        </div>
      )}

      {/* Grid items display */}
      {!displayLoader && !showNoDataMessage && (
        <div className="w-full md:w-11/12 grid grid-cols-2 sm:grid-cols-4 md:grid-cols-5 lg:grid-cols-6 xl:grid-cols-6 2xl:grid-cols-6 gap-6 mb-4 mt-5 max-h-80 overflow-y-auto custom-scrollbar px-3 pb-2 md:px-4">
          {filteredGridItems.map((item, index) => (
            <div
              key={index}
              className="flex w-full items-center justify-center "
            >
              <div
                className={`border rounded-xl px-2 py-3 hover:shadow-md dark:shadow-black dark:border-gray-700 w-11/12 md:w-full h-full cursor-pointer flex items-center justify-center flex-col gap-4 ${
                  selectedOptionInternal === item.code
                    ? "border-primary-700 hover:border-primary-400 dark:border-primary-500 dark:hover:border-primary-300"
                    : ""
                }`} // Change border color if the item is selected
                onClick={() => handleItemClick(item.code, item.name)}
              >
                {item.image && (
                  <div className="w-full h-12 flex justify-center items-center">
                    <img
                      src={item.image}
                      alt={item.name}
                      className="object-contain"
                    />
                  </div>
                )}
                <div className="flex items-center justify-center w-full px-3">
                  <p
                    className={`text-center text-sm max-sm:text-xs ${
                      item.name &&
                      item.image &&
                      "w-24 sm:w-[120px] whitespace-nowrap text-ellipsis overflow-hidden"
                    } w-full `}
                  >
                    {item.name}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}

      {buttonText && (
        <div
          className="border border-gray-300 dark:border-gray-600 rounded-lg hover:border-primary-400 dark:hover:border-primary-200 cursor-pointer w-48 mt-2 sm:mt-4 mb-4 flex items-center justify-center"
          onClick={handleButtonClick}
        >
          <p className="text-center p-3 text-primary-700 dark:text-primary-400">
            {buttonText}
          </p>
          <div className="text-lg">&#62;</div>
        </div>
      )}
    </div>
  );
};

export default CommonOptionsGrid;