import React from "react";
import PlanDetailsDesign from "../PlanDetailsDesign";
import MotorPremiumBreakup from "./MotorPremiumBreakup";

const addonKeyMappings: { [key: string]: string } = {
  NonElectricalAccessories: "Non Electrical Accessories",
  ElectricalAccessories: "Electrical Accessories",
  VoluntaryDeductable: "Voluntary Deductible",
  PaCover: "Personal Accident Cover",
  PaidDriverCover: "Paid Driver Cover",
  PaCoverForNamedPassenger: "PA Cover for Named Passenger",
  PaCoverForUnNamedPassenger: "PA Cover for Unnamed Passenger",
  TyreCover: "Tyre Cover",
  NilDepCover: "Zero Depreciation",
  InvoiceCover: "Invoice Cover",
  EngineProtection: "Engine Protection",
  NcbProtection: "NCB Protection",
  Consumables: "Consumables",
  KeyAndLockReplacement: "Key and Lock Replacement",
  AutomobileAssociationMembership: "Automobile Association Membership",
};

interface MotorPlanDetailsProps {
  backendData: any;
  sendingValues: any;
  hasExpiredAfterDate: boolean | null;
  hasPrevExpired: boolean | null;
  hasPrevClaim: boolean | null;
  newNcb: string;
  policyType: string;
}

const MotorPlanDetails: React.FC<MotorPlanDetailsProps> = ({
  backendData,
  sendingValues,
  hasExpiredAfterDate,
  hasPrevExpired,
  hasPrevClaim,
  newNcb,
  policyType,
}) => {
  // Calculate the value for "New No Claim Bonus"
  let noClaimBonus = "0%";
  if (hasExpiredAfterDate !== null) {
    if (hasExpiredAfterDate) {
      noClaimBonus = hasPrevClaim ? "0%" : newNcb;
    } else {
      noClaimBonus = "0%";
    }
  } else if (hasPrevExpired !== null && !hasPrevExpired) {
    noClaimBonus = hasPrevClaim ? "0%" : newNcb;
  }

  // Calculate the tax value
  let tax = "N/A";
  if (backendData) {
    if (backendData.TotalTax) {
      tax = backendData.TotalTax;
    } else if (backendData.CGST && backendData.SGST) {
      tax = (
        parseFloat(backendData.CGST) + parseFloat(backendData.SGST)
      ).toFixed(2);
    }
  }

  // Create the keyValuePairs array dynamically
  const keyValuePairs = [
    {
      key: "Cover Value(IDV)",
      value: backendData ? `₹ ${backendData.IDV}` : "N/A",
    },
    { key: "New No Claim Bonus", value: noClaimBonus },
  ];

  // Create the data array dynamically
  let data = [
    {
      name: "Basic OD Premium",
      content: backendData ? backendData.BasicOD : "N/A",
    },
    {
      name: "Basic TP Premiums",
      content: backendData ? backendData.BasicTP : "N/A",
    },
    {
      name: "Net Premium",
      content: backendData ? backendData.NetPremium : "N/A",
    },
    { name: "Tax", content: tax },
    {
      name: "Total Premium",
      content: backendData ? backendData.TotalPremium : "N/A",
    },
  ];

  if (policyType === "Own Damage") {
    data = data.filter((item) => item.name !== "Basic TP Premium");
  } else if (policyType === "Third Party") {
    data = data.filter((item) => item.name !== "Basic OD Premium");
  }

  if (backendData && backendData.NCBDiscount) {
    const index = data.findIndex((item) => item.name === "Basic TP Premium");
    if (index !== -1) {
      data.splice(index + 1, 0, {
        name: "NCB Discount",
        content: backendData.NCBDiscount,
      });
    }
  }

  // Create the AddonData array dynamically
  const addonData = Object.keys(addonKeyMappings).reduce<
    { key: string; value: string }[]
  >((acc, key) => {
    if (backendData && backendData[key]) {
      acc.push({ key: addonKeyMappings[key], value: backendData[key] });
    }
    return acc;
  }, []);

  return (
    <PlanDetailsDesign
      backendData={backendData}
      sendingValues={sendingValues}
      keyValuePairs={keyValuePairs}
    >
      <MotorPremiumBreakup PremiumBreakupData={data} AddonData={addonData} />
    </PlanDetailsDesign>
  );
};

export default MotorPlanDetails;
