import React from 'react';
import Radio from '../../Radio/Radio';

interface RadioGroupProps {
  label: string;
  description?: string;
  options: {
    name: string
    id: string;
    label: string;
    onChange: () => void;
    checked:boolean;
  }[];
}

const RadioGroup: React.FC<RadioGroupProps> = ({ label, description, options }) => (
  <div className="gap-5">
    <p className="font-semibold text-slate-600 dark:text-zinc-200">{label}</p>
    {description && <p className="text-xs sm:text-[13px] mt-1 sm:mt-0 text-zinc-500 dark:text-zinc-400">{description}</p>}
    <div className="flex items-center gap-6 mt-2">
      {options.map(option => (
        <Radio
          key={option.id}
          name={option.name}
          label={option.label}
          id={option.id}
          checked={option.checked} // Set the checked state
          onChange={option.onChange}
        />
      ))}
    </div>
  </div>
);

export default RadioGroup;
