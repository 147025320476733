import React from "react";

interface PersonalDetailsProps {
  ownerData: any;
  kycDetailsData: any;
  nomineeData: any;
}

const PersonalDetails: React.FC<PersonalDetailsProps> = ({
  ownerData,
  kycDetailsData,
  nomineeData,
}) => {
  return (
    <div className="h-full">
      <dl>
        <div className="space-y-2 ml-4">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-1">
            {/* Owner Data */}
            {ownerData && (
              <>
                <div className="flex py-2 mt-1">
                  <dt className="text-sm md:text-[14px] text-gray-500 dark:text-gray-300 w-1/3 max-sm:text-[12px]">
                    Name:
                  </dt>
                  <dd className="text-sm md:text-[14px] text-gray-900 dark:text-gray-200 font-medium w-2/3 ml-2 max-sm:text-[12px]">
                    {ownerData.firstName} {ownerData.lastName}
                  </dd>
                </div>
                <div className="flex py-2 mt-1">
                  <dt className="text-sm font-medium text-gray-500 dark:text-gray-300 w-1/3 max-sm:text-[12px]">
                    DOB:
                  </dt>
                  <dd className="text-sm text-gray-900 dark:text-gray-200 font-medium w-2/3 ml-2 max-sm:text-[12px]">
                    {ownerData.dateOfBirth}
                  </dd>
                </div>
                <div className="flex py-2">
                  <dt className="text-sm font-medium text-gray-500 dark:text-gray-300 w-1/3 max-sm:text-[12px]">
                    Email:
                  </dt>
                  <dd className="text-sm text-gray-900 dark:text-gray-200 font-medium w-2/3 ml-2 max-sm:text-[12px]">
                    {ownerData.emailId}
                  </dd>
                </div>
                
                {/* KYC Details Data */}
                {kycDetailsData && (
                  <>
                    <div className="flex py-2">
                      <dt className="text-sm font-medium text-gray-500 dark:text-gray-300 w-1/3 max-sm:text-[12px]">
                        PAN:
                      </dt>
                      <dd className="text-sm text-gray-900 dark:text-gray-200 font-medium w-2/3 ml-2 max-sm:text-[12px]">
                        {kycDetailsData.panNumber}
                      </dd>
                    </div>
                    <div className="flex py-2">
                      <dt className="text-sm font-medium text-gray-500 dark:text-gray-300 w-1/3 max-sm:text-[12px]">
                        Aadhar:
                      </dt>
                      <dd className="text-sm text-gray-900 dark:text-gray-200 font-medium w-2/3 ml-2 max-sm:text-[12px]">
                        {kycDetailsData.aadharNumber}
                      </dd>
                    </div>
                  </>
                )}

                {/* Nominee Data */}
                {nomineeData && (
                  <>
                    <div className="flex py-2">
                      <dt className="text-sm font-medium text-gray-500 dark:text-gray-300 w-1/3 max-sm:text-[12px]">
                        Nominee Name:
                      </dt>
                      <dd className="text-sm text-gray-900 dark:text-gray-200 font-medium w-2/3 ml-2 max-sm:text-[12px]">
                        {nomineeData.nomineeName}
                      </dd>
                    </div>
                    <div className="flex py-2">
                      <dt className="text-sm font-medium text-gray-500 dark:text-gray-300 w-1/3 max-sm:text-[12px]">
                        Nominee Age:
                      </dt>
                      <dd className="text-sm text-gray-900 dark:text-gray-200 font-medium w-2/3 ml-2 max-sm:text-[12px]">
                        {nomineeData.nomineeAge}
                      </dd>
                    </div>
                    <div className="flex py-2">
                      <dt className="text-sm font-medium text-gray-500 dark:text-gray-300 w-1/3 max-sm:text-[12px]">
                        Nominee's DOB:
                      </dt>
                      <dd className="text-sm text-gray-900 dark:text-gray-200 font-medium w-2/3 ml-2 max-sm:text-[12px]">
                        {nomineeData.nomineeDob}
                      </dd>
                    </div>
                    <div className="flex py-2">
                      <dt className="text-sm font-medium text-gray-500 dark:text-gray-300 w-1/3 max-sm:text-[12px]">
                        Relationship with Nominee:
                      </dt>
                      <dd className="text-sm text-gray-900 dark:text-gray-200 font-medium w-2/3 ml-4 max-sm:text-[12px]">
                        {nomineeData.nomineeRelationship}
                      </dd>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </dl>
    </div>
  );
};

export default PersonalDetails;