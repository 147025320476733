import { NavItemType } from "../components/Navigation/NavigationItem";
import { Route } from "../routes/types";


let counter = 0; const randomId = () => { const uniqueIdPrefix = 'id'; // Change this prefix to suit your needs 
  counter += 1; return `${uniqueIdPrefix}${counter.toString()}`; };


const OTHER_PAGE_CHILD: NavItemType[] = [
  // archive pages ----------------
  {
    id: randomId(),
    href: "/" as Route,
    name: "Motor Insurance",
    type: "dropdown",
    children: [
      {
        id: randomId(),
        href: "",
        name: "Car Insurance",
      },
      {
        id: randomId(),
        href: "",
        name: "Bike Insurance",
      },
      {
        id: randomId(),
        href: "/work-in-progress" as Route,
        name: "Premium Calculator",
      },
    ],
  },

  // single pages ----------------
  {
    id: randomId(),
    href: "/work-in-progress" as Route,
    name: "Health Insurance",
    type: "dropdown",
    children: [
      {
        id: randomId(),
        href: "/work-in-progress" as Route,
        name: "Mediclaim Policy",
      },
      {
        id: randomId(),
        href: "/work-in-progress" as Route,
        name: "Maternity Insurance",
      },
      {
        id: randomId(),
        href: "/work-in-progress" as Route,
        name: "Best Health Insurance Plans",
      },
      {
        id: randomId(),
        href: "/work-in-progress" as Route,
        name: "Health Insurance for NRI's",
      },
      {
        id: randomId(),
        href: "/work-in-progress" as Route,
        name: "Health Insurance for Parents",
      },
      {
        id: randomId(),
        href: "/work-in-progress" as Route,
        name: "Health Insurance for Children",
      },
      {
        id: randomId(),
        href: "/work-in-progress" as Route,
        name: "Renew Health Infinity Insurance",
      },
      {
        id: randomId(),
        href: "/work-in-progress" as Route,
        name: "Criticall Illness Insurance",
      },
    ],
  },

  // seach pages ----------------
  {
    id: randomId(),
    href: "/work-in-progress",
    name: "Life Insurance",
    type: "dropdown",
    children: [
      {
        id: randomId(),
        href: "/work-in-progress",
        name: "Postal Life Insurance",
      },
      {
        id: randomId(),
        href: "/work-in-progress",
        name: "Term Vs Life Insurance",
      },
    ],
  },

  // author pages ----------------
  {
    id: randomId(),
    href: "/work-in-progress" as Route,
    name: "Track Policy",
  },

  // dashboard pages ----------------
  {
    id: randomId(),
    href: "/work-in-progress",
    name: "News",
    type: "dropdown",
    children: [
      {
        id: randomId(),
        href: "/work-in-progress",
        name: "Investment News",
      },
      {
        id: randomId(),
        href: "/work-in-progress",
        name: "Business News",
      },
      {
        id: randomId(),
        href: "/work-in-progress",
        name: "Car Insurance",
      },
      {
        id: randomId(),
        href: "/work-in-progress",
        name: "Travel Insurance",
      },
      {
        id: randomId(),
        href: "/work-in-progress",
        name: "Health Insurance News  ",
      },
    ],
  },
];

export const NAVIGATION_DEMO_2: NavItemType[] = [
  {
    id: randomId(),
    href: "/",
    name: "Insurance",
    type: "dropdown",
    children: OTHER_PAGE_CHILD,
  },

  {
    id: randomId(),
    href: "/work-in-progress" as Route,
    name: "Claims",
  },

  {
    id: randomId(),
    href: "/work-in-progress" as Route,
    name: "About",
  },
  {
    id: randomId(),
    href: "/work-in-progress" as Route,
    name: "Support",
  },
  {
    id: randomId(),
    href: "/work-in-progress" as Route,
    name: "Renewal",
  },
  // single pages ----------------
  {
    id: randomId(),
    href: "/work-in-progress" as Route,
    name: "Explore",
    type: "dropdown",
    children: [
      {
        id: randomId(),
        href: "/work-in-progress" as Route,
        name: "Contact us",
      },
      {
        id: randomId(),
        href: "/work-in-progress" as Route,
        name: "What's new",
      },
      {
        id: randomId(),
        href: "/work-in-progress" as Route,
        name: "Awards",
      },
    ],
  },
];