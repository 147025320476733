import { createSlice, PayloadAction } from '@reduxjs/toolkit';


export interface MotorData {
    motorPremiumOn: boolean;
    currentStep: number | null;
    viewAllBrands: boolean;
    viewAllModels: boolean;
    selectedCompany: string;
    selectedModel: string;
    selectedFuel: string;
    selectedSubModel: string;
    selectedCC:string;
    companyCode: string;
    modelCode: string;
    fuelCode: string;
    subModelCode: string;
    ccCode:string;
    vechicalCode:string;
    rtoCode:string;
    selectedCode: any;
    sendingRto:string;
    selectedYear: any;
    selectedRegDate: any;
    userSelectedType: string | null;
    hasPrevPolicyExpired: boolean | null;
    hasExpiredAfterDate: boolean | null;
    hasMadePreviousClaim: boolean | null;
    prevTP: boolean;
    newTP: boolean;
    prevCompreh: boolean;
    proposalData: {
        premiumData: { [key: string]: any };
        responseData: { [key: string]: any };
    };
}

const initialState: MotorData = {
    motorPremiumOn: false,
    currentStep:null,
    viewAllBrands: false,
    viewAllModels: false,
    selectedCompany: "",
    selectedModel: "",
    selectedFuel: "",
    selectedSubModel: "",
    selectedCC: "",
    companyCode: "",
    modelCode: "",
    fuelCode: "",
    subModelCode: "",
    ccCode: "",
    vechicalCode:"", //idmotor
    rtoCode:"", // id
    sendingRto:"", // code + name
    selectedCode: "",//"" initial just code
    selectedYear: "",//
    selectedRegDate: "",
    userSelectedType: null,//null initial "DontKnow" or "New"
    hasPrevPolicyExpired: null,//null initial
    hasExpiredAfterDate: null,
    hasMadePreviousClaim: null,
    prevTP: false,
    newTP: false,
    prevCompreh: false,
    proposalData: {
        premiumData: {},
        responseData: {}
    }
}

const MotorSlice = createSlice({
    name: 'motorDetails',
    initialState,
    reducers: {
        setMotorPremiumOn:(state,action:PayloadAction<boolean>)=>{
            state.motorPremiumOn=action.payload
          },
        setCurrentStep: (state, action: PayloadAction<number | null>) => {
            state.currentStep = action.payload;
        },
        setViewAllBrands:(state,action:PayloadAction<boolean>)=>{
            state.viewAllBrands=action.payload
          },
          setViewAllModels:(state,action:PayloadAction<boolean>)=>{
            state.viewAllModels=action.payload
          },
        setSelectedCompany: (state, action: PayloadAction<string>) => {
            state.selectedCompany = action.payload;
        },
        setSelectedModel: (state, action: PayloadAction<string>) => {
            state.selectedModel = action.payload;
        },
        setSelectedFuel: (state, action: PayloadAction<string>) => {
            state.selectedFuel = action.payload;
        },
        setSelectedSubModel: (state, action: PayloadAction<string>) => {
            state.selectedSubModel = action.payload;
        },
        setSelectedCC: (state, action: PayloadAction<string>) => {
            state.selectedCC = action.payload;
        },
        setCompanyCode: (state, action: PayloadAction<string>) => {
            state.companyCode = action.payload;
        },
        setModelCode: (state, action: PayloadAction<string>) => {
            state.modelCode = action.payload;
        },
        setFuelCode: (state, action: PayloadAction<string>) => {
            state.fuelCode = action.payload;
        },
        setSubModelCode: (state, action: PayloadAction<string>) => {
            state.subModelCode = action.payload;
        },
        setCcCode: (state, action: PayloadAction<string>) => {
            state.ccCode = action.payload;
        },
        setVechicalCode: (state, action: PayloadAction<string>) => {
            state.vechicalCode = action.payload;
        },
        setRtoCode: (state, action: PayloadAction<string>) => {
            state.rtoCode = action.payload;
        },
        setSendingRto: (state, action: PayloadAction<any>) => {
            state.sendingRto = action.payload;
        },
        setSelectedCode: (state, action: PayloadAction<any>) => {
            state.selectedCode = action.payload;
        },
        setSelectedYear: (state, action: PayloadAction<any>) => {
            state.selectedYear = action.payload;
        },
        setSelectedRegDate: (state, action: PayloadAction<any>) => {
            state.selectedRegDate = action.payload;
        },
        setUserSelectedType: (state, action: PayloadAction<string | null>) => {
            state.userSelectedType = action.payload;
        },
        setHasExpiredAfterDate: (state, action: PayloadAction<boolean | null>) => {
            state.hasExpiredAfterDate = action.payload;
        },
      setHasPrevPolicyExpired:(state,action:PayloadAction<boolean | null>)=>{
        state.hasPrevPolicyExpired=action.payload
      },    
      setHasMadePreviousClaim:(state,action:PayloadAction<boolean | null>)=>{
        state.hasMadePreviousClaim=action.payload
      },
      setPrevTP:(state,action:PayloadAction<boolean>)=>{
        state.prevTP=action.payload
      },
      setNewTP:(state,action:PayloadAction<boolean>)=>{
        state.newTP=action.payload
      },
      setPrevCompreh:(state,action:PayloadAction<boolean>)=>{
        state.prevCompreh=action.payload
      },
      setPremiumData: (state, action: PayloadAction<{ [key: string]: any }>) => {
        state.proposalData.premiumData = action.payload;
      },
      setResponseData: (state, action: PayloadAction<{ [key: string]: any }>) => {
        state.proposalData.responseData = action.payload;
      }
      
    }
});

export const {
    setMotorPremiumOn,
    setCurrentStep,
    setViewAllBrands,
    setViewAllModels,
    setSelectedCompany,
    setSelectedModel,
    setSelectedFuel,
    setSelectedSubModel,
    setSelectedCC,
    setCompanyCode,
    setModelCode,
    setFuelCode,
    setSubModelCode,
    setCcCode,
    setVechicalCode,
    setRtoCode,
    setSendingRto,
    setSelectedCode,
    setSelectedYear,
    setSelectedRegDate,
    setUserSelectedType,
    setHasExpiredAfterDate,
    setHasPrevPolicyExpired,
    setHasMadePreviousClaim,
    setPrevTP,
    setNewTP,
    setPrevCompreh,
    setResponseData,
    setPremiumData,
} = MotorSlice.actions;

export default MotorSlice.reducer;
