import React, { useEffect, useState } from "react";
import ButtonPrimary from "../Button/ButtonPrimary";
import { carProposalPageData } from "../../data/Proposal/carProposal/carProposalData";
import Label from "../Label/Label";
import Input from "../Input/Input";
import Select from "../Select/Select";
import { useDispatch } from "react-redux";
import {
  updateFormState,
  updateKycDetails,
} from "../../reducers/slices/carBikeProposalSlice";
import { readFromDB, writeToDB } from "../../utils/indexDBUtils";
import ReviewProposal from "./ReviewProposal";
import { fetchSSEData } from "../Premium/PremiumFunctions/fetchSSEData";
import { useSelector } from "react-redux";
import { RootState } from "../../reducers/store/store";
import kycData from "../../data/Proposal/carProposal/kycData";
import motorProposalUserData from "../../data/Proposal/carProposal/motorProposalUserData";
import {
  setKYCData,
  setProposalData,
} from "../../reducers/slices/commonProposalSlice";
import { validatePincode } from "../../utils/formValidations";
// import { setResponseData } from "../../reducers/slices/MotorSlice";

interface KYCVerifiedData {
  [key: string]: any;
}
interface ProposalData {
  [key: string]: any;
}

export const verifyKycToIndexdb = async (status: boolean) => {
  await writeToDB("KYCStatus", status);
};

const KYC: React.FC = () => {
  const [selectedDocument, setSelectedDocument] = useState<string>("");
  const [uploadRequest, setUploadRequest] = useState<boolean>(false);
  const [noKyc, setNoKyc] = useState<boolean>(false);
  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);
  const [isKycVerified, setIsKycVerified] = useState<boolean>(false);
  const [insCompanyID, setInsCompanyID] = useState<string>("");
  const [showKycFailureMessage, setShowKycFailureMessage] = useState(false);
  const [showProposalFailureMessage, setShowProposalFailureMessage] =
    useState(false);
  const [formErrors, setFormErrors] = useState<{ [key: string]: string }>({});
  const [proposalResponseData, setProposalResponseData] = useState<
    ProposalData[]
  >([]);
  const dispatch = useDispatch();
  // const formState = useSelector((state: RootState) => state.form);
  const isLoading = useSelector(
    (state: RootState) => state.commonPremiumData.loadingStates["Kyc&Proposal"] || false
  );
  const motorData = useSelector((state: RootState) => state.motorDetails);
  const [formData, setFormData] = useState({
    panNumber: "",
    aadharNumber: "",
    document: "",
    documentUpload: "",
    pincode: "",
    city: "",
    state: "",
    accessToken: "",
    pullId: "",
  });
  const [insuranceItem, setInsuranceItem] = useState<string>("");
  const [start, setStart] = useState<string>("");
  // const kycDatas = kycData(formState, motorData);
  const [kycVerifiedData, setKycVerifiedData] = useState<KYCVerifiedData[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const { proposalData } = useSelector(
    (state: RootState) => state.motorDetails
  );
  let masterwithoutspace =
    proposalData.responseData.CompanyName.toLowerCase().replace(/\s+/g, "");

  const id = useSelector((state: RootState) => state.commonPremiumData.id);
  const buttonClass = isKycVerified
    ? ""
    : "cursor-not-allowed font-bold text-zinc-950 text-opacity-60 bg-zinc-200 dark:bg-zinc-800 dark:text-gray-500 hover:dark:bg-zinc-800 hover:dark:text-gray-500 hover:text-gray-950 hover:text-opacity-60 hover:bg-zinc-200";
  const button2Class = !isKycVerified
    ? ""
    : "cursor-not-allowed font-bold text-zinc-950 text-opacity-60 bg-zinc-200 dark:bg-zinc-800 dark:text-gray-500 hover:dark:bg-zinc-800 hover:dark:text-gray-500 hover:text-gray-950 hover:text-opacity-60 hover:bg-zinc-200";

  useEffect(() => {
    const funcData = async () => {
      const insItem = await readFromDB("whichTab");
      if (insItem && typeof insItem === "string") {
        const lowercaseInsuranceItem = (insItem as string).toLowerCase();
        setInsuranceItem(lowercaseInsuranceItem);
      } else {
        console.log("No insurance item found or the item is not a string.");
      }

      if (
        proposalData.responseData.CompanyName === "Shriram General Insurance"
      ) {
        setUploadRequest(true);
        setNoKyc(true);
      }
    };
    funcData();
  }, [proposalData]);


  useEffect(() => {
    const fetchData = async () => {
      const data = await readFromDB("motor-proposal-kycDetailsData");
      if (data) {
        setFormData(data);
        setSelectedDocument(data.document);
      }
      const insCompanyID = await readFromDB("vehicle-documentID");
      console.log("insCompanyID......", insCompanyID);
      setInsCompanyID(insCompanyID);
    };
    const fetchKYCStatus = async () => {
      const data = await readFromDB("KYCStatus");
      console.log(data);
      
      if (data) {
        setIsKycVerified(data);
      }
    };
    window.scrollTo(0, 0);
    fetchData();
    fetchKYCStatus();
  }, []);

  const validateForm = () => {
    const errors: { [key: string]: string } = {};
    const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
    const aadharRegex = /^[0-9]{12}$/;

    if (!formData.panNumber || !panRegex.test(formData.panNumber)) {
      errors.panNumber = "Invalid PAN number.";
    }
    if (!formData.aadharNumber || !aadharRegex.test(formData.aadharNumber)) {
      errors.aadharNumber = "Invalid Aadhar number.";
    }
    if (!formData.pincode || validatePincode(formData.pincode)) {
      errors.pincode = "Invalid Pincode.";
    }
    if (!formData.city) {
      errors.city = "City is required.";
    }
    if (!formData.state) {
      errors.state = "State is required.";
    }

    return errors;
  };

  const handleDocumentChange = async (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const { value } = event.target;
    setSelectedDocument(value);
    console.log("fsgfgfsg", value);

    setFormData({
      ...formData,
      document: value,
    });
    if(!noKyc){setIsKycVerified(false);
    await verifyKycToIndexdb(false);}
    setProposalResponseData([]);
 
  };

  const handleChange = async (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = event.target;

    // Transform PAN to uppercase if name is panNumber
    const updatedValue =
      name === "panNumber" ? value.slice(0, 10).toUpperCase() : value;

    // Update Aadhar to allow only numeric characters and limit to 12 digits
    const updatedAadharValue =
      name === "aadharNumber" ? value.replace(/\D/g, "").slice(0, 12) : value;

    if (name === "pincode") {
      const pincodeError = validatePincode(value);
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        pincode: pincodeError || "",
      }));
    }

    setFormData({
      ...formData,
      [name]: name === "panNumber" ? updatedValue : updatedAadharValue,
    });
    setProposalResponseData([]);

    if(!noKyc){setIsKycVerified(false);
    await verifyKycToIndexdb(false);}

  };

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64Format = reader.result as string;
        const base64String = base64Format.split(",")[1];

        setFormData({
          ...formData,
          documentUpload: base64String,
        });
      };
      reader.readAsDataURL(file);
    }
    setProposalResponseData([]);
    if(!noKyc){setIsKycVerified(false);
    await verifyKycToIndexdb(false);}
  };

  const functionforformstate = async () => {
    const existingFormState = await readFromDB("motor-proposal-FormState");
    const updatedFormState = { ...existingFormState, kycDetails: formData };
    console.log("hhhhhhhh", updatedFormState);
    dispatch(updateFormState(updatedFormState));
    await writeToDB("motor-proposal-FormState", updatedFormState);
  };

  useEffect(() => {
    console.log("done");
    dispatch(updateKycDetails(formData));
    functionforformstate();
  }, [formData]);

  const handleVerifyKYC = async () => {
    setProposalResponseData([]);
    const errors = validateForm();
    setFormErrors(errors);
    setLoading(true);
    if (Object.keys(errors).length > 0) {
      return;
    }
    // dispatch(updateKycDetails(formData));

    try {
      // dispatch(updateKycDetails(formData));

      await writeToDB("motor-proposal-kycDetailsData", formData);
      const existingFormState = await readFromDB("motor-proposal-FormState");
      // const updatedFormState = { ...existingFormState, kycDetails: formData };

      const kycDatas = kycData(existingFormState, motorData);
      console.log("kycDatas for kyc verification", kycDatas);

      // await writeToDB("motor-proposal-FormState", updatedFormState);
      const url =
        proposalData.responseData.CompanyName === "Bajaj Allianz"
          ? `https://aim-core.insurancepolicy4us.com/dummy/id=${id}/verifykyc/${insuranceItem}/${masterwithoutspace}`
          : `https://aim-core.insurancepolicy4us.com/dummy/id=${id}/verifykyc/${masterwithoutspace}`;

          const uniqueKey = "Kyc&Proposal"

      console.log("ddydhfhdgdhfgdy", url);

      const token = `Bearer ${import.meta.env.VITE_API_TOKEN}` as string;

      // Fetch SSE Data of KYC
      fetchSSEData(kycDatas, url, token,uniqueKey, setKycVerifiedData, dispatch);
      // setIsKycVerified(true); //for dummy
    } catch (error) {
      console.error("Error saving vehicle details:", error);
      alert("An error occurred while saving your details. Please try again.");
    }
  };

  useEffect(() => {
    console.log("kycVerifiedData after verification of kyc", kycVerifiedData);
    if (kycVerifiedData.length > 0) {

      if (proposalData.responseData.CompanyName === "National Insurance") {
        console.log("national data", kycVerifiedData);
        if (
          (kycVerifiedData[0].LoginURL !== null ||
            kycVerifiedData[0].LoginURL !== "" ||
            kycVerifiedData[0].LoginURL !== undefined) &&
          (kycVerifiedData[0].PullId !== "" ||
            kycVerifiedData[0].PullId !== null) &&
          (kycVerifiedData[0].AccessToken !== null ||
            kycVerifiedData[0].AccessToken !== "")
        ) {

          // Save pullId and accessToken to IndexedDB before redirecting
          (async () => {
            try {
              const existingFormState = await readFromDB(
                "motor-proposal-FormState"
              );
              const updatedFormState = {
                ...existingFormState,
                kycDetails: {
                  ...formData,
                  pullId: kycVerifiedData[0].PullId,
                  accessToken: kycVerifiedData[0].AccessToken,
                },
              };
              const kycDetailsData = {
                ...formData,
                pullId: kycVerifiedData[0].PullId,
                accessToken: kycVerifiedData[0].AccessToken,
              };
              setIsKycVerified(true);
              setStart("stop");
              setLoading(false);
              setShowKycFailureMessage(false);
              // Write the updated kycDetailsData to IndexedDB
              await writeToDB("motor-proposal-kycDetailsData", kycDetailsData);
              dispatch(updateFormState(updatedFormState));
              await writeToDB("motor-proposal-FormState", updatedFormState);
              await verifyKycToIndexdb(true);
              dispatch(setKYCData(kycVerifiedData));
              await writeToDB("KycVerifiedData", kycVerifiedData);


              window.location.href = kycVerifiedData[0].LoginURL;
            } catch (error) {
              console.error("Error saving KYC data to IndexedDB:", error);
            }
          })();
        } else {
          setLoading(false);
          setIsKycVerified(false);
          verifyKycToIndexdb(false);
          setShowKycFailureMessage(true);
        }
      } else if (
        kycVerifiedData[0].PAN_NO === formData.panNumber &&
        (kycVerifiedData[0].CKYC_NO !== null ||
          kycVerifiedData[0].CKYC_NO !== "")
      ) {
        (async () => {
          try {            
            setIsKycVerified(true);
            setStart("stop");
            setLoading(false);
            setShowKycFailureMessage(false);
            await verifyKycToIndexdb(true);
            dispatch(setKYCData(kycVerifiedData));
            await writeToDB("KycVerifiedData", kycVerifiedData);
            
          } catch (error) {
            console.error("Error saving KYC data to IndexedDB:", error);
          }
        })();
      } else {
        setLoading(false);
        setIsKycVerified(false);
        verifyKycToIndexdb(false);
        setShowKycFailureMessage(true);
      }
      console.log("kycVerifiedData after verification of kyc", kycVerifiedData);
    } else {
      if(start==="start"){  setLoading(false);
        setIsKycVerified(false);
      verifyKycToIndexdb(false);
      setShowKycFailureMessage(true);}
    }
  }, [kycVerifiedData]);

  useEffect(() => {
    if (isLoading) {
      setStart("start");
    }
  }, [isLoading]);

  useEffect(() => {
    if (noKyc) {
      setIsKycVerified(true);
    }
  }, [noKyc]);

  useEffect(() => {
    const handleProposalResponse = async () => {
      if (proposalResponseData.length > 0 && (isKycVerified || noKyc)) {
        const validProposal = proposalResponseData.some((data) => {
          const hasTotalPremium =
            data.TotalPremium &&
            data.TotalPremium !== undefined &&
            data.TotalPremium !== null &&
            data.TotalPremium !== "" &&
            typeof data.TotalPremium !== "object";
  
          const hasQuoteID =
            data.QuoteID &&
            data.QuoteID !== undefined &&
            data.QuoteID !== null &&
            data.QuoteID !== "" &&
            typeof data.QuoteID !== "object";
  
          const hasProposalNo =
            data.ProposalNo &&
            data.ProposalNo !== undefined &&
            data.ProposalNo !== null &&
            data.ProposalNo !== "" &&
            typeof data.ProposalNo !== "object";
  
          // Check the logic for validity
          if (hasQuoteID && hasTotalPremium) {
            return true; // TotalPremium is present and valid
          } else if (
            hasQuoteID &&
            !hasTotalPremium &&
            proposalData.responseData.CompanyName === "Bajaj Allianz"
          ) {
            return true; // QuoteID is present and valid, but TotalPremium is missing
          } else if (hasProposalNo && hasTotalPremium) {
            return true; // Both ProposalNo and TotalPremium are present and valid
          }
  
          return false; // None of the conditions are met
        });
  
        if (validProposal) {
          dispatch(setProposalData(proposalResponseData));
          console.log(proposalResponseData[0]);
  
          await writeToDB("responseObjectFromProposal", {
            responseData: proposalResponseData[0],
          });

          setShowProposalFailureMessage(false);
          setStart("stop");
          setLoading(false);
          setIsPopupOpen(true);
        } else {
          setLoading(false);
          setShowProposalFailureMessage(true);
          setIsPopupOpen(false);
        }
      } else {
        setLoading(false);
        setShowProposalFailureMessage(true);
        setIsPopupOpen(false);
      }
      console.log("Data after running proposal api", proposalResponseData);
    };
  
    handleProposalResponse(); // Call the async function
  }, [proposalResponseData, isKycVerified, noKyc, proposalData, dispatch]);
  

  const handleSubmit = async () => {
    console.log("gfcgcgcg");
    
    if (isKycVerified) {
      setLoading(true);
      // await functionforformstate();

      if (
        proposalData.responseData.CompanyName === "Shriram General Insurance"
      ) {
        await writeToDB("motor-proposal-kycDetailsData", formData);
      }
      const existingFormState = await readFromDB("motor-proposal-FormState");
      console.log("bbbbbbbbb", existingFormState);

      const motorProposalUserDatas = motorProposalUserData(
        existingFormState,
        motorData,
        insCompanyID
      );

      console.log("motorProposalUserDatas", motorProposalUserDatas);
      const policyType = motorProposalUserDatas.policyType?.toLowerCase(); // Extract policyType from updatedValuesState

      if (!insuranceItem || !policyType) {
        console.error("Insurance item or policy type is missing");
        return;
      }
      const url = `https://aim-core.insurancepolicy4us.com/dummy/id=${id}/generateproposal/${policyType}/${insuranceItem}/${masterwithoutspace}`;
      const uniqueKey = "Kyc&Proposal"
      const token = `Bearer ${import.meta.env.VITE_API_TOKEN}` as string;

      // Fetch SSE Data
      fetchSSEData(
        motorProposalUserDatas,
        url,
        token,
        uniqueKey,
        setProposalResponseData,
        dispatch
      );
    }
  };

  // console.log("Data after running proposal api", proposalResponseData);

  useEffect(() => {
    dispatch(setKYCData(kycVerifiedData));
    dispatch(setProposalData(proposalResponseData));
  }, [kycVerifiedData, proposalResponseData]);

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  return (
    <div>
      <div className="grid md:grid-cols-4 gap-6">
        <label className="block md:col-span-2">
          <Label>{carProposalPageData.KYC.label1}</Label>
          <Input
            type="text"
            className="mt-1"
            onChange={handleChange}
            name="panNumber"
            value={formData.panNumber}
            style={{ textTransform: "uppercase" }}
            required
          />
          {formErrors.panNumber && (
            <p className="text-red-600 text-xs mt-1">{formErrors.panNumber}</p>
          )}
        </label>
        <label className="block md:col-span-2">
          <Label>{carProposalPageData.KYC.label2}</Label>
          <Input
            type="text"
            className="mt-1"
            onChange={handleChange}
            name="aadharNumber"
            value={formData.aadharNumber}
            required
          />
          {formErrors.aadharNumber && (
            <p className="text-red-600 text-xs mt-1">
              {formErrors.aadharNumber}
            </p>
          )}
        </label>
        {uploadRequest && (
          <>
            <label className="block md:col-span-4">
              <Label>{carProposalPageData.KYC.label3}</Label>
              <Select
                className="mt-1"
                name="document"
                onChange={handleDocumentChange}
                value={selectedDocument}
              >
                <option value="-1"></option>
                {carProposalPageData.KYC.Doc.map((document, index) => (
                  <option key={index} value={document}>
                    {document}
                  </option>
                ))}
              </Select>
            </label>
            {selectedDocument && (
              <label className="block md:col-span-4">
                <Label>
                  {carProposalPageData.KYC.label4} {selectedDocument} :
                </Label>
                <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-zinc-300 dark:border-zinc-700 border-dashed rounded-md">
                  <div className="space-y-1 text-center">
                    <svg
                      className="mx-auto h-12 w-12 text-gray-400"
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 48 48"
                      aria-hidden="true"
                    >
                      <path
                        d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                    </svg>
                    <div className="flex flex-col sm:flex-row text-sm text-gray-500">
                      <label
                        htmlFor="file-upload"
                        className={`relative cursor-pointer rounded-md font-medium ${
                          formData.documentUpload &&  formData.documentUpload !== ""
                            ? "text-primary-800"
                            : "text-gray-300 hover:text-primary-800 "
                        } focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500`}
                      >
                        {formData.documentUpload &&  formData.documentUpload !== "" ? (
                          <span>Uploaded successfully</span>
                        ) : (
                          <span>Upload a PDF file</span>
                        )}
                        <input
                          id="file-upload"
                          name="documentUpload"
                          type="file"
                          accept=".pdf"
                          className="sr-only"
                          onChange={handleFileChange}
                        />
                      </label>
                      {formData.documentUpload &&  formData.documentUpload !== "" ? (
                       <></> 
                      ):(<p className="pl-1">or drag and drop</p>)}
                    </div>
                    {formData.documentUpload && formData.documentUpload !== "" ? (
                      <p className="text-xs text-gray-400">
                      You can change if required
                    </p> 
                    ) : (
                      <p className="text-xs text-gray-400">PDF up to 2MB</p>
                    )}
                  </div>
                </div>
              </label>
            )}
          </>
        )}
        <label className="block md:col-span-4">
          <Label>{carProposalPageData.KYC.label5}</Label>
          <Input
            type="text"
            className="mt-1"
            onChange={handleChange}
            name="pincode"
            value={formData.pincode}
            required
          />
          {formErrors.pincode && (
            <p className="text-red-600 text-xs mt-1">{formErrors.pincode}</p>
          )}
        </label>
        <label className="block md:col-span-4">
          <Label>{carProposalPageData.KYC.label6}</Label>
          <Input
            type="text"
            className="mt-1"
            onChange={handleChange}
            name="city"
            value={formData.city}
            required
          />
          {formErrors.city && (
            <p className="text-red-600 text-xs mt-1">{formErrors.city}</p>
          )}
        </label>
        <label className="block md:col-span-4">
          <Label>{carProposalPageData.KYC.label7}</Label>
          <Input
            type="text"
            className="mt-1"
            onChange={handleChange}
            name="state"
            value={formData.state}
            required
          />
          {formErrors.state && (
            <p className="text-red-600 text-xs mt-1">{formErrors.state}</p>
          )}
        </label>
        {/* {isKycVerified ? (
          <ButtonPrimary
            className={`md:col-span-1 ${
              isKycVerified &&
              "bg-green-700 hover:bg-green-700 cursor-not-allowed"
            }`}
            disabled
          >
            {carProposalPageData.KYC.button2}
          </ButtonPrimary>
        ) : (
          <ButtonPrimary className="md:col-span-1" onClick={handleVerifyKYC}>
            {carProposalPageData.KYC.button1}
          </ButtonPrimary>
        )} */}
        {!noKyc &&
          (isKycVerified ? (
            <ButtonPrimary className={`md:col-span-1 ${button2Class}`} disabled>
              {carProposalPageData.KYC.button2}
            </ButtonPrimary>
          ) : (
            <ButtonPrimary className="md:col-span-1" onClick={handleVerifyKYC}>
              {isLoading ? (
                <span className="flex items-center">
                  <svg
                    className="animate-spin h-5 w-5 mr-3"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A8.004 8.004 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                  Loading...
                </span>
              ) : (
                carProposalPageData.KYC.button1
              )}
            </ButtonPrimary>
          ))}
        <div className={`${!noKyc ? "md:col-span-2" : "md:col-span-1"} `}></div>
        <ButtonPrimary
          onClick={handleSubmit}
          className={`${!noKyc ? "md:col-span-1" : "md:col-span-4"} ${
            !isKycVerified && " cursor-not-allowed"
          }  ${buttonClass}`}
          disabled={!isKycVerified || isLoading}
        >
          {isKycVerified && isLoading ? (
            <span className="flex items-center">
              <svg
                className="animate-spin h-5 w-5 mr-3"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A8.004 8.004 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
              Loading...
            </span>
          ) : (
            carProposalPageData.KYC.button3
          )}
        </ButtonPrimary>
        {
  (!noKyc && !isKycVerified && start === "start" && !isLoading && !loading && showKycFailureMessage) && (
    <p className="md:col-span-4 text-xs text-red-500 fade-in-delay">
      We couldn't verify your KYC details. Please ensure all information is accurate.
    </p>
  )
}
{
  (noKyc || isKycVerified) && start === "start" && !isLoading && !loading && showProposalFailureMessage && (
    <p className="md:col-span-4 text-xs text-red-500 fade-in-delay">
      We couldn't process your proposal. There may be a technical issue, or some information might be incorrect. Please double-check your details and try again.
    </p>
  )
}
      </div>
      {isPopupOpen && <ReviewProposal closePopup={closePopup} />}
    </div>
  );
};

export default KYC;
