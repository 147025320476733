import React from "react";
import { BadgeP } from "./HeaderComponent";

interface SmallScreenHeaderDetailsProps {
  headerData: any;
  fullDiv: boolean;
  isCarBike: boolean;
  isDontKnow: boolean;
  isNew: boolean;
  setFullDiv: React.Dispatch<React.SetStateAction<boolean>>;
  newTP: boolean;
  prevTP: boolean;
}

const SmallScreenHeaderDetails: React.FC<SmallScreenHeaderDetailsProps> = ({
  headerData,
  fullDiv,
  isCarBike,
  isDontKnow,
  isNew,
  setFullDiv,
  prevTP,
  newTP,
}) => (
  <div
    className={`justify-between ${
      fullDiv ? "max-sm:hidden" : "max-sm:flex"
    } sm:hidden `}
  >
    <div className="flex flex-col w-10/12 ">
      <div className="w-full">
        <div className="pb-2 pt-1">
          <BadgeP text="Your Details" />
        </div>
        {headerData.title1 &&
          headerData.labelValue1 &&
          headerData.labelValue3 &&
          headerData.labelValue2 && (
            <p className="overflow-hidden whitespace-nowrap text-ellipsis text-sm font-bold">
              {headerData.title1}, {headerData.labelValue1},{" "}
              {headerData.labelValue3}, {headerData.labelValue2}
            </p>
          )}
      </div>

      <div className="w-full">
        <p className="overflow-hidden whitespace-nowrap text-ellipsis text-xs mt-2">
          {isCarBike &&
            isDontKnow &&
            headerData.label4 &&
            headerData.label4 !== " " && (
              <span>
                {headerData.label4}:{" "}
                {headerData.hasPrevExpired &&
                headerData.hasExpiredAfterDate !== null &&
                !headerData.hasExpiredAfterDate
                  ? "Expired, More Than 90 Days"
                  : headerData.labelValue4}
                ,
              </span>
            )}
          {isCarBike &&
            isNew &&
            headerData.label8 &&
            headerData.label8 !== " " && (
              <span>
                {" "}
                {headerData.label8}: {headerData.labelValue8},
              </span>
            )}
          {isCarBike &&
            !prevTP &&
            !newTP &&
            isDontKnow &&
            headerData.hasPrevClaim !== null &&
            headerData.label5 &&
            headerData.label5 !== " " && (
              <span>
                {" "}
                {headerData.label5}: {headerData.labelValue5 ? "Yes" : "No"},
              </span>
            )}
          {isCarBike &&
            !prevTP &&
            !newTP &&
            isDontKnow &&
            headerData.hasPrevClaim === false &&
            headerData.label6 &&
            headerData.label6 !== " " && (
              <span>
                {" "}
                {headerData.label6}: {headerData.labelValue6},
              </span>
            )}
          {isCarBike &&
            !prevTP &&
            !newTP &&
            isDontKnow &&
            headerData.hasPrevClaim === false &&
            headerData.label7 &&
            headerData.label7 !== "" && (
              <span>
                {" "}
                {headerData.label7}: {headerData.labelValue7},
              </span>
            )}
          {headerData.label2 && headerData.label2 !== " " && (
            <span>
              {" "}
              {headerData.label2}: {headerData.labelValue2}
            </span>
          )}
        </p>
      </div>
    </div>

    <div className="flex items-center justify-center pt-4">
      <div className="ml-5 mr-3" onClick={() => setFullDiv(true)}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M19 9l-7 7-7-7"
          />
        </svg>
      </div>
    </div>
  </div>
);

export default SmallScreenHeaderDetails;
