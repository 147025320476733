import { FormState } from "../../../reducers/slices/carBikeProposalSlice";
import { MotorData } from "../../../reducers/slices/MotorSlice";

//Inputs from the user
const motorProposalUserData = (formState: FormState, motorData: MotorData,insCompanyID:any) => {

  return {
    salutation: formState.ownerDetails.salutation,
    fullName: `${formState.ownerDetails.firstName} ${formState.ownerDetails.lastName}`,
    firstName: formState.ownerDetails.firstName,
    middleName: "",//empty string
    lastName: formState.ownerDetails.lastName,
    maritalStatus: formState.ownerDetails.maritalStatus,
    mobileNo: formState.ownerDetails.mobileNo,
    dateOfBirth: formState.ownerDetails.dateOfBirth,
    gender: formState.ownerDetails.gender,
    occupation: formState.ownerDetails.occupation,
    emailId: formState.ownerDetails.emailId,
    panNumber: formState.kycDetails.panNumber,
    vehicleRegistered: formState.ownerDetails.vehicleRegistered ? "Yes": "No",
    aadharNumber: formState.kycDetails.aadharNumber,
    address1: formState.communicationAddress.address1,
    address2: formState.communicationAddress.address2,
    address3: formState.communicationAddress.address3,
    address4: formState.communicationAddress.address4,
    P_PINCODE: formState.kycDetails.pincode,
    city: formState.kycDetails.city.toUpperCase(),
    state: formState.kycDetails.state,
    nomineeName: formState.nomineeDetails.nomineeName,
    nomineeDob: formState.nomineeDetails.nomineeDob,
    nomineeAge: formState.nomineeDetails.nomineeAge,
    nomineeRelationship: formState.nomineeDetails.nomineeRelationship,
    regAddress1: formState.vehicleDetails.regAddress1,
    regAddress2: formState.vehicleDetails.regAddress2,
    regAddress3: formState.vehicleDetails.regAddress3,
    regAddress4: formState.vehicleDetails.regAddress4,
    regPincode: formState.vehicleDetails.regPincode,
    InsCompanyID: insCompanyID, //from verified vehicle
    registrationDate: motorData.proposalData.premiumData.registrationDate,
    // manufacturingYear: "",//empty string
    prevPolicyClaim: motorData.proposalData.premiumData.prevPolicyClaim || "",
    previousNcb: motorData.proposalData.premiumData.previousNcb || "",
    currentNcb: motorData.proposalData.premiumData.currentNcb || "",
    // idv:"2398004.0",
    idv: motorData.proposalData.responseData.IDV || "",
    policyType: motorData.proposalData.premiumData.policyType || "",
    policyStartDate: motorData.proposalData.premiumData.policyStartDate || "",
    // policyEndDate: motorData.proposalData.premiumData.policyEndDate || "",
    prevPolicyStartDate: motorData.proposalData.premiumData.prevPolicyStartDate || "",
    prevPolicyEndDate: motorData.proposalData.premiumData.prevPolicyEndDate || "",
    prevPolicyNo: formState.previousInsurer.prevPolicyNo,
    prevPolicyType: motorData.proposalData.premiumData.prevPolicyType || "",
    prevInsurer: formState.previousInsurer.prevInsurer,
    prevInsurerAddress: formState.previousInsurer.prevInsurerAddress,
    registrationNo: ('registrationNo' in formState.vehicleDetails && formState.vehicleDetails.registrationNo !== "") 
    ? formState.vehicleDetails.registrationNo 
    : motorData.proposalData.premiumData.registrationNo,
    engineNo: formState.vehicleDetails.engineNo,
    chassisNo: formState.vehicleDetails.chassisNo,
    P4US_RTO_ID: motorData.proposalData.premiumData.P4US_RTO_ID || "",
    rtoName: motorData.proposalData.premiumData.rtoName || "",
    elecAccCover: motorData.proposalData.premiumData.elecAccCover || false,
    valueofElecAccCover: motorData.proposalData.premiumData.valueofElecAccCover || "",
    nonElecAccCover: motorData.proposalData.premiumData.nonElecAccCover || false,
    valueofNonElecAccCover: motorData.proposalData.premiumData.valueofNonElecAccCover || "",
    voluntaryDeductable: motorData.proposalData.premiumData.voluntaryDeductable || false,
    valueofVoluntaryDeductable: motorData.proposalData.premiumData.valueofVoluntaryDeductable || "",
    paCover: motorData.proposalData.premiumData.paCover || false,
    paidDriverCover: motorData.proposalData.premiumData.paidDriverCover || false,
    valueofPaidDriverCover: motorData.proposalData.premiumData.valueofPaidDriverCover || "",
    paCoverForNamedPassenger: motorData.proposalData.premiumData.paCoverForNamedPassenger || false,
    paCoverForUnnamedPassenger: motorData.proposalData.premiumData.paCoverForUnnamedPassenger || false,
    valueofPaCoverForUnnamedPassenger: motorData.proposalData.premiumData.valueofPaCoverForUnnamedPassenger || "",
    tyreCover: motorData.proposalData.premiumData.tyreCover || false,
    nilDepCover: motorData.proposalData.premiumData.nilDepCover || false,
    invoiceCover: motorData.proposalData.premiumData.invoiceCover || false,
    engineProtectorCover: motorData.proposalData.premiumData.engineProtectorCover || false,
    ncbProtectionCover: motorData.proposalData.premiumData.ncbProtectionCover || false,
    consumableCover: motorData.proposalData.premiumData.consumableCover || false,
    keyAndLockReplacementCover: motorData.proposalData.premiumData.keyAndLockReplacementCover || false,
    lossOfPersonalBelongings: motorData.proposalData.premiumData.lossOfPersonalBelongings || false,
    automobileAssMembershipCover: motorData.proposalData.premiumData.automobileAssMembershipCover || false,
    quoteId: motorData.proposalData.responseData.QuoteID || "",
    tpExpiryDate: motorData.proposalData.premiumData.tpExpiryDate || "",
    tpInsurer: formState.previousInsurer.tpInsurer,
    tpPolicyNumber: formState.previousInsurer.tpPolicyNumber,
    isItUnderLoan: formState.previousInsurer.isItUnderLoan || "No",
    loanProvider: formState.previousInsurer.loanProvider || "",
    loanProviderAddress: formState.previousInsurer.loanProviderAddress || "",
    previousPolicyExpiredAfterDays: motorData.proposalData.premiumData.previousPolicyExpiredAfterDays || "",
    dateOfPurchase:motorData.proposalData.premiumData.dateOfPurchase,
    nilDepRate:motorData.proposalData.responseData.NilDepRate || "",
    documentUpload:formState.kycDetails.documentUpload || "",
    accessToken:formState.kycDetails.accessToken || "",
    pullId:formState.kycDetails.pullId || "",
  };
};

export default motorProposalUserData;