export const initializeEditIcon = (keys: any, whichTab:string) => {
  const currentYear = new Date().getFullYear();
  const yearFromKeys = keys.selectedYear ? parseInt(keys.selectedYear, 10) : currentYear - 1;
  const initialDate1 = new Date(keys.selectedRegDate);
  // Convert to ISO string and split to get date
  const initialDate1Str = initialDate1.toISOString().split("T")[0];
  const initialDate2 = new Date();
  const initialDate2Str = initialDate2.toISOString().split("T")[0];
  let increment = 3;
  if (whichTab.toLowerCase().includes("bike")) {
    increment = 5; 
  }
const adjustedYear = yearFromKeys + increment;
  const initialDate3 = new Date();
  initialDate3.setFullYear(adjustedYear, initialDate1.getMonth(), initialDate1.getDate());
  const initialDate3Str = initialDate3.toISOString().split("T")[0];
  const initialDate = new Date();
  const adjustedDate = new Date(initialDate);
  const year = adjustedDate.getFullYear();
  const month = String(adjustedDate.getMonth() + 1).padStart(2, "0");
  const day = String(adjustedDate.getDate()).padStart(2, "0"); // Keep the day as is
  const currentDate = new Date();
  const Date5 = new Date(currentDate);
  Date5.setDate(currentDate.getDate() - 1); // Current date - 1 day
  const Date5Str = Date5.toISOString().split("T")[0];
  const Date6 = new Date(currentDate);
  Date6.setDate(currentDate.getDate() - 90);
  const Date6Str = Date6.toISOString().split("T")[0];

  // Initialize Date7
  const Date7 = new Date();
  Date7.setFullYear(adjustedYear, initialDate1.getMonth(), 1);
  const Date7Str = Date7.toISOString().split("T")[0];

  const adjustedDateStr = `${year}-${month}-${day}`;

  return {
    initialDate1Str,
    initialDate2Str,
    initialDate3Str,
    adjustedDateStr,
    Date5Str,
    Date6Str,
    Date7Str
  };
};
