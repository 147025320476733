export const calculateMinMaxIDV = (mainCardData: any[]) => {
  let minIDV = Number.MAX_SAFE_INTEGER;
  let maxIDV = Number.MIN_SAFE_INTEGER;

  mainCardData.forEach((cardData: any) => {
    // Log the full card data and the IDV to see what's being processed
    console.log('Card Data:', cardData);
    console.log('IDV:', cardData.IDV);

    // Ensure that IDV is parsed as a valid number, fallback to NaN for invalid cases
    const idv = typeof cardData.IDV === 'string' ? parseFloat(cardData.IDV) : cardData.IDV;

    // Log the parsed IDV
    console.log('Parsed IDV:', idv);

    // Only process IDV if it's a valid number
    if (!isNaN(idv) && idv !== null && idv !== undefined) {
      minIDV = Math.min(minIDV, idv);
      maxIDV = Math.max(maxIDV, idv);
    }
  });

  // Log final min and max IDV after the loop
  console.log('Final Min IDV:', minIDV);
  console.log('Final Max IDV:', maxIDV);

  // If no valid IDV was found, set defaults for min/max IDV
  if (minIDV === Number.MAX_SAFE_INTEGER) minIDV = 0;
  if (maxIDV === Number.MIN_SAFE_INTEGER) maxIDV = 0;

  return { minIDV, maxIDV };
};
