import React, { useEffect, useState } from "react";
import ButtonPrimary from "../components/Button/ButtonPrimary";
import { fetchSSEData } from "../components/Premium/PremiumFunctions/fetchSSEData";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { RootState } from "../reducers/store/store";
import { readFromDB } from "../utils/indexDBUtils";
// import { setPremiumData, setResponseData } from "../reducers/slices/MotorSlice";
import { useNavigate } from "react-router-dom";
import { avatarUrls } from "../data/authors";
import LinkLayout from "../components/Link/LinkLayout";

interface ResponseData {
  [key: string]: any;
}

interface ProposalResponseData {
  CompanyName?: string;
  QuoteID?: string;
  [key: string]: any;
}

interface ProposalData {
  responseData: ProposalResponseData;
  premiumData?: any;
}

const PaymentPage: React.FC = () => {
  const [response, setResponse] = useState<ResponseData[]>([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loading = useSelector(
    (state: RootState) =>
      state.commonPremiumData.loadingStates["policyDownload"] || false
  );
  const [paymentStatus, setPaymentStatus] = useState("success");
  const [downloadStarted, setDownloadStarted] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [Failed, setFailed] = useState<boolean>(false);
  const [policyNumber, setPolicyNumber] = useState<string>("");
  const [quoteId, setQouteId] = useState<string>("");
  const [companyName, setCompanyName] = useState<string>("");
  const [proposalDatas, setProposalDatas] = useState<ProposalData | null>(null);
  const [id, setId] = useState<string>("");
  const [imageUrl, setImageUrl] = useState<string>("");
  // const alertShown = useRef(false);

  useEffect(() => {
    const fetchWhichTab = async () => {
      const tab = await readFromDB("whichTab");
      const proposalData = await readFromDB("responseObjectFromProposal");

      const responseData = proposalData?.responseData || {};
      const id = await readFromDB("uniqueUserId");
      console.log(proposalData);
      if (tab && tab !== "" && id && id !== "") {
        if (tab === "Bike" || tab === "Car") {
          if (
            proposalData &&
            Object.keys(proposalData).length > 0 &&
            Object.keys(responseData).length > 0
          ) {
            setCompanyName(responseData.CompanyName);
            setProposalDatas(proposalData);
            setId(id);
            const imgUrl =
              avatarUrls.find(
                (avatar) => avatar.id === `${responseData.CompanyName}`
              )?.url || "";
            setImageUrl(imgUrl);
            console.log("proposaldata....", proposalData);
            if (responseData.CompanyName === "National Insurance") {
              const PNo = await readFromDB("policyRef");
              if (PNo && PNo !== null && PNo !== "") {
                const sanitizedPNo = PNo.replace(";", ""); // Or use PNo.split(";")[0] if only the first part is needed
                setPolicyNumber(sanitizedPNo);
                setFailed(false);
              } else {
                setFailed(true);
              }
            } else if (responseData.CompanyName === "Royal Sundaram") {
              const Qid = responseData.QuoteID;
              if (Qid && Qid !== null && Qid !== "") {
                setQouteId(Qid);
                setFailed(false);
              } else {
                setFailed(true);
              }
            } else if (
              responseData.CompanyName === "Shriram General Insurance"
            ) {
              const Qid = responseData.QuoteID;
              const PNo = responseData.ProposalNo;
              if (
                Qid &&
                Qid !== null &&
                Qid !== "" &&
                PNo &&
                PNo !== null &&
                PNo !== ""
              ) {
                setPolicyNumber(PNo);
                setQouteId(Qid);
                setFailed(false);
              } else {
                setFailed(true);
              }
            } else if (responseData.CompanyName === "Reliance General") {
              const pageUrl = window.location.href;
              const pattern = /\|(\d+)\|/;
              const matches = pageUrl.match(pattern);
              const PID = matches && matches[1];
              const statusPattern = /\|([^|]+)\|?$/;
              const statusMatches = pageUrl.match(statusPattern);
              const status = statusMatches && statusMatches[1];
              console.log("status", status);
              console.log("desiredNumber", PID);
              if (status === "Failure") {
                setFailed(true);
              } else if (
                PID &&
                PID !== null &&
                PID !== "" &&
                status === "Success"
              ) {
                setPolicyNumber(PID);
                setFailed(false);
              } else {
                setFailed(true);
              }
            }
          } else {
            navigate("/proposal");
          }
        }
      } else {
        navigate("/");
      }
    };
    fetchWhichTab();
  }, []);

  useEffect(() => {
    // Simulate a status check or update
    if (paymentStatus === "pending") {
      setPaymentStatus("success");
    }
  }, [paymentStatus]); // Only update if paymentStatus is "pending"

  const handleDownloadClick = async () => {
    setDownloadStarted(true); // Mark download process started
    const data = {
      policyNo: policyNumber,
      quoteId: quoteId,
    };
    console.log("data", data);
    const masterwithoutspace =
      proposalDatas?.responseData?.CompanyName?.toLowerCase().replace(
        /\s+/g,
        ""
      );
    const url = `https://aim-core.insurancepolicy4us.com/dummy/id=${id}/policydownload/${masterwithoutspace}`;

    const uniqueKey = "policyDownload";
    console.log("====================================");
    console.log(url);
    console.log("====================================");
    const token = `Bearer ${import.meta.env.VITE_API_TOKEN}` as string;

    // Fetch SSE Data of KYC
    fetchSSEData(
      data,
      // { policyNo: "920222423111086128" },
      url,
      token,
      uniqueKey,
      setResponse,
      dispatch
    );
  };
  useEffect(() => {
    if (downloadStarted) {
      console.log("responseeeeeeeeeeeeeeeeeeee", response);
      if (response.length < 1) {
        setError(true);
      } else {
        if (
          (companyName === "National Insurance" ||
            companyName === "Royal Sundaram") &&
          response.length > 0 &&
          response[0].PolicyBase64
        ) {
          const base64Data = response[0].PolicyBase64;
          const byteCharacters = atob(base64Data);
          const byteNumbers = new Array(byteCharacters.length)
            .fill(0)
            .map((_, i) => byteCharacters.charCodeAt(i));
          const byteArray = new Uint8Array(byteNumbers);
          const blob = new Blob([byteArray], { type: "application/pdf" });
          const downloadLink = document.createElement("a");
          downloadLink.href = URL.createObjectURL(blob);
          downloadLink.download = `${companyName} Policy.pd`;
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
          setError(false);
        } else if (response.length > 0 && response[0].PolicyDownloadLink) {
          console.log("response=================", response);
          const pdfUrl = response[0].PolicyDownloadLink;
          console.log("pdfUrl", pdfUrl);
          const fileName = `${companyName} Policy.pdf`;
          downloadPDF(pdfUrl, fileName);
          setError(false);
        } else {
          setError(true);
        }
      }
    }
  }, [response, downloadStarted]);

  // Function to download the PDF from the given URL
  function downloadPDF(pdfUrl: string, fileName: string) {
    const link = document.createElement("a");
    link.href = pdfUrl;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  const handleTryAgain = async () => {
    navigate("/proposal");
  };
  const handleBackToHome = () => {
    navigate("/");
  };

  return (
    <div>
      <div className="bg-white dark:bg-zinc-950">
        <div
          className={`absolute h-[400px] top-0 left-0 right-0 w-full bg-primary-100 bg-opacity-25 dark:bg-opacity-40 dark:bg-zinc-900`}
        />
        <div className="container relative pt-6 sm:pt-10 pb-1 lg:pt-18 lg:pb-4">
          <div className="mx-auto mt-7 bg-white rounded-xl sm:rounded-3xl lg:rounded-[40px] shadow-lg sm:p-10 lg:p-12 dark:bg-zinc-950 border dark:border-zinc-900">
            <div className="grid md:grid-cols-1 gap-6 p-4">
              <div className="text-center my-auto">
                <div className="flex justify-end">
                  <LinkLayout
                    link="Back to Home"
                    handleClick={handleBackToHome}
                  />
                </div>{" "}
                <div className="mx-auto mb-4 text-center flex items-center justify-center">
                  <img
                    src={imageUrl}
                    className="w-32 h-32 rounded-full shadow-lg"
                  />
                </div>
                {!Failed ? (
                  <>
                    <span className="font-semibold text-xl ">
                      Payment Successful with {companyName} 😁
                    </span>
                    <p className="mt-3">Thank you for your payment.</p>
                    <p>You can now download your policy document below.</p>

                    <ButtonPrimary
                      className={`mt-10 ${
                        loading ? "cursor-not-allowed" : "cursor-pointer"
                      }`}
                      onClick={handleDownloadClick}
                      disabled={loading} // Disable button during download
                    >
                      {loading ? (
                        <span className="flex items-center">
                          <svg
                            className="animate-spin h-5 w-5 mr-3"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <circle
                              className="opacity-25"
                              cx="12"
                              cy="12"
                              r="10"
                              stroke="currentColor"
                              strokeWidth="4"
                            ></circle>
                            <path
                              className="opacity-75"
                              fill="currentColor"
                              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A8.004 8.004 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                            ></path>
                          </svg>
                          Downloading...
                        </span>
                      ) : (
                        "Download Policy"
                      )}
                    </ButtonPrimary>
                  </>
                ) : (
                  <>
                    <span className="font-semibold text-xl ">
                      Payment Failed for {companyName} ☹
                    </span>
                    <p className="mt-3">
                      We're sorry, but your payment could not be processed.
                    </p>
                    <p>You can try again from the proposal stage.</p>
                    <ButtonPrimary
                      onClick={handleTryAgain}
                      className="mt-10 bg-red-600"
                    >
                      Try Again
                    </ButtonPrimary>
                  </>
                )}
              </div>
            </div>
            {error && !loading && (
              <p className="text-red-500 mt-3 flex justify-center">
                Failed to download policy. Please try again later.
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentPage;
