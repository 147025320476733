import React from "react";
import PLogoImage from "./PLogoImage";
import Link from "../Link";
import { logo } from "../styleComponents/logoStyles/logoStyles";

export interface LogoProps {
  img?: string;
}

const Logo: React.FC<LogoProps> = ({img}) => {
  return (
    <Link
      href="/"
      className={logo}
    >
      <PLogoImage src={img}/>
    </Link>
  );
};

export default Logo;
