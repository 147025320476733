import React from "react";

export interface RadioProps {
  name: string;
  label?: string;
  id: string;
  checked?: boolean;
  onChange?: (checked: boolean) => void;
  onClick?: () => void;
}

const Radio: React.FC<RadioProps> = ({
  name,
  label,
  id,
  checked,
  onChange,
  onClick,
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(event.target.checked);
    }
  };

  const handleClick = () => {
    if (onClick) {
      onClick();
    } else if (onChange) {
      // Call onChange with the opposite of current checked state for radio behavior
      onChange(!checked);
    }
  };

  return (
    <div className="flex items-center">
      <input
        id={id}
        name={name}
        type="radio"
        className="focus:ring-action-primary h-4 w-4  cursor-pointer text-action-primary border-primary"
        checked={checked}
        onChange={handleChange}
        onClick={handleClick}
      />
      {label && (
        <label
          htmlFor={id}
          className="ml-3 block text-sm cursor-pointer tracking-wider text-black dark:text-white"
        >
          {label}
        </label>
      )}
    </div>
  );
};

export default Radio;