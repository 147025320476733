import React, { useState } from "react";
import RadioGroup from "./RadioGroup";
import LinkLabel from "./LinkLabel";
import ErrorMessage from "./ErrorMessage";
import ButtonPrimary from "../../Button/ButtonPrimary";
import NewSelectionPage from "./NewSelectionPage";
import {
  hasExpiredOptions,
  expiredAfterDateOptions,
  madeClaimOptions,
} from "./RadioOptions";

interface Props {
  labelLinkText: string;
  handleLabelLink: () => void;
  selectedMotor: string;
  selectedFuel: string;
  selectedRto: string;
  selectedYear: string;
  handleHasExpired: (value: boolean) => void;
  handleExpiredAfterDate: (value: boolean | null) => void;
  handleMadeClaim: (value: boolean | null) => void;
  handleViewQuotesValid: () => void;
  userSelectedType: string | null;
}

const CommonLastPageOfselection: React.FC<Props> = ({
  labelLinkText,
  handleLabelLink,
  selectedMotor,
  selectedFuel,
  selectedRto,
  selectedYear,
  handleHasExpired,
  handleExpiredAfterDate,
  handleMadeClaim,
  handleViewQuotesValid,
  userSelectedType,
}) => {
  const [selectedOption, setSelectedOption] = useState<string | null>(null);
  const [hasExpired, setHasExpired] = useState<boolean | null>(null);
  const [expiredAfterDate, setExpiredAfterDate] = useState<boolean | null>(
    null
  );
  const [madeClaim, setMadeClaim] = useState<boolean | null>(null);
  const [hasExpiredError, setHasExpiredError] = useState<string>("");
  const [expiredAfterDateError, setExpiredAfterDateError] =
    useState<string>("");
  const [madeClaimError, setMadeClaimError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false); // State to manage loading state of the button

  const today = new Date();
  const ninetyDaysAgo = new Date(today.getTime() - 90 * 24 * 60 * 60 * 1000);

  function formatDate(date: Date): string {
    const day = String(date.getDate()).padStart(2, "0");
    const month = date.toLocaleString("en-US", { month: "short" });
    const year = date.getFullYear();
    return `${day} ${month}-${year}`;
  }

  const formattedDate = formatDate(ninetyDaysAgo);

  const handleViewQuotes = async (): Promise<void> => {
    setHasExpiredError("");
    setExpiredAfterDateError("");
    setMadeClaimError("");
    setLoading(true); // Set loading state to true

    let isValid = true;
    if (hasExpired === null) {
      setHasExpiredError(
        "Please select whether your previous policy has expired or not."
      );
      isValid = false;
      setLoading(false);
    }
    if (hasExpired && expiredAfterDate === null) {
      setExpiredAfterDateError(
        "Please select whether your policy expired after the specified date or not."
      );
      isValid = false;
      setLoading(false);
    }
    if (
      (hasExpired === false || (hasExpired && expiredAfterDate === true)) &&
      madeClaim === null
    ) {
      setMadeClaimError(
        "Please select whether you have made a claim in the previous year or not."
      );
      isValid = false;
      setLoading(false);
    }

    if (isValid) {
      await handleViewQuotesValid(); // Call the asynchronous function to handle quotes
      // After navigation, reset state and loading
      setLoading(false);
    } else {
      setTimeout(() => {
        setHasExpiredError("");
        setExpiredAfterDateError("");
        setMadeClaimError("");
      
      }, 4000);
    }
  };

  return (
    <>
      {userSelectedType === "DontKnow" && (
        <div className="w-full flex flex-col md:flex-row px-4 py-5 md:px-6 gap-2 md:gap-0">
          <div className="flex flex-col px-3 py-3 md:py-10 w-full md:w-60 shadow-md rounded-xl">
            <LinkLabel text={labelLinkText} onClick={handleLabelLink} />
            <div className="flex flex-col py-4 px-3">
              <div className="mb-4 text-xl text-zinc-900 dark:text-white font-[600]">
                {selectedMotor}
              </div>
              <div className="flex flex-row md:flex-col dark:text-zinc-300 gap-2.5 text-sm flex-wrap">
                <p>{selectedFuel}</p>
                <p>{selectedRto}</p>
                <p>{selectedYear}</p>
              </div>
            </div>
          </div>
          <div className="flex-1 px-0 md:px-4">
            <div className="flex flex-col gap-4 md:gap-5 pt-5 md:pt-8 pb-5 bg-primary-50/50 dark:bg-zinc-900/50 px-6 rounded-lg shadow-md">
              <RadioGroup
                label="Has your previous policy expired?"
                description="Refer to Own Damage expiry date if you have a Bundled policy"
                options={hasExpiredOptions({
                  onChangeHandlers: {
                    setHasExpired,
                    setExpiredAfterDate,
                    setMadeClaim,
                    handleHasExpired,
                    handleExpiredAfterDate,
                    handleMadeClaim,
                   
                  },
                  hasExpired,
                  expiredAfterDate,
                  madeClaim,
                 
                })}
              />
              {hasExpired !== null && hasExpired && (
                <RadioGroup
                  label={`Did your policy expire after ${formattedDate}?`}
                  options={expiredAfterDateOptions({
                    onChangeHandlers: {
                      setHasExpired,
                      setExpiredAfterDate,
                      setMadeClaim,
                      handleHasExpired,
                      handleExpiredAfterDate,
                      handleMadeClaim,
                      setSelectedOption,
                    },
                    hasExpired,
                    expiredAfterDate,
                    madeClaim,
                    selectedOption
                   
                  })}
                />
              )}
              {(hasExpired === false || expiredAfterDate === true) && (
                <RadioGroup
                  label="Have you made a claim in the previous year?"
                  options={madeClaimOptions({
                    onChangeHandlers: {
                      setHasExpired,
                      setExpiredAfterDate,
                      setMadeClaim,
                      handleHasExpired,
                      handleExpiredAfterDate,
                      handleMadeClaim,
                    },
                    hasExpired,
                    expiredAfterDate,
                    madeClaim
                  })}
                />
              )}

              <div className="w-full flex flex-col items-center justify-center mt-5 md:mt-8">
                <div className="mb-3 gap-5">
                  {hasExpiredError && (
                    <ErrorMessage message={hasExpiredError} />
                  )}
                  {expiredAfterDateError && (
                    <ErrorMessage message={expiredAfterDateError} />
                  )}
                  {madeClaimError && <ErrorMessage message={madeClaimError} />}
                </div>
                <ButtonPrimary
                 className={`w-3/4 md:w-1/2 ${!loading ? 'cursor-pointer' : 'cursor-not-allowed'}`}
                  onClick={handleViewQuotes}
                  disabled={loading} // Disable button when loading is true
                >
                  {loading ? (
                    <span className="flex items-center">
                      <svg
                        className="animate-spin h-5 w-5 mr-3"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A8.004 8.004 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                      Loading...
                    </span>
                  ) : (
                    "View Quotes"
                  )}
                </ButtonPrimary>
              </div>
            </div>
          </div>
        </div>
      )}
      {userSelectedType === "New" && (
        <NewSelectionPage
          labelLinkText={labelLinkText}
          handleLabelLink={handleLabelLink}
          selectedMotor={selectedMotor}
          selectedFuel={selectedFuel}
          selectedRto={selectedRto}
          selectedYear={selectedYear}
          handleViewQuotesValid={handleViewQuotesValid}
        />
      )}
    </>
  );
};

export default CommonLastPageOfselection;
