import  { FC } from "react";
import { PostDataType } from "../../data/types";
import Link from "../Link";
import Image from "../Image";
import { Card17PodcastCardTitle, Card17PodcastCardTitleDesc, Card17PodcastCardTitleSection, Card17PodcastImgSection, Card17PodcastLink, Card17PodcastMain, ConnectSecArrowDiv, lineClamp1 } from "../styleComponents/cardPodcastStyles/card17PodcastStyle";
import { objectCover } from "../styleComponents/cardCategoryStyles/cardCategory2Style";

export interface Card17PodcastProps {
  className?: string;
  post: PostDataType; 
}

const Card17Podcast: FC<Card17PodcastProps> = ({ className = "", post }) => {
  const { title, href, featuredImage} = post;


  return (
    <div
      className={`${Card17PodcastMain} ${className}`}
    >
      <Link href={href} className={Card17PodcastLink}>
        <div className={Card17PodcastImgSection}>
          <Image
            sizes="(max-width: 600px) 480px, 800px"
            className={objectCover}
            src={featuredImage}
            fill
            alt={title}
          />
        </div>
        <div className={Card17PodcastCardTitleSection}>
          <h2 className={Card17PodcastCardTitle}>
            <span className={lineClamp1} title={title}>
              {title}
            </span>
          </h2>
          <span className={Card17PodcastCardTitleDesc}>
              Join the conversation!
          </span>
        </div>
      </Link>
      <div  className={ConnectSecArrowDiv} >
      &#10140;
      </div>

    </div>
  );
};

export default Card17Podcast;
