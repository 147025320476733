
export const gridCarItems = [
    { id:"01",
      name: "Maruti Suzuki",
      image:"https://i.postimg.cc/9XnhnZyS/maruti.png",
      code: ""
     },
     {id:"02",
       name: "Hyundai",
     image:"https://i.postimg.cc/ZRjTRQBK/hyundai.png",
     code: ""
    },
    {id:"03",
    name: "KIA",
    image:"https://i.postimg.cc/GpfsRLhw/kia.png",
    code: ""
    },
    {id:"04",
      name: "Tata",
      image:"https://i.postimg.cc/N0gSMj1m/tata.png",
      code: ""
     },
     {id:"05",
      name: "Toyota",
      image:"https://i.postimg.cc/ZRnQ8ssq/toyota.png",
      code: ""
     },
     {id:"06",
      name: "Mahindra",
      image:"https://i.postimg.cc/rmhkct3D/Mahindra.png",
      code: ""
     },
     {id:"07",
      name: "Honda",
      image:"https://i.postimg.cc/dVKRxN9Q/honda.png",
      code: ""
     },
     {id:"08",
      name: "Renault",
      image:"https://i.postimg.cc/j53GBYnN/renault.png",
      code: ""
     },
     {id:"09",
      name: "Nissan",
      image:"https://i.postimg.cc/HkQ1nNFD/nissan.png",
      code: ""
     },
     {id:"10",
      name: "Volkswagen",
      image:"https://i.postimg.cc/B6zVM5RB/volkswagen.png",
      code: ""
     },
     {id:"11",
      name: "Skoda",
      image:"https://i.postimg.cc/W1cxBnz9/skoda.png",
      code: ""
     },
     {id:"12",
      name: "MG",
      image:"https://i.postimg.cc/kGvtnCK1/mg.png",
      code: ""
     },
    
  ];

