import React, { useState } from "react";
import ButtonPrimary from "../Button/ButtonPrimary";

interface ProposalData {
  [key: string]: any;
}

interface ShriramPaymentProps extends ProposalData {
  whichTab: string;
}

const ShriramPayment: React.FC<ShriramPaymentProps> = ({
  proposalData,
  whichTab,
}) => {
  const [loading, setLoading] = useState(false);

  // Determine the prodCode based on whichTab prop
  const prodCode = whichTab === "bike" ? "MOT-PRD-002" : "MOT-PRD-001";

  const handleSubmit = () => {
    // Set loading to true when form is submitted
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 8000);
  };

  console.log("nnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnn", proposalData);

  return (
    <form
      action="https://novaapiuat.shriramgi.com/uatnovapymt/MyDefaultCC.aspx"
      method="post"
      onSubmit={handleSubmit}
    >
      <input type="hidden" name="createdBy" value="NiveshIns" />

      <input type="hidden" name="paymentFrom" value="CCAVENUE" />

      {/* <input type="hidden" name="prodCode" value="MOT-PRD-002" /> */}
      <input type="hidden" name="prodCode" value={prodCode} />

      {/* <input type="hidden" name="QuoteId" value="3943483" /> */}
      <input type="hidden" name="QuoteId" value={proposalData[0].QuoteID} />

      {/* <input type="hidden" name="amount" value="1483.00" /> */}
      <input type="hidden" name="amount" value={proposalData[0].TotalPremium} />

      <input
        type="hidden"
        name="sourceUrl"
        value="https://www.insurancepolicy4us.com/payment"
      />

      <input type="hidden" name="DbFrom" value="NOVA" />

      <input type="hidden" name="application" value="POLICY4US" />

      <input type="hidden" name="prodName" value="MOTOR" />
      <div className=" w-full flex justify-center text-center">
          <ButtonPrimary
            className="max-sm:w-[7rem] sm:w-[14rem] md:w-[14rem]"
            type="submit"
          >
          {loading ? (
            <span className="flex items-center">
              <svg
                className="animate-spin h-5 w-5 mr-3"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A8.004 8.004 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            </span>
          ) : (
            "Pay Now"
          )}
        </ButtonPrimary>
      </div>
    </form>
  );
};

export default ShriramPayment;
