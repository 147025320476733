import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface commonData {
    isOpenPopup: boolean;
    whichTab: string;
    dbUpdateTrigger: number;
    planDetailPopup: boolean;
    loadingStates: Record<string, boolean>; // Track loading states for different APIs
    getNoData: boolean;
    id: string;
}

const initialState: commonData = {
    isOpenPopup: false,
    whichTab: "",
    dbUpdateTrigger: 0,
    planDetailPopup: false,
    loadingStates: {}, // Initialize as an empty object
    getNoData: false,
    id: "",
};

const commonPremiumSlice = createSlice({
    name: 'commonPremiumData',
    initialState,
    reducers: {
        setIsOpenPopup: (state, action: PayloadAction<boolean>) => {
            state.isOpenPopup = action.payload;
        },
        setWhichTab: (state, action: PayloadAction<string>) => {
            state.whichTab = action.payload;
        },
        triggerDbUpdate: (state) => {
            state.dbUpdateTrigger += 1;
        },
        setPlanDetailPopup: (state, action: PayloadAction<boolean>) => {
            state.planDetailPopup = action.payload;
        },
        setLoadingState: (state, action: PayloadAction<{ key: string; isLoading: boolean }>) => {
            const { key, isLoading } = action.payload;
            state.loadingStates[key] = isLoading;
        },
        setGetNoData: (state, action: PayloadAction<boolean>) => {
            state.getNoData = action.payload;
        },
        setId: (state, action: PayloadAction<string>) => {
            state.id = action.payload;
        },
    },
});

export const {
    setIsOpenPopup,
    setWhichTab,
    triggerDbUpdate,
    setPlanDetailPopup,
    setLoadingState,
    setGetNoData,
    setId,
} = commonPremiumSlice.actions;

export default commonPremiumSlice.reducer;
