import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../reducers/store/store";
import { setPlanDetailPopup } from "../../../reducers/slices/commonPremiumSlice";
import {
  setPremiumData,
  setResponseData,
} from "../../../reducers/slices/MotorSlice";
import { useNavigate } from "react-router-dom";
import PopLayout from "../../Popup/PopLayout";
import PremiumButton from "../../Button/PremiumButton";
import {  writeToDB } from "../../../utils/indexDBUtils";

interface PlanDetailProps {
  keyValuePairs: { key: string; value: string }[];
  backendData: any;
  sendingValues: any;
  children: React.ReactNode;
}

const PlanDetailsDesign: React.FC<PlanDetailProps> = ({
  keyValuePairs,
  backendData,
  sendingValues,
  children,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { planDetailPopup } = useSelector(
    (state: RootState) => state.commonPremiumData
  );
  const popupRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        popupRef.current &&
        !popupRef.current.contains(event.target as Node)
      ) {
        dispatch(setPlanDetailPopup(false));
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dispatch]);

  const handleBuyNow = async() => {
    dispatch(setPremiumData(sendingValues));
    dispatch(setResponseData(backendData));
    dispatch(setPlanDetailPopup(false));
    // const variantData = await readFromDB("motor-variant-corrected");
    // const CompanyName = variantData.companyName;
    // if(CompanyName!==backendData.CompanyName){
      const VerifyDetails ={correctedVariant: "", verified: false, companyName:""};
      await writeToDB("motor-variant-corrected", VerifyDetails);
      await writeToDB("completed-steps", 0);
           await writeToDB("KYCStatus", false);
      await writeToDB("mainCardData-proposal", []);
      await writeToDB("motor-proposal-CommunicationAddress", {});
      await writeToDB("motor-proposal-FormState", {});
      await writeToDB("motor-proposal-NomineeDetails", {});
      await writeToDB("motor-proposal-OwnerDetails", {});
      await writeToDB("motor-proposal-PreviousInsurerData", {});
     await writeToDB("KycVerifiedData", {});
     await writeToDB("policyRef", "");
     await writeToDB("responseObjectFromProposal", {});
      await writeToDB("motor-proposal-VehicleDetailsData", {});
      await writeToDB("motor-proposal-kycDetailsData", {});
      await writeToDB("vehicle-documentID", "");
    // }
    await writeToDB("responseObjectForProposal", {
      premiumData: sendingValues,
      responseData: backendData,
    });
    navigate("/proposal");
  };

  return (
    <>
      {planDetailPopup && (
        <PopLayout
          isOpen={planDetailPopup}
          onClose={() => {
            dispatch(setPlanDetailPopup(false));
          }}
          maxWidth=" md:max-w-screen-md max-w-md"
        >
          <div className="w-full flex flex-col md:flex-row px-4 pb-5 md:px-6 gap-3 md:gap-0">
           <div className=" flex items-center justify-center">
              <div className="flex flex-col px-3 py-3 md:py-5 md:h-72 h-60 w-56  md:w-60 shadow-md items-center justify-between rounded-xl bg-white dark:bg-zinc-900/30 gap-6 md:gap-10">
                <div className="w-32 max-sm:w-36 shadow-md shadow-gray-200 dark:shadow-zinc-900/40">
                  <img
                    className="w-32 h-12 max-sm:w-36 max-sm:h-14 rounded-md"
                    src={backendData.CompanyLogo}
                    alt=""
                  />
                </div>
                <div className="flex flex-col gap-2 text-xs font-medium">
                  {keyValuePairs.map(({ key, value }, index) => (
                    <div key={index} className="flex gap-2 flex-wrap">
                      <span>{key}:</span>
                      <span>{value}</span>
                    </div>
                  ))}
                </div>
                <div className="flex w-full justify-center items-center mt-auto md:mt-0 ">
                  <div className=" w-52 max-sm:w-48">
                    <PremiumButton
                      title="Buy Now"
                      label={`₹ ${backendData.NetPremium}`}
                      onClick={() => handleBuyNow()}
                      className1="flex w-full flex-col items-center cursor-pointer justify-center border-2 border-primary-700/80 dark:border-primary-300/90 hover:bg-gradient-to-t from-primary-50/80 to-white dark:from-zinc-950/60 dark:to-zinc-950/10 text-gray-800 dark:text-gray-50 py-3 px-4 rounded-2xl transition-all duration-300"
                      className2="cursor-pointer absolute -top-3 max-sm:text-[11px] left-3 flex items-center justify-center px-0.5 rounded-2xl bg-white text-primary-700 dark:bg-[#0d0d10] dark:text-primary-200 text-[12px] font-[500] z-10"
                      className3="text-xl max-sm:text-lg font-[500] text-primary-900 dark:text-primary-50"
                    />
                  </div>
                </div>
              </div>
           </div>

            <div className="flex-1 px-0 md:px-4">{children}</div>
          </div>
        </PopLayout>
      )}
    </>
  );
};

export default PlanDetailsDesign;
