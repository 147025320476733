import React, {  ChangeEvent } from "react";

interface DropdownInputCoverProps {
  placeholder: string;
  onChange: (value: number) => void;
  selectedAddon: { option: string; value: number }[]; // New prop for selected addons
  name: string; // Name of the addon
}

const DropdownInputCover: React.FC<DropdownInputCoverProps> = ({
  placeholder,
  onChange,
  selectedAddon,
  name,
}) => {
  // Find the selected addon object by name
  const selectedAddonObject = selectedAddon.find((addon) => addon.option === name);

  // Extract the value from the selected addon object
  const value = selectedAddonObject ? selectedAddonObject.value : 0; // Set default value to 0 if not found

  const handleInputChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const newValue = parseInt(e.target.value);
    onChange(newValue);
  };

  return (
    <div className="p-1 ml-7 mt-2">
        <p className="text-xs md:text-sm text-gray-500 mb-1.5 dark:text-gray-300">{placeholder}</p>
      <select
        value={value}
        onChange={handleInputChange}
        className="px-3 py-2 border border-gray-300 cursor-pointer rounded-md dark:bg-zinc-900 dark:text-white dark:placeholder:text-gray-400 text-gray-700 focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200 transition duration-200 appearance-none inputtextbox"
      >
        <option value={0}>0</option>
        <option value={2500}>&#8377; 2500</option>
        <option value={5000}>&#8377; 5000</option>
        <option value={7500}>&#8377; 7500</option>
        <option value={15000}>&#8377; 15000</option>
      </select>
    </div>
  );
};

export default DropdownInputCover;
