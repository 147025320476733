import React from "react";
import AddonsDetails from "./AddonsDetails";

interface MotorPremiumBreakupProps {
  PremiumBreakupData: any[];
  AddonData: any[];
}

const MotorPremiumBreakup: React.FC<MotorPremiumBreakupProps> = ({
  PremiumBreakupData,
  AddonData,
}) => {
  return (
    <div className="relative z-20">
      <div className="bg-white dark:bg-zinc-900 dark:border dark:border-zinc-800 shadow overflow-hidden sm:rounded-lg ">
        <div className="px-4 py-3 flex gap-2 flex-col md:flex-row md:justify-between sm:px-6">
          <div className="py-2">
            <h3 className="text-lg leading-6 font-medium text-zinc-900 dark:text-zinc-200">
              Premium Breakup
            </h3>
          </div>
          <AddonsDetails data={AddonData} />
        </div>

        <div className="border-t border-zinc-200 dark:border-zinc-900 max-sm:max-h-60 max-h-80 overflow-y-auto custom-scrollbar">
          <dl>
            {PremiumBreakupData.map((item, index) => {
              const isNetPremium = item.name === "Net Premium";
              return (
                <div
                  key={index}
                  className={`${
                    index % 2 === 0
                      ? "bg-zinc-100/50 dark:bg-zinc-800"
                      : "bg-white dark:bg-zinc-900"
                  } ${
                    isNetPremium ? "px-4 py-5 sm:px-6" : "px-4 py-4 sm:px-6"
                  }  sm:grid sm:grid-cols-3 sm:gap-4 `}
                >
                  <dt
                    className={`text-sm  ${
                      isNetPremium
                        ? "font-semibold text-balck dark:text-zinc-100"
                        : "font-medium text-zinc-500   dark:text-zinc-400"
                    } `}
                  >
                    {item.name}
                  </dt>
                  <dd
                    className={`mt-1 text-sm  ${
                      isNetPremium
                        ? "font-semibold text-black dark:text-zinc-100"
                        : "font-medium text-zinc-900 dark:text-zinc-300"
                    } sm:mt-0 sm:col-span-2`}
                  >
                    ₹ {item.content}
                  </dd>
                </div>
              );
            })}
          </dl>
        </div>
      </div>
    </div>
  );
};

export default MotorPremiumBreakup;
