import React, { useState, useEffect } from "react";
import Radio from "../Radio/Radio";

interface Option {
  name: string;
  code: string;
}

interface CommonRadioInputProps {
  heading: string;
  options: Option[];
  selectedOption: string | null;
  handleSelect: (name: string, code: string) => void;
}

const CommonRadioInput: React.FC<CommonRadioInputProps> = ({
  heading,
  options,
  selectedOption: selectedOptionProp,
  handleSelect,
}) => {
  const [selectedOption, setSelectedOption] = useState<string | null>(
    selectedOptionProp
  );

  // Update the state when the prop changes
  useEffect(() => {
    setSelectedOption(selectedOptionProp);
  }, [selectedOptionProp]);

  // Handle option selection
  const handleChange = (name: string, code: string) => {
    setSelectedOption(code);
    handleSelect(name, code);
  };

  return (
    <div className="px-6 py-4 mt-7 w-full h-[330px] flex flex-col items-center gap-2 mb-4">
      <h2 className="text-xl mb-2 ">{heading}</h2>
      {options.length === 0 ? (
        <div className="flex items-center justify-center h-60">
          <div className="loader"></div>
        </div>
      ) : (
        <div className="flex p-2 flex-col gap-3">
          {options.map((option) => (
            <Radio
              key={option.code}
              name="options"
              checked={selectedOption === option.code}
              label={option.name}
              id={option.code}
              onClick={() => handleChange(option.name, option.code)}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default CommonRadioInput;
