import React, { useState, ChangeEvent } from "react";

interface RangeInputProps {
  placeholder: string;
  onChange: (value: number | null) => void;
  selectedAddon: { option: string; value: number }[]; // New prop for selected addons
  name: string; // Name of the addon
}

const RangeInput: React.FC<RangeInputProps> = ({ placeholder, onChange, selectedAddon, name }) => {
  // Find the selected addon object by name
  const selectedAddonObject = selectedAddon.find((addon) => addon.option === name);

  // Extract the value from the selected addon object
  const value = selectedAddonObject ? selectedAddonObject.value : null;

  const [rangeValue, setRangeValue] = useState<number | null>(value !== null ? value : 10000);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = parseInt(e.target.value);
    setRangeValue(newValue);
    onChange(newValue);
  };

  return (
    <div className="p-1 pe-2 ml-7 mt-3">
      <p className="text-[14px] max-sm:text-[13px] text-gray-500 dark:text-gray-400">
        {placeholder}:
        <span className="font-semibold text-gray-700 dark:text-gray-300 ml-2 ">&#8377; {rangeValue !== null ? rangeValue : 10000}</span>
      </p>
      <input
        type="range"
        min={10000}
        max={200000}
        step={10000}
        value={rangeValue !== null ? rangeValue : 10000} // Convert null to initial value for unselected state
        onChange={handleInputChange}
        className="appearance-none w-full h-2 rounded-full focus:outline-none focus:ring-2 focus:ring-blue-200 focus:ring-opacity-50 cursor-pointer bg-gradient-to-r from-blue-50 to-blue-400 dark:from-slate-300 dark:to-gray-700" // Styled range input
      />

      <div className="flex justify-between text-slate-600 dark:text-slate-300 text-[13px] max-sm:text-[12px]">
        <span>&#8377; 10000</span>
        <span>&#8377; 200000</span>
      </div>
    </div>
  );
};

export default RangeInput;
