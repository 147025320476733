import React, { useState, useEffect } from "react";

interface Option {
  name: string;
  code?: string; // Optional if not all options have a code
}

interface CustomDropdownProps {
  heading: string;
  options: Option[];
  selectedOption: string;
  handleSelect: (name: string) => void;
  height?: string;
}

const VariantSelectDropdown: React.FC<CustomDropdownProps> = ({
  heading,
  options,
  selectedOption: selectedOptionProp,
  handleSelect,
  height,
}) => {
    const [selectedOption, setSelectedOption] = useState<string>(selectedOptionProp);
  const [isOpen, setIsOpen] = useState(true);

  useEffect(() => {
    console.log("Options: ", options); 
  }, [options]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedOption(e.target.value); // Update the input value
    setIsOpen(true); // Open the dropdown when typing in the input
  };

  const handleOptionClick = (option: Option) => {
    setSelectedOption(option.name);
    handleSelect(option.name); // Call the parent handler with the option's name
    setIsOpen(false);
  };
  return (
    <div className="relative ">
      <div
        className={`px-6 py-4 mt-3 w-full  ${
          height ? height : "h-[330px]"
        } flex flex-col  items-center gap-2 mb-4`}
      >
        <h2 className="text-lg mb-4 sm:text-xl">{heading}</h2>
        <div className="relative w-11/12 sm:w-4/5 md:w-1/2 z-50">
          <input
            type="text"
            onChange={handleInputChange}
            onClick={() => {
              setIsOpen(!isOpen);
            }}
            className="w-full h-10 text-[15px] px-4 py-2 border border-gray-300 rounded-md bg-gray-50 dark:bg-zinc-800 dark:border-zinc-700 dark:placeholder:text-zinc-200 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-300"
            placeholder="Search / Select..."
            value={selectedOption}
          />
          {isOpen && (
            <div className="absolute top-full w-full max-h-60 mt-1 overflow-y-auto custom-secScrollbar bg-gray-50 dark:bg-zinc-800 dark:border-zinc-700 border rounded-md shadow-lg">
              {options.map((option, index) => (
                <div
                  key={index}
                  className={`px-4 py-2 text-[15px] cursor-pointer hover:bg-gray-100 dark:hover:bg-zinc-700 ${
                    selectedOption === option.name ? "bg-blue-100 dark:bg-blue-900" : ""
                  }`}
                  onClick={() => handleOptionClick(option)}
                >
                  {option.name}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default VariantSelectDropdown;
