import BackgroundSection from "../components/BackgroundSection/BackgroundSection";
import SectionGridCategoryBox from "../components/SectionGridCategoryBox/SectionGridCategoryBox";
import { DEMO_AUTHORS } from "../data/authors";
import { DEMO_POSTS, DEMO_POSTS_AUDIO } from "../data/posts";
import { DEMO_CATEGORIES } from "../data/taxonomies";
import SectionLargeSlider from "../components/Sections/SectionLargeSlider";
import SectionSliderNewAuthors from "../components/Sections/SectionSliderNewAthors/SectionSliderNewAuthors";
import SectionMagazine8 from "../components/Sections/SectionMagazine8";
import {
  SectionMagazine8Comp,
  homePageMain,
  homePgComp,
  homePgComp2,
  sectionLargeSliderComp,
} from "../components/styleComponents/pagesStyles/homePageStyle";
import { container } from "../components/styleComponents/headerStyles/mainNav2LoggedStyle";
import { relative } from "../components/styleComponents/headerStyles/searchModalStyle";
import CommonPopupForSelection from "../components/CommonDesignForSelection/CommonPopupForSelection";
// const CommonPopupForSelection = lazy(() => import("../components/CommonDesignForSelection/CommonPopupForSelection"));


const PageHome = () => {
  return (
    <div className={homePageMain}>
      <CommonPopupForSelection />
      <div className={`${container} ${relative}`}>
        <SectionLargeSlider
          className={sectionLargeSliderComp}
          posts={DEMO_POSTS?.filter((_, i) => i < 3)}
        />

        <div className={homePgComp}>
          <BackgroundSection />
          <SectionGridCategoryBox
            categories={DEMO_CATEGORIES.filter((_, i) => i < 10)}
          />
        </div>

        <div className={homePgComp}>
          <SectionSliderNewAuthors
            heading="Our Partners"
            subHeading="We work with most of the leading insurance providers in India"
            authors={DEMO_AUTHORS.filter((_, i) => i < 10)}
          />
        </div>
      </div>

      <div className={`${container} ${homePgComp2}`}>
        <BackgroundSection />
        <SectionMagazine8
          className={SectionMagazine8Comp}
          posts={DEMO_POSTS_AUDIO.filter((_, i) => i < 6)}
        />
      </div>
    </div>
  );
};

export default PageHome;
