import React from "react";
import { NavItemType } from "./NavigationItem";
import { NAVIGATION_DEMO_2 } from "../../data/navigation";
import { Disclosure } from "@headlessui/react";
import Link from "../Link";
import Logo from "../Logo/Logo";
import SocialsList from "../SocialsList/SocialsList";
import SwitchDarkMode from "../SwitchDarkMode/SwitchDarkMode";
import ButtonClose from "../ButtonClose/ButtonClose";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setIsOpenPopup, setWhichTab } from "../../reducers/slices/commonPremiumSlice";
import LogOut from "../LogOut/LogOut";

export interface NavMobileProps {
  data?: NavItemType[];
  onClickClose?: () => void;
}

const NavMobile: React.FC<NavMobileProps> = ({
  data = NAVIGATION_DEMO_2,
  onClickClose,
}) => {
  const _renderMenuChild = (
    item: NavItemType,
    itemClass = " pl-3 text-gray-900 dark:text-gray-200 font-medium "
  ) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const handleClick = async (href: string, name: string) => {
      const modifiedName = name.includes("Insurance")
        ? name.replace(" Insurance", "")
        : name;
      if (href === "") {
        console.log("Setting whichTab to:", modifiedName); // Debugging log
        dispatch(setWhichTab(modifiedName));
        dispatch(setIsOpenPopup(true));
      }
    };

    return (
      <ul className="nav-mobile-sub-menu pl-6 pb-1 text-base">
        {item.children?.map((i, index) => (
          <Disclosure key={index} as="li">
            <Link
              href={{
                pathname: i.href || undefined,
              }}
              onClick={() => {
                navigate("/");
                handleClick(i.href, i.name);
              }}
              className={`flex text-sm rounded-lg hover:bg-zinc-100 dark:hover:bg-zinc-800 mt-0.5 pr-4 ${itemClass}`}
            >
              <span
                className={`py-2.5 ${!i.children ? "block w-full" : ""}`}
                onClick={onClickClose}
              >
                {i.name}
              </span>
              {i.children && (
                <span
                  className="flex items-center flex-grow"
                  onClick={(e) => e.preventDefault()}
                >
                  <Disclosure.Button
                    as="span"
                    className="flex justify-end flex-grow"
                  >
                    <svg
                      width="20px"
                      height="20px"
                      viewBox="0 0 48 48"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <title>arrowhead-down</title>
                      <g id="Layer_2" data-name="Layer 2">
                        <g id="invisible_box" data-name="invisible box">
                          <rect width="48" height="48" fill="none" />
                        </g>
                        <g id="icons_Q2" data-name="icons Q2">
                          <path
                            d="M24,27.2,13.4,16.6a1.9,1.9,0,0,0-3,.2,2.1,2.1,0,0,0,.2,2.7l12,11.9a1.9,1.9,0,0,0,2.8,0l12-11.9a2.1,2.1,0,0,0,.2-2.7,1.9,1.9,0,0,0-3-.2Z"
                            fill="#808080"
                          />
                        </g>
                      </g>
                    </svg>
                  </Disclosure.Button>
                </span>
              )}
            </Link>
            {i.children && (
              <Disclosure.Panel>
                {_renderMenuChild(i, "pl-3 text-gray-600 dark:text-gray-400 ")}
              </Disclosure.Panel>
            )}
          </Disclosure>
        ))}
      </ul>
    );
  };

  const _renderItem = (item: NavItemType, index: number) => {
    return (
      <Disclosure key={index} as="li" className="text-gray-900 dark:text-white">
        <Link
          className="flex w-full items-center py-2.5 px-4 font-medium uppercase tracking-wide text-sm hover:bg-zinc-100 dark:hover:bg-zinc-800 rounded-lg"
          href={{
            pathname: item.href || undefined,
          }}
        >
          <span
            className={!item.children ? "block w-full" : ""}
            onClick={onClickClose}
          >
            {item.name}
          </span>
          {item.children && (
            <span
              className="block flex-grow"
              onClick={(e) => e.preventDefault()}
            >
              <Disclosure.Button
                as="span"
                className="flex justify-end flex-grow"
              >
                <svg
                  width="20px"
                  height="20px"
                  viewBox="0 0 48 48"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <title>arrowhead-down</title>
                  <g id="Layer_2" data-name="Layer 2">
                    <g id="invisible_box" data-name="invisible box">
                      <rect width="48" height="48" fill="none" />
                    </g>
                    <g id="icons_Q2" data-name="icons Q2">
                      <path
                        d="M24,27.2,13.4,16.6a1.9,1.9,0,0,0-3,.2,2.1,2.1,0,0,0,.2,2.7l12,11.9a1.9,1.9,0,0,0,2.8,0l12-11.9a2.1,2.1,0,0,0,.2-2.7,1.9,1.9,0,0,0-3-.2Z"
                        fill="#808080"
                      />
                    </g>
                  </g>
                </svg>
              </Disclosure.Button>
            </span>
          )}
        </Link>
        {item.children && (
          <Disclosure.Panel>{_renderMenuChild(item)}</Disclosure.Panel>
        )}
      </Disclosure>
    );
  };


  return (
    <div className="overflow-y-auto w-full h-screen py-2 transition transform shadow-lg ring-1 dark:ring-zinc-700 bg-white dark:bg-zinc-950 divide-y-2 divide-zinc-100 dark:divide-zinc-800">
      <div className="py-6 px-5">
        <Logo img="https://i.postimg.cc/pXL47bcL/p4usLogo.png" />
        <div className="flex flex-col mt-5 text-gray-600 dark:text-gray-300 text-sm">
          <span>
            Your trusted partner in protection, ensuring your peace of mind
            through life's twists and turns.
          </span>

          <div className="flex justify-between items-center mt-4">
            <SocialsList itemClass="w-7 h-7 sm:w-8 sm:h-8 flex items-center justify-center rounded-full text-xl" />
            <span className="flex-1 flex items-center justify-end text-gray-700 dark:text-gray-100">
              <SwitchDarkMode />
              <LogOut />
            </span>
          </div>
        </div>
        <span className="absolute right-2 top-2 p-1">
          <ButtonClose onClick={onClickClose} />
        </span>

      </div>
      <ul className="flex flex-col py-6 px-2 space-y-1">
        {data.map(_renderItem)}
      </ul>
    </div>
  );
};

export default NavMobile;
