import React from "react";
import { BadgeP, Label } from "./HeaderComponent";
import SmallScreenHeaderDetails from "./SmallScreenHeaderDetails";
import Link1 from "../Links/Link1";
import { HeaderComponentProps } from "./HeaderComponent";
import Editicon1 from "../EditIcons/EditIcon1/Editicon1";

const HeaderDetails: React.FC<
  HeaderComponentProps & {
    fullDiv: boolean;
    setFullDiv: React.Dispatch<React.SetStateAction<boolean>>;
    isCarBike: boolean;
    isDontKnow: boolean;
    isNew: boolean;
    whichTab: string;
  mainCardData: any[];
  }
> = ({
  headerData,
  prevCompreh,
  updateCombinedData,
  prevTP,
  newTP,
  fullDiv,
  whichTab,
  setFullDiv,
  isCarBike,
  isDontKnow,
  isNew,
  mainCardData
}) => {
  return (
    <div
      className={`relative w-full ${
        fullDiv ? "h-[300px]" : "h-[110px]"
      } sm:h-[20rem] lg:h-[210px] xl:h-[210px] bg-primary-100/50 dark:bg-zinc-900/40 ring-1 ring-zinc-300/30 dark:ring-0 flex`}
    >
      <div className="absolute container inset-0 w-full h-full flex flex-col sm:flex-col lg:flex-row xl:flex-row justify-normal sm:justify-normal lg:justify-between xl:justify-between gap-5 max-sm:gap-3 max-sm:py-2 px-7 py-6">
        <SmallScreenHeaderDetails
          headerData={headerData}
          fullDiv={fullDiv}
          isCarBike={isCarBike}
          isDontKnow={isDontKnow}
          isNew={isNew}
          setFullDiv={setFullDiv}
          newTP={newTP}
          prevTP={prevTP}
        />
        <div
          className={`flex ${
            fullDiv ? "max-sm:flex" : "max-sm:hidden"
          } sm:hidden justify-end items-center `}
        >
          <div className="" onClick={() => setFullDiv(false)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M5 15l7-7 7 7"
              />
            </svg>
          </div>
        </div>
        {/* for large and small details header portion */}
        <div className={`flex ${fullDiv ? "max-sm:flex" : "max-sm:hidden"}`}>
          <div className="flex flex-col w-full space-y-1">
            <div className="flex justify-between w-full items-center mb-2">
              <div className="flex flex-wrap space-x-1">
                {headerData.badge1 && headerData.badge1 !== " " && (
                  <BadgeP text={headerData.badge1} />
                )}
              </div>
              {headerData.link1 && headerData.link1 !== " " && (
                <Link1 linkText={headerData.link1} />
              )}
            </div>
            <div className="text-lg max-sm:text-base dark:text-gray-50 font-bold">
              {headerData.title1}
            </div>
            <div className="flex items-center flex-wrap">
              {headerData.label1 && headerData.label1 !== " " && (
                <Label
                  label={headerData.label1}
                  value={headerData.labelValue1}
                />
              )}
              {headerData.label2 && headerData.label2 !== " " && (
                <>
                  <div className="mx-2 h-4 border-gray-400 border-r"></div>
                  <Label
                    label={headerData.label2}
                    value={headerData.labelValue2}
                  />
                </>
              )}
              {headerData.label3 && headerData.label3 !== " " && (
                <>
                  <div className="mx-2 h-4 border-gray-400 border-r"></div>
                  <Label
                    label={headerData.label3}
                    value={headerData.labelValue3}
                  />
                </>
              )}
            </div>
          </div>
        </div>
        <div className={`flex ${fullDiv ? "max-sm:flex" : "max-sm:hidden"}`}>
          <div className="flex flex-col w-full space-y-1 items-start sm:items-start lg:items-end xl:items-end 2xl:items-end">
            <div className="flex gap-4 items-center">
              {headerData.badge2 && headerData.badge2 !== " " && (
                <BadgeP text={headerData.badge2} />
              )}
              {isCarBike &&
                headerData.editIcon1 &&
                Object.keys(headerData.editIcon1).length !== 0 && (
                  <Editicon1
                    hasPrevExpired={headerData.hasPrevExpired}
                    hasExpiredAfterDate={headerData.hasExpiredAfterDate}
                    userSelectedType={headerData.userSelectedType}
                    button1keys={headerData.button1}
                    keys={headerData.editIcon1}
                    whichTab={whichTab}
                    updateCombinedData={updateCombinedData}
                    prevCompreh={prevCompreh}
                    prevTP={prevTP}
                    newTP={newTP}
                    button2Keys={headerData.button2}
                    button3Keys={headerData.button3}
                    mainCardData={mainCardData}
                  />
                )}
            </div>
            <div className="flex items-center flex-wrap ms-1">
              {isCarBike &&
                isDontKnow &&
                headerData.label4 &&
                headerData.label4 !== " " && (
                  <Label
                    label={headerData.label4}
                    value={
                      headerData.hasPrevExpired &&
                      headerData.hasExpiredAfterDate !== null &&
                      !headerData.hasExpiredAfterDate
                        ? "Expired, More Than 90 Days"
                        : headerData.labelValue4
                    }
                  />
                )}
              {isCarBike &&
                isNew &&
                headerData.label8 &&
                headerData.label8 !== " " && (
                  <Label
                    label={headerData.label8}
                    value={headerData.labelValue8}
                  />
                )}
              {isCarBike &&
                !prevTP &&
                !newTP &&
                isDontKnow &&
                headerData.hasPrevClaim !== null &&
                headerData.label5 &&
                headerData.label5 !== " " && (
                  <>
                    <div className="mx-2 h-4 border-gray-400 border-r"></div>
                    <Label
                      label={headerData.label5}
                      value={headerData.labelValue5 ? "Yes" : "No"}
                    />
                  </>
                )}
            </div>
            <div className="flex items-center flex-wrap ms-1">
              {isCarBike &&
                !prevTP &&
                !newTP &&
                isDontKnow &&
                headerData.hasPrevClaim === false &&
                headerData.label6 &&
                headerData.label6 !== " " && (
                  <Label
                    label={headerData.label6}
                    value={headerData.labelValue6}
                  />
                )}
              {isCarBike &&
                isNew &&
                headerData.label9 &&
                headerData.label9 !== " " && (
                  <Label
                    label={headerData.label9}
                    value={headerData.labelValue9}
                    isDate
                  />
                )}
              {isCarBike &&
                !prevTP &&
                !newTP &&
                isDontKnow &&
                headerData.hasPrevClaim === false &&
                headerData.label7 &&
                headerData.label7 !== "" && (
                  <>
                    <div className="mx-2 h-4 border-gray-400 border-r"></div>
                    <Label
                      label={headerData.label7}
                      value={headerData.labelValue7}
                    />
                  </>
                )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderDetails;
