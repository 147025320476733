import { FC } from "react";
import NavigationItem from "./NavigationItem";
import { NAVIGATION_DEMO_2 } from "../../data/navigation";
import { navigation } from "../styleComponents/navigationStyles/navigationStyle";

interface Props {}

const Navigation: FC<Props> = () => {
  return (
    <ul className ={navigation}>
      {NAVIGATION_DEMO_2.map((item) => (
        <NavigationItem key={item.id} menuItem={item} />
      ))}
    </ul>
  );
};

export default Navigation;
