import React, {useState } from "react";
import ButtonPrimary from "../components/Button/ButtonPrimary";
import Input from "../components/Input/Input";
import Heading2 from "../components/Heading/Heading2";
import { useNavigate } from "react-router-dom"; // Assuming you're using react-router for navigation
import {  writeToDB } from "../utils/indexDBUtils";

const LoginPage: React.FC = () => {
  const [formData, setFormData] = useState({ userName: "", password: "" });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();
  // const alertShownRef = useRef(false); // Ref to track if alert has been shown

  // const alertShow = async () => {
  //   const tokenExpired = await isTokenExpired();

  //   // Only show alert if it hasn't been shown yet and token is expired
  //   if (tokenExpired && !alertShownRef.current) {
  //     alert('Your session has expired. Please log in again.');
  //     alertShownRef.current = true; // Set to true after showing the alert
  //   }
  // };

  // useEffect(() => {
  //   alertShow();
  // }, []);



  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    try {
      const response = await fetch("https://core.insurancepolicy4us.com/api/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      const result = await response.json();

      if (response.ok && result.status) {
        // Save token and user data to IndexedDB
        const loginTime = new Date().toISOString();
        await writeToDB("userInfo", { token: result.token, data: result.data , loginTime:loginTime});
        // Navigate to home page
        navigate("/");
      } else {
        setError(result.message || "Login failed. Please try again.");
      }
    } catch (err) {
      setError("An error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-white to-zinc-200 dark:from-zinc-900 dark:to-zinc-950">
      <div className="relative mx-2 max-w-md w-full bg-white dark:bg-black p-8 sm:p-10 lg:p-12 rounded-2xl sm:rounded-3xl shadow-xl border dark:border-zinc-900">
        <header className="text-center">
          <Heading2 className="text-3xl font-bold text-neutral-900 dark:text-neutral-100">
            Login
          </Heading2>
        </header>

        <div className="mt-6">
          <form className="grid grid-cols-1 gap-6" onSubmit={handleSubmit}>
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Username
              </span>
              <Input
                type="text"
                className="mt-1 w-full"
                name="userName"
                value={formData.userName}
                onChange={handleInputChange}
                required
              />
            </label>

            <label className="block">
              <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                Password
              </span>
              <Input
                type="password"
                className="mt-1 w-full"
                name="password"
                value={formData.password}
                onChange={handleInputChange}
                required
              />
            </label>

            {error && <small className=" text-xs text-red-500">{error}</small>}

            <ButtonPrimary type="submit" className="w-full" disabled={loading}>
              {loading ? (
                <span className="flex items-center">
                  <svg
                    className="animate-spin h-5 w-5 mr-3"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A8.004 8.004 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                </span>
              ) : (
                "Continue"
              )}
            </ButtonPrimary>
          </form>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
