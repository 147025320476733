import { ButtonHTMLAttributes, FC } from "react";
import twFocusClass from "../../utils/twFocusClass";
import { nextBtnMain, prevNextBtnIcon, prevNextBtnProp } from "../styleComponents/nextPrevStyle";

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {}

const NextBtn: FC<Props> = ({ className = {prevNextBtnProp}, ...args }) => {
  return (
    <button
      className={` ${className} ${nextBtnMain}  ${twFocusClass()}`}
      {...args}
    >
      <span className={prevNextBtnIcon}>&#10095;</span>
    </button>
  );
};

export default NextBtn;
