import { ReactNode, useEffect, useState } from "react";
import { useWindowSize } from "react-use";
import { useSwipeable } from "react-swipeable";
import PrevBtn from "./NextPrev/PrevBtn";
import NextBtn from "./NextPrev/NextBtn";
import { mySliderMain, mySliderMotionDiv, mySliderMotionInnerDiv, mySliderMotionLi, mySliderMotionUl, mySliderNextBtn, mySliderPrevBtn } from "./styleComponents/mySliderStyle";

export interface MySliderProps<T> {
  className?: string;
  itemPerRow?: number;
  data: T[];
  renderItem?: (item: T, indx: number) => ReactNode;
  arrowBtnClass?: string;
}

export default function MySlider<T>({
  className = "",
  itemPerRow = 5,
  data,
  renderItem = () => <div></div>,
  arrowBtnClass = "top-1/2 -translate-y-1/2",
}: MySliderProps<T>) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [numberOfItems, setNumberOfItems] = useState(0);

  const windowWidth = useWindowSize().width;

  useEffect(() => {
    if (windowWidth < 320) {
      return setNumberOfItems(1);
    }
    if (windowWidth < 500) {
      return setNumberOfItems(itemPerRow - 3);
    }
    if (windowWidth < 1024) {
      return setNumberOfItems(itemPerRow - 2);
    }
    if (windowWidth < 1280) {
      return setNumberOfItems(itemPerRow - 1);
    }
    setNumberOfItems(itemPerRow);
  }, [itemPerRow, windowWidth]);

  function changeItemId(newVal: number) {
    setCurrentIndex(newVal);
  }

  const handlers = useSwipeable({
    onSwipedLeft: () => {
      if (currentIndex < data?.length - 1) {
        changeItemId(currentIndex + 1);
      }
    },
    onSwipedRight: () => {
      if (currentIndex > 0) {
        changeItemId(currentIndex - 1);
      }
    },
    trackMouse: true,
  });

  if (!numberOfItems) {
    return <div></div>;
  }

  return (
    <div className={`${mySliderMain} ${className}`}>
      <div className={mySliderMotionDiv} {...handlers}>
        <div className={mySliderMotionInnerDiv}>
          <ul
            className={mySliderMotionUl}
            style={{
              transform: `translateX(-${currentIndex * (100 / numberOfItems)}%)`,
              transition: 'transform 0.3s ease',
            }}
          >
            {data.map((item, indx) => (
              <li
                className={mySliderMotionLi}
                key={indx}
                style={{
                  width: `calc(100% / ${numberOfItems})`,
                }}
              >
                {renderItem(item, indx)}
              </li>
            ))}
          </ul>
        </div>

        {currentIndex > 0 && (
          <PrevBtn
            onClick={() => changeItemId(currentIndex - 1)}
            className={`${mySliderPrevBtn} ${arrowBtnClass}`}
          />
        )}

        {data.length > currentIndex + numberOfItems && (
          <NextBtn
            onClick={() => changeItemId(currentIndex + 1)}
            className={`${mySliderNextBtn} ${arrowBtnClass}`}
          />
        )}
      </div>
    </div>
  );
}