import { ReactNode } from "react";

const ProposalOuterLayout = ({ children }: { children: ReactNode }) => {
  return (
    <div className="bg-white dark:bg-zinc-950 pb-8">
      <div
        className={`absolute h-[400px] top-0 left-0 right-0 w-full bg-primary-100 bg-opacity-25 dark:bg-opacity-40 dark:bg-zinc-900`}
      />
      <div className=" sm:container mx-2 relative pt-6 sm:pt-10 pb-1 lg:pt-18 lg:pb-16">
        <div className="p-5 mx-auto bg-white rounded-xl sm:rounded-3xl lg:rounded-[40px] shadow-lg sm:p-10 lg:p-16 dark:bg-zinc-950 border dark:border-zinc-900">
          {children}
        </div>
      </div>
    </div>
  );
};

export default ProposalOuterLayout;
