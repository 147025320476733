import React from "react";
import ProposalOuterLayout from "../ProposalOuterLayout";
// import Layout from "../Layout";
import { useNavigate } from "react-router-dom";
import ButtonPrimary from "../../Button/ButtonPrimary";
import Heading from "../../Heading/Heading";
import Label from "../../Label/Label";
import { healthProposalPageData } from "../../../data/Proposal/healthProposal/healthProposalData";
import Input from "../../Input/Input";
import Select from "../../Select/Select";
import Heading2 from "../../Heading/Heading2";

const MembersDetails: React.FC = () => {
  const navigate = useNavigate();

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    navigate("/proposal/medical-details");
  };

  // Dummy member data
  const memberDetails = [
    { name: "Proposer", age: 30 },
    { name: "Partner", age: 25 },
    { name: "Son", age: 40 },
  ];

  return (
    <div>
      <ProposalOuterLayout>
        {/* <Layout> */}
          <span className={`lg:hidden md:block  `}>
            <Heading desc="">
              {healthProposalPageData.MembersDetails.title1}
            </Heading>
          </span>
          <form
            className="grid md:grid-cols-5 gap-6"
            onSubmit={handleSubmit}
            method="post"
          >
            {memberDetails.map((member, index) => (
              <>
                <Heading2 className="block md:col-span-5 mt-2" key={index}>
                  {member.name} - {member.age}
                </Heading2>

                <label className="block md:col-span-1">
                  <Label>{healthProposalPageData.MembersDetails.label1}</Label>

                  <Select className="mt-1">
                    <option value="-1"></option>
                    {healthProposalPageData.MembersDetails.Salutation.map(
                      (title, index) => (
                        <option key={index} value={title}>
                          {title}
                        </option>
                      )
                    )}
                  </Select>
                </label>
                <label className="block md:col-span-2">
                  <Label>{healthProposalPageData.MembersDetails.label2}</Label>

                  <Input type="text" className="mt-1" />
                </label>
                <label className="block md:col-span-2">
                  <Label>{healthProposalPageData.MembersDetails.label3}</Label>

                  <Input type="text" className="mt-1" />
                </label>
                <label className="block md:col-span-2">
                  <Label>{healthProposalPageData.MembersDetails.label4}</Label>

                  <Input type="text" className="mt-1" />
                </label>

                <label className="block">
                  <Label>{healthProposalPageData.MembersDetails.label5}</Label>

                  <Select className="mt-1">
                    <option value="-1"></option>
                    {healthProposalPageData.MembersDetails.Gender.map(
                      (insurer, index) => (
                        <option key={index} value={insurer}>
                          {insurer}
                        </option>
                      )
                    )}
                  </Select>
                </label>
                <label className="block md:col-span-2">
                  <Label>{healthProposalPageData.MembersDetails.label6}</Label>

                  <Select className="mt-1">
                    <option value="-1"></option>
                    {healthProposalPageData.MembersDetails.MaritalStatus.map(
                      (status, index) => (
                        <option key={index} value={status}>
                          {status}
                        </option>
                      )
                    )}
                  </Select>
                </label>
              </>
            ))}
            <ButtonPrimary className="md:col-span-5">
              {healthProposalPageData.MembersDetails.button1}
            </ButtonPrimary>
          </form>
        {/* </Layout> */}
      </ProposalOuterLayout>
    </div>
  );
};

export default MembersDetails;
