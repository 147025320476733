import { Dispatch } from "redux";
import { readFromDB } from "./indexDBUtils";
import { 
setNewTP,setPrevCompreh,setPrevTP 
} from "../reducers/slices/MotorSlice"; 

export const fetchSpecialDetailsIndexData = async (dispatch: Dispatch) => {
  const data = await readFromDB("motorTPdetails");

  if (data) {
    dispatch(setNewTP(data.newTP));
    dispatch(setPrevCompreh(data.prevCompreh));
    dispatch(setPrevTP(data.prevTP));
  } else {
    dispatch(setNewTP(false));
    dispatch(setPrevCompreh(false));
    dispatch(setPrevTP(false));
   
  }
};
