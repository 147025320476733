import React, { useEffect, useState } from "react";
import ButtonPrimary from "../Button/ButtonPrimary";
import { carProposalPageData } from "../../data/Proposal/carProposal/carProposalData";
import Label from "../Label/Label";
import Input from "../Input/Input";
import Select from "../Select/Select";
import Radio from "../Radio/Radio";
import { useDispatch } from "react-redux";
import { updatePreviousInsurer } from "../../reducers/slices/carBikeProposalSlice";
import { readFromDB, writeToDB } from "../../utils/indexDBUtils";
import {
  validateAddress,
  validatePolicyNumber,
} from "../../utils/formValidations";
import { markStepCompleted } from "./OwnerDetails";
import { verifyKycToIndexdb } from "./KYC";
import { useSelector } from "react-redux";
import { RootState } from "../../reducers/store/store";

interface PreviousInsurerProps {
  onSubmit: () => void;
}
interface FormData {
  prevInsurer: string;
  prevPolicyNo: string;
  prevInsurerAddress: string;
  tpInsurer: string;
  tpPolicyNumber: string;
  isItUnderLoan: boolean | string; // Allow both boolean and string
  loanProvider: string;
  loanProviderAddress: string;
}

const PreviousInsurer: React.FC<PreviousInsurerProps> = ({ onSubmit }) => {
  const dispatch = useDispatch();
  const {proposalData } = useSelector(
    (state: RootState) => state.motorDetails
  );

  // console.log("proposalData from premium",proposalData);
  let CompanyName = proposalData.responseData.CompanyName;
   // Mapping of shortened company names to full names
   const companyNameMapping: { [key: string]: string } = {
    "Bajaj Allianz": "Bajaj Allianz General Insurance Company Ltd",
    "Shriram General Insurance": "Shriram General Insurance Company Ltd",
    "Royal Sundaram": "Royal Sundaram General Insurance Company Ltd",
    "Reliance General": "Reliance General Insurance Company Ltd",
    "National Insurance": "National Insurance Company Limited",
  };
  const [formData, setFormData] = useState<FormData>({
    prevInsurer: "",
    prevPolicyNo: "",
    prevInsurerAddress: "",
    tpInsurer: "",
    tpPolicyNumber: "",
    isItUnderLoan: "No",
    loanProvider: "",
    loanProviderAddress: "",
  });

  const [isYesSelected, setIsYesSelected] = useState<boolean>(false);
  const [formErrors, setFormErrors] = useState<{ [key: string]: string }>({});
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    console.log("data", formData);
    const finalFormData = {
      ...formData,
      isItUnderLoan: formData.isItUnderLoan || "No", // Ensure it's included
    };

    // Validate all required fields
    const requiredFields = [
      "prevInsurer",
      "prevPolicyNo",
      "prevInsurerAddress",
      "tpInsurer",
      "tpPolicyNumber",
     
    ];

    if (isYesSelected) {
      requiredFields.push("loanProvider", "loanProviderAddress");
    }

    const newFormErrors: { [key: string]: string } = {};

    requiredFields.forEach((field) => {
      if (!finalFormData[field as keyof typeof finalFormData]) {
        newFormErrors[field] = "This field is required";
      }
    });

    // Validate policy numbers and addresses
    const prevPolicyError = validatePolicyNumber(finalFormData.prevPolicyNo);
    const tpPolicyError = validatePolicyNumber(finalFormData.tpPolicyNumber);
    const prevInsurerAddressError = validateAddress(
      finalFormData.prevInsurerAddress
    );
    const loanProviderAddressError = isYesSelected
      ? validateAddress(finalFormData.loanProviderAddress)
      : "";

    if (prevPolicyError) newFormErrors.prevPolicyNo = prevPolicyError;
    if (tpPolicyError) newFormErrors.tpPolicyNumber = tpPolicyError;
    if (prevInsurerAddressError)
      newFormErrors.prevInsurerAddress = prevInsurerAddressError;
    if (loanProviderAddressError)
      newFormErrors.loanProviderAddress = loanProviderAddressError;

    setFormErrors(newFormErrors);

    if (Object.keys(newFormErrors).length > 0) {
      return;
    }

    setIsLoading(true);
    try {

      dispatch(updatePreviousInsurer(finalFormData));
      console.log("PreviousInsurerData", finalFormData);
      // dispatch(updatePreviousInsurer(formData));
      // console.log("PreviousInsurerData", formData);

      writeToDB("motor-proposal-PreviousInsurerData", finalFormData);

      // Read existing FormState data
      const existingFormState = await readFromDB("motor-proposal-FormState");

      // Merge nomineeDetails with existing FormState data
      const updatedFormState = {
        ...existingFormState,
        previousInsurer: finalFormData,
      };

      // Write updated FormState data back to IndexedDB
      await writeToDB("motor-proposal-FormState", updatedFormState);
      markStepCompleted(5);
      // Move to the next step
      onSubmit();
    } catch (error) {
      console.error("Error saving communication address:", error);
      alert("An error occurred while saving your details. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const data = await readFromDB("motor-proposal-PreviousInsurerData");
      if (data) {
        setFormData(data);
        setIsYesSelected(data.isItUnderLoan === "Yes");
      }
    };
    window.scrollTo(0, 0);
    fetchData();
  }, []);

  const handleChange = async(
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = event.target;
    const updatedValue =
      name === "prevPolicyNo" || name === "tpPolicyNumber"
        ? value.toUpperCase()
        : value;

    setFormData({
      ...formData,
      [name]: updatedValue,
    });
    await verifyKycToIndexdb(false);

  };

   // Filter function to exclude the matching company
   const filterInsurers = (insurers: string[]) => {
    const fullCompanyName = companyNameMapping[CompanyName] || CompanyName;
    return insurers.filter(insurer => insurer !== fullCompanyName);
  };

  const handleRadioChange = async(checked: boolean, name: string) => {
    const value = checked ? "Yes" : "No";
    setIsYesSelected(checked);
    setFormData({
      ...formData,
      [name]: value,
      ...(checked ? {} : { loanProvider: "", loanProviderAddress: "" }),
    });
    await verifyKycToIndexdb(false);

  };

  return (
    <div>
      {/* <span className={`lg:hidden md:block  `}>
        <Heading desc="">{carProposalPageData.PreviousInsurer.label1}</Heading>
      </span> */}
      <form
        className="grid md:grid-cols-2 gap-6"
        onSubmit={handleSubmit}
        method="post"
      >
        <label className="block md:col-span-2">
          <Label>{carProposalPageData.PreviousInsurer.label1}</Label>
          <Select
            className="mt-1"
            onChange={handleChange}
            name="prevInsurer"
            value={formData.prevInsurer}
            required
          >
            <option value="-1"></option>
            {/* {carProposalPageData.PreviousInsurer.PreviousODPolicyInsurers.map(
              (insurer, index) => (
                <option
                  className="dark:bg-zinc-900"
                  key={index}
                  value={insurer}
                >
                  {insurer}
                </option>
              )
            )} */}
             {filterInsurers(carProposalPageData.PreviousInsurer.PreviousODPolicyInsurers).map(
            (insurer, index) => (
              <option className="dark:bg-zinc-900" key={index} value={insurer}>
                {insurer}
              </option>
            )
          )}
          </Select>
        </label>
        <label className="block md:col-span-2">
          <Label>{carProposalPageData.PreviousInsurer.label2}</Label>
          <Input
            className="mt-1"
            type="text"
            onChange={handleChange}
            name="prevPolicyNo"
            value={formData.prevPolicyNo}
            required
          />
          {formErrors.prevPolicyNo && (
            <p className="text-red-600 text-xs mt-1">
              {formErrors.prevPolicyNo}
            </p>
          )}
        </label>
        <label className="block md:col-span-2">
          <Label>{carProposalPageData.PreviousInsurer.label3}</Label>
          <Input
            className="mt-1"
            type="text"
            onChange={handleChange}
            name="prevInsurerAddress"
            value={formData.prevInsurerAddress}
            required
          />
          {formErrors.prevInsurerAddress && (
            <p className="text-red-600 text-xs mt-1">
              {formErrors.prevInsurerAddress}
            </p>
          )}
        </label>
        <label className="block md:col-span-2">
          <Label>{carProposalPageData.PreviousInsurer.label4}</Label>
          <Select
            className="mt-1"
            onChange={handleChange}
            name="tpInsurer"
            value={formData.tpInsurer}
          >
            <option value="-1"></option>
            {/* {carProposalPageData.PreviousInsurer.ActiveTPPolicyInsurers.map(
              (insurer, index) => (
                <option
                  className="dark:bg-zinc-900"
                  key={index}
                  value={insurer}
                >
                  {insurer}
                </option>
              )
            )} */}
             {filterInsurers(carProposalPageData.PreviousInsurer.ActiveTPPolicyInsurers).map(
            (insurer, index) => (
              <option className="dark:bg-zinc-900" key={index} value={insurer}>
                {insurer}
              </option>
            )
          )}
          </Select>
        </label>
        <label className="block md:col-span-2">
          <Label>{carProposalPageData.PreviousInsurer.label5}</Label>
          <Input
            className="mt-1"
            type="text"
            onChange={handleChange}
            name="tpPolicyNumber"
            value={formData.tpPolicyNumber}
          />
          {formErrors.tpPolicyNumber && (
            <p className="text-red-600 text-xs mt-1">
              {formErrors.tpPolicyNumber}
            </p>
          )}
        </label>
        <div className="block md:col-span-1">
          <Label className="block md:col-span-7">{carProposalPageData.PreviousInsurer.label6}</Label>
          <span className="inline-flex items-center space-x-4 mt-3">
            {/* <Radio
              name="isItUnderLoan"
              label="Yes"
              id="yes"
              checked={isYesSelected}
              onChange={(checked) =>
                handleRadioChange(checked, "isItUnderLoan")
              }
            />
            <Radio
              name="isItUnderLoan"
              label="No"
              id="no"
              checked={!isYesSelected}
              onChange={(checked) =>
                handleRadioChange(!checked, "isItUnderLoan")
              }
            /> */}
            <Radio
      name="isItUnderLoan"
      label="Yes"
      id="yes"
      checked={isYesSelected}
      onChange={() => handleRadioChange(true, "isItUnderLoan")}
    />
    <Radio
      name="isItUnderLoan"
      label="No"
      id="no"
      checked={!isYesSelected}
      onChange={() => handleRadioChange(false, "isItUnderLoan")}
    />
          </span>
        </div>
        {isYesSelected && (
          <>
            <label className="block md:col-span-2">
              <Label>{carProposalPageData.PreviousInsurer.label7}</Label>
              <Select
                className="mt-1"
                onChange={handleChange}
                name="loanProvider"
                value={formData.loanProvider}
              >
                <option value="-1"></option>
                {carProposalPageData.PreviousInsurer.ActiveTPPolicyInsurers.map(
                  (insurer, index) => (
                    <option
                      className="dark:bg-zinc-900"
                      key={index}
                      value={insurer}
                    >
                      {insurer}
                    </option>
                  )
                )}
              </Select>
            </label>
            <label className="block md:col-span-2">
              <Label>{carProposalPageData.PreviousInsurer.label8}</Label>
              <Input
                type="text"
                className="mt-1"
                onChange={handleChange}
                name="loanProviderAddress"
                value={formData.loanProviderAddress}
              />
              {formErrors.loanProviderAddress && (
                <p className="text-red-600 text-xs mt-1">
                  {formErrors.loanProviderAddress}
                </p>
              )}
            </label>
          </>
        )}
        <ButtonPrimary className="md:col-span-2" disabled={isLoading}>
          {carProposalPageData.PreviousInsurer.button1}
        </ButtonPrimary>
      </form>
    </div>
  );
};

export default PreviousInsurer;
