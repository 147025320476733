import React from "react";

interface BackButtonProps {
  onClick: () => void;
  isVisible?: boolean;
}

const BackButton: React.FC<BackButtonProps> = ({ onClick, isVisible }) => {
  return (
    <div>
      { isVisible !== false && (
        <div title="Previous Step" onClick={onClick} className="text-3xl text-gray-400 ml-5 dark:text-gray-400 cursor-pointer">
          &#60;
        </div>
      )}
    </div>
  );
};

export default BackButton;
