export const ReliancePayment = (proposalData:any, kycData:any, kycDetailsData:any) => {
  const userID = "100002"; //Confidential data hardcoded
  console.log("userID",userID);
  console.log("kyc",kycData);
  
  const proposalNo = proposalData[0].ProposalNo; // 'R22062300335';
  // const proposalNo = "R30072400431"; // 'R22062300335';
  console.log("proposalNo",proposalNo);
  const ProposalAmount = proposalData[0].TotalPremium; // 46864.00
  // const ProposalAmount = "199634"; // 46864.00
  console.log("ProposalAmount",ProposalAmount);
  const ckycNo = kycData[0].ckycNo;//30055035099349
  // const ckycNo = "30055035099349";//
  console.log("ckycNo",ckycNo);
  // const panNumber = "LVXPS1874F";
  const panNumber = kycDetailsData.panNumber;//LVXPS1874F
  console.log("panNumber",panNumber);
  const RGIURL = `https://rgipartners.reliancegeneral.co.in/PaymentIntegration/PaymentIntegration?ProposalNo=${proposalNo}&userID=${userID}&ProposalAmount=${ProposalAmount}&PaymentType=1&Responseurl=https://www.insurancepolicy4us.com/payment&CKYC=${ckycNo}&IsDocumentUpload=False&PanNo=${panNumber}&IsForm60=false`;
  console.log("RGIURL",RGIURL);
 
    window.open(RGIURL, '_blank'); // Open URL in a new tab
}
