import { useNavigate } from 'react-router-dom'
import { switchDarkModeSpan } from '../styleComponents/headerStyles/switchDarkModeStyle'
import { writeToDB } from '../../utils/indexDBUtils';
// import { setCurrentStep, setNewTP, setPremiumData, setPrevCompreh, setPrevTP, setResponseData } from '../../reducers/slices/MotorSlice';
// import { setIsOpenPopup } from '../../reducers/slices/commonPremiumSlice';
// import { useDispatch } from 'react-redux';

const LogOut = () => {
  const navigate = useNavigate();
  const handleLogOut = async() => {
 
      await writeToDB("userInfo", {});

    navigate('/login');
  }

  return (
   <button 
     title='Log-Out' 
     onClick={handleLogOut} 
     className='flex w-10 h-10 sm:w-12 sm:h-12 rounded-full text-gray-700 dark:text-gray-300 hover:bg-zinc-100 dark:hover:bg-zinc-800 focus:outline-none items-center justify-center'>
    <span className={switchDarkModeSpan}>Log-Out</span>
    <svg  width="22px" height="22px" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"  >
      <path d="M13.5 7.5L10.5 10.75M13.5 7.5L10.5 4.5M13.5 7.5L4 7.5M8 13.5H1.5L1.5 1.5L8 1.5" stroke="currentColor"/>
    </svg>
   </button>
  )
}

export default LogOut;
