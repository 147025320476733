import { Dispatch, SetStateAction } from 'react';

interface AddOn {
  option: string;
  value: any;
}

interface Option {
  name: string;
  input: boolean;
  range: boolean;
  dropdown: boolean;
}

const useCheckboxHandler = (
  selectedAddOns: AddOn[],
  options: Option[],
  setSelectedAddOns: Dispatch<SetStateAction<AddOn[]>>
) => {

  const handleCheckboxSelect = (name: string) => {
    const existingIndex = selectedAddOns.findIndex((option) => option.option === name);

    const selectedItem = options.find((item: any) => item.name === name);
    const hasInput = selectedItem?.input || selectedItem?.range || selectedItem?.dropdown;

    let initialValue: any = null;
    if (selectedItem?.range) {
      initialValue = 10000;
    } else if (selectedItem?.dropdown || selectedItem?.input) {
      initialValue = 0;
    }

    if (existingIndex !== -1) {
      const updatedOptions = [...selectedAddOns];
      updatedOptions.splice(existingIndex, 1);
      setSelectedAddOns(updatedOptions);
    } else {
      setSelectedAddOns((prevSelected) => [
        ...prevSelected,
        { option: name, value: hasInput ? initialValue : null },
      ]);
    }
  };

  return { handleCheckboxSelect};
};

export default useCheckboxHandler;
