import React, { useState } from "react";
import PremiumButton from "../../Button/PremiumButton";

interface ButtonProps {
  keys: any;
}

const Button4: React.FC<ButtonProps> = ({ keys }) => {
  const [isDropdownVisible, setIsDropdownVisible] = useState<boolean>(false);

  return (
    <div className={`relative `}>
      <div>
        <div
          onMouseEnter={() => setIsDropdownVisible(true)}
          onMouseLeave={() => setIsDropdownVisible(false)}
        >
          <PremiumButton title={keys.title} label={keys.selectedLabel} />
        </div>
        <div>
          {isDropdownVisible && (
            <div className="absolute left-0 top-12 max-sm:top-10 w-[250px] max-sm:w-[230px] mt-2 z-50 ">
              <div className="bg-white dark:bg-black/70 rounded-lg p-4 shadow-lg border dark:border-gray-800 shadow-gray-700/20 dark:shadow-zinc-900/10">
                <div className="flex flex-col w-full">
                  <div className="text-blue-700 dark:text-blue-500 text-[15px] max-sm:text-[14px] font-medium">
                    {keys.heading}
                  </div>
                  <div className="text-gray-700 dark:text-gray-300 text-[12px] max-sm:text-[9px]">
                    {keys.describtion}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Button4;
