import React from "react";

interface ForwardButtonProps {
  shouldShow: boolean;
  onClick: () => void;
  ml: string;
}

const ForwardButton: React.FC<ForwardButtonProps> = ({  shouldShow, onClick, ml }) => {
  return (
    <div>
      { shouldShow && (
        <div  title="Next Step" onClick={onClick} className={`text-3xl text-gray-400 ${ml} dark:text-gray-400 cursor-pointer`}>
          &#62;
        </div>
      )}
    </div>
  );
};

export default ForwardButton;
