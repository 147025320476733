import React from 'react';

const FooterBottom: React.FC = () => {
  return (
    <div className="container pt-8">
      <div className="text-center text-sm text-gray-600 dark:text-gray-600"  style={{ fontSize: '0.8rem' }}>
        <hr className="my-4 dark:border-zinc-900 " />
        Copyright Policy4us.com / Certified : IRDAI License Number -
        IRDAI/INT/WBA/64/2022 Insurance is the business of solicitation
        <br />
        Disclaimer: The information that is available on this portal is of the
        insurance company with whom www.insurancepolicy4us.com has a legal
        contract.
        <br /> The prospect's details can be shared with the insurance companies
        Registered Office: Policy4us Insurance Web Aggregator Private Limited,
        01st Floor, Chembakam Building, Infopark, Nalukettu Road, Thrissur -
        680308 (Kerala)
      </div>
    </div>
  );
}

export default FooterBottom;
