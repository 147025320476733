import { readFromDB, writeToDB } from "../../../utils/indexDBUtils";

  interface HeaderData {
    [key: string]: any;
  }
  
  interface UpdatedValues {
    [key: string]: any;
  }

export const updateIndexedDB = async (
  headerUpdates: Partial<HeaderData> = {},
  sendingUpdates: Partial<UpdatedValues> = {},
  setHeaderData: React.Dispatch<React.SetStateAction<HeaderData>>,
  setSendingValues: React.Dispatch<React.SetStateAction<UpdatedValues>>
) => {
  const storedData = await readFromDB("PremiumData");
  if (storedData) {
    const [{ headerData, updatedValues }] = storedData;

    const updatedHeaderData = { ...headerData, ...headerUpdates };
    const updatedSendingValues = { ...updatedValues };

    for (const key in sendingUpdates) {
      if (Object.prototype.hasOwnProperty.call(updatedSendingValues, key)) {
        updatedSendingValues[key] = sendingUpdates[key];
      }
    }

    const updatedData = [
      {
        headerData: updatedHeaderData,
        updatedValues: updatedSendingValues,
      },
    ];

    await writeToDB("PremiumData", updatedData);

    setHeaderData(updatedHeaderData);
    setSendingValues(updatedSendingValues);
  } else {
    console.error("No data found in IndexedDB.");
  }
};
