import { ReactNode, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Image from "../Image";
import { carBikeSubPages, healthSubPages } from "../../data/proposalData";
import { carProposalPageData } from "../../data/Proposal/carProposal/carProposalData";
import { healthProposalPageData } from "../../data/Proposal/healthProposal/healthProposalData";
import { readFromDB } from "../../utils/indexDBUtils";
import { RootState } from "../../reducers/store/store";
import { fetchProposalMotorData } from "../../utils/fetchProposalMotorData";
import { avatarUrls } from "../../data/authors";
import LinkLayout from "../Link/LinkLayout";
import BackButton from "../MotorSelection/MotorSelectionHeading/BackButton";
// import Badge from "../Badge/Badge";
import { BadgeP } from "../Premium/HeaderComponent/HeaderComponent";

interface SubPage {
  pageName: string;
  emoji: string;
}

interface PageData {
  headerSection: {
    heading1: string;
    heading2: string;
    heading3: string;
    heading4: string;
    heading5: string;
  };
}
export interface VariantDetails {
  correctedVariant: string;
  verified: boolean;
  companyName:string;
}

const Layout = ({
  children,
  currentStep,
  setStep,
  highestStepReached,
}: {
  children: ReactNode;
  currentStep: number;
  setStep: (step: number) => void;
  highestStepReached: number;
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { selectedCompany, selectedModel, proposalData } = useSelector(
    (state: RootState) => state.motorDetails
  );

  // console.log("proposalData from premium",proposalData);
  let premiumResponse = proposalData.responseData;

  const [correctedVariant, setCorrectedVariant] = useState<string>("");
  const [verifiedDetails, setVerifiedDetails] = useState<VariantDetails | null>(
    null
  );
  const carbikedata = carBikeSubPages();
  const selectedInfoString = `${selectedCompany}, ${selectedModel} ${correctedVariant}` ;
  const imageUrl = avatarUrls.find(
    (avatar) => avatar.id === `${premiumResponse.CompanyName}`
  )?.url;
  const formState = useSelector((state: RootState) => state.form);
  const { whichTab } = useSelector(
    (state: RootState) => state.commonPremiumData
  );
  const tabRefs = useRef<(HTMLButtonElement | null)[]>([]);

  useEffect(() => {
    const fetchVariant = async () => {
      const variantData = await readFromDB("motor-variant-corrected");
      if (variantData && variantData.correctedVariant !== "") {
        setVerifiedDetails(variantData);
        setCorrectedVariant(variantData.correctedVariant);
      } else {
        setTimeout(fetchVariant, 1000); // Retry after 1 second
      }
    };
  
    fetchVariant();

  }, []);

  useEffect(() => {
    fetchProposalMotorData(dispatch, formState);
  }, [formState]);

  useEffect(() => {
    const tabElement = tabRefs.current[currentStep - 1];
    if (tabElement) {
      tabElement.scrollIntoView({
        behavior: "smooth",
        inline: "center",
      });
    }
  }, [currentStep]);

  let selectedSubPages: SubPage[] = [];
  let selectedPageData: PageData;

  // Determine which subpages and page data to use based on currentTab
  if (whichTab === "Car" || whichTab === "Bike") {
    selectedSubPages = carbikedata;
    selectedPageData = carProposalPageData;
  } else if (whichTab === "health") {
    selectedSubPages = healthSubPages;
    selectedPageData = healthProposalPageData;
  } else {
    console.log("Invalid proposal type");
    return null; // Return null or some default component if currentTab is invalid
  }

  const handleBackToQuote = () => {
    navigate(`/${whichTab.toLowerCase()}/quote`);
  };

  const handleBackClick = () => {
    if (currentStep > 1) {
      setStep(currentStep - 1);
    }
  };

  return (
    <div className="nc-PageDashboard">
      <div className="bg-white dark:bg-zinc-950 md:p-5 sm:p-0 lg:p-0 flex md:flex-row flex-col">
        <div className="w-16 h-16 md:w-24 md:h-24 flex-shrink-0 mt-0 sm:mt-0 mr-2">
          <div className="wil-avatar relative flex-shrink-0 inline-flex items-center justify-center overflow-hidden text-gray-100 uppercase font-semibold rounded-full w-16 h-16 text-xl lg:text-2xl md:w-24 md:h-24 ring-4 ring-white dark:ring-0 shadow-xl z-0">
            <Image alt="Avatar" src={imageUrl} fill className="object-cover" />
          </div>
        </div>

        <div className="pt-5 md:pt-0 lg:ml-14 xl:ml-12 flex-grow flex flex-col lg:flex-row justify-between">
          <div className="max-w-screen-sm space-y-1.5 flex-col">
          
            <div className="inline-flex items-center text-xl sm:text-2xl lg:text-2xl font-semibold">
              <span className="flex"> {selectedInfoString} </span>
               {/* <svg
                      width="25px"
                      height="25px" 
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.75 2.44995C11.44 1.85995 12.57 1.85995 13.27 2.44995L14.85 3.80995C15.15 4.06995 15.71 4.27995 16.11 4.27995H17.81C18.87 4.27995 19.74 5.14995 19.74 6.20995V7.90995C19.74 8.29995 19.95 8.86995 20.21 9.16995L21.57 10.7499C22.16 11.4399 22.16 12.5699 21.57 13.2699L20.21 14.8499C19.95 15.1499 19.74 15.7099 19.74 16.1099V17.8099C19.74 18.8699 18.87 19.7399 17.81 19.7399H16.11C15.72 19.7399 15.15 19.9499 14.85 20.2099L13.27 21.5699C12.58 22.1599 11.45 22.1599 10.75 21.5699L9.17 20.2099C8.87 19.9499 8.31 19.7399 7.91 19.7399H6.18C5.12 19.7399 4.25 18.8699 4.25 17.8099V16.0999C4.25 15.7099 4.04 15.1499 3.79 14.8499L2.44 13.2599C1.86 12.5699 1.86 11.4499 2.44 10.7599L3.79 9.16995C4.04 8.86995 4.25 8.30995 4.25 7.91995V6.19995C4.25 5.13995 5.12 4.26995 6.18 4.26995H7.91C8.3 4.26995 8.87 4.05995 9.17 3.79995L10.75 2.44995Z"
                        stroke="#4682B4"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        fill="#4682B4"
                      />
                      <path
                        d="M8.38 12L10.79 14.42L15.62 9.57996"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>               */}
            </div>
            {verifiedDetails !== null &&
                verifiedDetails.correctedVariant !== "" &&
                verifiedDetails.verified && (<div><BadgeP text="Verified" /></div>)}

            <span className="block text-sm text-gray-500 dark:text-gray-400">
              {selectedPageData.headerSection.heading1} : {premiumResponse.IDV}{" "}
              | {selectedPageData.headerSection.heading2} :{" "}
              {premiumResponse.NetPremium} |{" "}
              {selectedPageData.headerSection.heading3} :{" "}
              {premiumResponse.TotalTax && premiumResponse.TotalTax !== ""
                ? premiumResponse.TotalTax
                : (parseFloat(premiumResponse.CGST) || 0) +
                  (parseFloat(premiumResponse.SGST) || 0)}{" "}
              | {selectedPageData.headerSection.heading4} :{" "}
              {premiumResponse.Tenure}
            </span>

            <div className="flex items-center text-xs font-medium space-x-2.5  text-gray-500 dark:text-gray-400 truncate">
              <span className="text-gray-700 dark:text-gray-300 truncate">
                {selectedPageData.headerSection.heading5} :{" "}
                {premiumResponse.TotalPremium}
              </span>
            </div>
          </div>
          <LinkLayout link="Back to Quote" handleClick={handleBackToQuote} />
        </div>
      </div>

      <div className="flex flex-col space-y-8 xl:space-y-8 mt-7 ">
        <div className="flex-1 rounded-xl p-1 md:border md:border-zinc-100 dark:border-zinc-900 ">
          {/* SIDEBAR */}
          <div className="w-[100%] m-auto md:p-1 pb-0 selective-scrollbar scrollable-container overflow-x-hidden hover:overflow-x-auto transition-all duration-300 sticky top-0 bg-white dark:bg-zinc-950">
            <ul className="hidden sm:flex space-x-4 text-gray-700 dark:text-gray-400">
              {selectedSubPages.map(({ pageName }, index) => {
                const isClickable = index < highestStepReached;
                return (
                  <li key={index} className="flex-shrink-0">
                    <button
                      ref={(el) => (tabRefs.current[index] = el)}
                      className={`px-5 py-2 text-sm font-medium rounded-t-lg flex items-center justify-between border-b-2 ${
                        currentStep === index + 1
                          ? "bg-zinc-100 dark:bg-zinc-800 text-gray-900 dark:text-gray-100 border-blue-500"
                          : "hover:text-gray-800 hover:bg-zinc-100 dark:hover:bg-zinc-800 dark:hover:text-gray-100 border-transparent"
                      } ${isClickable ? "" : "cursor-not-allowed opacity-80"}`}
                      onClick={() => isClickable && setStep(index + 1)}
                      disabled={!isClickable}
                    >
                      <span>{pageName}</span>
                    </button>
                  </li>
                );
              })}
            </ul>

            {/* Mobile View */}
            <div className="flex sm:hidden items-center gap-2 justify-center h-[40px] py-2 px-4 bg-zinc-100 rounded-t-lg dark:bg-zinc-950 border-b-2 border-blue-500">
              <BackButton
                onClick={handleBackClick}
                isVisible={currentStep > 1}
              />
              <span className="text-sm font-medium text-gray-900 dark:text-gray-100 truncate max-w-[calc(100%-3rem)]">
                {selectedSubPages[currentStep - 1]?.pageName}
              </span>
            </div>
          </div>
          <div className="p-7 selective-scrollbar scrollable-container">
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Layout;
