export const healthProposalPageData = {
    headerSection: {
        heading1: "Sum Assured",
        heading2: "Net-Premium",
        heading3: "Total Tax",
        heading4: "Tenure",
        heading5: "Total Premium",
    },
    ProposerDetailsData: {
      title1: "Proposer Details",
      label1: "Salutation",
      Salutation: [
        "Mr",
        "Mrs",
        "Ms",
        "Dr",
        "Miss",
        "M/S",
      ],
      label2: "First Name",
      // label3: "Middle Name",
      label4: "Last Name",
      label5: "Mobile No:",
      label6: "Age",
      label7: "Date of Birth",
      label8: "Gender",
      Gender:[
        "Male",
        "Female",
      ],
      label9: "Email ID",
      label10: "Marital Status",
      MaritalStatus:[
        "Married",
        "Single",
        "Divorcee",
        "Widow",
        "Widower",
      ],
      label11: "Height",
      label12: "Weight",
      label13: "Occupation",
      Occupation: [
        "Advocate/Lawyer",
        "Armed Forces",
        "Business / Sales Profession",
        "Chartered Accountants",
        "Central / State Government Employee",
        "Corporate Executive",
        "Engineering Profession",
        "Financial Services Profession",
        "Heads of Government",
        "Home Maker / Housewife",
        "IT Profession",
        "Medical Profession",
        "Musician / Artist",
        "Not Working",
        "Retired",
        "Self Employed",
        "Sports Person",
        "Student",
        "Teaching Profession",
        "Political Party Official",
        "Politician",
        "Senior Government Official",
        "Senior Judicial Official",
        "Senior Military Official",
        "State-owned Corporation Official",
        "Others",
      ],
      label16: "Address 1",
      label17: "Address 2",
      label18: "Address 3",
      label19: "Address 4",
      button1: "Next",
    },
    HealthNominee: {
      title1: "Nominee Details",
      label1: "Nominee Name",
      label2: "Nominee Age",
      label3: "Date of Birth",
      label4: "Relationship",
      Relationship: [
        "Spouse",
        "Son",
        "Daughter",
        "Mother",
        "Father",
        "Brother",
        "Sister",
        "Self",
        "Other",
    ],
      button1: "Next",
    },
    MembersDetails: {
      title1: "Members Details",
      label1: "Salutation",
      Salutation: [
        "Mr",
        "Mrs",
        "Ms",
        "Dr",
        "Miss",
        "M/S",
      ],
      label2: "First Name",
      label3: "Middle Name",
      label4: "Last Name",
      label5: "Age",
      label6: "Marital Status",
      MaritalStatus:[
        "Married",
        "Single",
        "Divorcee",
        "Widow",
        "Widower",
      ],
      label7: "Date of Birth",
      label8: "Gender",
      Gender:[
        "Male",
        "Female"
      ],
      label9: "Height",
      label10: "Weight",
      label11: "Occupation",
      button1: "Next",
    },
    MedicalDetails: {
      title1: "Vehicle Details",
      label1: "Registration Number",
      label2: "Registration Date",
      label3: "Chassis Number",
      label4: "Engine Number",
      label5: "Registration Address line 1",
      label6: "Registration Address line 2",
      label7: "Registration Address line 3",
      label8: "Registration Address line 4",
      label9: "Pincode",
      button1: "Next",
    },
    LifeStyleDetails: {
      title1: "Previous Policy Insurer Details",
      label1: "Previous Policy Insurer",
      PreviousPolicyInsurers: [
          "Acko General Insurance Ltd",
          "Bajaj Allianz General Insurance Company Ltd",
          "Future Generali India Insurance Company Ltd",
          "Go Digit General Insurance Ltd",
          "HDFC ERGO General Insurance Company Ltd",
          "IFFCO TOKIO General Insurance Company Ltd",
          "National Insurance Company Limited",
          "Reliance General Insurance Company Ltd",
          "Royal Sundaram General Insurance Company Ltd",
          "Shriram General Insurance Company Ltd",
          "The Oriental Insurance Company Limited",
          "United India Insurance company Ltd",
          "Universal Sompo General Insurance Company Ltd",
        ],
      label2: "Is your car presently under a loan agreement?",
      label3: "Loan Provider",
      label4: "Loan Provider Address",
      button1: "Next",
    }
}