import React, { FC } from "react";
import { TaxonomyType } from "../../data/types";
import Link from "../Link";
import NcImage from "../NcImage/NcImage";
import {
  CardCategory2ImgDesc,
  CardCategory2ImgHead,
  CardCategory2ImgProp,
  CardCategory2Main,
  objectCover,
} from "../styleComponents/cardCategoryStyles/cardCategory2Style";
import { useDispatch } from "react-redux";
import {
  setIsOpenPopup,
  setWhichTab,
} from "../../reducers/slices/commonPremiumSlice";

export interface CardCategory6Props {
  className?: string;
  taxonomy: TaxonomyType;
}

const CardCategory6: FC<CardCategory6Props> = ({
  className = "",
  taxonomy,
}) => {
  const { name, href = "/", thumbnail } = taxonomy;
  const dispatch = useDispatch();
  const handleClick = async (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    if (href === "") {
      e.preventDefault();
      console.log("Setting whichTab to:", name); // Debugging log
      dispatch(setWhichTab(name));
      dispatch(setIsOpenPopup(true));
    }
  };
  return (
    <Link
      href={href}
      onClick={handleClick}
      className={`${CardCategory2Main} ${className}`}
    >
      
      <NcImage
        containerClassName={CardCategory2ImgProp}
        src={thumbnail || ""}
        fill
        sizes="80px"
        alt="categories"
        className={objectCover}
      />
      <div className="mt-3">
        <h2 className={CardCategory2ImgHead}>{name}</h2>
        <span className={CardCategory2ImgDesc}>
          Insurance
        </span>
      </div>
    </Link>
  );
};

export default CardCategory6;
