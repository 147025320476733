import React from 'react';
import AccessoriesInput from './AccessoriesInput';
import RangeInput from './rangeInputCover';
import DropdownInputCover from './dropdownInputCover';
import Tooltip from './Tooltip';

interface OptionItemProps {
  item: any;
  isSelected: boolean;
  onCheckboxSelect: (name: string) => void;
  onInputUpdate: (name: string, value: any) => void;
  tooltipVisibleIndex: number | null;
  handleInfoHover: ( index: number) => void;
  handleInfoLeave: () => void;
  index: number;
  selectedAddOns: { option: string; value: any }[];
  options: any[];
}

const OptionItem: React.FC<OptionItemProps> = ({
  item,
  isSelected,
  onCheckboxSelect,
  onInputUpdate,
  tooltipVisibleIndex,
  handleInfoHover,
  handleInfoLeave,
  index,
  selectedAddOns,
  options
}) => {
  return (
    <div className="flex flex-col py-4">
      <div className="flex items-center relative">
        <input
          type="checkbox"
          className="mr-3 w-5 h-5 max-sm:w-5 max-sm:h-5 cursor-pointer appearance-none rounded border dark:bg-neutral-300 border-gray-400 checked:bg-blue-600 dark:checked:bg-blue-700 checked:border-transparent focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-200"
          id={`checkbox-${index}`}
          value={item.name}
          checked={isSelected}
          onChange={() => onCheckboxSelect(item.name)}
        />
        <div className="flex gap-2 flex-wrap">
          <label
            htmlFor={`checkbox-${index}`}
            className="text-sm max-sm:text-[14px] text-neutral-950 dark:text-neutral-50 font-[400] cursor-pointer"
          >
            {item.name}
          </label>
          <div
            className="cursor-pointer flex justify-center items-center"
            onMouseEnter={() => handleInfoHover(index)}
            onMouseLeave={handleInfoLeave}
          >
            <p className="text-gray-500 hover:text-gray-800 dark:hover:text-gray-400 text-base">
              <svg
                width="16px"
                height="16px"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="2" />
                <path d="M12 7H12.01" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
                <path
                  d="M10 11H12V16"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M10 16H14"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </p>
            <Tooltip
              content={item.about}
              isVisible={tooltipVisibleIndex === index}
              position={(options.length === 1 ||
                index < options.length - 2) ? 'top' : 'bottom'}
            />
          </div>
          {item.badge && item.badge !== ' ' && (
            <div className="transition-colors nc-Badge w-20 h-5 flex items-center justify-center px-2.5 py-1 rounded-full font-medium text-xs relative text-green-900 dark:text-green-950 bg-green-200 dark:bg-green-300">
              {item.badge}
            </div>
          )}
        </div>
      </div>
      {isSelected && (
        <>
          {item.input && (
            <AccessoriesInput
              placeholder={item.placeholder}
              onChange={(value: number) => onInputUpdate(item.name, value)}
              selectedAddon={selectedAddOns}
              name={item.name}
            />
          )}
          {item.range && (
            <RangeInput
              placeholder={item.placeholder}
              onChange={(value: number | null) => onInputUpdate(item.name, value)}
              selectedAddon={selectedAddOns}
              name={item.name}
            />
          )}
          {item.dropdown && (
            <DropdownInputCover
              placeholder={item.placeholder}
              onChange={(value: number) => onInputUpdate(item.name, value)}
              selectedAddon={selectedAddOns}
              name={item.name}
            />
          )}
        </>
      )}
    </div>
  );
};

export default OptionItem;
