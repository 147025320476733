import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../reducers/store/store";
import { setMotorPremiumOn } from "../../../reducers/slices/MotorSlice";
import ForwardButton from "./ForwardButton";
import BackButton from "./BackButton";
import OptionHeading from "./OptionHeading";
import { handleOptionClick } from "./handleOptionClick";
import { handelGoBack } from "./handelGoBack";
import { handleGoForward } from "./handleGoForward";
import { handleGoForwardSec } from "./handleGoForwardSec";
import { getColor } from "./getColor";
import { shouldShowForwardButton } from "./shouldShowForwardButton";

const MotorSelectionHeadings = () => {
  const [selectedOption, setSelectedOption] = useState("");
  const dispatch = useDispatch();
  const {
    selectedCompany,
    selectedModel,
    selectedFuel,
    selectedSubModel,
    selectedCode,
    userSelectedType,
    motorPremiumOn,
    currentStep,
    selectedYear,
    selectedRegDate,
  } = useSelector((state: RootState) => state.motorDetails);

  const { whichTab } = useSelector((state: RootState) => state.commonPremiumData);

  const optionObject = whichTab.toLowerCase().includes("bike")
    ? ["Brand", "Model", "Fuel", "Variant & CC", "Registration"]
    : ["Brand", "Model", "Fuel", "Variant", "Registration"];

  useEffect(() => {
    if (currentStep !== null) {
      setSelectedOption(optionObject[currentStep]);
    } else {
      dispatch(setMotorPremiumOn(true));
    }
  }, [currentStep]);

  return (
    <div className="flex w-full">
      {motorPremiumOn ? (
        <div className="flex items-center justify-center w-full">
          <p className="text-gray-800 dark:text-gray-300 text-lg font-light max-sm:text-md -ml-2">
            {whichTab.toLowerCase().includes("car") ? "Car Insurance" : "Bike Insurance"}
          </p>
        </div>
      ) : (
        <>
          {currentStep === 5 ? (
            <div className="flex items-center justify-center w-full">
              {userSelectedType === "DontKnow" && (
                <p className="text-gray-600 dark:text-gray-200 font-[400] max-sm:text-md -ml-2">
                  Just one step to see quotes
                </p>
              )}
              {userSelectedType === "New" && (
                <p className="text-primary-6000 dark:text-primary-200 font-[400] max-sm:text-md -ml-2">
                  {whichTab.toLowerCase().includes("car") ? "Selected Car Details" : "Selected Bike Details"}
                </p>
              )}
            </div>
          ) : (
            <>
              <div className="hidden sm:flex w-full flex-row py-2 px-4 gap-1 items-center justify-center">
                {optionObject.map((option, index) => (
                  <div className="w-full flex" key={index}>
                    {index === 0 && currentStep === 0 && (
                      <div className="sm:ml-2 lg:ml-5"><BackButton onClick={() => handelGoBack(index, optionObject, dispatch, setSelectedOption)} isVisible /></div>
                    )}
                    <OptionHeading
                      index={index}
                      option={option}
                      onClick={() => handleOptionClick(index, selectedCompany, selectedModel, selectedFuel, selectedSubModel, dispatch, optionObject, setSelectedOption)}
                      color={getColor(index, selectedCompany, selectedModel, selectedFuel, selectedSubModel, selectedCode, selectedOption, optionObject)}
                      optionObject={optionObject}
                    />
                    {userSelectedType === 'New' && index === 4 && (
                      <div className="mr-2">
                        <ForwardButton
                          shouldShow={shouldShowForwardButton(index, currentStep, selectedCompany, selectedModel, selectedFuel, selectedSubModel, selectedCode, selectedRegDate, selectedYear, userSelectedType)}
                          onClick={() => handleGoForward(dispatch)}
                          ml="ml-3"
                        />
                      </div>
                    )}
                  </div>
                ))}
              </div>
              <div className={`sm:hidden m-auto`}>
                  {optionObject.map((option, index) => (
                    <div className="w-full flex items-center justify-center gap-2" key={index}>
                      <BackButton onClick={() => handelGoBack(index, optionObject, dispatch, setSelectedOption)} isVisible={currentStep === index} />
                      <div className="text-blue-600 dark:text-sky-400 text-lg">
                        {currentStep === index ? option : ""}
                      </div>
                      {(userSelectedType === 'New' || index !== 4)  && <ForwardButton
                        shouldShow={shouldShowForwardButton(index, currentStep, selectedCompany, selectedModel, selectedFuel, selectedSubModel, selectedCode, selectedYear,selectedRegDate, userSelectedType)}
                        onClick={() => handleGoForwardSec(index, dispatch)}
                        ml="ml-0.5"
                      />}
                    </div>
                  ))}
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default MotorSelectionHeadings;
