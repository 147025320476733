interface RadioOption {
  name: string;
  id: string;
  label: string;
  onChange: () => void;
  checked: boolean; // Add checked property
}

interface RadioOptionProps {
  onChangeHandlers: {
    setHasExpired: React.Dispatch<React.SetStateAction<boolean | null>>;
    setExpiredAfterDate: React.Dispatch<React.SetStateAction<boolean | null>>;
    setMadeClaim: React.Dispatch<React.SetStateAction<boolean | null>>;
    handleHasExpired: (value: boolean) => void;
    handleExpiredAfterDate: (value: boolean | null) => void;
    handleMadeClaim: (value: boolean | null) => void;
    setSelectedOption?: (option: string) => void;
  };
  // Add current selection state
  hasExpired: boolean | null;
  expiredAfterDate: boolean | null;
  madeClaim: boolean | null;
  selectedOption?: string | null; // For tracking selected radio button
}

export const hasExpiredOptions: (props: RadioOptionProps) => RadioOption[] = ({
  onChangeHandlers,
  hasExpired
}: RadioOptionProps) => [
  {
    name: "option",
    id: 'NotExpired',
    label: 'Not Expired',
    onChange: () => {
      onChangeHandlers.setHasExpired(false);
      onChangeHandlers.setExpiredAfterDate(null);
      onChangeHandlers.setMadeClaim(null);
      onChangeHandlers.handleHasExpired(false);
      onChangeHandlers.handleExpiredAfterDate(null);
      onChangeHandlers.handleMadeClaim(null);
    },
    checked: hasExpired === false // Check if this option is selected
  },
  {  
    name: "option",
    id: 'Expired',
    label: 'Expired',
    onChange: () => {
      onChangeHandlers.setHasExpired(true);
      onChangeHandlers.setExpiredAfterDate(null);
      onChangeHandlers.setMadeClaim(null);
      onChangeHandlers.handleHasExpired(true);
      onChangeHandlers.handleExpiredAfterDate(null);
      onChangeHandlers.handleMadeClaim(null);
    },
    checked: hasExpired === true // Check if this option is selected
  },
];

export const expiredAfterDateOptions: (props: RadioOptionProps) => RadioOption[] = ({
  onChangeHandlers,
  expiredAfterDate,
  selectedOption = null // Destructure selectedOption from props and provide default value
}: RadioOptionProps) => [
  {
    name: "option3",
    id: 'Yes',
    label: 'Yes',
    onChange: () => {
      onChangeHandlers.setExpiredAfterDate(true);
      onChangeHandlers.setMadeClaim(null);
      onChangeHandlers.handleExpiredAfterDate(true);
      onChangeHandlers.handleMadeClaim(null);
      onChangeHandlers.setSelectedOption?.('Yes'); // Optional chaining for setSelectedOption
    },
    // Check if expiredAfterDate is true and selectedOption is 'Yes'
    checked: expiredAfterDate === true && (selectedOption ?? null) === 'Yes'
  },
  {
    name: "option3",
    id: 'No',
    label: 'No',
    onChange: () => {
      onChangeHandlers.setExpiredAfterDate(false);
      onChangeHandlers.setMadeClaim(null);
      onChangeHandlers.handleExpiredAfterDate(false);
      onChangeHandlers.handleMadeClaim(null);
      onChangeHandlers.setSelectedOption?.('No'); // Optional chaining for setSelectedOption
    },
    // Check if expiredAfterDate is false and selectedOption is 'No'
    checked: expiredAfterDate === false && (selectedOption ?? null) === 'No'
  },
  {
    name: "option3",
    id: 'IDontKnow',
    label: "I don't know",
    onChange: () => {
      onChangeHandlers.setExpiredAfterDate(false); // Handling 'I don't know'
      onChangeHandlers.setMadeClaim(null);
      onChangeHandlers.handleExpiredAfterDate(false);
      onChangeHandlers.handleMadeClaim(null);
      onChangeHandlers.setSelectedOption?.('IDontKnow'); // Optional chaining for setSelectedOption
    },
    // Check if selectedOption is 'IDontKnow'
    checked: (selectedOption ?? null) === 'IDontKnow'
  }
];

export const madeClaimOptions: (props: RadioOptionProps) => RadioOption[] = ({
  onChangeHandlers,
  madeClaim
}: RadioOptionProps) => [
  { 
    name: "option2",
    id: 'Yess',
    label: 'Yes',
    onChange: () => {
      onChangeHandlers.setMadeClaim(true);
      onChangeHandlers.handleMadeClaim(true);
    },
    checked: madeClaim === true // Check if this option is selected
  },
  {
    name: "option2",
    id: 'Noo',
    label: 'No',
    onChange: () => {
      onChangeHandlers.setMadeClaim(false);
      onChangeHandlers.handleMadeClaim(false);
    },
    checked: madeClaim === false // Check if this option is selected
  },
];
