import { useEffect, useState } from "react";
import { RootState } from "../../../reducers/store/store";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import FirstStepWithFunction from "../FirstStep/FirstStepWithFunction";
import { fetchMotorIndexData } from "../../../utils/fetchMotorIndexData";
import { fetchInitialMotorData } from "../../../utils/fetchInitialMotorData";
import BrandSelection from "./BrandSelection";
import ModelSelection from "./ModelSelection";
import FuelSelection from "./FuelSelection";
import VariantSelection from "./VariantSelection";
import RegistrationSelection from "./RegistrationSelection";
import LastStepofSelection from "./LastStepofSelection";

const MotorSelectionProcess = () => {
  const dispatch = useDispatch();
  const [brands, setBrands] = useState<any[]>([]);
  const [minimumMotorBrands, setMinimumMotorBrands] = useState<any[]>([]);
  const [motorModels, setMotorModels] = useState<any[]>([]);
  const [minimumModels, setMinimumModels] = useState<any[]>([]);
  const [fuels, setFuels] = useState<any[]>([]);
  const [varients, setVarients] = useState<any[]>([]);
  const [years, setYears] = useState<string>("");
  const [rto, setRto] = useState<any[]>([]);
  const {
    selectedCompany,
    selectedModel,
    selectedFuel,
    selectedSubModel,
    sendingRto,
    userSelectedType,
    companyCode,
    modelCode,
    fuelCode,
    subModelCode,
    motorPremiumOn,
    currentStep,
  } = useSelector((state: RootState) => state.motorDetails);
  const { whichTab } = useSelector(
    (state: RootState) => state.commonPremiumData
  );

  const isCar = whichTab.toLowerCase().includes("car");
  const collectionName = isCar ? "p4us_cars" : "p4us_bikes";

  useEffect(() => {
    if (whichTab.toLowerCase().includes("bike") || whichTab.toLowerCase().includes("car")) {
      fetchMotorIndexData(dispatch);
    }
  }, [whichTab, dispatch]);

  useEffect(() => {
    fetchInitialMotorData(
      whichTab,
      setBrands,
      setMinimumMotorBrands,
      setMotorModels,
      setMinimumModels,
      setFuels,
      setVarients,
      setRto,
      setYears,
    );
  }, [whichTab]);

  return (
    <div>
      {motorPremiumOn ? (
        <div>
          <FirstStepWithFunction />
        </div>
      ) : (
        <>
          {currentStep === 5 &&
          selectedCompany !== "" &&
          companyCode !== "" &&
          selectedModel !== "" &&
          modelCode !== "" &&
          selectedFuel !== "" &&
          fuelCode !== "" &&
          subModelCode !== "" &&
          selectedSubModel !== "" &&
          userSelectedType !== null &&
          sendingRto !== "" ? (
            <LastStepofSelection />
          ) : (
            <>
              {currentStep === 0 && (
                <BrandSelection
                  collectionName={collectionName}
                  brands={brands}
                  minimumMotorBrands={minimumMotorBrands}
                  setMinimumModels={setMinimumModels}
                  setMotorModels={setMotorModels}
                />
              )}

              {currentStep === 1 &&
                selectedCompany !== "" &&
                companyCode !== "" && (
                  <ModelSelection
                    collectionName={collectionName}
                    motorModels={motorModels}
                    minimumModels={minimumModels}
                    setFuels={setFuels}
                  />
                )}

              {currentStep === 2 &&
                selectedCompany !== "" &&
                companyCode !== "" &&
                selectedModel !== "" &&
                modelCode !== "" && (
                  <FuelSelection
                    collectionName={collectionName}
                    fuels={fuels}
                    setVarients={setVarients}
                  />
                )}

              {currentStep === 3 &&
                selectedCompany !== "" &&
                companyCode !== "" &&
                selectedModel !== "" &&
                modelCode !== "" &&
                selectedFuel !== "" &&
                fuelCode !== "" && (
                  <VariantSelection
                    collectionName={collectionName}
                    rto={rto}
                    setRto={setRto}
                    varients={varients}
                  />
                )}

              {currentStep === 4 &&
                selectedCompany !== "" &&
                companyCode !== "" &&
                selectedModel !== "" &&
                modelCode !== "" &&
                selectedFuel !== "" &&
                fuelCode !== "" &&
                subModelCode !== "" &&
                selectedSubModel !== "" && (
                  <RegistrationSelection rto={rto} years={years}  />
                )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default MotorSelectionProcess;