import { writeToDB } from "../../../../utils/indexDBUtils";
import { SelectedOption, Option } from "./Editicon1"; 

export const handleSubmit = async (
  userSelectedType: string,
  keys: any,
  prevTP: boolean,
  prevCompreh: boolean,
  newTP: boolean,
  button1keys: any,
  button2Keys: any,
  button3Keys: any,
  date2: string,
  date3: string,
  date4: string,
  dropdown1: string,
  dropdown2: string,
  radioOption1: string,
  newValue: string,
  updateCombinedData: (headerUpdates: object, sendingUpdates?: object) => void,
  setIsOpen: (isOpen: boolean) => void
) => {
  const sendingPolicyType =
    userSelectedType === "New"
      ? "Bundled"
      : prevTP
      ? "Thirdparty"
      : "Comprehensive";

  const updates : Record<string, any>= {
    editIcon1: {
      ...keys,
      label2Value:
        userSelectedType === "DontKnow" && keys.label2 !== ""
          ? dropdown1
          : keys.label2Value,
      label3Value:
        userSelectedType === "DontKnow" && keys.label3 !== ""
          ? date2
          : keys.label3Value,
      label4Value:
        userSelectedType === "DontKnow" && keys.label4 !== ""
          ? date3
          : keys.label4Value,
      label5Value:
        userSelectedType === "DontKnow" && keys.label5 !== ""
          ? radioOption1 === "Yes"
            ? true
            : false
          : keys.label5Value,

      label8Value:
        userSelectedType === "New" && keys.label8 !== ""
          ? date4
          : keys.label8Value,
    },
    labelValue4:
      userSelectedType === "DontKnow" && keys.label3 !== "" ? date2 : "",
    labelValue5:
      userSelectedType === "DontKnow" && keys.label5 !== ""
        ? radioOption1 === "Yes"
          ? true
          : false
        : false,
    label6:
      userSelectedType === "DontKnow" && keys.label6 !== ""
        ? radioOption1 === "Yes"
          ? ""
          : "Prev Ncb"
        : "",
    label7:
      userSelectedType === "DontKnow" && keys.label6 !== ""
        ? radioOption1 === "Yes"
          ? ""
          : "New Ncb"
        : "",
    labelValue6:
      userSelectedType === "DontKnow" && keys.label6 !== ""
        ? radioOption1 === "Yes"
          ? ""
          : dropdown2
        : "",
    labelValue7:
      userSelectedType === "DontKnow" && keys.label6 !== ""
        ? radioOption1 === "Yes"
          ? ""
          : newValue
        : "",
    labelValue9:
      userSelectedType === "New" && keys.label9 !== "" ? date4 : "",
  };

if (prevTP) {
  updates.button1 = {
    ...button1keys,
    selectedLabel: "Third Party",
  };
}

  const selectedOptions: SelectedOption[] = button3Keys.selectedOptions;
  const options: Option[] = button3Keys.options;

  const mappedOptions: (Option & { value: any })[] = selectedOptions.map(
    (selectedOption) => {
      const correspondingOption = options.find(
        (option) => option.name === selectedOption.option
      ) as Option;
      return {
        ...correspondingOption,
        value: selectedOption.value,
      };
    }
  );

  const filteredOptions = mappedOptions.filter(
    (option) => option.name !== "Personal Accident Cover"
  );

  const savingValues: Record<string, any> = {
    prevPolicyType:
      userSelectedType === "DontKnow" && keys.label2 !== ""
        ? dropdown1 === "Third Party"
          ? "Thirdparty"
          : dropdown1 === "Comprehensive"
          ? "Comprehensive"
          : dropdown1 === "Bundled"
          ? "Bundled"
          : "Owndamage"
        : keys.label2Value,
    prevPolicyEndDate:
      userSelectedType === "DontKnow" && keys.label3 !== ""
        ? date2
        : keys.label3Value,
    tpExpiryDate:
userSelectedType === "DontKnow" && keys.label4 !== ""
        ? date3
        : keys.label4Value,
    prevPolicyClaim:
      userSelectedType === "DontKnow" && keys.label5 !== ""
        ? radioOption1
        : keys.label5Value,
    previousNcb:
      prevTP || prevCompreh
        ? "0%"
        : userSelectedType === "DontKnow" && keys.label6 !== ""
        ? radioOption1 === "Yes"
          ? ""
          : dropdown2
        : "",
    policyStartDate:
      userSelectedType === "New" && keys.label8 !== ""
        ? date4
        : keys.label8Value,
    currentNcb:
      prevTP || prevCompreh
        ? "0%"
        : userSelectedType === "DontKnow" && keys.label6 !== ""
        ? radioOption1 === "Yes"
          ? ""
          : newValue
        : "",
    idv: prevTP ? "" : String(button2Keys.selectedRange),
  };
  
  // Conditionally add policyType only if prevTP is true
  if (prevTP) {
    savingValues.policyType = sendingPolicyType; // Send 'Thirdparty' value
  }
  
  // Add the remaining options logic as before
  filteredOptions.forEach((option) => {
    if (prevTP) {
      savingValues[option.backendName] = false;
      savingValues[option.valueName] = "0";
    } else {
      savingValues[option.backendName] = true;
      if (option.value !== null && option.valueName) {
        savingValues[option.valueName] = String(option.value);
      }
    }
  });
  // filteredOptions.forEach((option) => {
  //   if (prevTP) {
  //     savingValues[option.backendName] = false;
  //     savingValues[option.valueName] = "";
  //   } else {
  //     savingValues[option.backendName] = true;
  //     if (option.value !== null && option.valueName) {
  //       savingValues[option.valueName] = String(option.value);
  //     }
  //   }
  // });

  const specialdetail = {
    newTP: newTP,
    prevCompreh: prevCompreh,
    prevTP: prevTP,
  };

  await writeToDB("motorTPdetails", specialdetail);
  updateCombinedData(updates, savingValues); // Pass only the updates object
  setIsOpen(false);
};