import React from 'react';

interface LinkLabelProps {
  text: string;
  onClick: () => void;
}

const LinkLabel: React.FC<LinkLabelProps> = ({ text, onClick }) => (
  <div className="flex items-center mb-1" onClick={onClick}>
    <div className="text-xl sm:text-2xl text-primary-500 cursor-pointer">&lt;</div>
    <p className="ml-2 text-sm sm:text-[14px] text-gray-400 font-[500] cursor-pointer hover:text-gray-700 dark:hover:text-gray-100">
      {text}
    </p>
  </div>
);

export default LinkLabel;
