import React from "react";
import Premium from "../components/Premium/Premium";
// import { isTokenExpired, writeToDB } from "../utils/indexDBUtils";

const PremiumPage: React.FC = () => {

  return (
    <div>
      <Premium />
    </div>
  );
};

export default PremiumPage;
