import { FC } from "react";
import MainNav2Logged from "./MainNav2Logged";
import { headerLoggedStyle } from "../styleComponents/headerStyles/headerLoggedStyle";

export interface HeaderLoggedProps {}

const HeaderLogged: FC<HeaderLoggedProps> = () => {
  return (
    <div className={headerLoggedStyle}>
      <MainNav2Logged />
    </div>
  );
};

export default HeaderLogged;
