import React from "react";

interface DateInputProps {
  value: string;
  min: string;
  max: string;
  onChange: (value: string) => void;
  readonly? :boolean;
}

const DateInput: React.FC<DateInputProps> = ({ value, min, max, onChange , readonly }) => {
  return (
    <input
      type="date"
      value={value}
      min={min}
      max={max}
      {...(readonly && { readOnly: true })}
      onChange={(e) => {
        const selectedDate = new Date(e.target.value);
        const minDate = new Date(min);
        const maxDate = new Date(max);

        // Prevent entering invalid dates
        if (selectedDate < minDate) {
            onChange(min);
        } else if (selectedDate > maxDate) {
            onChange(max);
        } else {
            onChange(e.target.value);
        }
      }}
      className=" w-3/4 sm:w-full  text-[14px] text-gray-700 placeholder-gray-400 dark:text-white dark:placeholder-gray-100 border border-gray-400 rounded-md dark:bg-zinc-900  dark:border-zinc-800  focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200 transition duration-200 bg-gray-50"
    />
  );
};

export default DateInput;
