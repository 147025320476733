import React, { useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../reducers/store/store";
import OptionItem from "./OptionItem";
import useCheckboxHandler from "./useCheckboxHandler";
import useInputUpdateHandler from "./useInputUpdateHandler";
import PopLayout from "../../../Popup/PopLayout";
import PremiumButton from "../../../Button/PremiumButton";
import ButtonPrimary from "../../../Button/ButtonPrimary";

interface ButtonProps {
  keys: any;
  updateCombinedData: (headerUpdates: object, sendingUpdates?: object) => void;
  whichTab: string;
  loadingstate:boolean;
}

const Button3: React.FC<ButtonProps> = ({
  keys,
  updateCombinedData,
  whichTab,
  loadingstate
}) => {
  const { prevTP, newTP } = useSelector(
    (state: RootState) => state.motorDetails
  );
  const [selectedAddOns, setSelectedAddOns] = useState<
    { option: string; value: any }[]
  >([]);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [tooltipVisibleIndex, setTooltipVisibleIndex] = useState<number | null>(
    null
  );

  const optionlength =
    newTP || prevTP
      ? keys.selectedOptions.some(
          (option: any) => option.option === "Personal Accident Cover"
        ) && 1
      : keys.selectedOptions.length > 0 && keys.selectedOptions.length;

  const mandatoryOption = {
    name: "Personal Accident Cover",
    about:
      "Personal Accident cover covers the owner in case of death or disability due to an accident. It is mandatory as per Govt to have a PA cover. Add the PAcover in case you don't have it.",
    input: false,
    range: false,
    placeholder: "",
    dropdown: false,
    badge: "Mandatory",
    backendName: "paCover",
    valueName: "",
  };

  const options =
    (whichTab.toLowerCase().includes("car") ||
      whichTab.toLowerCase().includes("bike")) &&
    (prevTP || newTP)
      ? [mandatoryOption]
      : keys.options;

  const { handleCheckboxSelect } =
    useCheckboxHandler(selectedAddOns, options, setSelectedAddOns);
  const { handleInputUpdate } = useInputUpdateHandler(selectedAddOns, setSelectedAddOns);

  const handleSubmit = () => {
    const sendingData: { [key: string]: any } = {};
  
    // Initialize all options to false
    options.forEach((option: any) => {
      sendingData[option.backendName] = false; // Set all options to false by default
      if (option.valueName) {
        sendingData[option.valueName] = "0"; // If there is a value field, set it to null initially
      }
    });
  
    // Iterate through selected add-ons and set the corresponding values to true
    selectedAddOns.forEach((addOn) => {
      const selectedOption = options.find(
        (option: any) => option.name === addOn.option
      );
  
      if (selectedOption) {
        sendingData[selectedOption.backendName] = true;
      }
  
      if (addOn.value !== null && selectedOption && selectedOption.valueName) {
        sendingData[selectedOption.valueName] = String(addOn.value);
      }
    });
  
    const updates = {
      button3: {
        ...keys,
        selectedOptions: selectedAddOns,
      },
    };
  
    updateCombinedData(updates, sendingData);
    setIsOpen(false);
  };
  
  const handleInfoHover = (index: number) => {
    setTooltipVisibleIndex(index);
  };

  const handleInfoLeave = () => {
    setTooltipVisibleIndex(null);
  };
  return (
    <div className={`relative ${isOpen ? "z-50" : ""}`}>
      <div>
        <PremiumButton
          title={keys.title}
          label={keys.label}
          onClick={() => {
            setSelectedAddOns(keys.selectedOptions);
            setIsOpen(!isOpen);
          }}
          optionLength={optionlength}
          disable={loadingstate}
        />
      </div>
      <div>
        {isOpen && (
          <PopLayout
            isOpen={isOpen}
            onClose={() => setIsOpen(false)}
            title={keys.heading}
            maxWidth="max-w-[395px]"
          >
            <div className="pb-2 pt-1 px-4 max-sm:px-0 max-sm:pl-2 max-sm:pr-1">
              <div
                className={`pb-2 pt-3 px-4 max-sm:px-0 max-sm:pl-2 max-sm:pr-1 ${
                  options.length === 1
                    ? "h-[150px]"
                    : "max-h-80 overflow-y-auto custom-scrollbar"
                } w-full`}
              >
                {options.map((item: any, index: number) => (
                  <OptionItem
                    key={index}
                    item={item}
                    isSelected={selectedAddOns.some(
                      (option) => option.option === item.name
                    )}
                    onCheckboxSelect={handleCheckboxSelect}
                    onInputUpdate={handleInputUpdate}
                    tooltipVisibleIndex={tooltipVisibleIndex}
                    handleInfoHover={handleInfoHover}
                    handleInfoLeave={handleInfoLeave}
                    index={index}
                    selectedAddOns={selectedAddOns}
                    options={options} // Pass the options prop
                  />
                ))}
              </div>
              <div className="flex mt-4 mb-2 justify-center">
                <ButtonPrimary
                  onClick={handleSubmit}
                  className="w-1/2 mx-auto mt-2"
                >
                  Update
                </ButtonPrimary>
              </div>
            </div>
          </PopLayout>
        )}
      </div>
    </div>
  );
};

export default Button3;
