import { Dispatch } from "redux";
import { setCurrentStep } from "../../../reducers/slices/MotorSlice";

export const handleOptionClick = (
  index: number,
  selectedCompany: string,
  selectedModel: string,
  selectedFuel: string,
  selectedSubModel: string,
  dispatch: Dispatch,
  optionObject: string[],
  setSelectedOption: (option: string) => void
) => {
  if (
    index === 0 ||
    (index === 1 && selectedCompany !== "") ||
    (index === 2 && selectedModel !== "") ||
    (index === 3 && selectedFuel !== "") ||
    (index === 4 && selectedSubModel !== "")
  ) {
    dispatch(setCurrentStep(index));
    setSelectedOption(optionObject[index]);
  } else {
    handleOptionClick(index - 1, selectedCompany, selectedModel, selectedFuel, selectedSubModel, dispatch, optionObject, setSelectedOption);
  }
};
