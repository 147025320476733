import { FC } from "react";
import { backgroundSectionDiv, backgroundSectionMain, backgroundSectionSpan } from "../styleComponents/backgroundSectionStyle";

export interface BackgroundSectionProps {
  className?: string;
}

const BackgroundSection: FC<BackgroundSectionProps> = ({
  className = `${backgroundSectionMain}`,
}) => {
  return (
    <div
      className={`${backgroundSectionDiv} ${className} `}
    >
      <span className={backgroundSectionSpan}>bg</span>
    </div>
  );
};

export default BackgroundSection;
