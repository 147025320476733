import React from "react";
import { useDispatch, useSelector } from "react-redux";
import CommonOptionsGrid from "../../CommonDesignForSelection/CommonOptionsGrid";
import { RootState } from "../../../reducers/store/store";
import {
  setCurrentStep,
  setSelectedModel,
  setModelCode,
  setSelectedFuel,
  setFuelCode,
  setSelectedSubModel,
  setSubModelCode,
  setSelectedCode,
  setRtoCode,
  setSelectedYear,
  setVechicalCode,
  setSelectedCC,
  setCcCode,
  setSendingRto,
  setViewAllModels,
  setSelectedRegDate,
} from "../../../reducers/slices/MotorSlice";
import { fetchMotorFuels } from "../../../data/Premium/MotorData/api";

interface ModelSelectionProps {
  collectionName: string;
  motorModels: any[];
  minimumModels: any[];
  setFuels: React.Dispatch<React.SetStateAction<any[]>>;
}

const ModelSelection: React.FC<ModelSelectionProps> = ({
  collectionName,
  motorModels,
  minimumModels,
  setFuels,
}) => {
  const dispatch = useDispatch();
  const { companyCode, modelCode, viewAllModels, selectedCompany } =
    useSelector((state: RootState) => state.motorDetails);
  const { whichTab } = useSelector(
    (state: RootState) => state.commonPremiumData
  );

  const handleMotorModelClick = async (code: string, name: string) => {
    try {
      if (code !== modelCode) {
        dispatch(setSelectedModel(name));
        dispatch(setModelCode(code));
        dispatch(setSelectedFuel(""));
        dispatch(setFuelCode(""));
        dispatch(setSelectedSubModel(""));
        dispatch(setSubModelCode(""));
        dispatch(setSelectedCC(""));
        dispatch(setCcCode(""));
        dispatch(setVechicalCode(""));
        dispatch(setSelectedCode(""));
        dispatch(setRtoCode(""));
        dispatch(setSendingRto(""));
        dispatch(setSelectedYear(""));
        dispatch(setSelectedRegDate(""));
        dispatch(setCurrentStep(2));
        setFuels([]);
        const data = await fetchMotorFuels(collectionName, companyCode, code);
        setFuels(data);
      } else {
        dispatch(setSelectedModel(name));
        dispatch(setModelCode(code));
        dispatch(setCurrentStep(2));
        setFuels([]);
        const data = await fetchMotorFuels(collectionName, companyCode, code);
        setFuels(data);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleviewModels = () => {
    dispatch(setViewAllModels(true));
  };

  const handleCloseViewModels = () => {
    dispatch(setViewAllModels(false));
  };

  return (
    <>
      {!viewAllModels ? (
        <CommonOptionsGrid
          heading={`Which ${selectedCompany} ${
            whichTab.toLowerCase().includes("car") ? "car?" : "bike?"
          }`}
          subheading="TOP CARS"
          gridItems={minimumModels}
          buttonText={`View All ${whichTab.toLowerCase().includes("car") ? "Cars" : "Bikes"}`}
          handleClick={handleMotorModelClick}
          selectedOption={modelCode}
          handleButtonClick={handleviewModels}
        />
      ) : (
        <CommonOptionsGrid
          heading={`Which ${selectedCompany} ${
            whichTab.toLowerCase().includes("car") ? "car?" : "bike?"
          }`}
          searchPlaceholder="Search..."
          linkText="Show less"
          gridItems={motorModels}
          handleClick={handleMotorModelClick}
          handleLinkClick={handleCloseViewModels}
          selectedOption={modelCode}
        />
      )}
    </>
  );
};

export default ModelSelection;
