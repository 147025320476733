import React, { useEffect, useState } from "react";
// import Heading from "../Heading/Heading";
import ButtonPrimary from "../Button/ButtonPrimary";
import { carProposalPageData } from "../../data/Proposal/carProposal/carProposalData";
import Label from "../Label/Label";
import Input from "../Input/Input";
import { useDispatch } from "react-redux";
import { updateCommunicationAddress } from "../../reducers/slices/carBikeProposalSlice";
import { readFromDB, writeToDB } from "../../utils/indexDBUtils";
import { validateAddress } from "../../utils/formValidations";
import { markStepCompleted } from "./OwnerDetails";
import { verifyKycToIndexdb } from "./KYC";

interface CommunicationAddressProps {
  onSubmit: () => void; // Function to handle form submission and navigation
}

const CommunicationAddress: React.FC<CommunicationAddressProps> = ({
  onSubmit,
}) => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    address1: "",
    address2: "",
    address3: "",
    address4: "",
  });
  const [formErrors, setFormErrors] = useState<{ [key: string]: string }>({});
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    // Validate all required fields
    const requiredFields = ["address1", "address2", "address3", "address4"];
    
    const newFormErrors: { [key: string]: string } = {};

    requiredFields.forEach((field) => {
      const error = validateAddress(formData[field as keyof typeof formData]);
      if (error) newFormErrors[field] = error;
    });

    // Update formErrors state
    setFormErrors(newFormErrors);

    // Check if there are any validation errors
    if (Object.keys(newFormErrors).length > 0) {
      console.log("Form validation errors:", newFormErrors);
      return;
    }

    setIsLoading(true);
    try {
      // Update Redux store and IndexedDB
      dispatch(updateCommunicationAddress(formData));
      await writeToDB("motor-proposal-CommunicationAddress", formData);

      // Read existing FormState data
      const existingFormState = await readFromDB("motor-proposal-FormState");

      // Merge communicationAddress with existing FormState data
      const updatedFormState = {
        ...existingFormState,
        communicationAddress: formData,
      };

      // Write updated FormState data back to IndexedDB
      await writeToDB("motor-proposal-FormState", updatedFormState);
      markStepCompleted(3);
      // Call parent component's onSubmit function to navigate to next step
      onSubmit();
    } catch (error) {
      console.error("Error saving communication address:", error);
      alert("An error occurred while saving your details. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await readFromDB("motor-proposal-CommunicationAddress");
        if (data) {
          setFormData(data);
        }
      } catch (error) {
        console.error("Error fetching communication address:", error);
      }
    };
    window.scrollTo(0, 0);
    fetchData();
  }, []);

  const handleChange = async(
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    await verifyKycToIndexdb(false);
  };

  return (
    <div>
      {/* <span className={`lg:hidden md:block  `}>
        <Heading desc="">
          {carProposalPageData.CommunicationAddress.title1}
        </Heading>
      </span> */}
      <form
        className="grid md:grid-cols-2 gap-6"
        onSubmit={handleSubmit}
        method="post"
      >
        <label className="block md:col-span-1">
          <Label>{carProposalPageData.CommunicationAddress.label1}</Label>
          <Input
            type="text"
            className="mt-1"
            onChange={handleChange}
            name="address1"
            value={formData.address1}
            required
          />
          {formErrors.address1 && (
            <p className="text-red-600 text-xs mt-1">
              {formErrors.address1}
            </p>
          )}
        </label>
        <label className="block md:col-span-1">
          <Label>{carProposalPageData.CommunicationAddress.label2}</Label>
          <Input
            type="text"
            className="mt-1"
            onChange={handleChange}
            name="address2"
            value={formData.address2}
            required
          />
          {formErrors.address2 && (
            <p className="text-red-600 text-xs mt-1">
              {formErrors.address2}
            </p>
          )}
        </label>
        <label className="block md:col-span-1">
          <Label>{carProposalPageData.CommunicationAddress.label3}</Label>
          <Input
            type="text"
            className="mt-1"
            onChange={handleChange}
            name="address3"
            value={formData.address3}
            required
          />
          {formErrors.address3 && (
            <p className="text-red-600 text-xs mt-1">
              {formErrors.address3}
            </p>
          )}
        </label>
        <label className="block md:col-span-1">
          <Label>{carProposalPageData.CommunicationAddress.label4}</Label>
          <Input
            type="text"
            className="mt-1"
            onChange={handleChange}
            name="address4"
            value={formData.address4}
            required
          />
          {formErrors.address4 && (
            <p className="text-red-600 text-xs mt-1">
              {formErrors.address4}
            </p>
          )}
        </label>
        <ButtonPrimary type="submit" className="md:col-span-2" disabled={isLoading}>
          {isLoading ? "Saving..." : carProposalPageData.CommunicationAddress.button1}
        </ButtonPrimary>
      </form>
    </div>
  );
};

export default CommunicationAddress;
