import React from 'react';
import { pLogo } from '../styleComponents/logoStyles/pLogoImageStyle';

interface PLogoImageProps {
  src?: string;
}

const PLogoImage: React.FC<PLogoImageProps> = ({ src }) => {
  return (
    <div className={pLogo}>
      <img src={src} alt="logo" />
    </div>
  );
};

export default PLogoImage;
