import { createSlice, PayloadAction } from "@reduxjs/toolkit";
// import { writeToDB } from "../../utils/indexDBUtils";

interface OwnerDetailsData {
  vehicleRegistered: boolean;
  salutation: string;
  firstName: string;
  lastName: string;
  gender: string;
  mobileNo: string;
  dateOfBirth: string;
  maritalStatus: string;
  occupation: string;
  emailId: string;
}
interface NomineeDetailsData {
  nomineeName: string;
  nomineeAge: string;
  nomineeDob: string;
  nomineeRelationship: string;
}
interface CommunicationAddressData {
  address1: string;
  address2: string;
  address3: string;
  address4: string;
}
interface VehicleDetailsData {
  registrationNo: string;
  registrationDate: string;
  chassisNo: string;
  engineNo: string;
  regAddress1: string;
  regAddress2: string;
  regAddress3: string;
  regAddress4: string;
  regPincode: string;
}
interface PreviousInsurerData {
  prevInsurer: string;
  prevPolicyNo: string;
  prevInsurerAddress: string;
  tpInsurer: string;
  tpPolicyNumber: string;
  isItUnderLoan: boolean | string;
  loanProvider: string;
  loanProviderAddress: string;
  
}
interface kycDetailsData {
  panNumber: string;
  aadharNumber: string;
  document: string;
  documentUpload: string ;
  pincode: string;
  city: string;
  state: string;
  accessToken?: string;
  pullId?: string;
}
export interface FormState {
  ownerDetails: OwnerDetailsData;
  nomineeDetails: NomineeDetailsData;
  communicationAddress: CommunicationAddressData;
  vehicleDetails: VehicleDetailsData;
  previousInsurer: PreviousInsurerData;
  kycDetails: kycDetailsData;
}

const initialState: FormState = {
  ownerDetails: {
    vehicleRegistered: false,
    salutation: "",
    firstName: "",
    lastName: "",
    gender: "",
    mobileNo: "",
    dateOfBirth: "",
    maritalStatus: "",
    occupation: "",
    emailId: "",
  },
  nomineeDetails: {
    nomineeName: "",
    nomineeAge: "",
    nomineeDob: "",
    nomineeRelationship: "",
  },
  communicationAddress: {
    address1: "",
    address2: "",
    address3: "",
    address4: "",
  },
  vehicleDetails: {
    registrationNo: "",
    registrationDate: "",
    chassisNo: "",
    engineNo: "",
    regAddress1: "",
    regAddress2: "",
    regAddress3: "",
    regAddress4: "",
    regPincode: "",
  },
  previousInsurer: {
    prevInsurer: "",
    prevPolicyNo: "",
    prevInsurerAddress: "",
    tpInsurer: "",
    tpPolicyNumber: "",
    isItUnderLoan: false,
    loanProvider: "",
    loanProviderAddress: "",
  },
  kycDetails: {
    panNumber: "",
    aadharNumber: "",
    document: "",
    documentUpload: "",
    pincode: "",
    city: "",
    state: "",
    pullId: "",
    accessToken: "",
  },
};

interface UpdateFormStatePayload {
  ownerDetails: OwnerDetailsData;
  nomineeDetails: NomineeDetailsData;
  communicationAddress: CommunicationAddressData;
  vehicleDetails: VehicleDetailsData;
  previousInsurer: PreviousInsurerData;
  kycDetails: kycDetailsData;
}

const formSlice = createSlice({
  name: "form",
  initialState,
  reducers: {
    // Action to update owner details
    updateOwnerDetails: (state, action: PayloadAction<OwnerDetailsData>) => {
      state.ownerDetails = action.payload;
    },
    updateNomineeDetails: (
      state,
      action: PayloadAction<NomineeDetailsData>
    ) => {
      state.nomineeDetails = action.payload;
    },
    updateCommunicationAddress: (
      state,
      action: PayloadAction<CommunicationAddressData>
    ) => {
      state.communicationAddress = action.payload;
    },
    updateVehicleDetails: (
      state,
      action: PayloadAction<VehicleDetailsData>
    ) => {
      state.vehicleDetails = action.payload;
    },
    updatePreviousInsurer: (
      state,
      action: PayloadAction<PreviousInsurerData>
    ) => {
      state.previousInsurer = action.payload;
    },
    updateKycDetails: (
      state,
      action: PayloadAction<kycDetailsData>
    ) => {
      state.kycDetails = action.payload;
    },
    updateFormState: (state, action: PayloadAction<UpdateFormStatePayload>) => {
      state.ownerDetails = action.payload.ownerDetails;
      state.nomineeDetails = action.payload.nomineeDetails;
      state.communicationAddress = action.payload.communicationAddress;
      state.vehicleDetails = action.payload.vehicleDetails;
      state.previousInsurer = action.payload.previousInsurer;
      state.kycDetails = action.payload.kycDetails;
      // writeToDB("motor-proposal-FormState", state);
    },
  },
});

export const {
  updateOwnerDetails,
  updateNomineeDetails,
  updateCommunicationAddress,
  updateVehicleDetails,
  updatePreviousInsurer,
  updateKycDetails,
  updateFormState
} = formSlice.actions;


export default formSlice.reducer;
