export const hfull = "h-full"
export const renderListenButton = "w-14 h-14 flex items-center justify-center rounded-full bg-zinc-50 text-primary-500 cursor-pointer"
export const pauseIcon = "w-8 h-8" 
export const playIcon = "ml-0.5 w-8 h-8"
export const Card16PodcastMain = "nc-Card16Podcast relative flex flex-col"
export const Card16PodcastImgSection = "block flex-shrink-0 relative w-full rounded-3xl overflow-hidden"
export const Card16PodcastImgSpan = "bg-zinc-900 bg-opacity-30"
export const Card16PodcastAbsoluteLink = "absolute inset-0"
export const Card16PodcastMainContentSection = "w-11/12 transform -mt-32 "
export const Card16PodcastDownSection = "p-5 mt-20 bg-white dark:bg-zinc-900 shadow-xl dark:shadow-2xl rounded-3xl rounded-tl-none flex flex-col flex-grow"
export const Card16PodcastDownCard = "nc-card-title block sm:text-lg lg:text-xl font-semibold text-gray-900 dark:text-gray-100 "
export const Card16PodcastDownCardLink = "line-clamp-1"
export const Card16PodcastDownCardDesc = "block text-sm text-gray-500 dark:text-gray-400 mt-3 mb-5"
export const Card16PodcastDownCardDescSpan = "line-clamp-3"
