import { fetchMotorBrands, fetchMotorFuels, fetchMotorVariants, fetchMotors, fetchRto } from "../data/Premium/MotorData/api";
import { gridCarItems } from "../data/Premium/MotorData/CarSelectionData";
import { gridBikeItems } from "../data/Premium/MotorData/BikeSelectionData";
import { readFromDB } from "./indexDBUtils";

export const fetchInitialMotorData = async (
  whichTab: string,
  setBrands: (brands: any[]) => void,
  setMinimumMotorBrands: (brands: any[]) => void,
  setMotorModels: (models: any[]) => void,
  setMinimumModels: (models: any[]) => void,
  setFuels: (fuels: any[]) => void,
  setVarients: (varients: any[]) => void,
  setRto: (rto: any[]) => void,
  setYears: (years: string) => void,
): Promise<void> => {
  
  const isCar = whichTab === "Car";
  const collectionName = isCar ? "p4us_cars" : "p4us_bikes";
  const gridItems = isCar ? gridCarItems : gridBikeItems;

  try {
    if (whichTab === "Bike" || whichTab === "Car") {
      const brandsData = await fetchMotorBrands(collectionName);
      setBrands(brandsData);

      const brandCodeMap = new Map(brandsData.map((item) => [item.name.toUpperCase(), item.code]));

      const updatedBrands = gridItems.map((brand) => {
        const nameToCheck = brand.name.toUpperCase();
        if (brandCodeMap.has(nameToCheck)) {
          return {
            ...brand,
            code: brandCodeMap.get(nameToCheck),
          };
        } else {
          return brand;
        }
      });

      setMinimumMotorBrands(updatedBrands);
      const data = await readFromDB("motorDetails");

      if (data) {
          if(data.companyCode){
              const motorData = await fetchMotors(collectionName, data.companyCode);
          const firstTwelveModels = motorData.slice(0, 12);
          setMinimumModels(firstTwelveModels);
          setMotorModels(motorData);
   
          if(data.modelCode){
              const fuelsData = await fetchMotorFuels(collectionName, data.companyCode, data.modelCode);
            setFuels(fuelsData);
  
          if(data.fuelCode){
              const varientsData = await fetchMotorVariants(collectionName, data.companyCode, data.modelCode, data.fuelCode);
              setVarients(varientsData);
                   }
              }
          }
         
      }

      const rtoData = await fetchRto();
      setRto(rtoData);

      const currentYear = new Date().getFullYear();
      const minYear = (currentYear - 15).toString(); // Calculate and convert to string
      setYears(minYear); // Set the minYear string directly
      
    }
  } catch (error) {
    console.error("Error fetching initial data:", error);
  }
};
