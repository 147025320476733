export const carProposalPageData = {
  headerSection: {
    heading1: "IDV",
    heading2: "Net-Premium",
    heading3: "Total Tax",
    heading4: "Tenure",
    heading5: "Total Premium",
  },
  OwnerDetailsData: {
    title1: "Owner Details",
    label1: "Vehicle Registered In Company Name?",
    info: "Is your vehicle registered in your Company Name or not",
    label2: "Salutation",
    Salutation: ["Mr", "Mrs", "Ms", "Dr", "Miss", "M/S"],
    label3: "First Name",
    label4: "Last Name",
    label5: "Mobile No",
    label6: "Date of Birth",
    label7: "Gender",
    Gender: ["Male", "Female", "Others"],
    label8: "Email",
    label9: "Marital Status",
    MaritalStatus:[
      "Married",
      "Single",
      "Divorcee",
      "Widow",
      "Widower",
    ],
    label10: "Occupation",
    Occupation: [
      "Advocate/Lawyer",
      "Armed Forces",
      "Business / Sales Profession",
      "Chartered Accountants",
      "Central / State Government Employee",
      "Corporate Executive",
      "Engineering Profession",
      "Financial Services Profession",
      "Heads of Government",
      "Home Maker / Housewife",
      "IT Profession",
      "Medical Profession",
      "Musician / Artist",
      "Not Working",
      "Retired",
      "Self Employed",
      "Sports Person",
      "Student",
      "Teaching Profession",
      "Political Party Official",
      "Politician",
      "Senior Government Official",
      "Senior Judicial Official",
      "Senior Military Official",
      "State-owned Corporation Official",
      "Others",
    ],
    button1: "Next",
  },
  Nominee: {
    title1: "Nominee Details",
    label1: "Nominee Name",
    label2: "Nominee Age",
    label3: "Date of Birth",
    label4: "Relationship",
    Relationship: [
      "Spouse",
      "Son",
      "Daughter",
      "Mother",
      "Father",
      "Brother",
      "Sister",
      "Other",
    ],
    button1: "Next",
  },
  CommunicationAddress: {
    title1: "Communication Address",
    label1: "Address line 1",
    label2: "Address line 2",
    label3: "Address line 3",
    label4: "Address line 4",
    // label5: "Pincode",
    // label6: "City",
    // label7: "State",
    button1: "Next",
  },
  VehicleDetails: {
    title1: "Vehicle Details",
    label1: "Registration Number",
    label2: "Registration Date",
    label3: "Chassis Number",
    label4: "Engine Number",
    label5: "Registration Address line 1",
    label6: "Registration Address line 2",
    label7: "Registration Address line 3",
    label8: "Registration Address line 4",
    label9: "Pincode",
    button1: "Next",
  },
  PreviousInsurer: {
    title1: "Policy Insurer Details",
    label1: "Previous OD Policy Insurer",
    PreviousODPolicyInsurers: [
      //Basic OD
      "Acko General Insurance Ltd",
      "Bajaj Allianz General Insurance Company Ltd",
      "Future Generali India Insurance Company Ltd",
      "Go Digit General Insurance Ltd",
      "HDFC ERGO General Insurance Company Ltd",
      "IFFCO TOKIO General Insurance Company Ltd",
      "National Insurance Company Limited",
      "Reliance General Insurance Company Ltd",
      "Royal Sundaram General Insurance Company Ltd",
      "Shriram General Insurance Company Ltd",
      "The Oriental Insurance Company Limited",
      "United India Insurance company Ltd",
      "Universal Sompo General Insurance Company Ltd",
    ],
    label2: "Previous Policy Number",
    label3: "Previous Policy Address",
    label4: "Active TP Policy Insurer",
    ActiveTPPolicyInsurers: [
      //Basic TP
      "Acko General Insurance Ltd",
      "Bajaj Allianz General Insurance Company Ltd",
      "Future Generali India Insurance Company Ltd",
      "Go Digit General Insurance Ltd",
      "HDFC ERGO General Insurance Company Ltd",
      "IFFCO TOKIO General Insurance Company Ltd",
      "National Insurance Company Limited",
      "Reliance General Insurance Company Ltd",
      "Royal Sundaram General Insurance Company Ltd",
      "Shriram General Insurance Company Ltd",
      "The Oriental Insurance Company Limited",
      "United India Insurance company Ltd",
      "Universal Sompo General Insurance Company Ltd",
    ],
    label5: "Active TP Policy Number",
    label6: "Is your vehicle presently under a loan agreement?",
    label7: "Loan Provider",
    label8: "Loan Provider Address",
    button1: "Next",
  },
  KYC: { 
    title1: "KYC Verification",
    label1: "PAN Number",
    label2: "Aadhar Number",
    label3: "Select the Document type :",
    Doc: ["PAN", "Aadhar"],
    label4: "Upload the PDF of ",
    label5: "Pincode",
    label6: "City",
    label7: "State",
    button1: "Verify KYC",
    button2: "KYC Verified",
    button3: "Submit",
  },
};

