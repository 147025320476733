import { FC } from "react";
import { PostDataType } from "../../data/types";
import Link from "../Link";
import Avatar from "../Avatar/Avatar";

export interface CardAuthor2Props
  extends Pick<PostDataType,  "author"> {
  className?: string;
  hoverReadingTime?: boolean;
}

const CardAuthor2: FC<CardAuthor2Props> = ({
  className = "",
  author,
}) => {
  const { displayName, href = "/", avatar } = author;
  return (
    <Link
      href={href}
      className={`nc-CardAuthor2 relative inline-flex items-center ${className}`}
    >
      <Avatar
        sizeClass="h-10 w-10 text-base"
        containerClassName="flex-shrink-0 mr-3"
        radius="rounded-full"
        imgUrl={avatar}
        userName={displayName}
      />
      <div>
        <h2
          className={`text-sm text-neutral-700 hover:text-black dark:text-neutral-300 dark:hover:text-white font-medium`}
        >
          {displayName}
        </h2>
        <span
          className={`flex items-center mt-1 text-xs text-neutral-500 dark:text-neutral-400`}
        >
          <span>xxxxxxx</span>
          
        </span>
      </div>
    </Link>
  );
};

export default CardAuthor2;
