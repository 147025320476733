import FingerprintJS, { Agent, GetResult } from '@fingerprintjs/fingerprintjs';
import CryptoJS from 'crypto-js';

// Define the Component type
type Component<T> = ({
  value: T;
} | {
  error: unknown;
}) & {
  duration: number;
};

// Type guard to check if a component has a value
const hasValue = <T,>(component: Component<T> | undefined): component is { value: T; duration: number } => {
  return component !== undefined && 'value' in component;
};

// Define the UniqueData interface
interface UniqueData {
  browser: string;
  device: string;
  ip: string;
  screen: string;
  uniqueString: string;
}

const useUserIdentifier = async (): Promise<string> => {
  // Get the secret key from environment variables
  const secretKey = import.meta.env.VITE_USER_GENERATED_ID as string;
  if (!secretKey) {
    throw new Error('Secret key is not defined');
  }

  // Get device and browser information
  const fp: Agent = await FingerprintJS.load();
  const result: GetResult = await fp.get();
  const components: { [key: string]: Component<any> | undefined } = result.components;
  
  const userAgent = hasValue(components.userAgent) ? components.userAgent.value : navigator.userAgent;

  // Extract browser name from userAgent string
  const browserName = detectBrowser(userAgent);

  // Collect IP address
  const ipResponse = await fetch('https://api.ipify.org?format=json');
  const ipData = await ipResponse.json();
  const ip: string = ipData.ip;

  // Generate a unique string for each user
  const uniqueString = generateUniqueString();

  // Combine information into a single string
  const uniqueData: UniqueData = {
    browser: browserName,
    device: hasValue(components.platform) ? components.platform.value : 'unknown',
    ip: ip,
    screen: `${window.screen.width}x${window.screen.height}`,
    uniqueString: uniqueString,
  };

  const uniqueDataString = JSON.stringify(uniqueData);

  // Encrypt the string
  const encryptedId = CryptoJS.AES.encrypt(uniqueDataString, secretKey).toString();

  // Encode the encrypted string to make it URL-safe
  const safeEncryptedId = encodeURIComponent(encryptedId);

  return safeEncryptedId;
};

// Function to detect browser from userAgent string
const detectBrowser = (userAgent: string): string => {
  const ua = userAgent.toLowerCase();

  // Check for common browser strings
  if (ua.includes('firefox')) {
    return 'Mozilla Firefox';
  } else if (ua.includes('chrome')) {
    return 'Google Chrome';
  } else if (ua.includes('safari') && !ua.includes('chrome')) {
    return 'Apple Safari';
  } else if (ua.includes('opera') || ua.includes('opr')) {
    return 'Opera';
  } else if (ua.includes('edge')) {
    return 'Microsoft Edge';
  } else if (ua.includes('msie') || ua.includes('trident')) {
    return 'Internet Explorer';
  } else {
    return 'Unknown Browser';
  }
};

// Function to generate a unique string
const generateUniqueString = (): string => {
  return Math.random().toString(36).substring(2, 16);
};

export default useUserIdentifier;