import { FormState } from "../../../reducers/slices/carBikeProposalSlice";
import { MotorData } from "../../../reducers/slices/MotorSlice";

//Inputs from the user
const kycData = (formState: FormState, motorData: MotorData) => {
  return {
    salutation: formState.ownerDetails.salutation,
    firstName: formState.ownerDetails.firstName,
    lastName: formState.ownerDetails.lastName,
    fullName: `${formState.ownerDetails.firstName} ${formState.ownerDetails.lastName}`,
    mobileNo: formState.ownerDetails.mobileNo,
    dateOfBirth: formState.ownerDetails.dateOfBirth,
    gender: formState.ownerDetails.gender,
    emailId: formState.ownerDetails.emailId,
    panNumber: formState.kycDetails.panNumber,
    aadharNumber: formState.kycDetails.aadharNumber,
    quoteId: motorData.proposalData.responseData.QuoteID || "",
    documentUpload: formState.kycDetails.documentUpload || "",
    vehicleRegistered: formState.ownerDetails.vehicleRegistered ? "Yes": "No",
  };
};

export default kycData;
