import React, { useState } from "react";


interface AddonsDetailsProps {
  data: { key: string; value: string }[];
}

const AddonsDetails: React.FC<AddonsDetailsProps> = ({ data }) => {
  const [isPopupVisible, setIsPopupVisible] = useState<boolean>(false);

  return (
    <>
      <div
        className="px-3 py-2 mb-2 border w-48 rounded-lg flex items-center cursor-pointer hover:bg-zinc-50 dark:hover:bg-zinc-950/30 justify-between relative"
        onClick={() => setIsPopupVisible(!isPopupVisible)}
      >
        <p className="text-sm text-zinc-900  dark:text-zinc-200 font-medium">
          Selected AddOns
        </p>
        <div className="opacity-50 text-sm font-semibold">
        &#9660;
        </div>
      </div>
      {isPopupVisible && (
        <div className="absolute bg-white dark:bg-zinc-950/90 rounded-lg shadow-lg px-1 pb-5 w-80 max-sm:w-[270px] z-30 top-28 right-auto left-0 md:top-11 md:right-0 md:left-auto  mt-2">
          <div className="flex justify-between items-center mb-4 px-6 pt-6">
            <h3 className="text-sm font-semibold text-gray-900 dark:text-white">
              Addon Covers
            </h3>
            <button
              onClick={() => setIsPopupVisible(false)}
              className="text-gray-500 text-lg hover:text-zinc-700 dark:text-zinc-400 dark:hover:text-zinc-200"
            >
              ×
            </button>
          </div>
          <div className="max-h-52 overflow-y-auto custom-secScrollbar w-full px-6 pb-6">
            {data.length === 0 ? (
              <p className="text-xs text-gray-500 dark:text-gray-400 px-2 py-3">
                No addons provided.
              </p>
            ) : (
              <div className="space-y-3">
                {data.map((item, index) => (
                  <div
                    key={index}
                    className="flex justify-between text-xs text-zinc-700 dark:text-zinc-300"
                  >
                    <span>{item.key}:</span>
                    <span className="font-medium">₹ {item.value}</span>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default AddonsDetails;
