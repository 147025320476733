export const cursorPointer = "cursor-pointer"
export const searchModalButton = "flex w-10 h-10 sm:w-12 sm:h-12 rounded-full text-gray-700 dark:text-gray-300 hover:bg-zinc-100 dark:hover:bg-zinc-800 focus:outline-none items-center justify-center"
export const searchModalDialog = "relative z-[99]"
export const searchModalTransChild = "fixed inset-0 bg-black/40 transition-opacity" 
export const searchModalDiv = "fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20"
export const searchModalPanel ="block mx-auto max-w-2xl transform divide-y divide-gray-100 overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all"
export const relative ="relative"
export const SearchIcon ="pointer-events-none absolute top-3.5 left-4 h-5 w-5 text-gray-400"
export const SearchBox ="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm"
export const SearchComboBox ="max-h-80 scroll-py-10 scroll-pb-2 space-y-4 overflow-y-auto p-4 pb-2"
export const SearchResultSection ="py-14 px-6 text-center text-sm sm:px-14"
export const SearchResultSectionIcon ="mx-auto h-6 w-6 text-gray-400"
export const SearchNoResult ="mt-4 font-semibold text-gray-900"
export const SearchNoResultDesc ="mt-2 text-gray-500"