import React from "react";

interface RadioButtonComponentProps {
  label: string;
  value: string;
  selectedValue: string;
  onChange: (value: string) => void;
}

const RadioButtonComponent: React.FC<RadioButtonComponentProps> = ({ label, value, selectedValue, onChange }) => (
  <label
    htmlFor={value}
    className={`cursor-pointer rounded-md border h-9 flex items-center justify-center border-gray-300 dark:border-gray-900 px-2 py-2 text-sm font-medium ${
      selectedValue === value ? "bg-blue-50 text-blue-700 dark:bg-blue-600 dark:text-gray-100" : "bg-white text-gray-700 dark:bg-zinc-900 dark:text-zinc-300"
    }`}
  >
    <input
      type="radio"
      id={value}
      name="prevPolicy"
      value={value}
      checked={selectedValue === value}
      onChange={(e) => onChange(e.target.value)}
      className="hidden"
    />
    {label}
  </label>
);

export default RadioButtonComponent;