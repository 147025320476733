// import React, { useMemo } from "react";
// import { TaxonomyType } from "../../data/types";
// import { DEMO_CATEGORIES } from "../../data/taxonomies";
// import CardCategory6 from "../CardCategory6/CardCategory6";
// import Heading from "../Heading/Heading";
// import { SectionGridCategoryBoxCards, SectionGridCategoryBoxDiv } from "../styleComponents/sectionGridCategoryBoxStyles";

// export interface SectionGridCategoryBoxProps {
//   categories?: TaxonomyType[];
//   headingCenter?: boolean;
//   className?: string;
// }

// const SectionGridCategoryBox: React.FC<SectionGridCategoryBoxProps> = React.memo(({
//   categories,
//   headingCenter = true,
//   className = "",
// }) => {
//   // Memoize the filtered categories data
//   const DATA = useMemo(() => DEMO_CATEGORIES.filter((_, i) => i < 10), []);

//   const renderCategories = categories ?? DATA; // Use passed categories or default to DATA

//   return (
//     <div className={`${SectionGridCategoryBoxDiv} ${className}`}>
//       <Heading desc="Discover different products" isCenter={headingCenter}>
//         Our Products
//       </Heading>
//       <div className={SectionGridCategoryBoxCards}>
//         {renderCategories.map((item) => (
//           <CardCategory6 key={item.id} taxonomy={item} />
//         ))}
//       </div>
//     </div>
//   );
// });

// export default SectionGridCategoryBox;

import React, { useMemo } from "react";
import { TaxonomyType } from "../../data/types";
import { DEMO_CATEGORIES } from "../../data/taxonomies";
import CardCategory6 from "../CardCategory6/CardCategory6";
import Heading from "../Heading/Heading";
import { SectionGridCategoryBoxCards, SectionGridCategoryBoxDiv } from "../styleComponents/sectionGridCategoryBoxStyles";

export interface SectionGridCategoryBoxProps {
  categories?: TaxonomyType[];
  headingCenter?: boolean;
  className?: string;
}

const SectionGridCategoryBox: React.FC<SectionGridCategoryBoxProps> = React.memo(({
  categories,
  headingCenter = true,
  className = "",
}) => {
  // Memoize the filtered categories data
  const DATA = useMemo(() => DEMO_CATEGORIES.filter((_, i) => i < 10), []);

  const renderCategories = categories ?? DATA; // Use passed categories or default to DATA

  return (
    <div className={`${SectionGridCategoryBoxDiv} ${className}`}>
      <Heading desc="Discover different products" isCenter={headingCenter}>
        Our Products
      </Heading>
      <div className={SectionGridCategoryBoxCards}>
        {renderCategories.map((item) => (
          <CardCategory6 key={item.id} taxonomy={item} />
        ))}
      </div>
    </div>
  );
});

export default SectionGridCategoryBox;