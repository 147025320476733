import React from "react";

interface OptionHeadingProps {
  index: number;
  option: string;
  onClick: () => void;
  color: string;
  optionObject: any[];
}

const OptionHeading: React.FC<OptionHeadingProps> = ({ index, option, onClick, color, optionObject }) => {
  return (
    <div key={index} onClick={onClick} className="relative flex-1 flex items-center justify-center gap-3 px-1 py-1 md:px-3">
      <span className={`cursor-pointer text-[14px] lg:text-[16px] font-[500] ${color} ${index === 4 ? "ml-4" : ""}`} title={`Go to ${option}`}>
        {option}
      </span>
      {index < optionObject.length - 1 && (
        <span className="absolute right-0 top-0 h-full w-[1px] bg-gray-300"></span>
      )}
    </div>
  );
};

export default OptionHeading;
