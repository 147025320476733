import { Dispatch } from "redux";
import { setLoadingState } from "../../../reducers/slices/commonPremiumSlice";

export const fetchSSEData = (
  sendingValues: any,
  url: string,
  token: string,
  uniqueKey: string,
  setMainCardData: React.Dispatch<React.SetStateAction<any[]>>,
  dispatch: Dispatch<any>,
  logoMapping?: Record<string, string>,
) => {
  if (!sendingValues || Object.keys(sendingValues).length === 0) {
    console.warn("Sending values are empty, skipping API call.");
    return;
  }

  dispatch(setLoadingState({ key: uniqueKey, isLoading: true }));
  setMainCardData([]); // Clear mainCardData array

  const eventSourceInitDict: RequestInit = {
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    method: "POST",
    body: JSON.stringify(sendingValues),
  };

  fetch(url, eventSourceInitDict)
    .then((response) => {
      console.log("SSE connection established. Waiting for data...");
      const reader = response.body?.getReader();
      const decoder = new TextDecoder();
      let buffer = "";
      let isFirstData = true;
      let prevDataLength = 0;

      const processText = ({
        done,
        value,
      }: ReadableStreamReadResult<Uint8Array>): void => {
        if (done) {
          dispatch(setLoadingState({ key: uniqueKey, isLoading: false }));
          console.log("SSE connection closed.");
          return;
        }

        buffer += decoder.decode(value, { stream: true });

        const parts = buffer.split("\n\n");
        buffer = parts.pop() || "";

        parts.forEach((part) => {
          let parsedData;

          try {
            parsedData = JSON.parse(part.substring(5)); // Remove "data: " prefix
          } catch (error) {
            console.error("Error parsing SSE data:", error);
            return;
          }
          if (Array.isArray(parsedData)) {
            if (parsedData.length > 0) {
              const extractedData = parsedData.map((item: any) => {
                const cardData = Object.values(item)[0] as Record<string, any>;
                if (logoMapping && cardData.CompanyLogo && logoMapping[cardData.CompanyLogo]) {
                  cardData.CompanyLogo = logoMapping[cardData.CompanyLogo];
                }
                return cardData;
              });
              if (isFirstData) {
                setMainCardData(extractedData);
                isFirstData = false;
                prevDataLength = parsedData.length;
              } else {
                if (parsedData.length !== prevDataLength) {
                  setMainCardData(extractedData);
                  prevDataLength = parsedData.length;
                }
              }
            } else {
              console.warn("API response missing required data: mainCardData");
            }
          } else {
            console.warn("API response data is not an array:", parsedData);
          }
        });

        reader?.read().then(processText);
      };

      reader?.read().then(processText);
    })
    .catch((error) => {
      dispatch(setLoadingState({ key: uniqueKey, isLoading: false }));
      console.error("SSE error:", error);
    });
};
