import MotorselectingFirstPage from "../../CommonDesignForSelection/CommonMotorSelectingFirstPage";
import { useDispatch } from "react-redux";
import {
  setCompanyCode,
  setSelectedCompany,
  setViewAllBrands,
  setViewAllModels,
  setSelectedModel,
  setModelCode,
  setSelectedFuel,
  setFuelCode,
  setSelectedSubModel,
  setSubModelCode,
  setSelectedCode,
  setRtoCode,
  setSelectedYear,
  setVechicalCode,
  setSelectedCC,
  setCcCode,
  setSendingRto,
  setMotorPremiumOn,
  setCurrentStep,
  setUserSelectedType,
  setSelectedRegDate,
} from "../../../reducers/slices/MotorSlice";
import { useSelector } from "react-redux";
import { RootState } from "../../../reducers/store/store";

const FirstStepWithFunction = () => {
  const dispatch = useDispatch();
  const { whichTab } = useSelector(
    (state: RootState) => state.commonPremiumData
  );
  const handleDontKnowClick = () => {
    dispatch(setCompanyCode(""));
    dispatch(setSelectedCompany(""));
    dispatch(setViewAllBrands(false));
    dispatch(setViewAllModels(false));
    dispatch(setSelectedModel(""));
    dispatch(setModelCode(""));
    dispatch(setSelectedFuel(""));
    dispatch(setFuelCode(""));
    dispatch(setSelectedSubModel(""));
    dispatch(setSubModelCode(""));
    dispatch(setSelectedCode(""));
    dispatch(setRtoCode(""));
    dispatch(setSelectedYear(""));
    dispatch(setSelectedRegDate(""));
    dispatch(setVechicalCode(""));
    dispatch(setSelectedCC(""));
    dispatch(setCcCode(""));
    dispatch(setSendingRto(""));
    dispatch(setUserSelectedType("DontKnow"));
    dispatch(setMotorPremiumOn(false));
    dispatch(setCurrentStep(0));
  };

  const handleNewClick = () => {
    dispatch(setCompanyCode(""));
    dispatch(setSelectedCompany(""));
    dispatch(setViewAllBrands(false));
    dispatch(setViewAllModels(false));
    dispatch(setSelectedModel(""));
    dispatch(setModelCode(""));
    dispatch(setSelectedFuel(""));
    dispatch(setFuelCode(""));
    dispatch(setSelectedSubModel(""));
    dispatch(setSubModelCode(""));
    dispatch(setSelectedCode(""));
    dispatch(setRtoCode(""));
    dispatch(setSelectedYear(""));
    dispatch(setSelectedRegDate(""));
    dispatch(setVechicalCode(""));
    dispatch(setSelectedCC(""));
    dispatch(setCcCode(""));
    dispatch(setSendingRto(""));
    dispatch(setUserSelectedType("New"));
    dispatch(setMotorPremiumOn(false));
    dispatch(setCurrentStep(0));
  };

  const heading = whichTab.toLowerCase().includes("bike")
    ? "Shield your Ride, Get Bike Insurance Online."
    : "Get your Car Insured Online in a Flash!";

  const firstLinkText = whichTab.toLowerCase().includes("bike")
    ? "Don't know your bike number?"
    : "Don't know your car number?";

  const placeholder = whichTab.toLowerCase().includes("bike")
    ? "Enter Bike Number"
    : "Enter Car Number";

  const secondLinkText = whichTab.toLowerCase().includes("bike")
    ? "Bought a new bike?"
    : "Bought a new car?";

  const error = whichTab.toLowerCase().includes("bike")
    ? "Oops! This feature is unavailable. To view quotes now, use 'Don't Know Your Bike' or 'New Bike'."
    : "Oops! This feature is unavailable. To view quotes now, use 'Don't Know Your Car' or 'New Car'.";

  return (
    <div>
      <MotorselectingFirstPage
        heading={heading}
        placeholder={placeholder}
        firstLinkText={firstLinkText}
        firstLinkOnClick={handleDontKnowClick}
        secondLinkText={secondLinkText}
        secondLinkOnClick={handleNewClick}
        error={error}
      />
    </div>
  );
};

export default FirstStepWithFunction;
