import { Route, Routes, Navigate, useLocation } from "react-router-dom";
import SiteHeader from "../sections/SiteHeader";
import HomePage from "../pages/HomePage";
import { Page } from "./types";
import Footer from "../components/Footer/Footer";
import ProposalPage from "../pages/ProposalPage";
import WorkInProgress from "../pages/WorkInProgress";
import PremiumPage from "../pages/PremiumPage";
import PremiumProtectRoute from "./PremiumProtectedRoute";
import LoginPage from "../pages/LoginPage";
import PaymentPage from "../pages/PaymentPage";
import ProtectedRoute from "./ProtectedRoute";

export const pages: Page[] = [
  { path: "/login", component: LoginPage },
  { path: "/", component: HomePage },
  { path: "/car/quote", component: PremiumPage },
  { path: "/bike/quote", component: PremiumPage },
  { path: "/life/quote", component: PremiumPage },
  { path: "/health/quote", component: PremiumPage },
  { path: "/proposal", component: ProposalPage },
  { path: "/payment", component: PaymentPage },
  { path: "/work-in-progress", component: WorkInProgress },
];

const MyRoutes: React.FC = () => {
  const location = useLocation();
  // Define paths that shouldn't show the footer or navbar
  const noFooterPaths = ["/payment", "/login"];
  const noNavbarPaths = ["/payment", "/login"];

  const noFooterPage = noFooterPaths.some((path) =>
    location.pathname.includes(path)
  );
  const noNavbarPages = noNavbarPaths.some((path) =>
    location.pathname.includes(path)
  );

  return (
    <>
      {!noNavbarPages && <SiteHeader />}
      <Routes>
        {pages.map(({ component: Component, path }, index) => {
           if (path === "/login") {
            // Exclude ProtectedRoute for the login page
            return <Route key={index} path={path} element={<Component />} />;
          }
          if (path.includes("/quote")) {
            const allowedTab = path.split("/")[1]; // car, bike, life, health
            return (
              <Route
                key={index}
                path={path}
                element={
                  <ProtectedRoute>
                    <PremiumProtectRoute allowedTab={allowedTab}>
                      <Component />
                    </PremiumProtectRoute>
                  </ProtectedRoute>
                }
              />
            );
          }
          return (
            <Route
              key={index}
              element={
                <ProtectedRoute>
                  <Component />
                </ProtectedRoute>
              }
              path={path}
            />
          );
        })}
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
      {!noFooterPage && <Footer />}
    </>
  );
};

export default MyRoutes;
