import axios from 'axios';
interface RtoData {
  id: string;
  code: string;
  codeWithName: string;
}

interface FetchDocIdResponse {
  id: string;
}


export const fetchMotorBrands = async (collectionName: string): Promise<any[]> => {
  try {
    if (!collectionName) {
      throw new Error("Missing collectionName parameter");
    }

    const response = await axios.post("https://core.insurancepolicy4us.com/api/car/selectBrand", { collectionName });
    return response.data;
  } catch (error) {
    console.error("Error fetching car brands:", error);
    throw error;
  }
};

export const fetchMotors = async (collectionName: string, code: string): Promise<any[]> => {
  try {
    if (!collectionName) {
      throw new Error("Missing collectionName parameter");
    }
    if (!code) {
      throw new Error("Missing code parameter");
    }
    const response = await axios.post("https://core.insurancepolicy4us.com/api/car/selectCar", { collectionName, code });
    return response.data;
  } catch (error) {
    console.error("Error fetching car brands:", error);
    throw error;
  }
};

export const fetchMotorFuels = async (collectionName: string, brandCode: string, carCode: string): Promise<any[]> => {
  try {
    if (!collectionName || !brandCode || !carCode) {
      throw new Error("Missing parameters");
    }

    const response = await axios.post("https://core.insurancepolicy4us.com/api/car/selectFuel", { collectionName, brandCode, carCode });
    return response.data;
  } catch (error) {
    console.error("Error fetching car fuels:", error);
    throw error;
  }
};

export const fetchMotorVariants = async (collectionName: string, brandCode: string, carCode: string, fuelCode: string): Promise<any[]> => {
  try {
    if (!collectionName || !brandCode || !carCode || !fuelCode) {
      throw new Error("Missing parameters");
    }

    const response = await axios.post("https://core.insurancepolicy4us.com/api/car/selectVarient", { collectionName, brandCode, carCode, fuelCode });
    
    return response.data;
  } catch (error) {
    console.error("Error fetching car variants:", error);
    throw error;
  }
};

export const fetchP4usId = async (collectionName: string, brandCode: string, carCode: string, fuelCode: string, varianceCode: string, ccCode: string): Promise<string> => {
  try {
    if (!collectionName || !brandCode || !carCode || !fuelCode || !varianceCode || !ccCode) {
      throw new Error("Missing parameters");
    }

    const response = await axios.post("https://core.insurancepolicy4us.com/api/car/getCarId", { collectionName, brandCode, carCode, fuelCode, varianceCode, ccCode });
    
    return response.data.id;
  } catch (error) {
    console.error("Error fetching P4US_ID:", error);
    throw error;
  }
};

export const fetchRto = async (): Promise<RtoData[]> => {
  try {
    const response = await axios.get<RtoData[]>("https://core.insurancepolicy4us.com/api/Rto/RtoCode");
    
    return response.data;
  } catch (error) {
    console.error("Error fetching P4US_ID:", error);
    throw error;
  }
};

export const fetchVariantMapped = async (collectionName: string, vechicalCode: string): Promise<any[]> => {
  try {
    if (!collectionName || !vechicalCode ) {
      throw new Error("Missing parameters");
    }

    const response = await axios.post("https://core.insurancepolicy4us.com/api/car/varientMapped", { collectionName, vechicalCode });
    
    return response.data;
  } catch (error) {
    console.error("Error fetching car variants:", error);
    throw error;
  }
};
export const fetchDocId = async (collectionName: string, makeCode: string, modelCode: string, fuelCode: string, variant: string): Promise<FetchDocIdResponse> => {
  try {
    if (!collectionName || !makeCode || !modelCode || !fuelCode || !variant ) {
      throw new Error("Missing parameters");
    } 
    
    const response = await axios.post("https://core.insurancepolicy4us.com/api/car/fetchId", { collectionName, makeCode, modelCode, fuelCode, variant });
    
    return response.data;
  } catch (error) {
    // console.error("Error fetching car variants:", error);
    throw error;
  }
};