import { Dispatch, SetStateAction } from 'react';

interface AddOn {
  option: string;
  value: any;
}

  const useInputUpdateHandler = (
    selectedAddOns: AddOn[],
    setSelectedAddOns: Dispatch<SetStateAction<AddOn[]>>
  ) => {

  const handleInputUpdate = (name: string, value: any) => {
    const existingIndex = selectedAddOns.findIndex((option) => option.option === name);

    if (existingIndex !== -1) {
      const updatedOptions = [...selectedAddOns];
      updatedOptions[existingIndex].value = value;
      setSelectedAddOns(updatedOptions);
    } else {
      setSelectedAddOns((prevSelected) => [...prevSelected, { option: name, value }]);
    }
  };

  return { handleInputUpdate };
};

export default useInputUpdateHandler;
