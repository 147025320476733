import React, { HTMLAttributes, ReactNode } from "react";
import {
  heading,
  headingDesc,
  headingInnerDiv,
  headingMainDiv,
  maxWidth,
} from "../styleComponents/headingStyles/headingStyle";

export interface HeadingProps extends HTMLAttributes<HTMLHeadingElement> {
  fontClass?: string;
  desc?: ReactNode;
  isCenter?: boolean;
}

const Heading: React.FC<HeadingProps> = ({
  children,
  desc = "Protect What Matters Most ",
  className = "mb-10 md:mb-12 text-gray-900 dark:text-gray-50",
  isCenter = false,
  ...args
}) => {
  return (
    <div className={`${headingMainDiv} ${className}`}>
      <div className={isCenter ? headingInnerDiv : maxWidth}>
        <h2 className={heading} {...args}>
          {children || `Section Heading`}
        </h2>
        {desc && <span className={headingDesc}>{desc}</span>}
      </div>
    </div>
  );
};

export default Heading;
