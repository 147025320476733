import React, { ReactNode, useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { readFromDB } from "../utils/indexDBUtils";

interface PremiumProtectRouteProps {
  children: ReactNode;
  allowedTab: string;
}

const PremiumProtectRoute: React.FC<PremiumProtectRouteProps> = ({
  children,
  allowedTab,
}) => {
  const [whichTab, setWhichTab] = useState<string | null>(null);

  useEffect(() => {
    const fetchTab = async () => {
      const tab = await readFromDB("whichTab");
      setWhichTab(tab || "");
    };

    fetchTab();
  }, []);

  if (whichTab === null) {
    // While fetching the tab, you can show a loading indicator
    return <div></div>;
  }

  // Log values for debugging
  console.log(`whichTab: ${whichTab}, allowedTab: ${allowedTab}`);

  if (!whichTab || whichTab.trim() === "") {
    return <Navigate to="/" />;
  }

  if (whichTab.toLowerCase() !== allowedTab.toLowerCase()) {
    return <Navigate to={`/${whichTab.toLowerCase()}/quote`} />;
  }

  return <>{children}</>;
};

export default PremiumProtectRoute;
