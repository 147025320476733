import React, { useEffect, useRef } from "react";
import ButtonClose from "../ButtonClose/ButtonClose";

interface PopupProps {
  isOpen: boolean;
  onClose: () => void;
  title?: string; // title is now optional
  children: React.ReactNode;
  maxWidth: string; // maxWidth prop
  headingChild?: React.ReactNode; // new optional heading prop
  nonClose?: boolean; // new optional nonClose prop
}

const PopLayout: React.FC<PopupProps> = ({
  isOpen,
  onClose,
  title,
  children,
  maxWidth,
  headingChild,
  nonClose,
}) => {
  const popupRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (popupRef.current && !popupRef.current.contains(event.target as Node) && !nonClose) {
      onClose();
    }
  };

  useEffect(() => {
    if (isOpen && !nonClose) {
      document.addEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen, nonClose]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center dark:bg-opacity-90">
      <div
        ref={popupRef}
        className={`inline-container mx-3 sm:mx-5 block w-full my-5 overflow-hidden text-left align-middle transition-all transform bg-white border border-black border-opacity-5 shadow-xl rounded-2xl sm:my-8 dark:bg-zinc-950 dark:border-zinc-700 text-neutral-900 dark:text-neutral-300 ${maxWidth}`}
      >
        <div
          className={`py-4 px-4 text-center relative ${
            !headingChild && !title
              ? "mx-2 my-2"
              : "border-b border-neutral-100 dark:border-neutral-700"
          } md:py-5`}
        >
          {!nonClose && (
            <div className="absolute left-2 top-1/2 transform text-gray-500 dark:text-gray-400 cursor-pointer -translate-y-1/2 sm:left-4">
              <ButtonClose onClick={onClose} />
            </div>
          )}
          {(headingChild || title) && (
            <h3 className="text-[14px] font-normal text-primary-950 lg:text-[15px] dark:text-primary-50 mx-auto">
              {headingChild || title}
            </h3>
          )}
        </div>
        <div>{children}</div>
      </div>
    </div>
  );
};

export default PopLayout;
