import React from "react";
import InputProp from "./InputProps/InputProps";
import DateInputComponent from "./DateInputComponent";
import DropdownInputComponent from "./DropdownInputComponent";
import RadioButtonComponent from "./RadioButtonComponent";

interface RenderInputsProps {
  hasPrevExpired: boolean | null;
  hasExpiredAfterDate: boolean | null;
  userSelectedType: string;
  keys: any;
  date1: string;
  date2: string;
  date3: string;
  date4: string;
  dropdown1: string;
  dropdown2: string;
  radioOption1: string;
  setRadioOption1: (value: string) => void;
  setDate1: (value: string) => void;
  setDate2: (value: string) => void;
  setDate3: (value: string) => void;
  setDate4: (value: string) => void;
  setDropdown1: (value: string) => void;
  setDropdown2: (value: string) => void;
  handleDropdownChangeWrapper: (value: string) => void;
  minDate2Str?: string;
  maxDate2Str?: string;
  minDate3Str?: string;
  maxDate3Str?: string;
  minDate4Str?: string;
  maxDate4Str?: string;
  minDate5Str?: string;
  maxDate5Str?: string;
  minDate6Str?: string;
  maxDate6Str?: string;
  minDate7Str?: string;
  maxDate7Str?: string;
  prevCompreh: boolean;
  prevTP: boolean;
  newTP: boolean;
}

const RenderInputs: React.FC<RenderInputsProps> = ({
  hasPrevExpired,
  hasExpiredAfterDate,
  userSelectedType,
  keys,
  date1,
  date2,
  date3,
  date4,
  dropdown1,
  dropdown2,
  radioOption1,
  setRadioOption1,
  setDate2,
  setDate3,
  setDate4,
  setDropdown1,
  handleDropdownChangeWrapper,
  minDate2Str,
  maxDate2Str,
  minDate3Str,
  maxDate3Str,
  minDate4Str,
  maxDate4Str,
  minDate5Str,
  maxDate5Str,
  minDate6Str,
  maxDate6Str,
  minDate7Str,
  maxDate7Str,
  prevCompreh,
  prevTP,
  newTP,
}) => {

  return (
    <div className="flex flex-col pt-4  px-4 sm:px-6 md:pt-4 pb-2 items-center justify-between mb-2 gap-4 max-h-80 sm:max-h-[388px] overflow-y-auto custom-scrollbar">
       {userSelectedType === "DontKnow" && keys.label1 && keys.label1 !== "" && (
        <DateInputComponent label={keys.label1} value={date1} readonly/>
      )}
      {userSelectedType === "New" && keys.label7 && keys.label7 !== "" && (
        <div className="flex max-sm:flex-col w-full justify-between gap-4">
          <div className="text-[14px] font-[400] text-gray-900 dark:text-gray-200 flex justify-center items-center">
            {keys.label7}
          </div>
          <div className="flex w-full sm:w-1/2 items-center justify-center">
            <InputProp value={new Date().getFullYear().toString()} />
          </div>
        </div>
      )}
      {prevTP && (
        <p className="text-xs text-red-600 dark:text-red-400">
          Inspection will be required before purchase of comprehensive policy.
        </p>
      )}
       {userSelectedType === "DontKnow" && keys.label3 && keys.label3 !== "" && (<>
        {!hasPrevExpired && minDate2Str && maxDate2Str && (
        <DateInputComponent label={prevTP || prevCompreh ? "Expiry Date of your Previous policy" : keys.label3} value={date2} min={minDate2Str} max={maxDate2Str} onChange={setDate2} />
      )}
      {hasPrevExpired && hasExpiredAfterDate && minDate5Str && maxDate5Str && (
        <DateInputComponent label={keys.label3} value={date2} min={minDate5Str} max={maxDate5Str} onChange={setDate2} />
      )}
       {!prevTP && hasPrevExpired && !hasExpiredAfterDate && minDate6Str && maxDate6Str && (
        <DateInputComponent label={keys.label3} value={date2} min={minDate6Str} max={maxDate6Str} onChange={setDate2} />
      )}
       </>)}    
      {!prevTP && hasPrevExpired && !hasExpiredAfterDate && (
        <p className="text-xs text-red-600 dark:text-red-400">
          Your policy has already expired. Quotes will be shown accordingly.
        </p>
      )}
      {!prevTP && !prevCompreh && userSelectedType === "DontKnow" && keys.label4 && keys.label4 !== "" && (<>
        {!hasPrevExpired && minDate3Str && maxDate3Str && (
        <DateInputComponent label={keys.label4} value={date3} min={minDate3Str} max={maxDate3Str} onChange={setDate3} />
      )}
     {hasPrevExpired &&  (hasExpiredAfterDate || !hasExpiredAfterDate) &&
              minDate7Str &&
              maxDate7Str &&  (
        <DateInputComponent label={keys.label4} value={date3} min={minDate7Str} max={maxDate7Str} onChange={setDate3} />
      )} 
      </>)}  
      {userSelectedType === "New" && keys.label8 && keys.label8 !== "" && minDate4Str && maxDate4Str && (
        <DateInputComponent label={keys.label8} value={date4} min={minDate4Str} max={maxDate4Str} onChange={setDate4} />
      )}         
      {userSelectedType === "DontKnow" && keys.label2 && keys.label2 !== "" && (
        <DropdownInputComponent label={keys.label2} value={dropdown1} options={["Bundled", "Comprehensive", "Third Party", "Own Damage"]} onChange={setDropdown1} />
      )}
      {!prevTP &&
        !newTP &&
        userSelectedType === "DontKnow" &&
        keys.label5 &&
        keys.label5 !== "" &&
        (!hasPrevExpired || (hasPrevExpired && hasExpiredAfterDate)) && (
          <div className="flex max-sm:flex-col w-full justify-between gap-2 sm:gap-4 mt-1 border-t dark:border-gray-700">
            <div className="text-[14px] font-[400] text-gray-900 dark:text-gray-200 flex justify-center items-center sm:mt-5 mt-3">
              {keys.label5}
            </div>
            <div className="flex w-full sm:w-1/2 justify-center items-center gap-3 sm:mt-5 mt-1">
            <RadioButtonComponent label="Yes" value="Yes" selectedValue={radioOption1} onChange={setRadioOption1} />
            <RadioButtonComponent label="No" value="No" selectedValue={radioOption1} onChange={setRadioOption1} />
          </div>
          </div>
        )}
     {radioOption1 === "No" && !prevTP && !newTP && userSelectedType === "DontKnow" && keys.label6 && keys.label6 !== "" && (!hasPrevExpired || (hasPrevExpired && hasExpiredAfterDate)) && (
        <DropdownInputComponent label={keys.label6} value={dropdown2} options={["0%", "20%", "25%", "35%", "45%", "50%"]} onChange={handleDropdownChangeWrapper} />
      )}
    </div>
  );
};

export default RenderInputs;
