export const getColor = (
    index: number,
    selectedCompany: string,
    selectedModel: string,
    selectedFuel: string,
    selectedSubModel: string,
    selectedCode: string,
    selectedOption: string,
    optionObject: string[]
  ) => {
    const isOptionSelected =
      selectedOption !== undefined &&
      selectedOption !== "" &&
      optionObject !== undefined &&
      optionObject[index] !== undefined &&
      selectedOption.includes(optionObject[index]);
  
    if (index === 0 && selectedCompany !== "") {
      return isOptionSelected ? "text-black dark:text-white" : "text-blue-500 dark:text-sky-400";
    } else if (index === 1 && selectedModel !== "" && selectedCompany !== "") {
      return isOptionSelected ? "text-black dark:text-white" : "text-blue-500 dark:text-sky-400";
    } else if (index === 2 && selectedFuel !== "" && selectedCompany !== "" && selectedModel !== "") {
      return isOptionSelected ? "text-black dark:text-white" : "text-blue-500 dark:text-sky-400";
    } else if (index === 3 && selectedSubModel !== "" && selectedFuel !== "" && selectedCompany !== "" && selectedModel !== "") {
      return isOptionSelected ? "text-black dark:text-white" : "text-blue-500 dark:text-sky-400";
    } else if (index === 4 && selectedCode !== "" && selectedSubModel !== "" && selectedFuel !== "" && selectedCompany !== "" && selectedModel !== "") {
      return isOptionSelected ? "text-black dark:text-white" : "text-blue-500 dark:text-sky-400";
    } else if (isOptionSelected) {
      return "text-black dark:text-white";
    } else {
      return "text-gray-400";
    }
  };
  