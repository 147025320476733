import React, { useState, useEffect } from "react";
import ButtonPrimary from "../Button/ButtonPrimary";


interface Option {
  name: string;
  code: string;
}

interface CustomDropdownProps {
  heading: string;
  options: Option[];
  selectedOption: string;
  handleSelect: (name: string, code: string) => void;
  height?: string;
  secHeading?: string;
  secOption?: string[];
  secSelectedOption?: string;
  sechandleSelect?: (year: string) => void;
  thirdHeading?: string;
  thirdSelectedDate?: string;
  thirdMin?: string;
  thirdMax?: string;
  handleathird?: (date: Date) => void;
  handleNextStepClick?: () => void; // Optional prop for next step button click handler
}

const CommonSearchDropdown: React.FC<CustomDropdownProps> = ({
  heading,
  options,
  selectedOption: selectedOptionProp,
  handleSelect,
  height,
  secHeading,
  secOption,
  secSelectedOption,
  sechandleSelect,
  thirdHeading,
  thirdSelectedDate,
  thirdMin,
  thirdMax,
  handleathird,
  handleNextStepClick, // Optional prop for the next step button handler
}) => {
  const [selectedOption, setSelectedOption] =
    useState<string>(selectedOptionProp);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption2, setSelectedOption2] = useState<string>(
    secSelectedOption ? secSelectedOption : ""
  );
  const [isOpen2, setIsOpen2] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [filteredOptions, setFilteredOptions] = useState<Option[]>(options);
  const [dateValue, setDateValue] = useState<string>(
    thirdSelectedDate ? thirdSelectedDate : ""
  );

  useEffect(() => {
    setSelectedOption(selectedOptionProp);
    if (secSelectedOption) {
      setSelectedOption2(secSelectedOption);
    }
    if (thirdSelectedDate) {
      setDateValue(thirdSelectedDate);
    }
    if (selectedOption === "") {
      setIsOpen(true);
    }
  }, [selectedOptionProp, secSelectedOption]);

  const Value =
    searchText !== ""
      ? searchText
      : selectedOption !== "" &&
        options.find((option) => option.code === selectedOption)?.name;

  useEffect(() => {
    const normalize = (str: any) =>
      str.replace(/[^a-zA-Z0-9]/g, "").toLowerCase();

    if (!searchText) {
      setFilteredOptions(options);
    } else {
      const normalizedSearchText = normalize(searchText);
      const filtered = options.filter((option) =>
        normalize(option.name).includes(normalizedSearchText)
      );
      setFilteredOptions(filtered);
    }
  }, [searchText, options]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedOption("");
    setSearchText(e.target.value);
    setIsOpen(true); // Open the dropdown when typing in the input
    setIsOpen2(false);
  };

  const handleOptionClick = (name: string, code: string) => {
    setSearchText("");
    setSelectedOption(code);
    handleSelect(name, code);
    setIsOpen(false);
    setIsOpen2(true);
  };

  const handleSecOptionClick = (year: string) => {
    setSelectedOption2(year);
    if (sechandleSelect) {
      sechandleSelect(year);
    }
    setIsOpen2(false);
  };

  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const dateString = e.target.value;
    setDateValue(dateString); // Update the state with the string value

    // Convert the string into a Date object
    const dateObject = new Date(dateString);

    if (handleathird) {
      handleathird(dateObject); // Pass the Date object to handleathird
    }
  };

  const isValidDate =
    dateValue &&
    (!thirdMin || dateValue >= thirdMin) &&
    (!thirdMax || dateValue <= thirdMax);

  return (
    <div className="relative ">
      <div
        className={`px-6 py-4 mt-3 w-full  ${
          height ? height : "h-[330px]"
        } flex flex-col  items-center gap-2 mb-4`}
      >
        <h2 className="text-lg mb-4 sm:text-xl">{heading}</h2>
        {options.length === 0 ? (
          <div className="flex items-center justify-center h-60">
            <div className="loader"></div>
          </div>
        ) : (
          <div className="relative w-11/12 sm:w-4/5 md:w-1/2  z-50">
            <input
              type="text"
              value={Value ? Value : ""}
              onChange={handleInputChange}
              onClick={() => {
                setIsOpen(!isOpen);
                setIsOpen2(false);
              }}
              className="w-full h-10 text-[15px] px-4 py-2 border border-gray-300 rounded-md bg-gray-50 dark:bg-zinc-800 dark:border-zinc-700 dark:placeholder:text-zinc-200 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-300"
              placeholder="Search / Select..."
            />
            {isOpen && (
              <div className="absolute top-full w-full max-h-60 mt-1 overflow-y-auto custom-secScrollbar bg-gray-50 dark:bg-zinc-800 dark:border-zinc-700 border rounded-md shadow-lg">
                {filteredOptions.map((option, index) => (
                  <div
                    key={index}
                    className={`px-4 py-2 text-[15px] cursor-pointer hover:bg-gray-100 dark:hover:bg-zinc-700 ${
                      selectedOption === option.code
                        ? "bg-blue-100 dark:bg-blue-900"
                        : ""
                    }`}
                    onClick={() => handleOptionClick(option.name, option.code)}
                  >
                    {selectedOption === option.code && (
                      <span className="mr-2">&#10003;</span>
                    )}
                    {option.name}
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
        {secHeading && secOption && (
          <>
            <h2 className="text-lg mb-2 sm:text-[18px] mt-5">{secHeading}</h2>
            {secOption.length === 0 ? (
              <div className="flex items-center justify-center h-60">
                <div className="loader"></div>
              </div>
            ) : (
              <div className="relative w-3/4 sm:w-1/2 md:w-2/5  z-40 mb-5">
                <div
                  className="w-full h-10 px-4 py-2 border text-[15px] border-gray-300 rounded-md dark:bg-zinc-800 dark:border-zinc-700 dark:placeholder:text-zinc-200 dark:text-white bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-300 cursor-pointer"
                  onClick={() =>
                    selectedOption !== ""
                      ? setIsOpen2(!isOpen2)
                      : setIsOpen(true)
                  }
                >
                  {selectedOption2 || "Select..."}
                </div>
                {isOpen2 && (
                  <div className="absolute top-full  w-full max-h-44 mt-1 dark:bg-zinc-800 dark:border-zinc-700 overflow-y-auto custom-secScrollbar bg-gray-50 border rounded-md shadow-lg">
                    {secOption.map((option, index) => (
                      <div
                        key={index}
                        className={`px-4 py-2 text-[15px] cursor-pointer hover:bg-gray-100 dark:hover:bg-zinc-700 ${
                          selectedOption2 === option
                            ? "bg-blue-100 dark:bg-blue-900"
                            : ""
                        }`}
                        onClick={() => handleSecOptionClick(option)}
                      >
                        {selectedOption2 === option && (
                          <span className="mr-2">&#10003;</span>
                        )}
                        {option}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            )}
          </>
        )}
        {thirdHeading && thirdMin && thirdMax && handleathird && (
          <>
            <h2 className="text-lg mb-2 sm:text-[18px] mt-5">{thirdHeading}</h2>
            <div className="w-3/4 sm:w-1/2 md:w-2/5  z-40 mb-5">
              <input
                type="date"
                min={thirdMin}
                max={thirdMax}
                value={dateValue}
                onChange={handleDateChange}
                className="w-full h-10 px-4 py-2 border border-gray-300 rounded-md dark:bg-zinc-800 dark:border-zinc-700 dark:placeholder:text-zinc-200 dark:text-white bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-300"
              />
            </div>
            {/* Conditionally rendering the Next Step button */}
        {isValidDate && handleNextStepClick && (
          <div className="mt-4 w-3/4 sm:w-1/2 md:w-2/5 flex justify-center items-center">
            <ButtonPrimary className={`w-3/4 md:w-1/2 cursor-pointer`}
             onClick={handleNextStepClick}>
              Next
             </ButtonPrimary>
          </div>
        )}
          </>
          
        )}
        
      </div>
    </div>
  );
};

export default CommonSearchDropdown;
