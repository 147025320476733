import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { isTokenExpired, writeToDB } from '../utils/indexDBUtils';

interface ProtectedRouteProps {
  children: React.ReactNode; // Components to render if token is valid
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
 const [tokenExpired, setTokenExpired] = useState<boolean | null>(null); // null for loading state

  useEffect(() => {
    const checkToken = async () => {
      const expired = await isTokenExpired();
      setTokenExpired(expired);

      // If token is expired, remove token from IndexedDB
      if (expired) {
        await writeToDB('userInfo', {}); // Clear token from IndexedDB
      }
    };

    checkToken();
  }, []);
console.log(tokenExpired);

  if (tokenExpired === null) {
    return <div></div>; // Optionally render a loading state while checking
  }

  if (tokenExpired) {
    return <Navigate to="/login" replace />; // Redirect to login if expired
  }

  return <>{children}</>; // Render children if token is valid
};

export default ProtectedRoute;
