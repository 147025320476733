
export const gridBikeItems = [
    { id:"01",
      name: "Hero",
      image:"https://i.postimg.cc/htC29nvf/hero.png",
      code: ""
     },
     {id:"02",
       name: "Bajaj",
     image:"https://i.postimg.cc/rFMj3stD/bajaj.png",
     code: ""
    },
    {id:"03",
    name: "Honda",
    image:"https://i.postimg.cc/tRhDBW70/honda.png",
    code: ""
    },
    {id:"04",
      name: "TVS",
      image:"https://i.postimg.cc/DwYcScpG/tvs.png",
      code: ""
     },
     {id:"05",
      name: "Royal Enfield",
      image:"https://i.postimg.cc/X7pjWn8q/royal-Enfield.png",
      code: ""
     },
     {id:"06",
      name: "Yamaha",
      image:"https://i.postimg.cc/YqZpqTn3/yamaha.png",
      code: ""
     },
     {id:"07",
      name: "Suzuki",
      image:"https://i.postimg.cc/vmgtf6CN/suzuki.png",
      code: ""
     },
     {id:"08",
      name: "KTM",
      image:"https://i.postimg.cc/SKB7rxhN/ktm.png",
      code: ""
     },
     {id:"09",
      name: "Harley Davidson",
      image:"https://i.postimg.cc/sx1r3J5q/harly.png",
      code: ""
     },
     {id:"10",
      name: "Mahindra",
      image:"https://i.postimg.cc/mkqGkFwD/mahindra.png",
      code: ""
     },
     {id:"11",
      name: "Jawa",
      image:"https://i.postimg.cc/02Ms3ckJ/jawa.png",
      code: ""
     },
     {id:"12",
      name: "Triumph",
      image:"https://i.postimg.cc/XY90Nqyj/triumph.png",
      code: ""
     },
    
  ];

