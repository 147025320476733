// import CommunicationAddress from "../components/Proposal/CommunicationAddress";
import { useSelector } from "react-redux";
import HealthNominee from "../components/Proposal/HealthProposal/HealthNominee";
import LifeStyleDetails from "../components/Proposal/HealthProposal/LifeStyleDetails";
import MedicalDetails from "../components/Proposal/HealthProposal/MedicalDetails";
import MembersDetails from "../components/Proposal/HealthProposal/MembersDetails";
import ProposerDetails from "../components/Proposal/HealthProposal/ProposerDetails";
import KYC from "../components/Proposal/KYC";
// import Nominee from "../components/Proposal/Nominee";
// import OwnerDetails from "../components/Proposal/OwnerDetails";
// import PreviousInsurer from "../components/Proposal/PreviousInsurer";
// import VehicleDetails from "../components/Proposal/VehicleDetails";
import { Route } from "../routes/types";
import { RootState } from "../reducers/store/store";

export const carBikeSubPages = (): { pageName: string; emoji: string }[] => {
  // Access the userSelectedType from Redux state
  const userSelectedType = useSelector((state: RootState) => state.motorDetails.userSelectedType);

  // Define the base pages excluding "Previous Insurer"
  const basePages = [
    {
      emoji: "🙋‍♂️",
      pageName: "Owner Details",
    },
    {
      emoji: "👫",
      pageName: "Nominee Details",
    },
    {
      emoji: "🏠",
      pageName: "Communication Address",
    },
    {
      emoji: "🚗",
      pageName: "Vehicle Details",
    }
  ];

  // Conditionally insert "Previous Insurer" before "KYC Verify" if userSelectedType is not "New"
  if (userSelectedType !== "New") {
    basePages.push({
      emoji: "☂",
      pageName: "Previous Insurer",
    });
  }

  // Add "KYC Verify" at the end
  basePages.push({
    emoji: "🕵️‍♂️",
    pageName: "KYC Verify",
  });

  return basePages;
};
  
export const healthSubPages: { href: Route; pageName: string; emoji: string; component: React.FC }[] = [
    {
      href: "/proposal/proposer-details",
      emoji: "📕",
      pageName: "Proposer Details",
      component: ProposerDetails
    },
    {
      href: "/proposal/health-nominee",
      emoji: "📕",
      pageName: "Nominee Details",
      component: HealthNominee
    },
    {
      href: "/proposal/members-details",
      emoji: "📕",
      pageName: "Members Details",
      component: MembersDetails
    },
    {
      href: "/proposal/medical-details",
      emoji: "🛠",
      pageName: "Medical Details",
      component: MedicalDetails
    },
    {
      href: "/proposal/lifestyle-details",
      emoji: "📃",
      pageName: "LifeStyle Details",
      component: LifeStyleDetails
    },
    {
      href: "/proposal/kyc",
      emoji: "✍",
      pageName: "KYC Verify",
      component: KYC
    },
  ];