import { openDB, DBSchema } from "idb";

interface MyDB extends DBSchema {
  keyval: {
    key: string;
    value: any;
  };
}

const dbName = "my-database";
const storeName = "keyval";
const secretKey = "my-secret-key";

const dbPromise = openDB<MyDB>(dbName, 1, {
  upgrade(db) {
    if (!db.objectStoreNames.contains(storeName)) {
      db.createObjectStore(storeName);
    }
  },
});

// Helper function to XOR encrypt/decrypt the data
const xorEncryptDecrypt = (data: string, key: string): string => {
  let output = "";
  for (let i = 0; i < data.length; i++) {
    output += String.fromCharCode(
      data.charCodeAt(i) ^ key.charCodeAt(i % key.length)
    );
  }
  return output;
};

// Base64 encode
const base64Encode = (data: string): string => {
  return btoa(data);
};

// Base64 decode
const base64Decode = (data: string): string => {
  return atob(data);
};

// Write encrypted value to IndexedDB
export const writeToDB = async (key: string, value: any): Promise<void> => {
  const db = await dbPromise;
  const tx = db.transaction(storeName, "readwrite");
  const store = tx.objectStore(storeName);

  // Encrypt the key
  const encryptedKey = xorEncryptDecrypt(key, secretKey);
  const base64EncryptedKey = base64Encode(encryptedKey);

  // Convert value to JSON and XOR encrypt it
  const serializedValue = JSON.stringify(value);
  const encryptedValue = xorEncryptDecrypt(serializedValue, secretKey);

  // Base64 encode the encrypted value
  const base64EncryptedValue = base64Encode(encryptedValue);

  store.put(base64EncryptedValue, base64EncryptedKey);
  await tx.done;
};

// Read and decrypt value from IndexedDB
export const readFromDB = async (key: string): Promise<any | undefined> => {
  const db = await dbPromise;
  const tx = db.transaction(storeName, "readonly");
  const store = tx.objectStore(storeName);

  // Encrypt the key to match the stored encrypted key
  const encryptedKey = xorEncryptDecrypt(key, secretKey);
  const base64EncryptedKey = base64Encode(encryptedKey);

  const base64EncryptedValue = await store.get(base64EncryptedKey);
  if (!base64EncryptedValue) {
    return undefined;
  }

  // Base64 decode and XOR decrypt the value
  const encryptedValue = base64Decode(base64EncryptedValue);
  const decryptedValue = xorEncryptDecrypt(encryptedValue, secretKey);

  return JSON.parse(decryptedValue);
};

let alertShown = false; // Flag to track if the alert has been shown

export const isTokenExpired = async (): Promise<boolean> => {
  const userInfo = await readFromDB("userInfo");
  if (!userInfo || !userInfo.loginTime) {
    return true; // No user info or login time, consider expired
  }

  const now = new Date().getTime();
  const loginTimestamp = new Date(userInfo.loginTime).getTime();
  const oneDayInMs = 24 * 60 * 60 * 1000;

  const isExpired = now - loginTimestamp > oneDayInMs; // Check if expired

  // Show alert only once if expired
  if (isExpired && !alertShown) {
    alert("Your session has expired. Please log in again."); // Show alert
    alertShown = true; // Set the flag to true to prevent further alerts
  }

  return isExpired; // Return true if expired
};