import React, { useEffect, useState } from "react";
import { initializeEditIcon } from "./initializeEditIcon";
import { handleDropdownChange } from "./editIconFunctions";
import { calculateDates } from "./dateCalculations";
import RenderInputs from "./renderInputs";
import {
  setPrevCompreh,
  setPrevTP,
} from "../../../../reducers/slices/MotorSlice";
import { useDispatch } from "react-redux";
import { readFromDB } from "../../../../utils/indexDBUtils";
import PopLayout from "../../../Popup/PopLayout";
import LinkLayout from "../../../Link/LinkLayout";
import ButtonPrimary from "../../../Button/ButtonPrimary";
import { handleSubmit } from "./handleSubmit";
import { useSelector } from "react-redux";
import { RootState } from "../../../../reducers/store/store";

interface EditProps {
  hasPrevExpired: boolean | null;
  hasExpiredAfterDate: boolean | null;
  userSelectedType: string;
  button1keys: any;
  keys: any;
  whichTab: string;
  updateCombinedData: (headerUpdates: object, sendingUpdates?: object) => void;
  prevCompreh: boolean;
  prevTP: boolean;
  newTP: boolean;
  button2Keys: any;
  button3Keys: any;
  mainCardData: any[];
}

export interface Option {
  name: string;
  about: string;
  badge: string;
  input: boolean;
  placeholder: string;
  range: boolean;
  dropdown: boolean;
  backendName: string;
  valueName: string;
}

export interface SelectedOption {
  option: string;
  value: any;
}

const Editicon1: React.FC<EditProps> = ({
  hasPrevExpired,
  hasExpiredAfterDate,
  userSelectedType,
  button1keys,
  keys,
  whichTab,
  updateCombinedData,
  prevCompreh,
  prevTP,
  newTP,
  button2Keys,
  button3Keys,
  mainCardData
}) => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const {
    initialDate1Str,
    initialDate2Str,
    initialDate3Str,
    adjustedDateStr,
    Date5Str,
    Date6Str,
    Date7Str,
  } = initializeEditIcon(keys, whichTab);
  const [date1, setDate1] = useState("");
  const selectedYear = keys.selectedYear;
  const [date2, setDate2] = useState("");
  const [date3, setDate3] = useState("");
  const [date4, setDate4] = useState("");
  const [dropdown1, setDropdown1] = useState("");
  const [dropdown2, setDropdown2] = useState("");
  const [radioOption1, setRadioOption1] = useState("");
  const [newValue, setNewValue] = useState("");
  const {
    minDate2Str,
    maxDate2Str,
    minDate3Str,
    maxDate3Str,
    minDate4Str,
    maxDate4Str,
    minDate5Str,
    maxDate5Str,
    minDate6Str,
    maxDate6Str,
    maxDate7Str,
    minDate7Str,
  } = calculateDates(
    selectedYear,
    initialDate2Str,
    initialDate3Str,
    // adjustedDateStr,
    // whichTab,
  );

  const handleDropdownChangeWrapper = (value: string) => {
    handleDropdownChange(value, setDropdown2, setNewValue);
  };
  const loading = useSelector(
    (state: RootState) => state.commonPremiumData.loadingStates["premium"] || false
  );
  const hasMainCardData = mainCardData.length > 0;

  useEffect(() => {
    if (dropdown1 === "Third Party") {
      dispatch(setPrevTP(true));
      dispatch(setPrevCompreh(false)); // Set prevCompreh to false when dropdown1 is "ThirdParty"
    } else if (dropdown1 === "Comprehensive") {
      dispatch(setPrevCompreh(true));
      dispatch(setPrevTP(false)); // Set prevTP to false when dropdown1 is "Comprehensive"
    } else {
      dispatch(setPrevCompreh(false)); // Set both prevCompreh and prevTP to false for other cases
      dispatch(setPrevTP(false));
    }
  }, [dropdown1, dispatch]);

  return (
    <div className={`relative ${isOpen ? "z-50" : ""}`}>
      <div>
        <LinkLayout
          handleClick={() => {
            setRadioOption1(keys.label5Value ? "Yes" : "No");
            setDropdown1(keys.label2Value);
            setDropdown2(keys.label6Value);
            setNewValue(keys.label9Value);
            setDate1(
              keys.label1Value !== "" ? keys.label1Value : initialDate1Str
            );
            setDate2(
              keys.label3Value !== ""
                ? keys.label3Value
                : hasPrevExpired &&
                  hasExpiredAfterDate !== null &&
                  hasExpiredAfterDate
                ? Date5Str
                : hasPrevExpired &&
                  hasExpiredAfterDate !== null &&
                  !hasExpiredAfterDate
                ? Date6Str
                : initialDate2Str
            );
            setDate3(
              keys.label4Value !== ""
                ? keys.label4Value
                : hasPrevExpired &&
                  hasExpiredAfterDate !== null &&
                  (hasExpiredAfterDate || !hasExpiredAfterDate)
                ? Date7Str
                : initialDate3Str
            );
            setDate4(
              keys.label8Value !== "" ? keys.label8Value : adjustedDateStr
            );
            setIsOpen(!isOpen);
          }}
          link="View & Edit"
          disabled={loading && !hasMainCardData}
        />
      </div>
      <div>
        {isOpen && (
          <PopLayout
            isOpen={isOpen}
            onClose={() => {
              const restorePrevValues = async () => {
                const prevValues = await readFromDB("motorTPdetails");
                if (prevValues) {
                  dispatch(setPrevCompreh(prevValues.prevCompreh));
                  dispatch(setPrevTP(prevValues.prevTP));
                }
              };
              restorePrevValues();
              setIsOpen(false);
            }}
            title={keys.title}
            maxWidth="max-w-md"
          >
            <div className=" flex flex-col ">
              <RenderInputs
                hasPrevExpired={hasPrevExpired}
                hasExpiredAfterDate={hasExpiredAfterDate}
                userSelectedType={userSelectedType}
                keys={keys}
                date1={date1}
                date2={date2}
                date3={date3}
                date4={date4}
                dropdown1={dropdown1}
                dropdown2={dropdown2}
                radioOption1={radioOption1}
                setRadioOption1={setRadioOption1}
                setDate1={setDate1}
                setDate2={setDate2}
                setDate3={setDate3}
                setDate4={setDate4}
                setDropdown1={setDropdown1}
                setDropdown2={setDropdown2}
                handleDropdownChangeWrapper={handleDropdownChangeWrapper}
                minDate2Str={minDate2Str}
                maxDate2Str={maxDate2Str}
                minDate3Str={minDate3Str}
                maxDate3Str={maxDate3Str}
                minDate4Str={minDate4Str}
                maxDate4Str={maxDate4Str}
                minDate5Str={minDate5Str}
                maxDate5Str={maxDate5Str}
                minDate6Str={minDate6Str}
                maxDate6Str={maxDate6Str}
                minDate7Str={minDate7Str}
                maxDate7Str={maxDate7Str}
                prevCompreh={prevCompreh}
                prevTP={prevTP}
                newTP={newTP}
              />

              <div className="flex mt-3 justify-center items-center pb-5">
                <ButtonPrimary
                  onClick={() =>
                    handleSubmit(
                      userSelectedType,
                      keys,
                      prevTP,
                      prevCompreh,
                      newTP,
                      button1keys,
                      button2Keys,
                      button3Keys,
                      date2,
                      date3,
                      date4,
                      dropdown1,
                      dropdown2,
                      radioOption1,
                      newValue,
                      updateCombinedData,
                      setIsOpen
                    )
                  }
                  className="w-1/2 mx-automt-2"
                >
                  Update
                </ButtonPrimary>
              </div>
            </div>
          </PopLayout>
        )}
      </div>
    </div>
  );
};

export default Editicon1;
