import React from "react";

interface InputProps {
  value: string;
}

const InputProp: React.FC<InputProps> = ({ value }) => {
  return (
    <input
      type="text"
      value={value}
      readOnly
      className=" w-3/4 sm:w-full  text-[14px] text-gray-700 placeholder-gray-400 dark:text-white dark:placeholder-gray-100 border border-gray-400 rounded-md dark:bg-zinc-900  dark:border-zinc-800  focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200 transition duration-200 bg-gray-50"
    />
  );
};

export default InputProp;
