import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ButtonPrimary from "../Button/ButtonPrimary";

interface Props {
  heading: string;
  firstLinkText: string;
  placeholder: string;
  firstLinkOnClick: () => void;
  secondLinkText: string;
  secondLinkOnClick: () => void;
  error: string;
}

const MotorselectingFirstPage: React.FC<Props> = ({
  heading,
  firstLinkText,
  placeholder,
  firstLinkOnClick,
  secondLinkText,
  secondLinkOnClick,
  error,
}) => {
  const [showMessage, setShowMessage] = useState(false);
  const navigate = useNavigate(); // Initialize navigate hook

  const handleViewQuotes = () => {
    setShowMessage(true);
    setTimeout(() => {
      setShowMessage(false);
    }, 6000);
  };

  const handleRenewClick = () => {
    navigate("/work-in-progress");
  };

  return (
    <div className="p-6 flex flex-col space-y-6  items-center justify-center">
      <div className="text-center ">
        <p className="text-xl  text-gray-400 dark:text-zinc-200 font-bold max-sm:text-[16px] ">
          {heading}
        </p>
      </div>

      <div className="flex flex-col w-full items-center justify-center">
        {showMessage && (
          <p className=" text-center text-[13px] ml-0 lg:-ml-10 mb-1 text-red-600 dark:text-red-400">
            {error}
          </p>
        )}
        <div className="flex flex-col gap-4 md:flex-row items-center w-11/12 md:w-2/3">
          <input
            type="text"
            placeholder={placeholder}
            className="px-6 py-4 dark:bg-zinc-800 dark:border-zinc-600 dark:shadow-zinc-900/20 rounded-md shadow-lg focus:outline-none focus:ring focus:ring-blue-200 dark:focus:ring-blue-600/50 border focus:border-blue-100 border-primary-300 w-full dark:placeholder:text-zinc-300 placeholder:text-gray-400"
          />
          <ButtonPrimary
            onClick={handleViewQuotes}
          >
            View Quotes
          </ButtonPrimary>
        </div>
      </div>
      <div className="w-full md:w-3/4 flex px-6  flex-col md:items-center gap-4  justify-between md:flex-row">
        <div className="text-sm flex flex-wrap cursor-pointer" onClick={firstLinkOnClick}>
          <div className="mr-2">{firstLinkText}</div>
          <div className="text-blue-500 mr-2 hover:text-blue-700 dark:text-blue-500 dark:hover:text-blue-600">Click here</div>
        </div>
        <div className="text-sm flex flex-wrap cursor-pointer" onClick={secondLinkOnClick}>
          <div className="mr-2">{secondLinkText}</div>
          <div className="text-blue-500 hover:text-blue-700 mr-2 dark:text-blue-500 dark:hover:text-blue-600">Click here</div>
        </div>
      </div>
      <div className="md:ml-auto pt-8 flex items-center justify-center">
        <div className="flex flex-row md:flex-col items-center justify-center md:items-end md:text-right text-sm flex-wrap">
          <div className="font-medium text-gray-500 dark:text-gray-300 mr-2">
            Already Bought from Policy4us?
          </div>
          <div className="  text-gray-400 hover:text-black dark:hover:text-white underline cursor-pointer mr-2" onClick={handleRenewClick}>
            Renew here
          </div>
        </div>
      </div>
    </div>
  );
};

export default MotorselectingFirstPage;
