import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { RootState } from "../../reducers/store/store";
import { setIsOpenPopup } from "../../reducers/slices/commonPremiumSlice";
import MotorSelectionHeadings from "../MotorSelection/MotorSelectionHeading/MotorSelectionHeadings";
import MotorSelectionProcess from "../MotorSelection/MotorSelectionProcess/MotorSelectionProcess";
import { setCurrentStep } from "../../reducers/slices/MotorSlice";
import PopLayout from "../Popup/PopLayout";

const CommonPopupForSelection = () => {
  const dispatch = useDispatch();
  const isOpenPopup = useSelector(
    (state: RootState) => state.commonPremiumData.isOpenPopup
  );

  return (
    <>
      {isOpenPopup && (
        <PopLayout
          isOpen={isOpenPopup}
          onClose={() => {
            dispatch(setCurrentStep(null));
            dispatch(setIsOpenPopup(false));
          }}
          headingChild={<MotorSelectionHeadings />}
          maxWidth="max-w-screen-lg"
        >
          <div className=" w-full">
            <MotorSelectionProcess />
          </div>
        </PopLayout>
      )}
    </>
  );
};

export default CommonPopupForSelection;
