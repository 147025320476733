import React, { useEffect, useState } from "react";
import ButtonPrimary from "../Button/ButtonPrimary";
import { carProposalPageData } from "../../data/Proposal/carProposal/carProposalData";
import Label from "../Label/Label";
import Input from "../Input/Input";
import Select from "../Select/Select";
import { useDispatch } from "react-redux";
import { updateNomineeDetails } from "../../reducers/slices/carBikeProposalSlice";
import { readFromDB, writeToDB } from "../../utils/indexDBUtils";
import {
  getAgeFromDob,
  validateNomineeAge,
  validateNomineeDob,
  validateNomineeName,
  validateNomineeRelationship,
} from "../../utils/formValidations";
import { markStepCompleted } from "./OwnerDetails";
import { verifyKycToIndexdb } from "./KYC";

interface NomineeProps {
  onSubmit: () => void; // Function to handle form submission and navigation
}

const Nominee: React.FC<NomineeProps> = ({ onSubmit }) => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    nomineeName: "",
    nomineeAge: "",
    nomineeDob: "",
    nomineeRelationship: "",
  });
  const [formErrors, setFormErrors] = useState<{ [key: string]: string }>({});
  const [isLoading, setIsLoading] = useState(false);
  const [ownerAge, setOwnerAge] = useState<any>(0);

  // Fetch owner's age from indexDB on component mount
  useEffect(() => {
    const fetchOwnerAge = async () => {
      try {
        const ownerAgeFromDB = await readFromDB("motor-proposal-OwnerDetails");        
        const age = getAgeFromDob(ownerAgeFromDB.dateOfBirth)      
        setOwnerAge(age);
      } catch (error) {
        console.error("Error fetching owner's age:", error);
      }
    };
    window.scrollTo(0, 0);
    fetchOwnerAge();
  }, []);

  useEffect(() => {

    console.log("formData.nomineeRelationship",formData.nomineeRelationship);
  },[formData.nomineeRelationship])
  // Handle form submission
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    // Validate all required fields
    const requiredFields = [
      "nomineeName",
      "nomineeAge",
      "dateOfBirth",
      "relationship",
    ];
   
    const newFormErrors: { [key: string]: string } = {};
    requiredFields.forEach((field) => {
      switch (field) {
        case "nomineeName":
          const nameError = validateNomineeName(formData.nomineeName);
          if (nameError) newFormErrors.nomineeName = nameError;
          break;
          case "nomineeAge":
            const ageError = validateNomineeAge(formData.nomineeAge, formData.nomineeDob,formData.nomineeRelationship, ownerAge);
            if (ageError) newFormErrors.nomineeAge = ageError;
            break;
        case "nomineeDob":
          const dobError = validateNomineeDob(formData.nomineeDob,formData.nomineeAge);
          if (dobError) newFormErrors.nomineeDob = dobError;
          break;
        case "nomineeRelationship":
          const relationshipError = validateNomineeRelationship(
            formData.nomineeRelationship
          );
          if (relationshipError)
            newFormErrors.nomineeRelationship = relationshipError;
          break;
        default:
          break;
      }
    });

    // Update formErrors state
    setFormErrors(newFormErrors);

    // Check if there are any validation errors
    if (Object.keys(newFormErrors).length > 0) {
      console.log("Form validation errors:", newFormErrors);
      return;
    }
    setIsLoading(true);
    try {
      // Update Redux store and IndexedDB
      dispatch(updateNomineeDetails(formData));
      await writeToDB("motor-proposal-NomineeDetails", formData);

      // Read existing FormState data
      const existingFormState = await readFromDB("motor-proposal-FormState");

      // Merge nomineeDetails with existing FormState data
      const updatedFormState = {
        ...existingFormState,
        nomineeDetails: formData,
      };

      // Write updated FormState data back to IndexedDB
      await writeToDB("motor-proposal-FormState", updatedFormState);

      markStepCompleted(2);
      // Call parent component's onSubmit function to navigate to next step
      onSubmit();
    } catch (error) {
      console.error("Error saving nominee details:", error);
      alert("An error occurred while saving your details. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  // Fetch existing data from IndexedDB on component mount
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await readFromDB("motor-proposal-NomineeDetails");
        if (data) {
          setFormData(data);
        }
      } catch (error) {
        console.error("Error fetching nominee details:", error);
      }
    };
    fetchData();
  }, []);

  // Handle input changes
  const handleChange = async(
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    await verifyKycToIndexdb(false);

  };

  return (
    <div>
      <form
        className="grid md:grid-cols-2 gap-6"
        onSubmit={handleSubmit}
        method="post"
      >
        <label className="block">
          <Label>{carProposalPageData.Nominee.label1}</Label>
          <Input
            type="text"
            className="mt-1"
            onChange={handleChange}
            name="nomineeName"
            value={formData.nomineeName}
            required
          />
          {formErrors.nomineeName && (
            <p className="text-red-600 text-xs mt-1">
              {formErrors.nomineeName}
            </p>
          )}
        </label>
        <label className="block">
          <Label>{carProposalPageData.Nominee.label4}</Label>
          <Select
            className="mt-1"
            onChange={handleChange}
            name="nomineeRelationship"
            value={formData.nomineeRelationship}
            required
          >
            <option value=""></option>
            {carProposalPageData.Nominee.Relationship.map((relation, index) => (
              <option key={index} value={relation}>
                {relation}
              </option>
            ))}
          </Select>
        </label>
        <label className="block">
          <Label>{carProposalPageData.Nominee.label2}</Label>
          <Input
            type="text"
            className="mt-1"
            onChange={handleChange}
            name="nomineeAge"
            value={formData.nomineeAge}
            required
          />
          {formErrors.nomineeAge && (
            <p className="text-red-600 text-xs mt-1">
              {formErrors.nomineeAge}
            </p>
          )}
        </label>
        <label className="block">
          <Label>{carProposalPageData.Nominee.label3}</Label>
          <Input
            type="date"
            className="mt-1"
            onChange={handleChange}
            name="nomineeDob"
            value={formData.nomineeDob}
            required
          />
          {formErrors.nomineeDob && (
            <p className="text-red-600 text-xs mt-1">
              {formErrors.nomineeDob}
            </p>
          )}
        </label>        
        <div className="md:col-span-2">
          <ButtonPrimary type="submit" className="w-full" disabled={isLoading}>
            {isLoading ? "Saving..." : carProposalPageData.Nominee.button1}
          </ButtonPrimary>
        </div>
      </form>
    </div>
  );
};

export default Nominee;
