import React, { useEffect, useState } from 'react';
import Layout from '../components/Proposal/Layout';
import ProposalOuterLayout from '../components/Proposal/ProposalOuterLayout';
import OwnerDetails from '../components/Proposal/OwnerDetails';
import Nominee from '../components/Proposal/Nominee';
import CommunicationAddress from '../components/Proposal/CommunicationAddress';
import VehicleDetails from '../components/Proposal/VehicleDetails';
import PreviousInsurer from '../components/Proposal/PreviousInsurer';
import KYC from '../components/Proposal/KYC';
import { setId, setWhichTab } from "../reducers/slices/commonPremiumSlice";
import { fetchMotorIndexData } from "../utils/fetchMotorIndexData";
import {
  setPremiumData,
  setResponseData,
} from "../reducers/slices/MotorSlice";
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {  readFromDB } from '../utils/indexDBUtils';
import { useSelector } from 'react-redux';
import { RootState } from '../reducers/store/store';

const ProposalPage: React.FC = () => {
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [highestStepReached, setHighestStepReached] = useState<number>(1);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userSelectedType = useSelector(
    (state: RootState) => state.motorDetails.userSelectedType
  );


  useEffect(() => {
    const fetchWhichTab = async () => {
      const tab = await readFromDB("whichTab");
      const proposalData = await readFromDB("responseObjectForProposal");
      const step = await readFromDB("completed-steps");
      if(step && step !== ""&& step !== null){
        setCurrentStep(step + 1);
        setHighestStepReached((prevHighest) => Math.max(prevHighest, step + 1));
      }
      const premiumData = proposalData?.premiumData || {};
      const responseData = proposalData?.responseData || {};
      const id = await readFromDB("uniqueUserId");
      if (tab && tab !== "" && id && id !=="") {
        dispatch(setWhichTab(tab));
        dispatch(setId(id));
        if (tab === "Bike" || tab === "Car") {
          fetchMotorIndexData(dispatch);
          if (
            proposalData &&
            Object.keys(proposalData).length > 0 &&
            Object.keys(premiumData).length > 0 &&
            Object.keys(responseData).length > 0
          ) {
            dispatch(setPremiumData(premiumData));
            dispatch(setResponseData(responseData));
          } else {
            navigate(`/${tab.toLowerCase()}/quote`);
          }
        }
      } else {
        navigate("/");
      }

    };
    window.scrollTo(0, 0);
    // checkTokenAndRedirect(navigate);
    fetchWhichTab();
  }, []);
  

  const handleNextStep = () => {
    setCurrentStep((prevStep) => {
      const newStep = prevStep + 1;
      setHighestStepReached((prevHighest) => Math.max(prevHighest, newStep));
      return newStep;
    });
  };

  const handleSetStep = (step: number) => {
    // Allow navigation to completed steps and current step, but not to future steps
    if (step <= highestStepReached) {
      setCurrentStep(step);
    }
  };
  const renderCurrentStep = () => {
    if (currentStep === 5 && userSelectedType === 'New') {
      return <KYC />;
    }
    if (currentStep === 6) {
      return <KYC />;
    }
    switch (currentStep) {
      case 1:
        return <OwnerDetails onSubmit={handleNextStep} />;
      case 2:
        return <Nominee onSubmit={handleNextStep} />;
      case 3:
        return <CommunicationAddress onSubmit={handleNextStep} />;
      case 4:
        return <VehicleDetails onSubmit={handleNextStep} />;
      case 5:
        return <PreviousInsurer onSubmit={handleNextStep} />;
      default:
        return null;
    }
  };

  return (
    <ProposalOuterLayout>
      <Layout currentStep={currentStep} setStep={handleSetStep} highestStepReached={highestStepReached}>
        {renderCurrentStep()}
      </Layout>
    </ProposalOuterLayout>
  );
};

export default ProposalPage; 