import React, { useState } from "react";
import { formatDate } from "../../../utils/formateDate";
import HeaderDetails from "./HeaderDetails";
import ButtonHeader from "./ButtonHeader";

export interface HeaderComponentProps {
  headerData: any;
  updateCombinedData: (headerUpdates: object, sendingUpdates?: object) => void;
  prevCompreh: boolean;
  prevTP: boolean;
  newTP: boolean;
  whichTab: string;
  mainCardData: any[];
}

interface BadgeProps {
  text: string;
}

interface LabelProps {
  label: string;
  value: string | number | boolean | null;
  isDate?: boolean;
}

export const BadgeP: React.FC<BadgeProps> = ({ text }) => (
  <div className="transition-colors nc-Badge inline-flex px-2.5 py-1 rounded-full font-medium text-xs relative text-yellow-900 dark:text-yellow-950 bg-yellow-200 dark:bg-yellow-300">
    {text}
  </div>
);

export const Label: React.FC<LabelProps> = ({ label, value, isDate }) => (
  <div>
    <span className="mr-2 max-sm:text-xs text-gray-700 dark:text-gray-200 text-sm">
      {label}:
    </span>
    <span className="text-sm max-sm:text-xs dark:text-gray-50 font-medium">
      {isDate && typeof value === "string" ? formatDate(value) : value}
    </span>
  </div>
);

const HeaderComponent: React.FC<HeaderComponentProps> = ({
  headerData,
  updateCombinedData,
  prevCompreh,
  prevTP,
  newTP,
  whichTab,
  mainCardData,
}) => {
  const [fullDiv, setFullDiv] = useState<boolean>(false);
  const isCarBike =
    whichTab.toLowerCase().includes("bike") ||
    whichTab.toLowerCase().includes("car");
  const isDontKnow = headerData.userSelectedType === "DontKnow";
  const isNew = headerData.userSelectedType === "New";

  return (
    <div className="w-full ">
      <HeaderDetails
        headerData={headerData}
        prevCompreh={prevCompreh}
        prevTP={prevTP}
        newTP={newTP}
        whichTab={whichTab}
        fullDiv={fullDiv}
        setFullDiv={setFullDiv}
        isCarBike={isCarBike}
        isDontKnow={isDontKnow}
        isNew={isNew}
        mainCardData={mainCardData}
        updateCombinedData={updateCombinedData}
      />
      {/* button header portion */}
      {/* small button header portion */}
      <div className="w-full sm:hidden bg-white py-5 px-4 dark:bg-black ">
        <ButtonHeader
          headerData={headerData}
          isCarBike={isCarBike}
          isDontKnow={isDontKnow}
          isNew={isNew}
          mainCardData={mainCardData}
          newTP={newTP}
          prevCompreh={prevCompreh}
          prevTP={prevTP}
          updateCombinedData={updateCombinedData}
          whichTab={whichTab}
        />
      </div>
      {/* large button header portion */}
      <div className="container max-sm:hidden -mt-20 max-sm:-mt-16 lg:-mt-[70px]">
        <div className="relative bg-white dark:bg-black dark:shadow-[#08070781] p-7 lg:p-7 rounded-3xl md:rounded-3xl shadow-xl flex">
          <ButtonHeader
            headerData={headerData}
            isCarBike={isCarBike}
            isDontKnow={isDontKnow}
            isNew={isNew}
            mainCardData={mainCardData}
            newTP={newTP}
            prevCompreh={prevCompreh}
            prevTP={prevTP}
            updateCombinedData={updateCombinedData}
            whichTab={whichTab}
          />
        </div>
      </div>
    </div>
  );
};

export default HeaderComponent;
