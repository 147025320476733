import { FC } from "react";
import { PostDataType } from "../../data/types";
import Link from "../Link";
import Image from "../Image";
import {
  Card16PodcastAbsoluteLink,
  Card16PodcastDownCard,
  Card16PodcastDownCardDesc,
  Card16PodcastDownCardDescSpan,
  Card16PodcastDownCardLink,
  Card16PodcastDownSection,
  Card16PodcastImgSection,
  Card16PodcastImgSpan,
  Card16PodcastMain,
  Card16PodcastMainContentSection,
  hfull,
} from "../styleComponents/cardPodcastStyles/card16PodcastStyle";
import { objectCover } from "../styleComponents/cardCategoryStyles/cardCategory2Style";

export interface Card16PodcastProps {
  className?: string;
  post: PostDataType;
  ratio?: string;
}

const Card16Podcast: FC<Card16PodcastProps> = ({
  className = { hfull },
  post,
  ratio = "aspect-w-3 xl:aspect-w-4 aspect-h-3",
}) => {
  const { title, href, desc, featuredImage } = post;

  return (
    <div className={`${Card16PodcastMain} ${className}`}>
      <Link href={href} className={`${Card16PodcastImgSection} ${ratio}`}>
        <Image
          fill
          alt=""
          sizes="(max-width: 600px) 480px, 800px"
          src={featuredImage}
          className={objectCover}
        />
        <span className={Card16PodcastImgSpan}></span>
      </Link>

      {/* ABSOLUTE */}
      <Link href={href} className={Card16PodcastAbsoluteLink}></Link>

      {/* MAIN CONTENT */}
      <div className={Card16PodcastMainContentSection}>
        <div className={Card16PodcastDownSection}>
          <h2 className={Card16PodcastDownCard}>
            <Link
              href={href}
              className={Card16PodcastDownCardLink}
              title={title}
            >
              {title}
            </Link>
          </h2>
          <span className={Card16PodcastDownCardDesc}>
            <span className={Card16PodcastDownCardDescSpan}>{desc}</span>
          </span>
        </div>
      </div>
    </div>
  );
};

export default Card16Podcast;
