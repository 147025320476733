import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../reducers/store/store";
import {
  setCurrentStep,
  setHasPrevPolicyExpired,
  setHasExpiredAfterDate,
  setHasMadePreviousClaim,
  setPrevCompreh,
  setPrevTP,
  setNewTP,
  setPremiumData,
  setResponseData,
} from "../../../reducers/slices/MotorSlice";
import { writeToDB } from "../../../utils/indexDBUtils";
import getMotorDataArray from "../../../data/Premium/MotorData/MotorData";
import {
  setId,
  setIsOpenPopup,
  triggerDbUpdate,
} from "../../../reducers/slices/commonPremiumSlice";
import { useNavigate } from "react-router-dom";
import CommonLastPageOfselection from "../../CommonDesignForSelection/CommonLastPageOfselection/CommonLastPageOfselection";
import useUserIdentifier from "../../../hooks/useCreateUniqueID";

const LastStepofSelection = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const motorDataArray = getMotorDataArray();
  const {
    selectedCompany,
    selectedModel,
    selectedFuel,
    selectedSubModel,
    selectedCC,
    sendingRto,
    selectedYear,
    selectedRegDate,
    selectedCode,
    userSelectedType,
    companyCode,
    modelCode,
    fuelCode,
    subModelCode,
    rtoCode,
    ccCode,
    vechicalCode,
    hasPrevPolicyExpired,
    hasExpiredAfterDate,
    hasMadePreviousClaim,
  } = useSelector((state: RootState) => state.motorDetails);
  const { whichTab } = useSelector(
    (state: RootState) => state.commonPremiumData
  );

  const handleHasExpired = (value: boolean) => {
    dispatch(setHasPrevPolicyExpired(value));
  };

  const handleExpiredAfterDate = (value: boolean | null) => {
    dispatch(setHasExpiredAfterDate(value));
  };

  const handleMadeClaim = (value: boolean | null) => {
    dispatch(setHasMadePreviousClaim(value));
  };

  const handleViewQuotesValid = async () => {
    const motorDetails = {
      selectedCompany: selectedCompany,
      selectedModel: selectedModel,
      selectedFuel: selectedFuel,
      selectedSubModel: selectedSubModel,
      selectedCC: selectedCC,
      sendingRto: sendingRto,
      selectedYear: selectedYear,
      selectedRegDate: selectedRegDate,
      selectedCode: selectedCode,
      userSelectedType: userSelectedType,
      companyCode: companyCode,
      modelCode: modelCode,
      fuelCode: fuelCode,
      subModelCode: subModelCode,
      rtoCode: rtoCode,
      ccCode: ccCode,
      vechicalCode: vechicalCode,
      hasPrevPolicyExpired: hasPrevPolicyExpired,
      hasExpiredAfterDate: hasExpiredAfterDate,
      hasMadePreviousClaim: hasMadePreviousClaim,
    };
    const specialdetail = {
      newTP: false,
      prevCompreh: false,
      prevTP: false,
    };
    const VerifyDetails = {
      correctedVariant: "",
      verified: false,
      companyName: "",
    };
    const id = await useUserIdentifier();
    await writeToDB("responseObjectForProposal", {});
    await writeToDB("uniqueUserId", id);
    await writeToDB("whichTab", whichTab);
    await writeToDB("motorTPdetails", specialdetail);
    await writeToDB("motorDetails", motorDetails);
    await writeToDB("PremiumData", motorDataArray);
    await writeToDB("motor-variant-corrected", VerifyDetails);
    await writeToDB("completed-steps", 0);
    await writeToDB("KYCStatus", false);
    await writeToDB("mainCardData-proposal", []);
    await writeToDB("motor-proposal-CommunicationAddress", {});
    await writeToDB("motor-proposal-FormState", {});
    await writeToDB("motor-proposal-NomineeDetails", {});
    await writeToDB("motor-proposal-OwnerDetails", {});
    await writeToDB("motor-proposal-PreviousInsurerData", {});
    await writeToDB("KycVerifiedData", {});
    await writeToDB("policyRef", "");
    await writeToDB("responseObjectFromProposal", {});
    await writeToDB("motor-proposal-VehicleDetailsData", {});
    await writeToDB("motor-proposal-kycDetailsData", {});
    await writeToDB("vehicle-documentID", "");

    // Trigger the update
    dispatch(setPremiumData({}));
    dispatch(setResponseData({}));
    dispatch(setId(id));
    dispatch(triggerDbUpdate());
    dispatch(setPrevCompreh(false));
    dispatch(setPrevTP(false));
    dispatch(setNewTP(false));
    dispatch(setIsOpenPopup(false));
    dispatch(setCurrentStep(null));

    if (whichTab.toLowerCase().includes("car")) {
      navigate("/car/quote");
    } else {
      navigate("/bike/quote");
    }
  };

  return (
    <div>
      <CommonLastPageOfselection
        labelLinkText={`Not your ${
          whichTab.toLowerCase().includes("car") ? "car?" : "bike?"
        }`}
        handleLabelLink={() => {
          dispatch(setCurrentStep(0));
        }}
        selectedMotor={`${selectedCompany} ${selectedModel} ${selectedSubModel}`}
        selectedFuel={selectedFuel}
        selectedRto={sendingRto}
        selectedYear={selectedYear}
        handleHasExpired={handleHasExpired}
        handleExpiredAfterDate={handleExpiredAfterDate}
        handleMadeClaim={handleMadeClaim}
        handleViewQuotesValid={handleViewQuotesValid}
        userSelectedType={userSelectedType}
      />
    </div>
  );
};

export default LastStepofSelection;
