import React, { useEffect, useState } from "react";
import Label from "../Label/Label";
import Input from "../Input/Input";
import ButtonPrimary from "../Button/ButtonPrimary";
import { carProposalPageData } from "../../data/Proposal/carProposal/carProposalData";
import Select from "../Select/Select";
import { readFromDB, writeToDB } from "../../utils/indexDBUtils";
import { useDispatch } from "react-redux";
import { updateOwnerDetails } from "../../reducers/slices/carBikeProposalSlice";
import Radio from "../Radio/Radio";
import VehicleVerification from "./VehicleVerification";
import {
  validateDateOfBirth,
  validateEmail,
  validateMobileNumber,
} from "../../utils/formValidations";
import { VariantDetails } from "./Layout";
import { verifyKycToIndexdb } from "./KYC";

interface OwnerDetailsProps {
  onSubmit: () => void; // Function to handle submission and proceed to next step
}

export const markStepCompleted = async (step: number) => {
  const completedSteps = (await readFromDB("completed-steps")) || [];
  if (completedSteps !== step) {
    await writeToDB("completed-steps", step);
  }
};

const OwnerDetails: React.FC<OwnerDetailsProps> = ({ onSubmit }) => {
  const dispatch = useDispatch();
  const [verifiedDetails, setVerifiedDetails] = useState<VariantDetails | null>(
    null
  );
  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);
  const [dobError, setDobError] = useState<string>("");
  const [mobileError, setMobileError] = useState<string>("");
  const [emailError, setEmailError] = useState<string>("");
  const [formError, setFormError] = useState<string>("");
  const [formData, setFormData] = useState({
    vehicleRegistered: false,
    salutation: "",
    firstName: "",
    lastName: "",
    gender: "",
    mobileNo: "",
    dateOfBirth: "",
    emailId: "",
    maritalStatus: "",
    occupation: "",
  });

  const [popupDelayDone, setPopupDelayDone] = useState(false); // For delaying popup display

  useEffect(() => {
    const fetchVariant = async () => {
      const variantData = await readFromDB("motor-variant-corrected");
      if (variantData) {
        setVerifiedDetails(variantData);
      } else {
        setTimeout(fetchVariant, 100); // Retry after 1 second if data is not available
      }
    };

    fetchVariant();
    window.scrollTo(0, 0);
  }, []);

  // Delay the popup opening after some time (e.g., 1 second)
  useEffect(() => {
    const delayPopup = setTimeout(() => {
      setPopupDelayDone(true);
    }, 100); // 1000 ms delay before allowing popup to show

    return () => clearTimeout(delayPopup); // Cleanup timeout on component unmount
  }, []);

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  // Only show the popup if delay is done and the variant is not verified
  useEffect(() => {
    if (
      popupDelayDone &&
      (verifiedDetails === null ||
        (verifiedDetails && !verifiedDetails.verified))
    ) {
      setIsPopupOpen(true);
    }
  }, [popupDelayDone, verifiedDetails]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    // Check if all required fields are filled
    const requiredFields = [
      "firstName",
      "lastName",
      "gender",
      "mobileNo",
      "dateOfBirth",
      "emailId",
    ];
    const allFieldsFilled = requiredFields.every(
      (field) => formData[field as keyof typeof formData] !== ""
    );

    if (
      formData.maritalStatus === "Married" &&
      formData.salutation === "Miss"
    ) {
      setFormError("Invalid salutation for married status.");
      return;
    }

    if (allFieldsFilled) {
      dispatch(updateOwnerDetails(formData));
      writeToDB("motor-proposal-OwnerDetails", formData);
      const updatedFormState = {
        ownerDetails: formData,
      };
      writeToDB("motor-proposal-FormState", updatedFormState);

      // Mark step as completed
      markStepCompleted(1); // assuming this is step 1

      onSubmit(); // Proceed to the next step provided by parent component
    } else {
      alert("Please fill all required fields.");
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const data = await readFromDB("motor-proposal-OwnerDetails");
      if (data && Object.keys(data).length > 0) {
        setFormData(data);
      } else {
        setFormData((prevFormData) => ({
          ...prevFormData,
          vehicleRegistered: false,
        }));
      }
    };
    fetchData();
  }, []);

  const handleChange = async(
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value, type } = event.target;

    if (type === "checkbox") {
      const checked = (event.target as HTMLInputElement).checked; // Cast event.target to HTMLInputElement
      const updatedFormData = {
        ...formData,
        [name]: checked,
      };
      setFormData(updatedFormData);
    } else {
      const updatedFormData = {
        ...formData,
        [name]: name === "emailId" ? value.toLowerCase() : value,
      };
      setFormData(updatedFormData);
    }

    // Validation based on field name
    switch (name) {
      case "dateOfBirth":
        const dobError = validateDateOfBirth(value);
        setDobError(dobError || ""); // Set dobError or clear if no error
        break;
      case "mobileNo":
        const mobileError = validateMobileNumber(value);
        setMobileError(mobileError || ""); // Set mobileError or clear if no error
        break;
      case "emailId":
        const emailError = validateEmail(value);
        setEmailError(emailError || ""); // Set emailError or clear if no error
        break;
      default:
        break;
    }
    await verifyKycToIndexdb(false);
  };
  console.log("formData ....", formData);

  const handleRadioChange = async(value: boolean) => {
    setFormData((prevState) => ({
      ...prevState,
      vehicleRegistered: value,
    }));
    await verifyKycToIndexdb(false);
  };

  // Determine if the Next button should be disabled
  const isNextDisabled =
    dobError !== "" ||
    mobileError !== "" ||
    Object.values(formData).some((value) => value === "");

  return (
    <div>
      <div className="">
        <form
          className="grid md:grid-cols-7 gap-6"
          onSubmit={handleSubmit}
          method="post"
        >
          <div className="block md:col-span-7">
            <Label className="block md:col-span-7">
              {carProposalPageData.OwnerDetailsData.label1}
            </Label>
            <span className="inline-flex items-center space-x-4 mt-3">
              <Radio
                name="vehicleRegistered"
                label="Yes"
                id="yes"
                checked={formData.vehicleRegistered === true}
                onChange={() => handleRadioChange(true)}
              />
              <Radio
                name="vehicleRegistered"
                label="No"
                id="no"
                checked={formData.vehicleRegistered === false}
                onChange={() => handleRadioChange(false)}
              />
            </span>
          </div>
          <div className="md:col-span-1">
            <Label>{carProposalPageData.OwnerDetailsData.label2}</Label>
            <Select
              className="mt-1"
              onChange={handleChange}
              name="salutation"
              value={formData.salutation}
              required
            >
              <option value="-1"></option>
              {carProposalPageData.OwnerDetailsData.Salutation.map(
                (title, index) => (
                  <option key={index} value={title}>
                    {title}
                  </option>
                )
              )}
            </Select>
          </div>
          <div className="md:col-span-3">
            <Label>{carProposalPageData.OwnerDetailsData.label3}</Label>
            <Input
              type="text"
              className="mt-1"
              name="firstName"
              onChange={handleChange}
              value={formData.firstName}
              required
            />
          </div>
          <div className="md:col-span-3">
            <Label>{carProposalPageData.OwnerDetailsData.label4}</Label>
            <Input
              type="text"
              className="mt-1"
              name="lastName"
              onChange={handleChange}
              value={formData.lastName}
              required
            />
          </div>
          <div className="md:col-span-1">
            <Label>{carProposalPageData.OwnerDetailsData.label7}</Label>
            <Select
              className="mt-1"
              name="gender"
              onChange={handleChange}
              value={formData.gender}
              required
            >
              <option value="-1"></option>
              {carProposalPageData.OwnerDetailsData.Gender.map(
                (gender, index) => (
                  <option key={index} value={gender}>
                    {gender}
                  </option>
                )
              )}
            </Select>
          </div>
          <div className="md:col-span-3">
            <Label>{carProposalPageData.OwnerDetailsData.label5}</Label>
            <Input
              type="text"
              className="mt-1"
              name="mobileNo"
              onChange={handleChange}
              value={formData.mobileNo}
              pattern="\d{0,10}"
              maxLength={10}
              required
            />
            {mobileError && (
              <p className="text-red-600 text-xs mt-1">{mobileError}</p>
            )}
          </div>
          <div className="md:col-span-3">
            <Label>{carProposalPageData.OwnerDetailsData.label6}</Label>
            <Input
              type="date"
              className="mt-1"
              name="dateOfBirth"
              onChange={handleChange}
              value={formData.dateOfBirth}
              required
            />
            {dobError && (
              <p className="text-red-600 mt-1 text-xs">{dobError}</p>
            )}
          </div>
          <div className="md:col-span-4">
            <Label>{carProposalPageData.OwnerDetailsData.label10}</Label>
            <Select
              className="mt-1"
              name="occupation"
              onChange={handleChange}
              value={formData.occupation}
              required
            >
              <option value="-1"></option>
              {carProposalPageData.OwnerDetailsData.Occupation.map(
                (occupation, index) => (
                  <option key={index} value={occupation}>
                    {occupation}
                  </option>
                )
              )}
            </Select>
          </div>
          <div className="md:col-span-3">
            <Label>{carProposalPageData.OwnerDetailsData.label9}</Label>
            <Select
              className="mt-1"
              name="maritalStatus"
              onChange={handleChange}
              value={formData.maritalStatus}
              required
            >
              <option value="-1"></option>
              {carProposalPageData.OwnerDetailsData.MaritalStatus.map(
                (maritalStatus, index) => (
                  <option key={index} value={maritalStatus}>
                    {maritalStatus}
                  </option>
                )
              )}
            </Select>
            {formError && (
              <p className="text-red-600 mt-1 text-xs md:col-span-7">
                {formError}
              </p>
            )}
          </div>
          <div className="md:col-span-7">
            <Label>{carProposalPageData.OwnerDetailsData.label8}</Label>
            <Input
              type="email"
              className="mt-1"
              name="emailId"
              onChange={handleChange}
              value={formData.emailId}
              required
            />
            {emailError && (
              <p className="text-red-600 mt-1 text-xs">{emailError}</p>
            )}
          </div>
          <ButtonPrimary
            type="submit"
            className="md:col-span-7"
            disabled={isNextDisabled}
          >
            {carProposalPageData.OwnerDetailsData.button1}
          </ButtonPrimary>
        </form>
      </div>
      {isPopupOpen && (
        <VehicleVerification
          isPopupOpen={isPopupOpen}
          closePopup={closePopup}
        />
      )}
    </div>
  );
};

export default OwnerDetails;
