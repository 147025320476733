export const calculateDates = (
  // selectedYear: string,
  initialDate2Str: string,
  initialDate3Str: string,
  adjustedDateStr: string,
  // whichTab: string
) => {
  let minDate2: Date | undefined;
  let maxDate2: Date | undefined;
  let minDate2Str: string | undefined;
  let maxDate2Str: string | undefined;
  let minDate3: Date | undefined;
  let maxDate3: Date | undefined;
  let minDate3Str: string | undefined;
  let maxDate3Str: string | undefined;
  let minDate4: Date | undefined;
  let maxDate4: Date | undefined;
  let minDate4Str: string | undefined;
  let maxDate4Str: string | undefined;
  let minDate5: Date | undefined;
  let maxDate5: Date | undefined;
  let minDate5Str: string | undefined;
  let maxDate5Str: string | undefined;
  let minDate6: Date | undefined;
  let maxDate6: Date | undefined;
  let minDate6Str: string | undefined;
  let maxDate6Str: string | undefined;
  let minDate7: Date | undefined;
  let maxDate7: Date | undefined;
  let minDate7Str: string | undefined;
  let maxDate7Str: string | undefined;

  if (initialDate2Str) {
    minDate2 = new Date(initialDate2Str);
    maxDate2 = new Date(minDate2);
    maxDate2.setDate(maxDate2.getDate() + 60); // Add 61 days to minDate
    minDate2Str = minDate2.toISOString().split("T")[0];
    maxDate2Str = maxDate2.toISOString().split("T")[0];
  }

  if (initialDate3Str) {
    const Date3 = new Date(initialDate3Str);
    maxDate3 = new Date(Date3);

    // Calculate minDate3 as one year before maxDate3
    minDate3 = new Date(
      maxDate3.getFullYear() - 1,
      maxDate3.getMonth(),
      maxDate3.getDate() + 1
    );
    // Convert to ISO strings
    minDate3Str = minDate3.toISOString().split("T")[0];
    maxDate3Str = maxDate3.toISOString().split("T")[0];
  }

  if (adjustedDateStr) {
    const Date4 = new Date(adjustedDateStr);
    minDate4 = new Date(Date4);
    maxDate4 = new Date(
      Date4.getFullYear(),
      Date4.getMonth(),
      Date4.getDate() + 7
    );
    minDate4Str = minDate4.toISOString().split("T")[0];
    maxDate4Str = maxDate4.toISOString().split("T")[0];
  }

  // Calculate minDate5 and maxDate5
  const currentDate = new Date();
  maxDate5 = new Date(currentDate);
  maxDate5.setDate(currentDate.getDate() - 1); // Current date - 1 day
  minDate5 = new Date(currentDate);
  minDate5.setDate(currentDate.getDate() - 89);
  minDate5Str = minDate5.toISOString().split("T")[0];
  maxDate5Str = maxDate5.toISOString().split("T")[0];

  const currentdate6 = new Date();
  minDate6 = new Date(
    currentdate6.getFullYear() - 1, // Subtract 1 year from current year
    currentdate6.getMonth(), // Use current month
    currentdate6.getDate() + 2 // Add 1 day to current date
  );
  maxDate6 = new Date(currentdate6); // Set maxDate6 to today's date
  maxDate6.setDate(currentdate6.getDate() - 90);
  minDate6Str = minDate6.toISOString().split("T")[0];
  maxDate6Str = maxDate6.toISOString().split("T")[0];

  const initialDate3 = new Date(initialDate3Str);
  // Set maxDate7 to match the year and month from initialDate3 but set the day to 1
  maxDate7 = new Date();
  maxDate7.setFullYear(initialDate3.getFullYear(), initialDate3.getMonth(), 1);
  minDate7 = new Date(maxDate7);
  minDate7.setFullYear(maxDate7.getFullYear() - 1);
  minDate7Str = minDate7.toISOString().split("T")[0];
  maxDate7Str = maxDate7.toISOString().split("T")[0];

  return {
    minDate2Str,
    maxDate2Str,
    minDate3Str,
    maxDate3Str,
    minDate4Str,
    maxDate4Str,
    minDate5Str,
    maxDate5Str,
    minDate6Str,
    maxDate6Str,
    minDate7Str,
    maxDate7Str,
  };
};
