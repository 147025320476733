import React, { useRef, useState } from "react";
import useClickOutside from "../../../utils/useClickOutside";
import { setNewTP } from "../../../reducers/slices/MotorSlice";
import { useDispatch } from "react-redux";
import { writeToDB } from "../../../utils/indexDBUtils";
import PremiumButton from "../../Button/PremiumButton";

interface ButtonProps {
  keys: any;
  updateCombinedData: (headerUpdates: object, sendingUpdates?: object) => void;
  prevCompreh: boolean;
  prevTP: boolean;
  previousNcb: string;
  currentNcb: string;
  button2Keys: any;
  button3Keys: any;
  loadingstate: boolean;
}

interface Option {
  name: string;
  about: string;
  badge: string;
  input: boolean;
  placeholder: string;
  range: boolean;
  dropdown: boolean;
  backendName: string;
  valueName: string;
}

interface SelectedOption {
  option: string;
  value: any;
}

const Button1: React.FC<ButtonProps> = ({
  keys,
  updateCombinedData,
  prevCompreh,
  prevTP,
  button2Keys,
  button3Keys,
  previousNcb,
  currentNcb,
  loadingstate,
}) => {
  const dispatch = useDispatch();
  const [isOpenDropdown, setIsOpenDropdown] = useState<boolean>(false);
  const popupRef = useRef<HTMLDivElement>(null);
  let isNewTP = false; // Initialize isNewTP to false by default

  const handleOptionSelect = async (value: string) => {
    
    let policyType = value;

    // Map value to specific strings
    if (value === "Own Damage") {
      policyType = "Owndamage";
      isNewTP = false;
    } else if (value === "Comprehensive") {
      policyType = "Comprehensive";
      isNewTP = false;
    } else if (value === "Third Party") {
      policyType = "Thirdparty";
      isNewTP = true; // Set isNewTP to true only when "Third Party" is selected
    }

    // Dispatch action based on isNewTP value
    dispatch(setNewTP(isNewTP));

    const updates = {
      button1: {
        ...keys,
        selectedLabel: value,
      },
    };
    const selectedOptions: SelectedOption[] = button3Keys.selectedOptions;
    const options: Option[] = button3Keys.options;

    console.log("Selected Options:", selectedOptions);
    console.log("Options:", options);

    const mappedOptions: (Option & { value: any })[] = selectedOptions.map(
      (selectedOption) => {
        const correspondingOption = options.find(
          (option) => option.name === selectedOption.option
        ) as Option;
        return {
          ...correspondingOption,
          value: selectedOption.value,
        };
      }
    );

    console.log("Mapped Options:", mappedOptions);

    const filteredOptions = mappedOptions.filter(
      (option) => option.name !== "Personal Accident Cover"
    );

    console.log("Filtered Options:", filteredOptions);
    const sendingPolicyType =
     isNewTP
      ? "Thirdparty"
      : "Bundled";

    const sendingData: Record<string, any> = {
      policyType: policyType,
      prevPolicyType
      : sendingPolicyType,
      idv: isNewTP ? "" : String(button2Keys.selectedRange),
      previousNcb: isNewTP ? "0%" : previousNcb,
      currentNcb: isNewTP ? "0%" : currentNcb,
    };


    filteredOptions.forEach((option) => {
      if (isNewTP) {
        sendingData[option.backendName] = false;
        sendingData[option.valueName] = "";
      } else {
        sendingData[option.backendName] = true;
        if (option.value !== null && option.valueName) {
          sendingData[option.valueName] = String(option.value);
        }
      }
    });

    console.log("Sending Data:", sendingData);

    const specialdetail = {
      newTP: isNewTP,
      prevCompreh: prevCompreh,
      prevTP: prevTP,
    };

    await writeToDB("motorTPdetails", specialdetail);
    updateCombinedData(updates, sendingData);
    setIsOpenDropdown(false);
  };

  const filteredOptions = prevTP
    ? ["Third Party"]
    : keys.options;

  useClickOutside(popupRef, isOpenDropdown, setIsOpenDropdown);

  return (
    <div className={`relative ${isOpenDropdown ? "z-50 dropdown-open" : ""}`}>
      <div>
        <div>
          <PremiumButton
            title={keys.title}
            label={keys.selectedLabel}
            onClick={() => setIsOpenDropdown(!isOpenDropdown)}
            disable={loadingstate}
          />
        </div>
        <div>
          {isOpenDropdown && (
            <div
              ref={popupRef}
              className={`absolute right-0 top-0 w-full py-1 mt-2.5 overflow-auto text-[14px] text-gray-900 dark:text-gray-200 bg-white rounded-b-xl shadow-lg shadow-gray-400/60 dark:shadow-zinc-900/20 max-h-60 border-2 border-t-0 border-primary-700/70 dark:border-blue-500/60 dark:bg-black z-50 transition-all duration-600 dropdown-content`}
            >
              {filteredOptions.map((option: any) => (
                <div
                  key={option} // Use option itself as key
                  className={`select-none relative py-2 sm:pl-10 sm:pr-4 max-sm:flex max-sm:text-xs w-full hover:text-primary-800 hover:dark:text-gray-200 max-sm:justify-center max-sm:items-center hover:bg-primary-50 hover:dark:bg-gray-800 cursor-pointer ${
                    keys.selectedLabel === option
                      ? "font-[600] bg-primary-50 dark:bg-zinc-900 text-gray-700 dark:text-gray-100"
                      : "font-normal"
                  }`}
                  onClick={() => handleOptionSelect(option)}
                >
                  <div className="block sm:truncate ">
                    <div className="max-sm:hidden text-primary-700 absolute inset-y-0 left-0 flex items-center pl-3 dark:text-blue-400">
                      {keys.selectedLabel === option && (
                        <span className="text-base font-semibold">
                          &#10003;
                        </span>
                      )}
                    </div>
                    <div>{option}</div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Button1;