import React, { useEffect, useState } from "react";
import PopLayout from "../Popup/PopLayout";
import { readFromDB } from "../../utils/indexDBUtils";
import ButtonPrimary from "../Button/ButtonPrimary";
import RoyalSundaramPayment from "../Payment/RoyalSundaramPayment";
import ShriramPayment from "../Payment/ShriramPayment";
import { ReliancePayment } from "../Payment/ReliancePayment";
import { BajajPayment } from "../Payment/BajajPayment";
import { GodigitPayment } from "../Payment/GodigitPayment";
import NationalPayment from "../Payment/NationalPayment";
import { useSelector } from "react-redux";
import { RootState } from "../../reducers/store/store";
import { avatarUrls } from "../../data/authors";
import PersonalDetails from "./ReviewProposalDetails/PersonalDetails";
import VehicleDetails from "./ReviewProposalDetails/VehicleDetails";

interface ReviewProposalProps {
  closePopup: () => void;
}

const ReviewProposal: React.FC<ReviewProposalProps> = ({ closePopup }) => {
  const [ownerData, setOwnerData] = useState<any>(null);
  const [nomineeData, setNomineeData] = useState<any>(null);
  const [vehicleDetailsData, setVehicleDetailsData] = useState<any>(null);
  const [previousInsurerData, setPreviousInsurerData] = useState<any>(null);
  const [kycDetailsData, setKycDetailsData] = useState<any>(null);
  const [selectedCompany, setSelectedCompany] = useState("");
  const [kycData, setKycData] = useState<any>(null);
  const [totalPremium, setTotalPremium] = useState(0);

  const kycdata = useSelector((state: RootState) => state.data.kycData);
  const proposalResponseData = useSelector(
    (state: RootState) => state.data.proposalData
  );
  const { proposalData, userSelectedType } = useSelector(
    (state: RootState) => state.motorDetails
  );
  const { whichTab } = useSelector(
    (state: RootState) => state.commonPremiumData
  );

  useEffect(() => {
    const fetchTotalPremiumData = async () => {
      const totalPremiumdata = await readFromDB("responseObjectFromProposal");
      setTotalPremium(totalPremiumdata?.responseData?.TotalPremium || 0);
    };

    fetchTotalPremiumData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const data = await readFromDB("KycVerifiedData");
      setKycData(data || kycdata);

      const ownerDatas = await readFromDB("motor-proposal-OwnerDetails");
      if (ownerDatas) {
        setOwnerData(ownerDatas);
      }

      const nomineeDatas = await readFromDB("motor-proposal-NomineeDetails");
      if (nomineeDatas) {
        setNomineeData(nomineeDatas);
      }

      const vehicleDetailsDatas = await readFromDB(
        "motor-proposal-VehicleDetailsData"
      );
      if (vehicleDetailsDatas) {
        setVehicleDetailsData(vehicleDetailsDatas);
      }

      const previousInsurerDatas = await readFromDB(
        "motor-proposal-PreviousInsurerData"
      );
      if (previousInsurerDatas) {
        setPreviousInsurerData(previousInsurerDatas);
      }

      const kycDetailsDatas = await readFromDB("motor-proposal-kycDetailsData");
      if (kycDetailsDatas) {
        setKycDetailsData(kycDetailsDatas);
      }
    };

    setSelectedCompany(proposalData.responseData.CompanyName);
    fetchData();
  }, [proposalData.responseData.CompanyName]);

  const handleSubmit = () => {
    switch (selectedCompany) {
      case "Reliance General":
        ReliancePayment(proposalResponseData, kycData, kycDetailsData);
        break;
      case "Bajaj Allianz":
        BajajPayment(proposalResponseData);
        break;
      case "Go Digit":
        GodigitPayment();
        break;
      default:
        console.log("Unknown company name");
        break;
    }
  };

  const [activeTab, setActiveTab] = useState("personal");
  const [imageUrl, setImageUrl] = useState<string>("");
  const responseData = proposalData?.responseData || {};
  

  useEffect(() => {
    const imgUrl =
      avatarUrls.find((avatar) => avatar.id === `${responseData.CompanyName}`)
        ?.url || "";
    setImageUrl(imgUrl);
  }, [responseData.CompanyName, avatarUrls]);

  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    window.addEventListener("resize", handleResize);
    // Cleanup listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const logoMapping: Record<string, string> = {
    "Bajaj Allianz": "https://i.postimg.cc/MZNzT2zs/Bajaj.jpg",
    GoDigit: "https://i.postimg.cc/PqXjcdMq/GoDigit.jpg",
    "Iffco Tokio": "https://i.postimg.cc/9Mzjm1K5/Iffco-Toko.jpg",
    National: "https://i.postimg.cc/J0kVJ0Rp/National.jpg",
    Reliance: "https://i.postimg.cc/CxN1dPPz/Reliance.jpg",
    "Royal Sundaram": "https://i.postimg.cc/Pq5qDW9K/Royal.jpg",
    Shriram: "https://i.postimg.cc/nh0Fnsrm/Shriram.jpg",
  };

  return (
    <PopLayout
      isOpen={true}
      onClose={closePopup}
      maxWidth="max-w-screen-lg"
      title="Review Important Details"
    >
      <div className="mx-auto">
        <div className="flex flex-row items-center justify-between p-5 max-w-3xl mx-auto">
         {/* Image */}
         <div className={isMobile ? "hidden" : ""}>
            {totalPremium !== 0 ? (
              <img src={imageUrl} alt="Insurance" className="w-16 h-auto" />
            ) : null}
          </div>

          {/* Total Premium and Payment Button */}
          <div className="flex w-full justify-between items-center">
            <div
              className={`text-center whitespace-nowrap overflow-hidden text-ellipsis ${
                isMobile ? "" : "ml-9"
              }`}
            >
              {totalPremium === 0 ? (
                // Display the logo when totalPremium is 0
                <img
                  src={logoMapping[selectedCompany] || "defaultLogoURL"}
                  alt={selectedCompany}
                  className="w-32 h-auto"
                />
              ) : (
                // Display the total amount details when totalPremium is greater than 0
                <div>
                  <h2>Total Amount</h2>
                  <p className="font-semibold">&#8377; {totalPremium}</p>
                  <h2 className="text-[10px] mt-[-5px]">(inclusive of GST)</h2>
                </div>
              )}
            </div>

            {/* Payment Button */}
            <div className="flex justify-center">
              {selectedCompany === "Royal Sundaram" ? (
                <RoyalSundaramPayment proposalData={proposalResponseData} />
              ) : selectedCompany === "Shriram General Insurance" ? (
                <ShriramPayment
                  proposalData={proposalResponseData}
                  whichTab={whichTab}
                />
              ) : selectedCompany === "National Insurance" && ownerData ? (
                <NationalPayment proposalData={proposalResponseData} />
              ) : (
                <div className=" w-full flex justify-center text-center">
                  <ButtonPrimary
                    className="max-sm:w-[7rem] sm:w-[14rem] md:w-[14rem]"
                    onClick={handleSubmit}
                  >
                    Pay Now
                  </ButtonPrimary>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="bg-white dark:bg-gray-800 p-6 rounded-lg order-1 md:order-2 mt-[-10px]">
          {/* Tabs Navigation */}
          <div className="flex mb-4">
            <button
              onClick={() => setActiveTab("personal")}
              className={`w-1/2 py-4 px-4 text-center border-t border-l border-r ${
                activeTab === "personal"
                  ? "border-gray-200 text-indigo-600 dark:text-white rounded-tl-lg rounded-tr-none max-sm:text-[12px]" // Top-left corner only
                  : "border-transparent text-gray-600 dark:text-gray-400 max-sm:text-[12px]"
              }`}
            >
              Personal Details
            </button>
            <button
              onClick={() => setActiveTab("vehicle")}
              className={`w-1/2 py-4 px-4 text-center border-t border-l border-r ${
                activeTab === "vehicle"
                  ? "border-gray-200 text-indigo-600 dark:text-white rounded-tl-lg rounded-tr-none max-sm:text-[12px]" // Top-right corner only
                  : "border-transparent text-gray-600 dark:text-gray-400 max-sm:text-[12px]"
              }`}
            >
              Vehicle Details
            </button>
          </div>

          <div
            className={`h-[277px] overflow-y-auto border custom-scrollbar border-gray-200 rounded-md rounded-tl-none mt-[-17px] pt-5 pb-5 ${
              activeTab === "personal" ? "border-gray-200" : "border-gray-200"
            }`}
          >
            {activeTab === "personal" && ownerData && (
              <div>
                <PersonalDetails
                  ownerData={ownerData}
                  kycDetailsData={kycDetailsData}
                  nomineeData={nomineeData}
                />
              </div>
            )}

            {activeTab === "vehicle" && vehicleDetailsData && (
              <div>
                <VehicleDetails
                  vehicleDetailsData={vehicleDetailsData}
                  previousInsurerData={previousInsurerData}
                  userSelectedType={userSelectedType}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </PopLayout>
  );
};

export default ReviewProposal;