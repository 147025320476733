import twFocusClass from "../../utils/twFocusClass";
import { ButtonHTMLAttributes, FC } from "react";
import { prevNextBtnIcon, prevBtnMain, prevNextBtnProp } from "../styleComponents/nextPrevStyle";

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {}

const PrevBtn: FC<Props> = ({ className ={prevNextBtnProp} , ...args }) => {
  return (
    <button
      className={`${prevBtnMain} ${className} ${twFocusClass()}`}
      {...args}
    >
      <span className={prevNextBtnIcon}>

      &#10094;
      </span>
    </button>
  );
};

export default PrevBtn;
