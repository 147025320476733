import React from "react";
import ProposalOuterLayout from "../ProposalOuterLayout";
// import Layout from "../Layout";
import ButtonPrimary from "../../Button/ButtonPrimary";
import { useNavigate } from "react-router-dom";
import Heading from "../../Heading/Heading";

const LifeStyleDetails: React.FC = () => {
  const navigate = useNavigate();

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    navigate("/proposal/kyc");
  };
  return (
    <div>
      <ProposalOuterLayout>
        {/* <Layout> */}
        <span className={`lg:hidden md:block  `}>
        <Heading desc="">
          LifeStyle Details
          </Heading>
          </span>
          <form
            className="grid md:grid-cols-2 gap-6"
            onSubmit={handleSubmit}
            // action="/proposal/kyc"
            method="post"
          >
            <ButtonPrimary className="md:col-span-2">Next</ButtonPrimary>
          </form>
        {/* </Layout> */}
      </ProposalOuterLayout>
    </div>
  );
};

export default LifeStyleDetails;
