import React, { useEffect } from "react";
import ProposalOuterLayout from "../ProposalOuterLayout";
// import Layout from "../Layout";
import { useNavigate } from "react-router-dom";
import ButtonPrimary from "../../Button/ButtonPrimary";
import Heading from "../../Heading/Heading";
import axios from "axios";
// import Label from "../../Label/Label";
// import Input from "../../Input/Input";
// import Checkbox from "../../Checkbox/Checkbox";
// import Select from "../../Select/Select";

const MedicalDetails: React.FC = () => {
  const navigate = useNavigate();

  // const [diseases, setDiseases] = useState<any>([]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    navigate("/proposal/lifestyle-details");
  };

  useEffect(() => {
    console.log("hello fetch");

    const handleFetchData = async () => {
      console.log("hello fetch 2");
      // const company = "bajaj_disease";
      const company = "go digit";
      const response = await axios.post("https://core.insurancepolicy4us.com/api/dataFetch", {
        company,
      });

      console.log("response", response.data);
      // setDiseases(response.data);
    };
    handleFetchData();
  }, []);

  // const renderInputBasedOnType = (question: any) => {
  //   switch (question.QUESTION_TYPE) {
  //     case "Checkbox":
  //       return (
  //         <label className="block md:col-span-2">
  //           <Checkbox label={question.QUESTION} name={question.QUESTION} />
  //         </label>
  //       );
  //     case "Radio":
  //       return (
  //         <div className="md:col-span-2">
  //           <Label>{question.QUESTION}</Label>
  //           <div className="flex items-center">
  //             <input
  //               type="radio"
  //               id="yes"
  //               name={question.QUESTION}
  //               className="mr-2"
  //             />
  //             <label htmlFor="yes">Yes</label>
  //             <input
  //               type="radio"
  //               id="no"
  //               name={question.QUESTION}
  //               className="ml-4 mr-2"
  //             />
  //             <label htmlFor="no">No</label>
  //           </div>
  //         </div>
  //       );
  //     case "Dropdown":
  //       return (
  //         <>
  //           <Label>{question.QUESTION}</Label>
  //           <Select className="mt-1">
  //             <option value="">Select an option</option>
  //             {Array.isArray(question.ANSWER) &&
  //               question.ANSWER.map((item: string, index: number) => (
  //                 <option key={index} value={item}>
  //                   {item}
  //                 </option>
  //               ))}
  //           </Select>
  //         </>
  //       );
  //     case "Label":
  //       return (
  //         <>
  //           <Label>{question.QUESTION}</Label>
  //         </>
  //       );
  //     case "Text":
  //     default:
  //       return (
  //         <label className="block md:col-span-2">
  //           <Label>{question.QUESTION}</Label>
  //           <Input type="text" className="mt-1" />
  //         </label>
  //       );
  //   }
  // };

  return (
    <div>
      <ProposalOuterLayout>
        {/* <Layout> */}
          {/* <button onClick={handleFetchData}>data</button> */}

          <span className={`lg:hidden md:block  `}>
            <Heading desc="">Medical Details</Heading>
          </span>
          <form
            className="grid md:grid-cols-2 gap-6"
            onSubmit={handleSubmit}
            method="post"
          >
            {/* {diseases.map((disease, index) => (
              <div key={index} className="md:col-span-2 ml-4">
                {renderInputBasedOnType({
                  QUESTION: disease.MAIN_QUESTION,
                  QUESTION_TYPE: disease.MAIN_QUESTION_TYPE,
                  ANSWER: disease.MAIN_ANSWER,
                })}
                {Array.isArray(disease.SUB_QUESTIONS) &&
                  disease.SUB_QUESTIONS.map((subQuestion, subIndex) => (
                    <div key={subIndex} className="ml-4 mt-2">
                      {renderInputBasedOnType({
                        QUESTION: subQuestion.SUB_QUESTION,
                        QUESTION_TYPE: subQuestion.SUB_QUESTION_TYPE,
                        ANSWER: subQuestion.SUB_ANSWER,
                      })}
                    </div>
                  ))}
              </div>
            ))} */}

            <ButtonPrimary className="md:col-span-2">Next</ButtonPrimary>
          </form>
        {/* </Layout> */}
      </ProposalOuterLayout>
    </div>
  );
};

export default MedicalDetails;
