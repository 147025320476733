import { useSelector } from 'react-redux';
import { RootState } from '../../../reducers/store/store';


const getMotorDataArray = () => {
  const  {
    selectedCompany,
    selectedModel,
    selectedFuel,
    selectedSubModel,
    sendingRto,
    selectedYear,
    selectedRegDate,
    selectedCode,
    userSelectedType,
    rtoCode,
    vechicalCode,
    hasPrevPolicyExpired,
    hasExpiredAfterDate,
    hasMadePreviousClaim,
  } = useSelector((state: RootState) => state.motorDetails);
  const {whichTab} = useSelector((state: RootState) => state.commonPremiumData);
  const newDate = new Date().toISOString().split("T")[0];
  const initialDate2 = new Date();
  const initialDate2Str = initialDate2.toISOString().split("T")[0];
  const previousDate = new Date(initialDate2);
  previousDate.setDate(initialDate2.getDate() - 1);
  const previousDateString = previousDate.toISOString().split("T")[0];
  const ExpiryDate = userSelectedType === "New" ? "" : !hasPrevPolicyExpired ? newDate : hasExpiredAfterDate ? previousDateString : "Expired, More Than 90 Days"
  const newYear = new Date().getFullYear().toString();
  const currentYear = new Date().getFullYear();
  const policySartDate =  new Date().toISOString().split("T")[0];
  const nextDate = new Date(Date.now() + 24 * 60 * 60 * 1000).toISOString().split("T")[0];
  const yearFromKeys = selectedYear !== "" ? parseInt(selectedYear, 10) : currentYear - 1;
  const initialDate1 = new Date(selectedRegDate);
  // Convert to ISO string and split to get date
  const initialDate1Str = initialDate1.toISOString().split("T")[0];
  const currentDate = new Date();
  const Date6 = new Date(currentDate);
  Date6.setDate(currentDate.getDate() - 90);
  const Date6Str = Date6.toISOString().split("T")[0];
  const prevExpDate = userSelectedType === "New" ? "" : !hasPrevPolicyExpired ? initialDate2Str : hasExpiredAfterDate ? previousDateString : Date6Str
  const PolicyType = userSelectedType === "New" ? "Bundled" : "Comprehensive";
  const initialDate3 = new Date();
    let increment = 3;
    if (whichTab.toLowerCase().includes("bike")) {
      increment = 5; 
    }
  const adjustedYear = yearFromKeys + increment;
  initialDate3.setFullYear(adjustedYear, initialDate1.getMonth(), initialDate1.getDate());
  const initialDate3Str = initialDate3.toISOString().split("T")[0];
  const Date7 = new Date();
  Date7.setFullYear(adjustedYear, initialDate1.getMonth(), 1);
  const Date7Str = Date7.toISOString().split("T")[0];
  const tpexpiry = userSelectedType === "New" ? "" : !hasPrevPolicyExpired ? initialDate3Str : (!hasExpiredAfterDate || hasExpiredAfterDate ) &&  Date7Str
  const PrevPolicyType = userSelectedType === "New" ? "" : "Bundled";
  const newNcb = userSelectedType === "New" ? "0%" : hasPrevPolicyExpired ? hasExpiredAfterDate ? hasMadePreviousClaim ? "0%" : "20%" : "0%" : hasMadePreviousClaim ? "0%" : "20%"
  const yearifNot = userSelectedType === "New" ? newYear : (currentYear - 1).toString();
  const NewPolicyStartDate = userSelectedType === "New" ? policySartDate : nextDate;
  const RegNumber = `${selectedCode}-AB-1234`
  const selectedInfoString = `${selectedCompany}, ${selectedModel} ${selectedSubModel}`; 
  const dateOfPurchase = userSelectedType === "New" ? new Date(Date.now() - 24 * 60 * 60 * 1000).toISOString().split("T")[0] : new Date(initialDate1.getTime() - 24 * 60 * 60 * 1000).toISOString().split("T")[0];
  const text = whichTab.toLowerCase().includes("bike") ? "1yr Own Damage + 5yr Third Party" : "1yr Own Damage + 3yr Third Party";
    
  const options = whichTab.toLowerCase().includes("car") ? 
  [
    {
      name: "Zero Depreciation Cover",
      about: "Zero Depreciation Cover",
      badge: "",
      input: false,
      placeholder: "",
      range: false,
      dropdown: false,
      backendName: "nilDepCover",
      valueName: ""
    },
    {
      name: "Consumables",
      about: "Regular consumables like nuts, bolts, oil and others are not paid for at the time of claims. This add-on offer coverage for consumables, damaged enough to be reused in the future. The damages are caused due to accidents and collision. vehicles not more than 3 years of age are eligible for this top-up.",
      input: false,
      range: false,
      placeholder: "",
      dropdown: false,
      badge: "",
      backendName: "consumableCover",
      valueName: ""
    },
    {
      name: "Automobile Membership",
      about: "Automobile Membership Cover is like a safety plan for your vehical. You pay a bit each month (premium) to a company. If your vehical gets hurt (damaged) in an accident, the company helps pay to fix it. This protects you from a big bill!",
      input: false,
      range: false,
      placeholder: "",
      dropdown: false,
      badge: "",
      backendName: "automobileAssMembershipCover",
      valueName: ""
    },
    {
      name: "Driver Cover",
      about: "The add-on is an insurance for a paid-up driver. In circumstances of unfortunate death and permanent total disability, caused due to an accident, insurers are liable to pay all bills.",
      input: false,
      range: true,
      placeholder: "Selected Range",
      dropdown: false,
      badge: "",
      backendName: "paidDriverCover",
      valueName: "valueofPaidDriverCover"
    },
    {
      name: "Personal Accident Cover",
      about: "Personal Accident cover covers the owner in case of death or disability due to an accident. It is mandatory as per Govt to have a PA cover. Add the PAcover in case you don't have it.",
      input: false,
      range: false,
      placeholder: "",
      dropdown: false,
      badge: "Mandatory",
      backendName: "paCover",
      valueName: ""
    },
    {
      name: "Key Replacement",
      about: "Key Replacement covers the cost of substituting ignition keys. Partial amounts of the claim is settled.",
      input: false,
      range: false,
      placeholder: "",
      dropdown: false,
      badge: "",
      backendName: "key&lockReplacementCover",
      valueName: ""
    },
    {
      name: "Invoice Cover",
      about: "It is a protective add-on that makes up for the gap in between the insured declared value and the on-road price of the vehicle. Reiumbursements are available in cases of total loss.",
      input: false,
      range: false,
      placeholder: "",
      dropdown: false,
      badge: "",
      backendName: "invoiceCover",
      valueName: ""
    },
    {
      name: "Engine Protection Cover",
      about: "Comprehensive insurance policy do not cover damages incurred in a vehicles engine or its parts like the gearbox, pistons, crankshaft, pins and others. This add-on ensure maximum financial protection by offering cover for the repair of damaged engines and its parts.",
      input: false,
      range: false,
      placeholder: "",
      dropdown: false,
      badge: "",
      backendName: "engineProtectorCover",
      valueName: ""
    },
    {
      name: "NCB Protection",
      about: "Go claim-free for years and enjoy discounts as big as 50% in premium. The 'No Claim Bonus' add-on guarantees discounts, calculated on the IDV of the vehicle even after a claim has been processed. Terms and conditions are applicable.",
      input: false,
      range: false,
      placeholder: "",
      dropdown: false,
      badge: "",
      backendName: "ncbProtectionCover",
      valueName: ""
    },
    {
      name: "Tyre Cover",
      about: "This add-on makes it certain that an insurer is liable to indemnify all expenses related to the replacement of tyres with a new or a near equivalent tyre. A traditional comprehensive insurance plan partly covers for damages in tyres. Model, make and other details are not compromised with.",
      input: false,
      range: false,
      placeholder: "",
      dropdown: false,
      badge: "",
      backendName: "tyreCover",
      valueName: ""
    },
    {
      name: "Loss Of Personal Belongings",
      about: "With this add-on, the insurer compensates the policyholder for the loss of personal belongings such as the articles or other items of a personal nature which are likely to be used, carried or worn",
      input: false,
      range: false,
      placeholder: "",
      dropdown: false,
      badge: "",
      backendName: "lossOfPersonalBelongings",
      valueName: ""
    },
    {
      name: "Electrical Accessories",
      about: "Electrical Accessories",
      input: true,
      range: false,
      placeholder: "Enter Electrical Value",
      dropdown: false,
      badge: "",
      backendName: "elecAccCover",
      valueName: "valueofElecAccCover"
    },
    {
      name: "Non Electrical Accessories",
      about: "Non Electrical Accessories",
      input: true,
      range: false,
      placeholder: "Enter Non Electric Value",
      dropdown: false,
      badge: "",
      backendName: "nonElecAccCover",
      valueName: "valueofNonElecAccCover"
    },
    {
      name: "Passenger Cover",
      about: "The cover is a personal accident insurance for the passengers in a vehicle. Lumpsum amounts of compensation are paid out in circumstances of permanent disability and death.",
      input: false,
      range: true,
      placeholder: "Selected Range",
      dropdown: false,
      badge: "",
      backendName: "paCoverForUnnamedPassenger",
      valueName: "valueofPaCoverForUnnamedPassenger"
    },
    {
      name: "Voluntary Deductible",
      about: "You will pay a cheaper premium. If you take a claim, only then you will have to pay a minimum of Rs. 2500 otherwise you keep enjoying the discount benefit.",
      input: false,
      range: false,
      placeholder: "Select your value",
      dropdown: true,
      badge: "",
      backendName: "voluntaryDeductable",
      valueName: "valueofVoluntaryDeductable"
    }
  ] : [{
    name: "Zero Depreciation Cover",
    about: "Zero Depreciation Cover",
    badge: "",
    input: false,
    placeholder: "",
    range: false,
    dropdown: false,
    backendName: "nilDepCover",
    valueName: ""
  }, {
    name: "Personal Accident Cover",
    about: "Personal Accident cover covers the owner in case of death or disability due to an accident. It is mandatory as per Govt to have a PA cover. Add the PAcover in case you don't have it.",
    input: false,
    range: false,
    placeholder: "",
    dropdown: false,
    badge: "Mandatory",
    backendName: "paCover",
    valueName: ""
  },
  {
    name: "Invoice Cover",
    about: "It is a protective add-on that makes up for the gap in between the insured declared value and the on-road price of the vehicle. Reiumbursements are available in cases of total loss.",
    input: false,
    range: false,
    placeholder: "",
    dropdown: false,
    badge: "",
    backendName: "invoiceCover",
    valueName: ""
  },
  {
    name: "Engine Protection Cover",
    about: "Comprehensive insurance policy do not cover damages incurred in a vehicles engine or its parts like the gearbox, pistons, crankshaft, pins and others. This add-on ensure maximum financial protection by offering cover for the repair of damaged engines and its parts.",
    input: false,
    range: false,
    placeholder: "",
    dropdown: false,
    badge: "",
    backendName: "engineProtectorCover",
    valueName: ""
  },]


  const BackToSelectionLink = whichTab.toLowerCase().includes("car") ? "Not your car?" : "Not your bike?";
  const vehicalLabel = whichTab.toLowerCase().includes("car") ? "Your Car" : "Your Bike"
 

  // Construct and return the array
  return [
    {
      headerData: {
        userSelectedType: userSelectedType,
        hasPrevExpired:hasPrevPolicyExpired,
        hasExpiredAfterDate:hasExpiredAfterDate,
        hasPrevClaim:hasMadePreviousClaim,
        badge1: vehicalLabel,
        badge2: "Prev Policy Details",
        editIcon1: {
          selectedYear: selectedYear,
          selectedRegDate:selectedRegDate,
          title: "Edit Policy Details",
          label1: "Registration Date",
          label2: "Previous Policy Type",
          label3: "Expiry Date of Prev OD policy",
          label4: "Expiry Date of Active TP policy",
          label5: "Claim in Previous Policy",
          label6: "Previous NCB",
          label7: "Manufacturing Year",
          label8: "When do you want your policy to start?",
          label1Value: "",
          label2Value: "Bundled",
          label3Value: "",
          label4Value: "",
          label5Value: hasMadePreviousClaim,
          label6Value: "0%",
          label8Value: "",
          label9Value: "20%"
        },
        link1: BackToSelectionLink,
        title1: selectedInfoString,
        label1: "Fuel",
        label2: "Reg Year",
        label3: "Reg RTO",
        label4: "Exp Date",
        label5: "Claim",
        label6: "Prev Ncb",
        label7: "New Ncb",
        label8: "Manufacturing year",
        label9: "New Policy Start Date",
        labelValue1: selectedFuel,
        labelValue2: selectedYear ? selectedYear : yearifNot,
        labelValue3: selectedCode,
        labelValue4: ExpiryDate,
        labelValue5: hasMadePreviousClaim,
        labelValue6: "0%",
        labelValue7: "20%",
        labelValue8: newYear,
        labelValue9: policySartDate,
        subHeading: "Prices Exclusive of GST",
        button1: {
          title: "Policy Type",
          selectedLabel: "Comprehensive",
          options: [
            "Own Damage",
            "Comprehensive",
            "Third Party"
          ]
        },
        button2: {
          title: "Cover Value",
          label: "IDV",
          heading: "Drag to select your IDV",
          sublabel: "Selected Value",
          selectedRange: 0,
          minValue: null,
          maxValue: null,
          buttonLabel: "Update",
          describtionHeading: "What is IDV?",
          describtion: "IDV (Insured Declared Value) is the maximum payout from your insurer for total vehicle damage or theft."
        },
        button3: {
          title: "Select Covers",
          label: "Add-Ons",
          heading: "Add-ons Cover",
          selectedOptions: [
            {option: 'Personal Accident Cover', value: null},],
          options: options
        },
        button4: {
          title: "Policy Type",
          selectedLabel: "Bundled", //for nc type
          heading: "Comprehensive for 1st Year",
          describtion: text,
        },
     
      },
    updatedValues:{
          P4US_ID:vechicalCode, // from master
          registrationDate:userSelectedType === "New" ? newDate : initialDate1Str,
          dateOfPurchase: dateOfPurchase,
          prevPolicyExpired:hasPrevPolicyExpired !== null ? hasPrevPolicyExpired ? "Yes" : "No" : "No",
          prevPolicyExpiredAfterDates:hasExpiredAfterDate !== null ? hasExpiredAfterDate  ? "Yes" : "No" : "No",
          previousNcb: "0%",
          currentNcb: newNcb,
          prevPolicyClaim: hasMadePreviousClaim !== null ? hasMadePreviousClaim?  "Yes" : "No" : "No",
          idv:"0", // initial ""
          policyType: PolicyType,
          prevPolicyEndDate: prevExpDate,
          tpExpiryDate:tpexpiry,
          prevPolicyType: PrevPolicyType,
          policyStartDate: NewPolicyStartDate,
          registrationNo: RegNumber, //selected from master 
          P4US_RTO_ID: rtoCode,
          rtoName: sendingRto, // from master kl-64 chalakudy
          elecAccCover:false,
          valueofElecAccCover:"0",
          nonElecAccCover:false,
          valueofNonElecAccCover:"0",
          voluntaryDeductable:false,
          valueofVoluntaryDeductable:"0",
          paCover:true,
          paidDriverCover:false,
          valueofPaidDriverCover:"0",
          paCoverForNamedPassenger:false,
          paCoverForUnnamedPassenger:false,
          valueofPaCoverForUnnamedPassenger:"0",
          tyreCover:false,
          nilDepCover:false,
          invoiceCover:false,
          engineProtectorCover:false,
          ncbProtectionCover:false,
          consumableCover:false,
          keyAndLockReplacementCover:false,
          automobileAssMembershipCover:false,
          lossOfPersonalBelongings:false,

    },
    }
  ]
};

export default getMotorDataArray;