
import ButtonPrimary from "../components/Button/ButtonPrimary";

const WorkInProgress = () => {


  return (
    <div className="nc-WorkInProgress">
      <div className="container relative py-16 lg:py-20 flex flex-col lg:flex-row gap-2">
        <div className="flex flex-col items-start justify-center px-4 w-full lg:w-1/2">
          <header className="text-center max-w-2xl mx-auto space-y-6">
            <h2></h2>
            <div className=" flex justify-center items-center w-full">
              <p className="text-8xl md:text-9xl max-sm:text-7xl font-semibold tracking-widest">
                O
              </p>
              <p className="text-6xl max-sm:text-6xl animate-spin">⚙</p>
              <p className="text-8xl md:text-9xl max-sm:text-7xl font-semibold tracking-widest">
                {" "}
                ps!
              </p>
            </div>

            <span className="block text-sm text-gray-800 sm:text-base dark:text-gray-200 tracking-wider font-medium">
              {`THE PAGE YOU WERE LOOKING FOR IS UNDER CONSTRUCTION.`}
            </span>
            <ButtonPrimary href="/" className="mt-4">
              Return Home Page
            </ButtonPrimary>
          </header>
        </div>

        {/* Image with responsive sizing */}
        <img
          src="https://i.postimg.cc/pTKpzDxk/website-under-construction.png" // Replace with appropriate image
          alt="Under Construction"
          className="w-full h-auto object-cover lg:w-1/2 lg:h-auto lg:max-w-[70%] lg:max-h-[80vh]" // Responsive sizing for large screens
        />
      </div>
    </div>
  );
};

export default WorkInProgress;
