// formValidations.ts

//Date of Birth Validation
export const validateDateOfBirth = (dobString: string): string | undefined => {
  const selectedDate = new Date(dobString);
  const eighteenYearsAgo = new Date();
  eighteenYearsAgo.setFullYear(eighteenYearsAgo.getFullYear() - 18);

  // Check if the selected date is more recent than 18 years ago
  if (selectedDate > eighteenYearsAgo) {
    return "The date of birth must indicate an age of at least 18 years.";
  }

  // Check if the age is above 100 years old
  const hundredYearsAgo = new Date();
  hundredYearsAgo.setFullYear(hundredYearsAgo.getFullYear() - 100);

  if (selectedDate < hundredYearsAgo) {
    return "The date of birth indicates an age above 100 years, please verify.";
  }

  // Return undefined if date is valid (no error)
  return undefined;
};

//Mobile Number Validation
export const validateMobileNumber = (mobileNumber: string): string | undefined => {
    const mobileNumberPattern = /^[0-9]*$/;
  
    // Check if mobile number contains only numbers
    if (!mobileNumberPattern.test(mobileNumber)) {
      return "Mobile number must contain only numbers.";
    }
  
    // Check if mobile number exceeds 10 digits
    if (mobileNumber.length !== 10 ) {
      return "Mobile number cannot exceed 10 digits.";
    }
  
    // Return undefined if mobile number is valid (no error)
    return undefined;
  };

//EmailID Validations
export const validateEmail = (email: string): string | undefined => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  
    if (!emailPattern.test(email)) {
      return "Invalid email address.";
    }
  
    return undefined;
  };

export const validateNomineeName = (value: string): string | undefined => {
    if (!value.trim()) {
      return "Nominee name is required";
    }
    // Additional custom validations if needed
    return undefined; // No error
  };
  
export const validateNomineeAge = (value: string, dob: string, relation: string, ownerAge: any): string | undefined => {
    const age = Number(value);
    if (!value.trim()) {
      return "Nominee age is required";
    } else if (isNaN(age) || age <= 0) {
      return "Invalid age";
    } else if (age < 18 || age > 100) {
      return "Nominee age must be between 18 and 100";
    }

    if (dob.trim()) {
        const calculatedAge = getAgeFromDob(dob);
        if (calculatedAge !== age) {
          return `Nominee age must match the date of birth`;
        }
      }
    
      if (relation === "Son" || relation === "Daughter") {
        if (ownerAge - age < 18) {
          return "Nominee age should be 18 years or more younger than the owner's age";
        }
      } else if (relation === "Father" || relation === "Mother") {
        if (age - ownerAge < 18) {
          return "Nominee age should be 18 years or more older than the owner's age";
        }
      }

    return undefined; // No error
  };
  
export const validateNomineeDob = (value: string, age: string): string | undefined => {
    if (!value.trim()) {
      return "Nominee date of birth is required";
    }
  
    const calculatedAge = getAgeFromDob(value);
    if (calculatedAge < 18 || calculatedAge > 100) {
      return "Nominee age must be between 18 and 100 based on date of birth";
    }
  
    if (age.trim() && Number(age) !== calculatedAge) {
      return `Nominee date of birth must match the age: ${calculatedAge}`;
    }
  
    return undefined; // No error
  };
  
export const getAgeFromDob = (dob: string): number => {
    const birthDate = new Date(dob);
    const ageDifMs = Date.now() - birthDate.getTime();
    const ageDate = new Date(ageDifMs);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  };
  
export const validateNomineeRelationship = (value: string): string | undefined => {
    if (!value.trim()) {
      return "Nominee relationship is required";
    }
    // Additional custom validations if needed
    return undefined; // No error
  };
  
export const validateAddress = (value: string): string | undefined => {
    if (!value.trim()) {
      return "Address is required";
    } else if (value.length < 3) {
      return "Address must be at least 3 characters long";
    }
    return undefined; // No error
  };

export const validateEngineChassisNumber = (value: string): string | undefined => {
    if (value.length < 7) {
      return "Number must be at least 7 characters long";
    } else if (value.length > 17) {
      return "Number cannot exceed 17 characters";
    }
    return undefined; // No error
  };

export const validatePincode = (value: string): string | undefined => {
    const pincodePattern = /^\d{6}$/;
    if (!pincodePattern.test(value)) {
      return "That doesn't look like a valid Pincode. Try again?";
    }
    return undefined; // No error
  };

export const validatePolicyNumber = (value: string): string | undefined => {
    if (value.length < 8 || value.length > 30) {
      return "Policy number must be between 8 and 30 characters.";
    }
    return undefined;
  };
  // formValidations.ts

// export const validateRegistrationNumber = (registrationNo: string, selectedCode: string): string | null => {
//   // Define regex patterns for different parts
//   const rtoNumberPattern = /^\d{2}$/;
//   const lettersPattern = /^[A-Z]+$/;
//   const numberPattern = /^\d{1,4}$/;

//   // Split the registration number into parts
//   const parts = registrationNo.split('-');
  
//   if (parts.length !== 4) {
//     return "Registration number must be in the format 'XX-YY-AB-1234'";
//   }

//   const [stateCode, rtoNumber, letters, number] = parts;

//   // Extract state code and RTO number from selectedCode
//   const [selectedStateCode, selectedRTO] = selectedCode.split('-');

//   // Validate Part 1: Two-letter State Code
//   if (stateCode !== selectedStateCode) {
//     return `Invalid state code for Registration number. You have selected ${selectedCode}`;
//   }

//   // Validate Part 2: RTO Number
//   if (!rtoNumberPattern.test(rtoNumber) || rtoNumber !== selectedRTO) {
//     return `Invalid RTO number for Registration number. You have selected ${selectedCode}`;
//   }

//   // Validate Part 3: Letters
//   if (!lettersPattern.test(letters)) {
//     return "Invalid letters format for Registration number";
//   }

//   // Validate Part 4: Number
//   if (!numberPattern.test(number) || parseInt(number) < 1 || parseInt(number) > 9999) {
//     return "Invalid number for Registration number. Must be between 1 and 9999";
//   }

//   return null;
// };

export const validateRegistrationNumber = (registrationNo: string, selectedCode: string): string | undefined => {
  // Clean and uppercase the registration number and selected code
  const cleanedRegistrationNo = registrationNo.toUpperCase();
  const cleanedSelectedCode = selectedCode.replace(/-/g, '').toUpperCase();

  // Define the regex pattern for Indian vehicle registration numbers
  const registrationPattern = /^[A-Z]{2}[0-9]{2}[A-Z]{1,2}[0-9]{1,4}$/;

  // Check if registration number starts with the selected code
  if (!cleanedRegistrationNo.startsWith(cleanedSelectedCode)) {
    return `Invalid registration number. The first part must start with ${cleanedSelectedCode}`;
  }

  // Validate the registration number format
  if (!registrationPattern.test(cleanedRegistrationNo)) {
    return `Invalid registration number format. The format should be like ${cleanedSelectedCode}XX1234.`;
  }

  // If all checks pass, return undefined (no error)
  return undefined;
};


