import React, { useEffect, useState } from "react";
import { readFromDB, writeToDB } from "../../utils/indexDBUtils";
import { useSelector } from "react-redux";
import { RootState } from "../../reducers/store/store";
import { useNavigate } from "react-router-dom";
import { fetchSSEData } from "../Premium/PremiumFunctions/fetchSSEData";
import { useDispatch } from "react-redux";
import ButtonPrimary from "../Button/ButtonPrimary";


interface ProposalData {
  [key: string]: any;
}

interface PaymetData {
  [key: string]: any;
}

const NationalPayment: React.FC<ProposalData> = ({proposalData}) => {

  const [start, setStart] = useState<string>("");
  const [apiResponse, setApiResponse] =useState<
  PaymetData[]
>([]);
const isLoading = useSelector(
  (state: RootState) => state.commonPremiumData.loadingStates["NIPayment"] || false
);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const id = useSelector((state: RootState) => state.commonPremiumData.id);
  const [details, setDetails] = useState<any>({});

  useEffect(() => {
    const fetchData = async() => {
      const ownerDetails = await readFromDB("motor-proposal-OwnerDetails")
      setDetails(ownerDetails)
    }
    fetchData()
  },[])

  useEffect(() => {
    if (apiResponse.length>0) {
      setStart("stop");
      console.log("fjghggfg");
    }
  }, [apiResponse,isLoading]);

  useEffect(() => {
    console.log("ffffnnnnn");
    
    if(start==="stop"){
      if (apiResponse.length > 0) {
        const validProposal = apiResponse.some((data) => {
          const hasPaymentBase64 =
            data.PaymentBase64 &&
            data.PaymentBase64 !== undefined &&
            data.PaymentBase64 !== null &&
            data.PaymentBase64 !== "" &&
            typeof data.PaymentBase64 !== "object";
  
          const hasPolicyNumber =
            data.PolicyNo &&
            data.PolicyNo !== undefined &&
            data.PolicyNo !== null &&
            data.PolicyNo !== "" &&
            typeof data.PolicyNo !== "object";
  
        
          // Check the logic for validity
          if (hasPaymentBase64 && hasPolicyNumber) {
            return true; // TotalPremium is present and valid
          } 
          return false; // None of the conditions are met
        });
  
        if (validProposal) {
          (async () => {
            try {
              const base64Data = apiResponse[0].PaymentBase64;
          const byteCharacters = atob(base64Data);
          const byteNumbers = new Array(byteCharacters.length).fill(0).map((_, i) => byteCharacters.charCodeAt(i));
          const byteArray = new Uint8Array(byteNumbers);
          const blob = new Blob([byteArray], { type: "application/pdf" });
          const downloadLink = document.createElement("a");
          downloadLink.href = URL.createObjectURL(blob);
          downloadLink.download = `Policy_${apiResponse[0].PolicyNo}.pdf`;
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
  
          // Save PolicyRef to IndexedDB
          await writeToDB("policyRef", apiResponse[0].PolicyNo);
          navigate("/payment")
            } catch (error) {
              console.error("Error saving KYC data to IndexedDB:", error);
            }
          })();
             
        } else {
          navigate("/payment");
        }
      } else {
        navigate("/payment");
      }
    }
   
  }, [apiResponse, start]);

  const handleSubmit = async () => {
      // Call API with proposal response data and owner names
      console.log("Proposal Response Data:", proposalData);

      const apiData = {
        quoteId:proposalData[0].QuoteID,
        customerId: proposalData[0].CustomerID,
        totalPremium: proposalData[0].TotalPremium,
        fullName: `${details.firstName} ${details.lastName}`,
      };

      const url = `https://aim-core.insurancepolicy4us.com/dummy/id=${id}/payment/nationalinsurance`;
      const uniqueKey = "NIPayment"

      const token = `Bearer ${import.meta.env.VITE_API_TOKEN}` as string;

      // Fetch SSE Data
      fetchSSEData(
        apiData,
        url,
        token,
        uniqueKey,
        setApiResponse,
        dispatch
      );
      setStart("start");
  };
  return (
    <div className=" w-full flex justify-center text-center">
    <ButtonPrimary
      className="max-sm:w-[7rem] sm:w-[14rem] md:w-[14rem]"
       onClick={handleSubmit}>{isLoading ? (
                <span className="flex items-center">
                  <svg
                    className="animate-spin h-5 w-5 mr-3"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A8.004 8.004 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                </span>
              ) : (
                "Pay Now"
              )}</ButtonPrimary>
  </div>
  )
}

export default NationalPayment
