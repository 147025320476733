import React from "react";
import DateInput from "./InputProps/DateInputProps";
import ReadOnlyDate from "./InputProps/ReadOnlyDate";

interface DateInputComponentProps {
  label: string;
  value: string;
  min?: string;
  max?: string;
  onChange?: (value: string) => void;
  readonly?: boolean;
}

const DateInputComponent: React.FC<DateInputComponentProps> = ({
  label,
  value,
  min,
  max,
  onChange,
  readonly,
}) => (
  <div className="flex max-sm:flex-col w-full justify-between gap-4">
    <div className="text-[14px] font-[400] text-gray-900 dark:text-gray-200 flex justify-center items-center">
      {label}
    </div>
    <div className="flex w-full sm:w-1/2 items-center justify-center">
      {!readonly && min && max  && onChange ?(
        <DateInput
          value={value}
          min={min }
          max={max}
          onChange={onChange}
          readonly={readonly}
        />
      ) :(
        <ReadOnlyDate value={value} />
      ) }
    </div>
  </div>
);

export default DateInputComponent;
