import React from "react";

interface LinkProps {
  link: string;
  handleClick: () => void;
  className?: string;
  disabled?: boolean;  // Optional disabled prop
}

const LinkLayout: React.FC<LinkProps> = ({ link, handleClick, className, disabled }) => {
  // Disable the click handler if disabled
  const handleLinkClick = () => {
    if (!disabled) {
      handleClick();
    }
  };

  return (
    <div onClick={handleLinkClick}>
      <p
        className={`${
          disabled
            ? 'text-gray-400 cursor-not-allowed'  // Styles when disabled
            : 'text-blue-500 hover:text-blue-800 dark:text-blue-300 dark:hover:text-blue-500 cursor-pointer'
        } ${className} text-[12.5px] underline`}
      >
        {link}
      </p>
    </div>
  );
};

export default LinkLayout;
