import React from "react";

interface DropdownInputProps {
  value: string;
  options: string[];
  onChange: (value: string) => void;
}

const DropdownInput: React.FC<DropdownInputProps> = ({
  value,
  options,
  onChange,
}) => {
  return (
    <select
      value={value}
      onChange={(e) => onChange(e.target.value)}
      className=" w-3/4 sm:w-full cursor-pointer text-[14px] text-gray-700 placeholder-gray-400 dark:text-white dark:placeholder-gray-100 border border-gray-400 rounded-md dark:bg-zinc-900  dark:border-zinc-800  focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200 transition duration-200 bg-gray-50"
    >
      {options.map((option) => (
        <option key={option} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
};

export default DropdownInput;
