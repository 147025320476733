import { FC, Fragment, ReactNode, useState } from "react";
import { Combobox, Dialog, Transition } from "@headlessui/react";
import { useNavigate } from "react-router-dom";
import { DEMO_CATEGORIES } from "../../data/taxonomies";
import {
  SearchBox,
  SearchComboBox,
  SearchIcon,
  SearchNoResult,
  SearchNoResultDesc,
  SearchResultSection,
  SearchResultSectionIcon,
  cursorPointer,
  relative,
  searchModalButton,
  searchModalDialog,
  searchModalDiv,
  searchModalPanel,
  searchModalTransChild,
} from "../styleComponents/headerStyles/searchModalStyle";
import { useDispatch } from "react-redux";
import {
  setIsOpenPopup,
  setWhichTab,
} from "../../reducers/slices/commonPremiumSlice";

const categories = DEMO_CATEGORIES.filter((_, i) => i < 9);

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

interface Props {
  renderTrigger?: () => ReactNode;
}

const SearchModal: FC<Props> = ({ renderTrigger }) => {
  const [open, setOpen] = useState(false);
  const [rawQuery, setRawQuery] = useState("#");

  const router = useNavigate();
  const dispatch = useDispatch();

  const handleClick = async (name: string) => {
    dispatch(setWhichTab(name));
    dispatch(setIsOpenPopup(true));
  };

  const query = rawQuery.toLowerCase().replace(/^[#>]/, "");

  const filteredProjects =
    rawQuery === ""
      ? categories
      : categories.filter((project) =>
          project.name.toLowerCase().includes(query)
        );

  return (
    <>
      <div onClick={() => setOpen(true)} className={cursorPointer}>
        {renderTrigger ? (
          renderTrigger()
        ) : (
          <button className={searchModalButton}>
            <svg
              width={22}
              height={22}
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.5 21C16.7467 21 21 16.7467 21 11.5C21 6.25329 16.7467 2 11.5 2C6.25329 2 2 6.25329 2 11.5C2 16.7467 6.25329 21 11.5 21Z"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M22 22L20 20"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
        )}
      </div>

      <Transition.Root
        show={open}
        as={Fragment}
        afterLeave={() => setRawQuery("#")}
        appear
      >
        <Dialog
          as="div"
          className={searchModalDialog}
          onClose={() => setOpen(false)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className={searchModalTransChild} />
          </Transition.Child>

          <div className={searchModalDiv}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-100"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-100"
            >
              <Dialog.Panel
                className={searchModalPanel}
                as="form"
                onSubmit={(e) => {
                  e.preventDefault();
                  router("/");
                  setOpen(false);
                }}
              >
                <Combobox
                  onChange={(item: any) => {
                    if (item.href === "") {
                      router("/");
                      handleClick(item.name);
                    } else {
                      router(item.href);
                    }
                    setOpen(false);
                  }}
                  name="searchpallet"
                >
                  <div className={relative}>
                    
                    <span  className={SearchIcon}>
                      <svg
                        width="20px"
                        height="18px"
                        viewBox="0 0 1024 1024"
                        className="icon"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill="#999"
                          d="M795.904 750.72l124.992 124.928a32 32 0 01-45.248 45.248L750.656 795.904a416 416 0 1145.248-45.248zM480 832a352 352 0 100-704 352 352 0 000 704z"
                        />
                      </svg>
                    </span>
                    <Combobox.Input
                      className={SearchBox}
                      placeholder="Search..."
                      onChange={(event) => setRawQuery(event.target.value)}
                    />
                  </div>

                  {filteredProjects.length > 0 && (
                    <Combobox.Options static className={SearchComboBox}>
                      {filteredProjects.length > 0 && (
                        <li>
                          <h2 className="text-xs font-semibold text-gray-900">
                            Insurances
                          </h2>
                          <ul className="-mx-4 mt-2 text-sm text-gray-700">
                            {filteredProjects.map((project) => (
                              <Combobox.Option
                                key={project.id}
                                value={project}
                                className={({ active }) =>
                                  classNames(
                                    "flex select-none items-center px-4 py-2",
                                    active && "bg-indigo-600 text-white"
                                  )
                                }
                              >
                                <span className="ml-3 flex-auto truncate">
                                  {project.name} Insurance
                                </span>
                              </Combobox.Option>
                            ))}
                          </ul>
                        </li>
                      )}
                    </Combobox.Options>
                  )}

                  {query !== "" && filteredProjects.length === 0 && (
                    <div className={SearchResultSection}>
                      <div className={SearchResultSectionIcon}>
                        <svg
                          version="1.1"
                          id="Layer_1"
                          xmlns="http://www.w3.org/2000/svg"
                          x="0px"
                          y="0px"
                          width="20px"
                          height="20px"
                          viewBox="0 0 35 32"
                          enable-background="new 0 0 35 32"
                        >
                          <g>
                            <path
                              fill="#828282"
                              d="M21.799,2.954C20.694,1.05,19.19,0,17.564,0c-1.626,0-3.131,1.05-4.236,2.954L1.059,24.087
                              c-1.12,1.947-1.24,3.969-0.33,5.546c0.866,1.5,2.565,2.363,4.664,2.367h24.341c0,0,0,0,0.001,0
                              c2.102-0.004,3.804-0.864,4.667-2.361c0.905-1.567,0.783-3.581-0.335-5.525L21.799,2.954z M33.537,29.139
                              c-0.681,1.18-2.067,1.858-3.804,1.861H5.394c-1.731-0.003-3.115-0.684-3.799-1.867c-0.727-1.26-0.606-2.917,0.33-4.546
                              L14.193,3.456C15.112,1.872,16.309,1,17.564,1c1.255,0,2.452,0.872,3.37,2.456l12.268,21.157
                              C34.137,26.239,34.259,27.889,33.537,29.139z"
                            />
                            <path
                              fill="#828282"
                              d="M17.564,20c0.276,0,0.5-0.224,0.5-0.5v-10c0-0.276-0.224-0.5-0.5-0.5s-0.5,0.224-0.5,0.5v10
                              C17.064,19.776,17.288,20,17.564,20z"
                            />
                            <path
                              fill="#828282"
                              d="M17.5,22.5c-1.103,0-2,0.897-2,2s0.897,2,2,2s2-0.897,2-2S18.603,22.5,17.5,22.5z M17.5,25.5
                              c-0.551,0-1-0.448-1-1s0.449-1,1-1s1,0.448,1,1S18.051,25.5,17.5,25.5z"
                            />
                          </g>
                        </svg>
                      </div>
                      <p className={SearchNoResult}>No results found</p>
                      <p className={SearchNoResultDesc}>
                        We couldn’t find anything with that term. Please try
                        again.
                      </p>
                    </div>
                  )}
                </Combobox>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default SearchModal;
