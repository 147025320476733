import React, { useEffect, useState, useRef } from "react";
// import Heading from "../Heading/Heading";
import ButtonPrimary from "../Button/ButtonPrimary";
import { carProposalPageData } from "../../data/Proposal/carProposal/carProposalData";
import Label from "../Label/Label";
import Input from "../Input/Input";
import { useDispatch, useSelector } from "react-redux";
import { updateVehicleDetails } from "../../reducers/slices/carBikeProposalSlice";
import { readFromDB, writeToDB } from "../../utils/indexDBUtils";
import {
  validateAddress,
  validateEngineChassisNumber,
  validatePincode,
  validateRegistrationNumber,
} from "../../utils/formValidations";
import { RootState } from "../../reducers/store/store";
import { markStepCompleted } from "./OwnerDetails";
import { verifyKycToIndexdb } from "./KYC";

interface VehicleDetailsProps {
  onSubmit: () => void; // Function to handle form submission and navigation
}

const VehicleDetails: React.FC<VehicleDetailsProps> = ({ onSubmit }) => {
  const dispatch = useDispatch();
  const { selectedCode, proposalData, userSelectedType } = useSelector(
    (state: RootState) => state.motorDetails
  );

  const [formData, setFormData] = useState({
    registrationNo: "",
    registrationDate: "",
    chassisNo: "",
    engineNo: "",
    regAddress1: "",
    regAddress2: "",
    regAddress3: "",
    regAddress4: "",
    regPincode: "",
  });
  const [formErrors, setFormErrors] = useState<{ [key: string]: string }>({});
  const [isLoading, setIsLoading] = useState(false);

  // Create refs for each input field
  const registrationNoRef = useRef<HTMLInputElement>(null);
  const registrationDateRef = useRef<HTMLInputElement>(null);
  const chassisNoRef = useRef<HTMLInputElement>(null);
  const engineNoRef = useRef<HTMLInputElement>(null);
  const regAddress1Ref = useRef<HTMLInputElement>(null);
  const regAddress2Ref = useRef<HTMLInputElement>(null);
  const regAddress3Ref = useRef<HTMLInputElement>(null);
  const regAddress4Ref = useRef<HTMLInputElement>(null);
  const regPincodeRef = useRef<HTMLInputElement>(null);

  const refs = {
    registrationNo: registrationNoRef,
    registrationDate: registrationDateRef,
    chassisNo: chassisNoRef,
    engineNo: engineNoRef,
    regAddress1: regAddress1Ref,
    regAddress2: regAddress2Ref,
    regAddress3: regAddress3Ref,
    regAddress4: regAddress4Ref,
    regPincode: regPincodeRef,
  };

  useEffect(() => {
    console.log("regi",proposalData.premiumData.registrationDate);
    
          const Datee = proposalData.premiumData.registrationDate;
          setFormData((prevFormData) => ({
            ...prevFormData,
            registrationDate: Datee,
          }));

  }, [proposalData]);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const finalFormData = {
      ...formData,
      registrationDate: formData.registrationDate || proposalData.premiumData.registrationDate || "", // Ensure it's included
    };

    // Validate all required fields
    const requiredFields = [
      "registrationNo",
      "chassisNo",
      "engineNo",
      "regAddress1",
      "regAddress2",
      "regAddress3",
      "regAddress4",
      "regPincode",
    ];

    if (userSelectedType === 'New' && proposalData.responseData.CompanyName !== "National Insurance") {
      const registrationNoIndex = requiredFields.indexOf('registrationNo');
      if (registrationNoIndex !== -1) {
        requiredFields.splice(registrationNoIndex, 1);
      }
    }

    const newFormErrors: { [key: string]: string } = {};

    requiredFields.forEach((field) => {
      if (!finalFormData[field as keyof typeof finalFormData]) {
        newFormErrors[field] = "This field is required";
      }
    });

    // Validate engine and chassis numbers
    const engineError = validateEngineChassisNumber(finalFormData.engineNo);
    const chassisError = validateEngineChassisNumber(finalFormData.chassisNo);
    const pincodeError = validatePincode(finalFormData.regPincode);
    const address1Error = validateAddress(finalFormData.regAddress1);
    const address2Error = validateAddress(finalFormData.regAddress2);
    const address3Error = validateAddress(finalFormData.regAddress3);
    const address4Error = validateAddress(finalFormData.regAddress4);
    let registrationError = undefined;
    if (finalFormData.registrationNo) {
      registrationError = validateRegistrationNumber(
        finalFormData.registrationNo,
        selectedCode
      );
    }

    if (engineError) newFormErrors.engineNo = engineError;
    if (chassisError) newFormErrors.chassisNo = chassisError;
    if (pincodeError) newFormErrors.regPincode = pincodeError;
    if (address1Error) newFormErrors.regAddress1 = address1Error;
    if (address2Error) newFormErrors.regAddress2 = address2Error;
    if (address3Error) newFormErrors.regAddress3 = address3Error;
    if (address4Error) newFormErrors.regAddress4 = address4Error;
    if (registrationError) newFormErrors.registrationNo = registrationError;

    // Update formErrors state
    setFormErrors(newFormErrors);

    // Check if there are any validation errors
    if (Object.keys(newFormErrors).length > 0) {
      console.log("Form validation errors:", newFormErrors);

      // Focus on the first input field with an error
      const firstErrorField = Object.keys(newFormErrors)[0] as keyof typeof refs;
      refs[firstErrorField]?.current?.focus();

      return;
    }

    setIsLoading(true);
    try {
      // Update Redux store and IndexedDB
      dispatch(updateVehicleDetails(finalFormData));
      console.log("form", finalFormData);
      
      await writeToDB("motor-proposal-VehicleDetailsData", finalFormData);

      // Read existing FormState data
      const existingFormState = await readFromDB("motor-proposal-FormState");

      // Merge vehicleDetails with existing FormState data
      let updatedFormState = {
        ...existingFormState,
        vehicleDetails: finalFormData,
      };
      
      // Check if userSelectedType is 'New' and add previousInsurer if necessary
      if (userSelectedType === "New") {
        updatedFormState = {
          ...updatedFormState,
          previousInsurer: {
            prevInsurer: "",
            prevPolicyNo: "",
            prevInsurerAddress: "",
            tpInsurer: "",
            tpPolicyNumber: "",
            isItUnderLoan: "No",
            loanProvider: "",
            loanProviderAddress: "",
          },
        };
      }
      // Write updated FormState data back to IndexedDB
      await writeToDB("motor-proposal-FormState", updatedFormState);
      markStepCompleted(4);
      // Call parent component's onSubmit function to navigate to the next step
      onSubmit();
    } catch (error) {
      console.error("Error saving vehicle details:", error);
      alert("An error occurred while saving your details. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await readFromDB("motor-proposal-VehicleDetailsData");
        if (data) {
          setFormData(data);
        }
      } catch (error) {
        console.error("Error fetching vehicle details:", error);
      }
    };

    window.scrollTo(0, 0);
    fetchData();
  }, []);


  const handleChange = async(
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = event.target;
    let updatedValue = value;
    if (name === "registrationNo") {
      updatedValue = value.replace(/[^a-zA-Z0-9]/g, ""); // Remove non-alphanumeric characters
    }
  
    // For registrationNo, engineNo, and chassisNo, convert to uppercase
    if (name === "registrationNo" || name === "engineNo" || name === "chassisNo") {
      updatedValue = updatedValue.toUpperCase();
    }
  
    setFormData({
      ...formData,
      [name]: updatedValue,
    });
    await verifyKycToIndexdb(false);

  };

  return (
    <div>
      <form
        className="grid md:grid-cols-2 gap-6"
        onSubmit={handleSubmit}
        method="post"
      >
        <label className="block">
        {userSelectedType === 'New' && proposalData.responseData.CompanyName !== "National Insurance"
    ?  `${carProposalPageData.VehicleDetails.label1} (If available)`
    : carProposalPageData.VehicleDetails.label1}
          <Input
            ref={registrationNoRef}
            type="text"
           className="mt-1"
            onChange={handleChange}
            name="registrationNo"
            value={formData.registrationNo || ""}
            required={ userSelectedType !== 'New' || 
              (userSelectedType === 'New' && proposalData.responseData.CompanyName === "National Insurance")} 
          />
          {formErrors.registrationNo && (
            <p className="text-red-600 text-xs mt-1">
              {formErrors.registrationNo}
            </p>
          )}
        </label>
       <label className="block">
          <Label>{carProposalPageData.VehicleDetails.label2}</Label>
          <Input
            ref={registrationDateRef}
            type="date"
            className="mt-1"
            name="registrationDate"
            value={formData.registrationDate||proposalData.premiumData.registrationDate}
            style={{ textTransform: "uppercase" }}
            readOnly
          />
        </label>
        <label className="block">
          <Label>{carProposalPageData.VehicleDetails.label3}</Label>
          <Input
            ref={chassisNoRef}
            type="text"
            className="mt-1"
            onChange={handleChange}
            name="chassisNo"
            value={formData.chassisNo || ""}
            required
          />
          {formErrors.chassisNo && (
            <p className="text-red-600 text-xs mt-1">
              {formErrors.chassisNo}
            </p>
          )}
        </label>
        <label className="block">
          <Label>{carProposalPageData.VehicleDetails.label4}</Label>
          <Input
            ref={engineNoRef}
            type="text"
            className="mt-1"
            onChange={handleChange}
            name="engineNo"
            value={formData.engineNo || ""}
            required
          />
          {formErrors.engineNo && (
            <p className="text-red-600 text-xs mt-1">
              {formErrors.engineNo}
            </p>
          )}
        </label>
        <label className="block">
          <Label>{carProposalPageData.VehicleDetails.label5}</Label>
          <Input
            ref={regAddress1Ref}
            type="text"
            className="mt-1"
            onChange={handleChange}
            name="regAddress1"
            value={formData.regAddress1 || ""}
            required
          />
          {formErrors.regAddress1 && (
            <p className="text-red-600 text-xs mt-1">
              {formErrors.regAddress1}
            </p>
          )}
        </label>
        <label className="block">
          <Label>{carProposalPageData.VehicleDetails.label6}</Label>
          <Input
            ref={regAddress2Ref}
            type="text"
            className="mt-1"
            onChange={handleChange}
            name="regAddress2"
            value={formData.regAddress2 || ""}
            required
          />
          {formErrors.regAddress2 && (
            <p className="text-red-600 text-xs mt-1">
              {formErrors.regAddress2}
            </p>
          )}
        </label>
        <label className="block">
          <Label>{carProposalPageData.VehicleDetails.label7}</Label>
          <Input
            ref={regAddress3Ref}
            type="text"
            className="mt-1"
            onChange={handleChange}
            name="regAddress3"
            value={formData.regAddress3|| ""}
            required
          />
          {formErrors.regAddress3 && (
            <p className="text-red-600 text-xs mt-1">
              {formErrors.regAddress3}
            </p>
          )}
        </label>
        <label className="block">
          <Label>{carProposalPageData.VehicleDetails.label8}</Label>
          <Input
            ref={regAddress4Ref}
            type="text"
            className="mt-1"
            onChange={handleChange}
            name="regAddress4"
            value={formData.regAddress4 || ""}
            required
          />
          {formErrors.regAddress4 && (
            <p className="text-red-600 text-xs mt-1">
              {formErrors.regAddress4}
            </p>
          )}
        </label>
        <label className="block">
          <Label>{carProposalPageData.VehicleDetails.label9}</Label>
          <Input
            ref={regPincodeRef}
            type="text"
            className="mt-1"
            onChange={handleChange}
            name="regPincode"
            value={formData.regPincode || ""}
            required
          />
          {formErrors.regPincode && (
            <p className="text-red-600 text-xs mt-1">
              {formErrors.regPincode}
            </p>
          )}
        </label>
        <div className="md:col-span-2">
          <ButtonPrimary
            type="submit"
            className="mt-2 w-full"
            disabled={isLoading}
          >
            {isLoading ? "Submitting..." : "Next"}
          </ButtonPrimary>
        </div>
      </form>
    </div>
  );
};

export default VehicleDetails;
