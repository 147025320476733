import { Dispatch } from "redux";
import { readFromDB } from "./indexDBUtils";
import { 
  setSelectedCompany, setSelectedModel, setSelectedFuel, setSelectedSubModel, setSelectedCC, 
  setSendingRto, setSelectedYear,setSelectedRegDate, setSelectedCode, setUserSelectedType, setCompanyCode, 
  setModelCode, setFuelCode, setSubModelCode, setRtoCode, setCcCode, setVechicalCode, 
  setHasPrevPolicyExpired, setHasExpiredAfterDate, setHasMadePreviousClaim 
} from "../reducers/slices/MotorSlice"; 

export const fetchMotorIndexData = async (dispatch: Dispatch) => {
  const data = await readFromDB("motorDetails");


  if (data) {
    dispatch(setSelectedCompany(data.selectedCompany));
    dispatch(setSelectedModel(data.selectedModel));
    dispatch(setSelectedFuel(data.selectedFuel));
    dispatch(setSelectedSubModel(data.selectedSubModel));
    dispatch(setSelectedCC(data.selectedCC));
    dispatch(setSendingRto(data.sendingRto));
    dispatch(setSelectedYear(data.selectedYear));
    dispatch(setSelectedRegDate(data.selectedRegDate));
    dispatch(setSelectedCode(data.selectedCode));
    dispatch(setUserSelectedType(data.userSelectedType));
    dispatch(setCompanyCode(data.companyCode));
    dispatch(setModelCode(data.modelCode));
    dispatch(setFuelCode(data.fuelCode));
    dispatch(setSubModelCode(data.subModelCode));
    dispatch(setRtoCode(data.rtoCode));
    dispatch(setCcCode(data.ccCode));
    dispatch(setVechicalCode(data.vechicalCode));
    dispatch(setHasPrevPolicyExpired(data.hasPrevPolicyExpired));
    dispatch(setHasExpiredAfterDate(data.hasExpiredAfterDate));
    dispatch(setHasMadePreviousClaim(data.hasMadePreviousClaim));

  } else {
    dispatch(setSelectedCompany(""));
    dispatch(setSelectedModel(""));
    dispatch(setSelectedFuel(""));
    dispatch(setSelectedSubModel(""));
    dispatch(setSelectedCC(""));
    dispatch(setSendingRto(""));
    dispatch(setSelectedYear(""));
    dispatch(setSelectedRegDate(""));
    dispatch(setSelectedCode(""));
    dispatch(setUserSelectedType(""));
    dispatch(setCompanyCode(""));
    dispatch(setModelCode(""));
    dispatch(setFuelCode(""));
    dispatch(setSubModelCode(""));
    dispatch(setRtoCode(""));
    dispatch(setCcCode(""));
    dispatch(setVechicalCode(""));
    dispatch(setHasPrevPolicyExpired(null));
    dispatch(setHasExpiredAfterDate(null));
    dispatch(setHasMadePreviousClaim(null));
  }
};
