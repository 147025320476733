import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../reducers/store/store";
import {
  setCurrentStep,
  setSelectedCode,
  setRtoCode,
  setSelectedYear,
  setSendingRto,
  setSelectedRegDate,
} from "../../../reducers/slices/MotorSlice";
import CommonSearchDropdown from "../../CommonDesignForSelection/CommonSearchDropdown";

interface RegistrationSelectionProps {
  rto: any[];
  years: string;
}

const RegistrationSelection: React.FC<RegistrationSelectionProps> = ({
  years,
  rto,
}) => {
  const dispatch = useDispatch();
  const { selectedCode, userSelectedType, rtoCode, selectedRegDate } = useSelector(
    (state: RootState) => state.motorDetails
  );
  const { whichTab } = useSelector(
    (state: RootState) => state.commonPremiumData
  );

  const handleRegCode = async (name: string, code: string) => {
    try {
      const formattedName = name.replace(/-/, "").replace(/ (.+)$/, "-$1");
      const selectedrto = rto.find(
        (rto) => rto.name === name && rto.code === code
      );

      if (selectedrto) {
        const { rtoCode } = selectedrto;
        dispatch(setRtoCode(code));
        dispatch(setSelectedCode(rtoCode));
        dispatch(setSendingRto(formattedName));
      }
      if (userSelectedType === "New") {
        const formattedDate = new Date().toISOString().split("T")[0];  
        // Get the year and convert it to a string
        const year = new Date().getFullYear().toString();
    
        // Dispatch the formatted date (without time)
        dispatch(setSelectedRegDate(formattedDate));
        
        // Dispatch the year as a string
        dispatch(setSelectedYear(year));
        dispatch(setCurrentStep(5));
      } 
    } catch (error) {
      console.error("Error:", error);
    }
  };


  const handleRegDate = async (Datee: Date) => {
    console.log("dateeeeee", Datee);
  
    try {
      // Format the date to a string (YYYY-MM-DD) by removing the time
      const formattedDate = new Date(Datee).toISOString().split("T")[0];
  
      // Get the year and convert it to a string
      const year = new Date(Datee).getFullYear().toString();
  
      // Dispatch the formatted date (without time)
      dispatch(setSelectedRegDate(formattedDate));
      
      // Dispatch the year as a string
      dispatch(setSelectedYear(year));
  
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleNextStepClick = async () => {
   
    try {
      if (selectedCode !== "" && rtoCode !== "" && selectedRegDate !== "" ) {
        dispatch(setCurrentStep(5));
      }
  
    } catch (error) {
      console.error("Error:", error);
    }
  };
  

  const currentYear = new Date().getFullYear();
  const maxDate = `${currentYear - 1}-12-31`;

  // Calculate the min date from the years prop
  const minDate = `${years}-01-01`;

  return (
    <>
      {userSelectedType === "DontKnow" && (
        <CommonSearchDropdown
          heading={`Where is your ${
            whichTab.toLowerCase().includes("car") ? "car" : "bike"
          } registered?`}
          options={rto}
          selectedOption={rtoCode}
          handleSelect={handleRegCode}
          height="h-[400px]"
          thirdHeading="Which date was it registered?"
          thirdSelectedDate={selectedRegDate}
          thirdMax={maxDate}
          thirdMin={minDate}        
          handleathird={handleRegDate}
          handleNextStepClick={handleNextStepClick}
        />
      )} 
      {userSelectedType === "New" && (
        <CommonSearchDropdown
          heading={`Where is your ${
            whichTab.toLowerCase().includes("car") ? "car" : "bike"
          } registered?`}
          options={rto}
          selectedOption={rtoCode}
          handleSelect={handleRegCode}
          height="h-[400px]"
        />
      )}
    </>
  );
};

export default RegistrationSelection;
