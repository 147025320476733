import React from "react";
import Button1 from "../Buttons/Button1";
import Button4 from "../Buttons/Button4";
import Button2 from "../Buttons/Button2";
import Button3 from "../Buttons/Button3/Button3";
import { useSelector } from "react-redux";
import { RootState } from "../../../reducers/store/store";

interface ButtonHeaderProps {
  headerData: any;
  isCarBike: boolean;
  isDontKnow: boolean;
  isNew: boolean;
  prevCompreh: boolean;
  prevTP: boolean;
  newTP: boolean;
  whichTab: string;
  mainCardData: any;
  updateCombinedData: (data: any) => void;
}

const ButtonHeader: React.FC<ButtonHeaderProps> = ({
  headerData,
  isCarBike,
  isDontKnow,
  isNew,
  prevCompreh,
  prevTP,
  newTP,
  whichTab,
  mainCardData,
  updateCombinedData,
}) => {
  const loading = useSelector(
    (state: RootState) => state.commonPremiumData.loadingStates["premium"] || false
  );
  const hasMainCardData = mainCardData.length > 0;
  return (
    <div className="w-full flex gap-7 flex-wrap justify-evenly">
      {isCarBike &&
        (headerData.userSelectedType
          ? isDontKnow && headerData.button1
          : headerData.button1) &&
        Object.keys(headerData.button1).length !== 0 && (
          <Button1
            keys={headerData.button1}
            updateCombinedData={updateCombinedData}
            prevCompreh={prevCompreh}
            prevTP={prevTP}
            previousNcb={headerData.labelValue6}
            currentNcb={headerData.labelValue7}
            button2Keys={headerData.button2}
            button3Keys={headerData.button3}
            loadingstate={loading && !hasMainCardData}
          />
        )}
      {isCarBike &&
        isNew &&
        headerData.button4 &&
        Object.keys(headerData.button4).length !== 0 && (
          <Button4 keys={headerData.button4} />
        )}
      {isCarBike &&
        !prevTP &&
        !newTP &&
        headerData.button2 &&
        Object.keys(headerData.button2).length !== 0 && (
          <Button2
            keys={headerData.button2}
            updateCombinedData={updateCombinedData}
            mainCardData={mainCardData}
          />
        )}
      {headerData.button3 && Object.keys(headerData.button3).length !== 0 && (
        <Button3
          keys={headerData.button3}
          updateCombinedData={updateCombinedData}
          whichTab={whichTab}
          loadingstate={loading && !hasMainCardData}
        />
      )}
    </div>
  );
};

export default ButtonHeader;
