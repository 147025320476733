import { Popover, Transition } from "@headlessui/react";
import { FC, Fragment } from "react";
import { relative } from "../styleComponents/headerStyles/searchModalStyle";
import { notifications, notificationsState, notifyDropdownContent, notifyDropdownContentSection, notifyDropdownHeading, notifyDropdownPopover, notifyDropdownPopoverPanel, notifyDropdownPopoverPanelDiv, notifyDropdownPopoverPanelDivInner, textOpacity } from "../styleComponents/headerStyles/notifyDropdownStyle";

interface Props {
  className?: string;
}

const NotifyDropdown: FC<Props> = ({ className = "hidden sm:block" }) => {
  return (
    <div className={className}>
      <Popover className={relative}>
        {({ open }) => (
          <>
            <Popover.Button
              className={`${open ? "" : textOpacity} ${notifyDropdownPopover}`}
            >
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path
                  d="M12 6.43994V9.76994"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                />
                <path
                  d="M12.02 2C8.34002 2 5.36002 4.98 5.36002 8.66V10.76C5.36002 11.44 5.08002 12.46 4.73002 13.04L3.46002 15.16C2.68002 16.47 3.22002 17.93 4.66002 18.41C9.44002 20 14.61 20 19.39 18.41C20.74 17.96 21.32 16.38 20.59 15.16L19.32 13.04C18.97 12.46 18.69 11.43 18.69 10.76V8.66C18.68 5 15.68 2 12.02 2Z"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                />
                <path
                  d="M15.33 18.8201C15.33 20.6501 13.83 22.1501 12 22.1501C11.09 22.1501 10.25 21.7701 9.65004 21.1701C9.05004 20.5701 8.67004 19.7301 8.67004 18.8201"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                />
              </svg>
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className={notifyDropdownPopoverPanel}>
                <div className={notifyDropdownPopoverPanelDiv}>
                  <div className={notifyDropdownPopoverPanelDivInner}>
                    <h3 className={notifyDropdownHeading}>Notifications</h3>
                      <div
                        className={notifyDropdownContent}
                      >
                        <div className={notifyDropdownContentSection}>
                          <p className={notifications}>
                            You have no notifications
                          </p>
                        </div>
                        <span className={notificationsState}></span>
                      </div>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  );
};

export default NotifyDropdown;
