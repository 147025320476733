export const cardLarge1MainDiv = "nc-CardLarge1 nc-CardLarge1--hasAnimation relative flex flex-col-reverse md:flex-row justify-end "
export const cardLarge1Section1 = "md:absolute z-10 md:left-0 md:top-1/2 md:-translate-y-1/2 w-full -mt-8 md:mt-0 px-3 sm:px-6 md:px-0 md:w-3/5 lg:w-1/2 xl:w-2/5"
export const cardLarge1CardLeft = "nc-CardLarge1__left p-4 sm:p-8 2xl:py-14 xl:py-12 md:px-10 bg-white/40 dark:bg-zinc-900/40 backdrop-filter backdrop-blur-lg shadow-lg dark:shadow-2xl rounded-3xl space-y-3 sm:space-y-5 "
export const cardLarge1CardTitle = "nc-card-title text-base sm:text-xl lg:text-2xl font-semibold "
export const cardLarge1CardTitleLink = "line-clamp-2"
export const cardLarge1MoveBtnDiv = "p-4 sm:pt-8 sm:px-10"
export const cardLarge1MoveBtn = "w-11 h-11 text-xl"
export const cardLarge1CardRightSection = "w-full md:w-4/5 lg:w-2/3"
export const cardLarge1CardRight = "nc-CardLarge1__right block relative"
export const cardLarge1CardRightImg = "absolute inset-0 object-cover rounded-3xl"
export const cardLarge1CardRightImgProp = "aspect-w-16 aspect-h-12 sm:aspect-h-9 md:aspect-h-14 lg:aspect-h-8 2xl:aspect-h-9 relative"