import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setPlanDetailPopup } from "../../reducers/slices/commonPremiumSlice";
import {
  setPremiumData,
  setResponseData,
} from "../../reducers/slices/MotorSlice";
import { useNavigate } from "react-router-dom";
import MotorPlanDetails from "./PlanDetails/MotorPlanDetails/MotorPlanDetails";
import { useSelector } from "react-redux";
import { RootState } from "../../reducers/store/store";
import PremiumButton from "../Button/PremiumButton";
import LinkLayout from "../Link/LinkLayout";
import {  writeToDB } from "../../utils/indexDBUtils";
import { useThemeMode } from "../../hooks/useThemeMode";

interface MainCardComponentProps {
  mainCardData: any[];
  headerData: any;
  sendingValues: any;
}

const MainCardComponent: React.FC<MainCardComponentProps> = ({
  mainCardData,
  headerData,
  sendingValues,
}) => {
  const { isDarkMode } = useThemeMode();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectedCardData, setSelectedCardData] = useState<any>(null);
  const loading = useSelector(
    (state: RootState) =>
      state.commonPremiumData.loadingStates["premium"] || false
  );

  const [isLoadingExtended, setIsLoadingExtended] = useState<boolean>(true);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsLoadingExtended(false);
    }, 2000); // Adjust this delay as needed

    return () => clearTimeout(timeout);
  }, [loading]);

  const isObject = (value: any) =>
    typeof value === "object" && value !== null && !Array.isArray(value);

  const handleBuyNow = async (cardData: any) => {
    dispatch(setPremiumData(sendingValues));
    dispatch(setResponseData(cardData));
    // const variantData = await readFromDB("motor-variant-corrected");
    // const CompanyName = variantData.companyName;
    // if (CompanyName !== cardData.CompanyName) {
    const VerifyDetails = {
      correctedVariant: "",
      verified: false,
      companyName: "",
    };
    await writeToDB("motor-variant-corrected", VerifyDetails);
    await writeToDB("completed-steps", 0);
    await writeToDB("KYCStatus", false);
    await writeToDB("mainCardData-proposal", []);
    await writeToDB("motor-proposal-CommunicationAddress", {});
    await writeToDB("motor-proposal-FormState", {});
    await writeToDB("motor-proposal-NomineeDetails", {});
    await writeToDB("motor-proposal-OwnerDetails", {});
    await writeToDB("motor-proposal-PreviousInsurerData", {});
    await writeToDB("KycVerifiedData", {});
    await writeToDB("motor-proposal-VehicleDetailsData", {});
    await writeToDB("motor-proposal-kycDetailsData", {});
    await writeToDB("vehicle-documentID", "");
    await writeToDB("policyRef", "");

    // }
    await writeToDB("responseObjectForProposal", {
      premiumData: sendingValues,
      responseData: cardData,
    });
    navigate("/proposal");
  };

  const handlePlanDetails = (cardData: any) => {
    setSelectedCardData(cardData);
    dispatch(setPlanDetailPopup(true));
  };

  const hasObjectValue = (cardData: any) =>
    Object.values(cardData).some(isObject);

  // const animationStyle = {
  //   animation: "colorMove 5s infinite",
  //   backgroundSize: "200% 200%",
  //   backgroundPosition: "top left",
  // };

  //   const keyframes = `
  //   @keyframes colorMove {
  //     0% { background-color: #d9d4d4; } /* Silver */
  //     25% { background-color: #d6d4d4; } /* Light Gray */
  //     50% { background-color: #A9A9A9; } /* Dark Gray */
  //     75% { background-color: #B0C4DE; } /* Light Steel Blue */
  //     100% { background-color: #C0C0C0; } /* Back to Silver */
  //   }
  // `;

  // Append keyframes to the document's style sheet
  // const styleSheet = document.styleSheets[0];
  // styleSheet.insertRule(keyframes, styleSheet.cssRules.length);

  return (
    <>
      {(loading || isLoadingExtended) && mainCardData.length <= 0 && (
        <div className="flex py-7 mt-5 items-center justify-center w-full h-full">
          <div className="loader"></div>
        </div>
      )}
      {/*===================== marked code ======================= */}
      {loading &&
        mainCardData.length === 1 &&
        (!mainCardData[0]?.TotalPremium ||
          !mainCardData[0]?.NetPremium ||
          hasObjectValue(mainCardData[0])) && (
          <div className="grid mx-5 sm:grid-cols-2 max-sm:gap-7 gap-6 md:gap-8 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-4 ">
            <div
              className={` ${
                isDarkMode
                  ? "loader-card-component-dark"
                  : "loader-card-component"
              } shadow-md dark:shadow-slate-950/40 shadow-zinc-400/50 dark:bg-zinc-500/10`}
            >
              <div className="first-rectangle dark:bg-my-custom-color-dark"></div>
              <div className="second-rectangle dark:bg-my-custom-color-dark"></div>
              <div className="third-rectangle dark:bg-my-custom-color-dark"></div>
            </div>
          </div>
        )}
      {/*==================== marked code  ======================= */}
      {mainCardData.length > 1 ||
      (mainCardData.length === 1 &&
        mainCardData[0].TotalPremium &&
        mainCardData[0].NetPremium &&
        !hasObjectValue(mainCardData[0])) ? (
        <div className="grid mx-5 sm:grid-cols-2 max-sm:gap-7 gap-6 md:gap-8 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-4 ">
          {mainCardData?.map(
            (CardData, index) =>
              CardData &&
              !hasObjectValue(CardData) && // Skip cards with object values
              CardData.NetPremium &&
              CardData.TotalPremium && (
                <div
                  key={index}
                  className={`nc-Card7 relative flex flex-col group rounded-3xl overflow-hidden z-0 items-center justify-center `}
                >
                  <div
                    className={`flex items-center justify-center relative py-2 px-2 w-[250px] h-[310px] max-sm:w-[250px] max-sm:h-[330px] `}
                  >
                    <div className=" w-full h-full rounded-3xl bg-white dark:bg-zinc-900/60 shadow-md dark:shadow-slate-950/40 shadow-zinc-400/50">
                      <div className="p-5 flex flex-col items-center justify-center">
                        <div className="shadow-md shadow-gray-200 dark:shadow-zinc-900/40">
                          <img
                            className="w-32 h-12 max-sm:w-36 max-sm:h-14 rounded-md"
                            src={CardData.CompanyLogo}
                            alt=""
                          />
                        </div>
                        <div className="w-40 h-px bg-gray-300 dark:bg-zinc-700 my-4"></div>
                        {CardData.IDV && CardData.IDV !== " " && (
                          <div className="-ml-1 flex items-start p-2 text-xs gap-1 dark:text-gray-300/90">
                            Cover Value:
                            <span className="font-[500] text-xs">
                              &#8377; {CardData.IDV}
                            </span>
                          </div>
                        )}
                        {CardData.Tenure && CardData.Tenure !== " " && (
                          <div className="-ml-1 -mt-2  flex items-start p-2 text-xs gap-1 dark:text-gray-300/90">
                            Tenure:
                            <span className="font-[500] text-xs">
                              {CardData.Tenure} year
                            </span>
                          </div>
                        )}
                        <div className="w-full flex flex-col mt-5 items-center  justify-center">
                          <div className=" w-full max-sm:w-11/12 item-center  justify-center flex flex-col">
                            {CardData.NetPremium &&
                              CardData.NetPremium !== " " && (
                                <PremiumButton
                                  title="Buy Now"
                                  label={`₹ ${CardData.NetPremium}`}
                                  onClick={() => handleBuyNow(CardData)}
                                  className1="flex w-full flex-col items-center cursor-pointer justify-center border-2 border-primary-700/80 dark:border-zinc-500 hover:bg-gradient-to-t from-primary-50/80  to-white dark:from-zinc-950/60 dark:to-zinc-950/10 text-gray-800 dark:text-gray-50  py-3 px-4 rounded-xl transition-all duration-300"
                                  className2=" cursor-pointer absolute -top-2 left-3 flex items-center justify-center px-0.5 rounded-2xl  bg-white text-primary-700 dark:bg-[#121214] dark:text-zinc-300 text-xs font-[500] z-10"
                                  className3="text-xl max-sm:text-lg font-[500] text-primary-900 dark:text-white"
                                />
                              )}
                            <div className=" flex justify-center items-center p-4 mt-1 text-xs ">
                              <LinkLayout
                                link="Plan Details"
                                handleClick={() => handlePlanDetails(CardData)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
          )}
          <MotorPlanDetails
            backendData={selectedCardData}
            sendingValues={sendingValues}
            hasExpiredAfterDate={headerData.hasExpiredAfterDate}
            hasPrevExpired={headerData.hasPrevExpired}
            newNcb={headerData.labelValue7}
            hasPrevClaim={headerData.hasPrevClaim}
            policyType={
              headerData.button1 ? headerData.button1.selectedLabel : ""
            }
          />

          {/*===================== marked code ======================= */}
          {loading && (
            <div
              className={` ${
                isDarkMode
                  ? "loader-card-component-dark"
                  : "loader-card-component"
              } shadow-md dark:shadow-slate-950/40 shadow-zinc-400/50 dark:bg-zinc-500/10`}
            >
              <div className="first-rectangle dark:bg-my-custom-color-dark"></div>
              <div className="second-rectangle dark:bg-my-custom-color-dark"></div>
              <div className="third-rectangle dark:bg-my-custom-color-dark"></div>
            </div>
          )}
          {/*==================== marked code  ======================= */}
        </div>
      ) : (
        !loading &&
        !isLoadingExtended &&
        (mainCardData.length === 0 ||
          (mainCardData.length === 1 && !mainCardData[0].TotalPremium) ||
          !mainCardData[0].NetPremium ||
          hasObjectValue(mainCardData[0])) && ( // Check if the only object has keys with object values
          <>
            <div className="flex flex-col mt-2 items-center justify-center w-full h-full">
              <div className="flex justify-center items-center opacity-60 dark:opacity-40">
                <img
                  src="https://i.postimg.cc/bvnXsnTv/not-found.png"
                  alt="No data"
                  className="w-3/5 max-w-xs"
                />
              </div>
              <div className="text-center text-zinc-800 dark:text-zinc-300 opacity-80">
                <h2 className="text-lg sm:text-xl font-light mb-2">
                  We're sorry, but we couldn't find any motor insurance policies
                  that match your criteria.
                </h2>
                <p className="text-xs sm:text-sm mb-4">
                  If you are experiencing network issues, please check your
                  connection and try again.
                </p>
              </div>
            </div>
          </>
        )
      )}
    </>
  );
};

export default MainCardComponent;
