export const navigationItemMain = "menu-item flex-shrink-0"
export const renderMainItemDiv = "h-20 flex-shrink-0 flex items-center"
export const renderMainItemLink = "inline-flex items-center text-sm lg:text-[15px] font-medium text-gray-700 dark:text-gray-300 py-2.5 px-4 xl:px-5 rounded-full hover:text-gray-900 hover:bg-zinc-100 dark:hover:bg-zinc-800 dark:hover:text-slate-200"
export const renderDropMenuNavlink = "flex items-center font-normal text-gray-600 dark:text-gray-400 py-2 px-4 rounded-md hover:text-gray-700 hover:bg-zinc-100 dark:hover:bg-zinc-800 dark:hover:text-gray-200"
export const renderDropMenuNavIcon = "ml-2 h-4 w-4 text-gray-600/50 text-4xl"
export const renderDropMenuNavChild = "menu-item menu-dropdown relative px-2"
export const renderDropMenuNavChildPopover = "sub-menu absolute z-10 w-56 left-full pl-2 top-0"
export const renderDropMenuNavChildPopoverUl = "rounded-xl shadow-lg ring-1 ring-black ring-opacity-5 dark:ring-white dark:ring-opacity-10 text-sm relative bg-white py-4 grid space-y-1 dark:bg-zinc-950 dark:text-gray-200"
export const renderDropdMenu = "menu-item menu-dropdown relative"
export const renderDropdMenuPopover = "sub-menu absolute transform z-10 w-56 top-full left-0"
export const renderDropdMenuPopoverUl = "rounded-2xl shadow-lg ring-1 ring-black ring-opacity-5 dark:ring-white dark:ring-opacity-10 text-sm relative bg-white  dark:bg-zinc-950 dark:text-gray-200 py-4 grid space-y-1"
