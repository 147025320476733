import React from "react";
import twFocusClass from "../../utils/twFocusClass";

export interface ButtonCloseProps {
  className?: string;
  onClick?: () => void;
}

const ButtonClose: React.FC<ButtonCloseProps> = ({
  className = "",
  onClick = () => {},
}) => {
  return (
    <button
      className={
        `w-8 h-8 flex items-center justify-center  rounded-full text-gray-700 dark:text-gray-300 hover:bg-zinc-100 dark:hover:bg-zinc-700 ${className} ` +
        twFocusClass()
      }
      onClick={onClick}
    >
      <span className="sr-only">Close</span>
      <span className="text-zinc-600/70 text-3xl dark:text-gray-400">×</span>
    </button>
  );
};

export default ButtonClose;
