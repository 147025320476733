// export const BajajPayment = () => {
export const BajajPayment = (proposalData:any) => {
  const userId = "policy4usmotor@general.bajajallianz.co.in";
// const transactionId = "1415823362";
const transactionId = proposalData[0].QuoteID;
console.log("userId",userId);
console.log("transactionId",transactionId);
const BJAURL = `https://webservicesint.bajajallianz.com/Insurance/WS/new_cc_payment.jsp?requestId=${transactionId}&Username=${userId}&sourceName=WS_MOTOR`;
console.log("BJAURL",BJAURL);

  window.open(BJAURL, '_blank'); // Open URL in a new tab
}