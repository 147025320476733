import React from "react";
import { useDispatch, useSelector } from "react-redux";
import CommonOptionsGrid from "../../CommonDesignForSelection/CommonOptionsGrid";
import { RootState } from "../../../reducers/store/store";
import {
  setCompanyCode,
  setSelectedCompany,
  setViewAllBrands,
  setCurrentStep,
  setSelectedModel,
  setModelCode,
  setSelectedFuel,
  setFuelCode,
  setSelectedSubModel,
  setSubModelCode,
  setSelectedCode,
  setRtoCode,
  setSelectedYear,
  setSelectedRegDate,
  setVechicalCode,
  setSelectedCC,
  setCcCode,
  setSendingRto,
} from "../../../reducers/slices/MotorSlice";
import { fetchMotors } from "../../../data/Premium/MotorData/api";

interface BrandSelectionProps {
  collectionName: string;
  brands: any[];
  minimumMotorBrands: any[];
  setMinimumModels: React.Dispatch<React.SetStateAction<any[]>>;
  setMotorModels: React.Dispatch<React.SetStateAction<any[]>>;
}

const BrandSelection: React.FC<BrandSelectionProps> = ({
  collectionName,
  brands,
  minimumMotorBrands,
  setMinimumModels,
  setMotorModels,
}) => {
  const dispatch = useDispatch();
  const { companyCode, viewAllBrands } = useSelector(
    (state: RootState) => state.motorDetails
  );
  const { whichTab } = useSelector(
    (state: RootState) => state.commonPremiumData
  );

  console.log("brand", minimumMotorBrands);
  
  const handleBrandClick = async (code: string, name: string) => {
    try {
      const uppercasedName = name.toUpperCase();

      if (code !== companyCode) {
        dispatch(setSelectedCompany(uppercasedName));
        dispatch(setCompanyCode(code));
        dispatch(setSelectedModel(""));
        dispatch(setModelCode(""));
        dispatch(setSelectedFuel(""));
        dispatch(setFuelCode(""));
        dispatch(setSelectedSubModel(""));
        dispatch(setSubModelCode(""));
        dispatch(setSelectedCC(""));
        dispatch(setCcCode(""));
        dispatch(setVechicalCode(""));
        dispatch(setSelectedCode(""));
        dispatch(setRtoCode(""));
        dispatch(setSendingRto(""));
        dispatch(setSelectedYear(""));
        dispatch(setSelectedRegDate(""));
        dispatch(setCurrentStep(1));
        setMinimumModels([]);
        setMotorModels([]);
        const data = await fetchMotors(collectionName, code);
        const firstTwelveModels = data.slice(0, 12);
        setMinimumModels(firstTwelveModels);
        setMotorModels(data);
      } else {
        dispatch(setSelectedCompany(uppercasedName));
        dispatch(setCompanyCode(code));
        dispatch(setCurrentStep(1));
        setMinimumModels([]);
        setMotorModels([]);
        const data = await fetchMotors(collectionName, code);
        const firstTwelveModels = data.slice(0, 12);
        setMinimumModels(firstTwelveModels);
        setMotorModels(data);
      }
    } catch (error) {
      console.error("Error ", error);
    }
  };

  const handleviewBrands = () => {
    dispatch(setViewAllBrands(true));
  };
  const handleCloseViewBrands = () => {
    dispatch(setViewAllBrands(false));
  };

  return (
    <>
      {!viewAllBrands ? (
        <CommonOptionsGrid
          heading={`What is your ${
            whichTab.toLowerCase().includes("car") ? "car's" : "bike's"
          } brand?`}
          subheading="Top Brands"
          gridItems={minimumMotorBrands}
          buttonText="View All Brands"
          handleClick={handleBrandClick}
          selectedOption={companyCode}
          handleButtonClick={handleviewBrands}
        />
      ) : (
        <CommonOptionsGrid
          heading={`What is your ${
            whichTab.toLowerCase().includes("car") ? "car's" : "bike's"
          } brand?`}
          searchPlaceholder="Search..."
          linkText="Show less"
          gridItems={brands}
          handleClick={handleBrandClick}
          handleLinkClick={handleCloseViewBrands}
          selectedOption={companyCode}
        />
      )}
    </>
  );
};

export default BrandSelection;
