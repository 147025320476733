import { FC, Fragment, useState } from "react";
import { Route } from "../../routes/types";
import Link from "../Link";
import { Popover, Transition } from "@headlessui/react";
import {
  navigationItemMain,
  renderDropMenuNavChild,
  renderDropMenuNavChildPopover,
  renderDropMenuNavChildPopoverUl,
  renderDropMenuNavIcon,
  renderDropMenuNavlink,
  renderDropdMenu,
  renderDropdMenuPopover,
  renderDropdMenuPopoverUl,
  renderMainItemDiv,
  renderMainItemLink,
} from "../styleComponents/navigationStyles/navigationItemStyle";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setIsOpenPopup, setWhichTab } from "../../reducers/slices/commonPremiumSlice";

export interface NavItemType {
  id: string;
  name: string;
  href: Route;
  targetBlank?: boolean;
  children?: NavItemType[];
  type?: "dropdown" | "megaMenu" | "none";
  isNew?: boolean;
}

export interface NavigationItemProps {
  menuItem: NavItemType;
}

const NavigationItem: FC<NavigationItemProps> = ({ menuItem }) => {
  const [menuCurrentHovers, setMenuCurrentHovers] = useState<string[]>([]);

  const onMouseEnterMenu = (id: string) => {
    setMenuCurrentHovers((state) => [...state, id]);
  };

  const onMouseLeaveMenu = (id: string) => {
    setMenuCurrentHovers((state) => {
      return state.filter((item, index) => {
        return item !== id && index < state.indexOf(id);
      });
    });
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleClick = async (href: string, name: string) => {
    if (href === "") {
      console.log("Setting whichTab to:", name); // Debugging log
      dispatch(setWhichTab(name));
      dispatch(setIsOpenPopup(true));
    }
  };

  // ===================== MENU DROPDOW =====================
  const renderDropdownMenu = (menuDropdown: NavItemType) => {
    const isHover = menuCurrentHovers.includes(menuDropdown.id);
    return (
      <Popover
        as="li"
        className={renderDropdMenu}
        onMouseEnter={() => onMouseEnterMenu(menuDropdown.id)}
        onMouseLeave={() => onMouseLeaveMenu(menuDropdown.id)}
      >
        {() => (
          <>
            <Popover.Button as={Fragment}>
              {renderMainItem(menuDropdown)}
            </Popover.Button>
            <Transition
              as={Fragment}
              show={isHover}
              enter="transition ease-out duration-150"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel static className={renderDropdMenuPopover}>
                <ul className={renderDropdMenuPopoverUl}>
                  {menuDropdown.children?.map((i) => {
                    if (i.type) {
                      return renderDropdownMenuNavlinkHasChild(i);
                    } else {
                      return (
                        <li
                          key={i.id}
                          className={`px-2 ${i.isNew ? "menuIsNew" : ""}`}
                        >
                          {renderDropdownMenuNavlink(i)}
                        </li>
                      );
                    }
                  })}
                </ul>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    );
  };

  const renderDropdownMenuNavlinkHasChild = (item: NavItemType) => {
    const isHover = menuCurrentHovers.includes(item.id);
    return (
      <Popover
        as="li"
        key={item.id}
        className={renderDropMenuNavChild}
        onMouseEnter={() => onMouseEnterMenu(item.id)}
        onMouseLeave={() => onMouseLeaveMenu(item.id)}
      >
        {() => (
          <>
            <Popover.Button as={"p"}>
              {renderDropdownMenuNavlink(item)}
            </Popover.Button>
            <Transition
              as={Fragment}
              show={isHover}
              enter="transition ease-out duration-150"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel static className={renderDropMenuNavChildPopover}>
                <ul className={renderDropMenuNavChildPopoverUl}>
                  {item.children?.map((i) => {
                    if (i.type) {
                      return renderDropdownMenuNavlinkHasChild(i);
                    } else {
                      return (
                        <li key={i.id} className="px-2">
                          {renderDropdownMenuNavlink(i)}
                        </li>
                      );
                    }
                  })}
                </ul>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    );
  };

  const renderDropdownMenuNavlink = (item: NavItemType) => {
    const modifiedName = item.name.includes("Insurance")
      ? item.name.replace(" Insurance", "")
      : item.name;

    return (
      <Link
        className={renderDropMenuNavlink}
        href={{
          pathname: item.href || "",
        }}
        onClick={() => {
          navigate("/");
          handleClick(item.href, modifiedName);
        }}
      >
        {item.name}
        {item.type && (
          <svg
          width="20px"
          height="20px"
          viewBox="0 0 48 48"
          xmlns="http://www.w3.org/2000/svg"
        >
          <title>arrowhead-down</title>
          <g id="Layer_2" data-name="Layer 2">
            <g id="invisible_box" data-name="invisible box">
              <rect width="48" height="48" fill="none" />
            </g>
            <g id="icons_Q2" data-name="icons Q2">
              <path
                d="M24,27.2,13.4,16.6a1.9,1.9,0,0,0-3,.2,2.1,2.1,0,0,0,.2,2.7l12,11.9a1.9,1.9,0,0,0,2.8,0l12-11.9a2.1,2.1,0,0,0,.2-2.7,1.9,1.9,0,0,0-3-.2Z"
                fill="#808080"
              />
            </g>
          </g>
        </svg>
        )}
      </Link>
    );
  };
  // ===================== MENU MAIN MENU =====================
  const renderMainItem = (item: NavItemType) => {
    return (
      <div className={renderMainItemDiv}>
        <Link
          className={renderMainItemLink}
          href={{
            pathname: item.href || undefined,
          }}
        >
          {item.name}
          {item.type && (
            <span className={renderDropMenuNavIcon}>
              <svg
                width="20px"
                height="20px"
                viewBox="0 0 48 48"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title>arrowhead-down</title>
                <g id="Layer_2" data-name="Layer 2">
                  <g id="invisible_box" data-name="invisible box">
                    <rect width="48" height="48" fill="none" />
                  </g>
                  <g id="icons_Q2" data-name="icons Q2">
                    <path
                      d="M24,27.2,13.4,16.6a1.9,1.9,0,0,0-3,.2,2.1,2.1,0,0,0,.2,2.7l12,11.9a1.9,1.9,0,0,0,2.8,0l12-11.9a2.1,2.1,0,0,0,.2-2.7,1.9,1.9,0,0,0-3-.2Z"
                      fill="#808080"
                    />
                  </g>
                </g>
              </svg>
            </span>
          )}
        </Link>
      </div>
    );
  };

  switch (menuItem.type) {
    case "dropdown":
      return renderDropdownMenu(menuItem);
    default:
      return <li className={navigationItemMain}>{renderMainItem(menuItem)}</li>;
  }
};

export default NavigationItem;
