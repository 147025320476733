  // store.ts
  import { configureStore } from '@reduxjs/toolkit';
  import motorReducer from '../slices/MotorSlice';
  import commonPremiumReducer from '../slices/commonPremiumSlice';
  import formReducer from '../slices/carBikeProposalSlice';
  import dataReducer from '../slices/commonProposalSlice';
  const store = configureStore({
    reducer: {
      motorDetails:motorReducer,
      commonPremiumData:commonPremiumReducer,
      form: formReducer,
      data: dataReducer,
    },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        immutableCheck: process.env.NODE_ENV !== 'production' ? false : true, // Disable in development
      }),
  });

  export type RootState = ReturnType<typeof store.getState>;
  

  export default store;
